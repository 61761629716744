import * as moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

import styles from './signatureModal.module.scss';
import { ReactComponent as PenSvg } from '../../assets/svgs/Pen.svg';
import { signatureTypes } from '../../constant';
import { getCurrentIp } from '../../services/signature';
import Button from '../button';
import SignaturePad from '../signature-pad';
import TabsContainer from '../tabs-container';
import TypeSignature from '../type-signature';
import UploadSignature from '../upload-signature';

function SignatureModal({ userRole, onClose, showModal, setSignature, signatureFileName }) {
  const { t } = useTranslation('lang');

  const [activeTab, setActiveTab] = useState('type');
  const [currentIP, setCurrentIP] = useState('');
  const [typedSignature, setTypedSignature] = useState('');
  const [signaturePad, setSignaturePad] = useState(null);
  const [signatureFiles, setSignatureFiles] = useState([]);

  const saveSignature = async (signature, signatureType) => {
    const formData = new FormData();
    if (signatureType === signatureTypes.TYPE) {
      formData.append('signature', signature);
    } else if (signatureType === signatureTypes.DRAW || signatureType === signatureTypes.UPLOAD) {
      formData.append(signatureFileName, signature);
    }
    formData.append('signatureType', signatureType);
    await setSignature(formData);
    onClose(true);
  };

  const handleFileSignature = (signature, type) => {
    try {
      saveSignature(signature, type);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onSaveDrawSignature = async (imageURL) => {
    try {
      const image = await fetch(imageURL);
      const imageFile = await image.blob();
      handleFileSignature(imageFile, signatureTypes.DRAW);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onSaveTextSignature = () => {
    saveSignature(typedSignature, signatureTypes.TYPE);
  };

  const onSaveSignatureFile = () => {
    if (signatureFiles.length > 0) handleFileSignature(signatureFiles[0], signatureTypes.UPLOAD);
  };

  const fetchCurrentIP = async () => {
    try {
      const {
        data: {
          data: { ip },
        },
      } = await getCurrentIp();
      setCurrentIP(ip);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchCurrentIP();
  }, []);

  const tabs = [
    {
      key: 'type',
      title: 'components.signature.tabs.type',
      onSave: onSaveTextSignature,
      onClear: () => setTypedSignature(''),
      component: (
        <TypeSignature typedSignature={typedSignature} setTypedSignature={setTypedSignature} />
      ),
    },
    {
      key: 'draw',
      title: 'components.signature.tabs.draw',
      onSave: () => {
        const url = signaturePad?.toDataURL('png');
        onSaveDrawSignature(url);
      },
      onClear: () => signaturePad.clear(),
      component: <SignaturePad setSignaturePad={setSignaturePad} />,
    },
    {
      key: 'upload',
      title: 'components.signature.tabs.upload',
      onSave: onSaveSignatureFile,
      onClear: () => setSignatureFiles([]),
      component: (
        <UploadSignature signatureFiles={signatureFiles} setSignatureFiles={setSignatureFiles} />
      ),
    },
  ];

  const onSave = () => {
    tabs.find((tab) => tab.key === activeTab).onSave();
  };

  const onClear = () => {
    tabs.find((tab) => tab.key === activeTab).onClear();
  };

  return (
    <div className={`${styles.digitalSignatureContainer}`}>
      <Modal
        show={showModal}
        onHide={onClose}
        centered
        keyboard={false}
        dialogClassName={`${styles.digitalSignatureContainer}`}
        className={`${styles.signatureModal}`}
        backdropClassName={`${styles.signatureModalBackdrop}`}
      >
        <Modal.Header
          className={`d-flex align-items-center justify-content-between ${styles.digitalSignatureHeader}`}
        >
          <div className={`d-flex align-items-center  ${styles.customerNameContainer}`}>
            <div className={`${styles.icon}`}>
              <PenSvg />
            </div>
            <div>
              <p>{t(userRole.label)}</p>
            </div>
          </div>
          <div>
            <Button className={`projectBtn ${styles.closeBtn}`} onClick={onClose}>
              {t('components.button.close')}
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="signatureTabContainer">
            <TabsContainer activeTab={activeTab} tabs={tabs} setActiveTab={setActiveTab} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            className={`d-flex align-items-center justify-content-between ${styles.digitalSignatureContent}`}
          >
            <div>
              <div>{t('components.signature.digitally_signed')}:</div>
              <div>IP: {currentIP}</div>
              <div>
                {t('components.signature.date_time')}: {moment().format('DD.MM.yyyy HH:mm')}
              </div>
            </div>
            <div className={`d-sm-flex align-items-sm-center ${styles.btnFlexContainer}`}>
              <div>
                <Button className="projectBtn grayBtn" onClick={onClear}>
                  {t('components.button.clear')}
                </Button>
              </div>
              {/* <div>
                <Button className="projectBtn grayBtn">undo</Button>
              </div> */}
              <div>
                <Button className="projectBtn greenBtn" onClick={onSave}>
                  {t('components.button.save')}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

SignatureModal.propTypes = {
  userRole: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  signatureFileName: PropTypes.string,
};

SignatureModal.defaultProps = {
  signatureFileName: 'signatureFile',
};
export default SignatureModal;
