import apiClient from './api-client';

export const getTechnicalInformation = (offerRequestId) =>
  apiClient.get(`/v1/offer-requests/${offerRequestId}/technical-information/`);

export const saveTechnicalInformation = (offerRequestId, body) =>
  apiClient.put(`/v1/offer-requests/${offerRequestId}/technical-information`, body);

export const saveOfferRequestAdditionalInfo = (offerRequestId, additionalInfo) =>
  apiClient.put(`/v1/offer-requests/${offerRequestId}/additional-information`, additionalInfo);
