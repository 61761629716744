import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import * as yup from 'yup';

import styles from './index.module.scss';
import TextControl from '../../../components/form-fields/text-control';
import Translate from '../../../components/translate';
import { getOriginalErrors } from '../../../utils/errorHandler';

function FirstNameInput({ register, error }) {
  return (
    <div>
      <TextControl
        label="components.user.first_name"
        placeholder="John"
        name="firstName"
        register={register}
      />
      {error && <div className={`${styles.errorLabel}`}>{error}</div>}
    </div>
  );
}

function LastNameInput({ register, error }) {
  return (
    <div>
      <TextControl
        label="components.user.last_name"
        placeholder="Doe"
        name="lastName"
        register={register}
      />
      {error && <div className={`${styles.errorLabel}`}>{error}</div>}
    </div>
  );
}

function EmailInput({ register, error }) {
  return (
    <div>
      <TextControl
        label="components.user.email"
        type="email"
        placeholder="johndoe@example.com"
        name="email"
        register={register}
      />
      {error && <div className={`${styles.errorLabel}`}>{error}</div>}
    </div>
  );
}

function PhoneInput({ register, error }) {
  return (
    <div>
      <TextControl
        label="components.user.phone"
        placeholder="+000XXXXXXX"
        name="phone"
        register={register}
      />
      {error && <div className={`${styles.errorLabel}`}>{error}</div>}
    </div>
  );
}

function AddressInput({ register, name, error }) {
  return (
    <div>
      <TextControl
        label="components.address.address"
        placeholder="22 Corona St. New Baltimore, MI 48047"
        name={name}
        register={register}
      />
      {error && <div className={`${styles.errorLabel}`}>{error}</div>}
    </div>
  );
}

function ZipCodeInput({ register, name, error }) {
  return (
    <div>
      <TextControl
        label="components.address.zip_code"
        placeholder="R45 5D5"
        name={name}
        register={register}
      />
      {error && <div className={`${styles.errorLabel}`}>{error}</div>}
    </div>
  );
}

function CityInput({ register, name, error }) {
  return (
    <div>
      <TextControl
        label="components.address.city"
        placeholder="Helsinki"
        name={name}
        register={register}
      />
      {error && <div className={`${styles.errorLabel}`}>{error}</div>}
    </div>
  );
}

function AddressControl({ register, addressInputName, zipCodeInputName, cityInputName, errors }) {
  return (
    <div className={`d-md-flex flex-md-wrap justify-content-between ${styles.addressColumn}`}>
      <AddressInput
        register={register}
        name={addressInputName}
        error={errors[addressInputName]?.message}
      />
      <ZipCodeInput
        register={register}
        name={zipCodeInputName}
        error={errors[zipCodeInputName]?.message}
      />
      <CityInput register={register} name={cityInputName} error={errors[cityInputName]?.message} />
    </div>
  );
}

function IsTargetInstallationAddressDifferent({ value, setValue }) {
  return (
    <label className="switch" htmlFor="is_the_address_of_the_installation_target_different">
      <Translate id="offer.request.is_the_address_of_the_installation_target_different" />
      <input
        className="switchInput"
        type="checkbox"
        checked={value}
        id="is_the_address_of_the_installation_target_different"
        onChange={(event) => setValue('isTargetInstallationAddressDifferent', event.target.checked)}
      />
      <span className="slider round">
        <Translate id="common.yes" />
      </span>
    </label>
  );
}

function CustomerInformation({
  customerDetails,
  onUpdateCustomerDetails,
  offerRequest,
  onUpdateOfferRequest,
  apiError,
}) {
  const customerSchema = yup.object({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string(),
    phone: yup.string(),
    address: yup.string(),
    zipCode: yup.string(),
    city: yup.string(),
    installationTargetAddress: yup.string(),
    installationTargetCity: yup.string(),
    installationTargetZipCode: yup.string(),
    isTargetInstallationAddressDifferent: yup.boolean().default(false),
  });

  const {
    register,
    formState: { errors },
    watch,
    reset,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    resolver: yupResolver(customerSchema),
  });

  watch(
    (
      {
        firstName,
        lastName,
        email,
        phone,
        address,
        zipCode,
        city,
        installationTargetAddress,
        installationTargetCity,
        installationTargetZipCode,
        isTargetInstallationAddressDifferent,
      },
      name
    ) => {
      onUpdateCustomerDetails((prev) => ({
        ...prev,
        firstName,
        lastName,
        email,
        phone,
        address,
        zipCode,
        city,
      }));
      onUpdateOfferRequest((prev) => ({
        ...prev,
        installationTargetAddress: isTargetInstallationAddressDifferent
          ? installationTargetAddress
          : address,
        installationTargetCity: isTargetInstallationAddressDifferent
          ? installationTargetCity
          : city,
        installationTargetZipCode: isTargetInstallationAddressDifferent
          ? installationTargetZipCode
          : zipCode,
        isTargetInstallationAddressDifferent,
      }));
      clearErrors(name.name);
    }
  );

  useEffect(() => {
    reset({
      ...customerDetails,
      ...offerRequest,
      firstName: customerDetails.firstName || '',
      lastName: customerDetails.lastName || '',
      email: customerDetails.email || '',
      phone: customerDetails.phone || '',
      address: customerDetails.address || '',
      zipCode: customerDetails.zipCode || '',
      city: customerDetails.city || '',
    });
  }, []);

  useEffect(() => {
    if (apiError) {
      const originalErrors = getOriginalErrors(apiError);
      originalErrors.forEach((error) => {
        if (error.field) {
          setError(error.field, { message: error.message });
        } else {
          toast.error(error.message);
        }
      });
    }
  }, [apiError]);

  return (
    <div className={`${styles.customerInformationContainer}`}>
      <div className={`${styles.headerContainer}`}>
        <h1 className={`${styles.headerTitle}`}>
          <Translate id="offer.request.customer_information" />
        </h1>
      </div>
      <form>
        <div
          className={`d-md-flex flex-md-wrap justify-content-between ${styles.contentContainer}`}
        >
          <FirstNameInput register={register} error={errors.firstName?.message} />
          <LastNameInput register={register} error={errors.lastName?.message} />
          <EmailInput register={register} error={errors.email?.message} />
          <PhoneInput register={register} error={errors.phone?.message} />
          <AddressControl
            register={register}
            addressInputName="address"
            zipCodeInputName="zipCode"
            cityInputName="city"
            errors={errors}
          />
        </div>
        <div className={`${styles.addressContainer}`}>
          <div className={`${styles.sliderBtnContainer}`}>
            <IsTargetInstallationAddressDifferent
              value={offerRequest.isTargetInstallationAddressDifferent}
              setValue={setValue}
            />
          </div>
          {offerRequest.isTargetInstallationAddressDifferent && (
            <div className={`${styles.addressContent}`}>
              <h2 className={`${styles.headerTitle}`}>
                <Translate id="offer.request.enter_the_address_of_the_installation_target" />
              </h2>
              <AddressControl
                register={register}
                addressInputName="installationTargetAddress"
                zipCodeInputName="installationTargetCity"
                cityInputName="installationTargetZipCode"
                errors={errors}
              />
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

FirstNameInput.propTypes = {
  register: PropTypes.func.isRequired,
  error: PropTypes.string,
};

FirstNameInput.defaultProps = {
  error: '',
};

LastNameInput.propTypes = {
  register: PropTypes.func.isRequired,
  error: PropTypes.string,
};

LastNameInput.defaultProps = {
  error: '',
};

EmailInput.propTypes = {
  register: PropTypes.func.isRequired,
  error: PropTypes.string,
};

EmailInput.defaultProps = {
  error: '',
};

PhoneInput.propTypes = {
  register: PropTypes.func.isRequired,
  error: PropTypes.string,
};

PhoneInput.defaultProps = {
  error: '',
};

AddressInput.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
};

AddressInput.defaultProps = {
  error: '',
};

ZipCodeInput.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
};

ZipCodeInput.defaultProps = {
  error: '',
};

CityInput.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
};

CityInput.defaultProps = {
  error: '',
};

AddressControl.propTypes = {
  register: PropTypes.func.isRequired,
  addressInputName: PropTypes.string.isRequired,
  zipCodeInputName: PropTypes.string.isRequired,
  cityInputName: PropTypes.string.isRequired,
  errors: PropTypes.instanceOf(Object),
};

AddressControl.defaultProps = {
  errors: {},
};

CustomerInformation.propTypes = {
  customerDetails: PropTypes.instanceOf(Object),
  onUpdateCustomerDetails: PropTypes.func,
  offerRequest: PropTypes.instanceOf(Object),
  onUpdateOfferRequest: PropTypes.func,
  apiError: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.instanceOf(Object)])
    .isRequired,
};

CustomerInformation.defaultProps = {
  customerDetails: {},
  onUpdateCustomerDetails: () => {},
  offerRequest: {},
  onUpdateOfferRequest: () => {},
};

IsTargetInstallationAddressDifferent.propTypes = {
  value: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default CustomerInformation;
