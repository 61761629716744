import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
// import CaptureImage from '../../../../components/capture-image';
import DropDownControl from '../../../../components/form-fields/drop-down-control';
import RadioControl from '../../../../components/form-fields/radio-control';
import TextControl from '../../../../components/form-fields/text-control';
import UploadFiles from '../../../../components/uploadFiles';
import {
  heatCirculationMethod,
  groundFloorStructureMethod,
  secondFloorStructureMethod,
} from '../../../../constant';

function HeatCirculationInput({ onChange, value }) {
  const { t } = useTranslation('lang');
  const heatCirculationOptions = Object.keys(heatCirculationMethod).map((key) => ({
    id: key,
    label: t(heatCirculationMethod[key]),
  }));
  return (
    <DropDownControl
      label="offer.technical_information.heat_circulation"
      options={heatCirculationOptions}
      selected={value}
      onChange={onChange}
      selectionKey="id"
    />
  );
}

function GroundFloorStructureInput({ onChange, value }) {
  const { t } = useTranslation('lang');
  const groundFloorStructureOptions = Object.keys(groundFloorStructureMethod).map((key) => ({
    id: key,
    label: t(groundFloorStructureMethod[key]),
  }));
  return (
    <DropDownControl
      label="offer.technical_information.ground_floor_structure"
      options={groundFloorStructureOptions}
      selected={value}
      onChange={onChange}
      selectionKey="id"
    />
  );
}

function SecondFloorStructureInput({ onChange, value }) {
  const { t } = useTranslation('lang');
  const secondFloorStructureOptions = Object.keys(secondFloorStructureMethod).map((key) => ({
    id: key,
    label: t(secondFloorStructureMethod[key]),
  }));
  return (
    <DropDownControl
      label="offer.technical_information.2nd_floor_structure"
      options={secondFloorStructureOptions}
      selected={value}
      onChange={onChange}
      selectionKey="id"
    />
  );
}

function ElectricityAvailableInput({ onChange, value }) {
  return (
    <TextControl
      label="offer.technical_information.electricity_available"
      onChange={onChange}
      value={value}
    />
  );
}

function DemolitionNeedsInput({ onChange, value }) {
  return (
    <TextControl
      label="offer.technical_information.demolition_needs"
      onChange={onChange}
      value={value}
    />
  );
}

function WaterWellOnPropertyInput({ value, onChange, distanceValue, distanceChange }) {
  return (
    <RadioControl
      label="offer.technical_information.water_well_on_the_property"
      noLabel="common.no"
      yesLabel="common.yes"
      distanceLabel="offer.technical_information.distance"
      name="waterWellOnProperty"
      value={value}
      onChange={onChange}
      distanceValue={distanceValue}
      distanceChange={distanceChange}
    />
  );
}

function SewerWellOnPropertyInput({ value, onChange, distanceValue, distanceChange }) {
  return (
    <RadioControl
      label="offer.technical_information.sewer_well_on_the_property"
      noLabel="common.no"
      yesLabel="common.yes"
      distanceLabel="offer.technical_information.distance"
      name="sewerWellOnProperty"
      value={value}
      onChange={onChange}
      distanceValue={distanceValue}
      distanceChange={distanceChange}
    />
  );
}

function UploadFilesArea({ files, setFiles }) {
  return (
    <div className={`${styles.fileUploadColumn}`}>
      <div className={`${styles.fileUploadMainContainer}`}>
        <div className={`${styles.fileUploadContainer}`}>
          <div>
            <UploadFiles files={files} setFiles={setFiles} multiple capture />
          </div>
        </div>
        {/* <p className={`${styles.fileUploadError}`}>{t('components.capture_image.error')}</p> */}
      </div>
    </div>
  );
}

function TechnicalInformation({
  technicalInformation,
  setTechnicalInformation,
  technicalInformationAttachments,
  setTechnicalInformationAttachments,
}) {
  const { t } = useTranslation('lang');

  const handleInputChange = (field, value) => {
    setTechnicalInformation((prevData) => ({ ...prevData, [field]: value }));
  };

  const setHeatCirculationInput = (value) => {
    handleInputChange('heatCirculation', value);
  };

  const setGroundFloorStructureInput = (value) => {
    handleInputChange('groundFloorStructure', value);
  };
  const setSecondFloorStructureInput = (value) => {
    handleInputChange('secondFloorStructure', value);
  };
  const setElectricityAvailableInput = (value) => {
    handleInputChange('electricityAvailable', value);
  };
  const setDemolitionNeedsInput = (value) => {
    handleInputChange('demolitionNeeds', value);
  };
  const setWaterWellOnPropertyInput = (value) => {
    handleInputChange('isWaterWellOnProperty', value === 'yes');
  };

  const setSeverOnPropertyInput = (value) => {
    handleInputChange('isSewerWellOnProperty', value === 'yes');
  };
  const setSeverWellDistance = (value) => {
    handleInputChange('sewerWellOnPropertyDistance', value);
  };
  const setWaterWellDistance = (value) => {
    handleInputChange('waterWellOnPropertyDistance', value);
  };

  return (
    <div className={`${styles.technicalInformationContainer}`}>
      <p className={`${styles.technicalTitle}`}>{t('offer.onsite_memo.technical_information')}</p>
      <div className={`d-md-flex flex-md-wrap ${styles.contentContainer}`}>
        <div className={`${styles.inputContainer}`}>
          <HeatCirculationInput
            onChange={setHeatCirculationInput}
            value={technicalInformation.heatCirculation}
          />
          <GroundFloorStructureInput
            onChange={setGroundFloorStructureInput}
            value={technicalInformation.groundFloorStructure}
          />
          <SecondFloorStructureInput
            onChange={setSecondFloorStructureInput}
            value={technicalInformation.secondFloorStructure}
          />
          <ElectricityAvailableInput
            onChange={(event) => setElectricityAvailableInput(event.target.value)}
            value={technicalInformation.electricityAvailable}
          />
          <DemolitionNeedsInput
            onChange={(event) => setDemolitionNeedsInput(event.target.value)}
            value={technicalInformation.demolitionNeeds}
          />
          <WaterWellOnPropertyInput
            value={technicalInformation.isWaterWellOnProperty}
            onChange={(event) => setWaterWellOnPropertyInput(event.target.value)}
            distanceValue={technicalInformation.waterWellOnPropertyDistance}
            distanceChange={setWaterWellDistance}
          />
          <SewerWellOnPropertyInput
            value={technicalInformation.isSewerWellOnProperty}
            onChange={(event) => setSeverOnPropertyInput(event.target.value)}
            distanceValue={technicalInformation.sewerWellOnPropertyDistance}
            distanceChange={setSeverWellDistance}
          />
        </div>
        <div className={`${styles.uploadContainer}`}>
          <p className={`${styles.technicalTitle}`}>
            {t('offer.technical_information.attachments')}
          </p>
          <UploadFilesArea
            files={technicalInformationAttachments}
            setFiles={setTechnicalInformationAttachments}
          />
        </div>
        <div className={`${styles.textAreaContainer}`}>
          <p className={`${styles.technicalTitle}`}>
            {t('offer.technical_information.additional_comments_for_technical_information')}
          </p>
          <textarea
            className={`inputField ${styles.textArea}`}
            cols="30"
            rows="10"
            value={technicalInformation.comment}
            onChange={(e) => handleInputChange('comment', e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

TechnicalInformation.propTypes = {
  technicalInformation: PropTypes.instanceOf(Object).isRequired,
  setTechnicalInformation: PropTypes.func.isRequired,
  technicalInformationAttachments: PropTypes.instanceOf(Array),
  setTechnicalInformationAttachments: PropTypes.func,
};

TechnicalInformation.defaultProps = {
  technicalInformationAttachments: [],
  setTechnicalInformationAttachments() {},
};

HeatCirculationInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
GroundFloorStructureInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
SecondFloorStructureInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

ElectricityAvailableInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
DemolitionNeedsInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

UploadFilesArea.propTypes = {
  files: PropTypes.instanceOf(Array).isRequired,
  setFiles: PropTypes.func.isRequired,
};

export default TechnicalInformation;
