import { companyUserTaskStatuses } from '../../../../constant';

export const companyUserTaskStatusTranslations = [
  {
    id: 1,
    label: 'company.user.task_listing.statuses.all',
    value: 'ALL',
  },
  {
    id: 2,
    label: 'company.user.task_listing.statuses.new',
    value: companyUserTaskStatuses.NEW,
  },
  {
    id: 3,
    label: 'company.user.task_listing.statuses.completed',
    value: companyUserTaskStatuses.COMPLETED,
  },
];
