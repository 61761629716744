import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import CalendarControl from '../../../../components/form-fields/calendar-control';
import TextControl from '../../../../components/form-fields/text-control';

function RequestedDrillingTimeInput({ value, onChange }) {
  return (
    <CalendarControl
      label="offer.onsite_memo.requested_drilling_time"
      selected={value}
      onChange={onChange}
    />
  );
}

function RequestedDeliveryTimeInput({ value, onChange }) {
  return (
    <CalendarControl
      label="offer.onsite_memo.requested_delivery_time"
      selected={value}
      onChange={onChange}
    />
  );
}

function RequestedInstallationTime({ value, onChange }) {
  return (
    <CalendarControl
      label="offer.onsite_memo.requested_installation_time"
      selected={value}
      onChange={onChange}
    />
  );
}

function ClientTypeInput({ value, onChange }) {
  return (
    <TextControl
      label="offer.onsite_memo.client_type"
      placeholder="Text field"
      value={value}
      onChange={onChange}
    />
  );
}

function AdditionalInformation({ additionalInformation, setAdditionalInformation }) {
  const { t } = useTranslation('lang');

  const changeAdditionalInformation = (value, fieldName) => {
    setAdditionalInformation({ ...additionalInformation, [fieldName]: value });
  };

  return (
    <div className={`${styles.additionalInformationContainer}`}>
      <div className={`d-md-flex ${styles.additionalInformationContent}`}>
        <div className={`${styles.inputContainer}`}>
          <p className={`${styles.technicalTitle}`}>
            {t('offer.onsite_memo.additional_information')}
          </p>
          <RequestedDrillingTimeInput
            value={additionalInformation?.requestedDrillingTime}
            onChange={(e) => changeAdditionalInformation(e, 'requestedDrillingTime')}
          />
          <RequestedDeliveryTimeInput
            value={additionalInformation?.requestedDeliveryTime}
            onChange={(e) => changeAdditionalInformation(e, 'requestedDeliveryTime')}
          />
          <RequestedInstallationTime
            value={additionalInformation?.requestedInstallationTime}
            onChange={(e) => changeAdditionalInformation(e, 'requestedInstallationTime')}
          />
          <ClientTypeInput
            value={additionalInformation?.clientType}
            onChange={(e) => changeAdditionalInformation(e.target.value, 'clientType')}
          />
        </div>
        <div className={`${styles.textAreaContainer}`}>
          <p className={`${styles.technicalTitle}`}>
            {t('offer.onsite_memo.additional_comments_for_additional_information')}
          </p>
          <textarea
            className={`inputField ${styles.textArea}`}
            rows="8"
            cols="50"
            value={additionalInformation?.comment}
            onChange={(e) => changeAdditionalInformation(e.target.value, 'comment')}
          />
        </div>
      </div>
    </div>
  );
}

RequestedDrillingTimeInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

RequestedDeliveryTimeInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

RequestedInstallationTime.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

ClientTypeInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

AdditionalInformation.propTypes = {
  additionalInformation: PropTypes.instanceOf(Object).isRequired,
  setAdditionalInformation: PropTypes.func.isRequired,
};

export default AdditionalInformation;
