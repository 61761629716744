import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import NumberControl from '../form-fields/number-control';
import NumberTranslate from '../number-translate';
import Translate from '../translate';

function OfferPrice({ price }) {
  return (
    <NumberControl value={price} unit="units.euro" isPrice isControlRequired={false} disable />
  );
}

function Comment({ name, comment, onCommentChange }) {
  const { t } = useTranslation('lang');

  const onCommentInput = (event) => {
    onCommentChange(event.target.value);
  };

  return (
    <textarea
      name={name}
      placeholder={t('offer.offer_preview.write_a_comment')}
      className={`inputField ${styles.itemContentTextArea}`}
      value={comment}
      onChange={onCommentInput}
    />
  );
}

function OfferSummary({ vatRate, comments, setComments, summaryCalculation }) {
  const { t } = useTranslation('lang');

  const headers = [
    t('components.offer_summary.items'),
    t('components.offer_summary.purchase_price'),
    t('components.offer_summary.offer_price', { vat_rate: vatRate }),
    t('components.offer_summary.comment'),
  ];

  const commentableTypes = {
    heating_devices: 'heatingDevicesComment',
    services: 'servicesComment',
    products: 'productsComment',
  };

  const clientTotalLabel = t('components.offer_summary.client_total');

  const items = [
    {
      id: 1,
      name: 'Heating Device',
      key: 'heating_devices',
      purchasePriceInclusiveVat: summaryCalculation.heatingDevice?.purchasePriceInclusiveVat,
      purchasePriceExclusiveVat: summaryCalculation.heatingDevice?.purchasePriceExclusiveVat,
      offerPrice: summaryCalculation.heatingDevice?.sellingPriceInclusiveVat,
      purchasePriceVat: summaryCalculation.heatingDevice?.purchasePriceVat,
    },
    {
      id: 2,
      name: 'Services',
      key: 'services',
      purchasePriceInclusiveVat: summaryCalculation.service?.purchasePriceInclusiveVat,
      purchasePriceExclusiveVat: summaryCalculation.service?.purchasePriceExclusiveVat,
      offerPrice: summaryCalculation.service?.sellingPriceInclusiveVat,
      purchasePriceVat: summaryCalculation.service?.purchasePriceVat,
    },
    {
      id: 3,
      name: 'Products',
      key: 'products',
      purchasePriceInclusiveVat: summaryCalculation.product?.purchasePriceInclusiveVat,
      purchasePriceExclusiveVat: summaryCalculation.product?.purchasePriceExclusiveVat,
      offerPrice: summaryCalculation.product?.sellingPriceInclusiveVat,
      purchasePriceVat: summaryCalculation.product?.purchasePriceVat,
    },
  ];

  const onCommentChange = (commentableType, comment) => {
    setComments({
      ...comments,
      [commentableTypes[commentableType]]: comment === '' ? null : comment,
    });
  };

  return (
    <div className={`${styles.offerSummaryContainer}`}>
      <div className={`${styles.offerSummaryTitleContainer}`}>
        <h3 className={`${styles.offerSummaryTitle}`}>
          <Translate id="offer.offer_preview.offer_summary" />
        </h3>
      </div>
      <div className={`d-none d-md-block ${styles.summaryContainer} ${styles.desktopSummary}`}>
        <div
          className={`d-flex align-items-center ${styles.flexContainer} ${styles.headerContainer}`}
        >
          {headers.map((head) => (
            <div key={head}>{head}</div>
          ))}
        </div>
        {items.map((item) => (
          <div
            key={item.id}
            className={`d-flex align-items-center ${styles.flexContainer} ${styles.contentContainer}`}
          >
            <div>{item.name}</div>
            <div>
              <NumberTranslate value={item.purchasePriceExclusiveVat} /> &#8364;{' '}
              <span>
                {' '}
                &#40;
                <NumberTranslate value={item.purchasePriceInclusiveVat} /> {t('units.euro')} VAT{' '}
                {item.purchasePriceVat}% &#41;
              </span>
            </div>

            <div>
              <OfferPrice price={item.offerPrice} />
            </div>
            <div>
              <Comment
                comment={comments[commentableTypes[item.key]]}
                onCommentChange={(comment) => onCommentChange(item.key, comment)}
              />
            </div>
          </div>
        ))}
      </div>

      <div className={`d-md-none ${styles.summaryContainer} ${styles.mobileSummary}`}>
        <div className={`${styles.headerText}`}>{headers[0]}</div>
        {items.map((item) => (
          <div className={`${styles.summaryContent}`} key={item.id}>
            <div className={`${styles.itemName}`}>{item.name}</div>
            <div className={`${styles.itemContent}`}>
              <div>{headers[1]}</div>
              <div>
                <NumberTranslate value={item.purchasePriceInclusiveVat} /> &#8364;{' '}
                <span>
                  {' '}
                  &#40;
                  <NumberTranslate value={item.purchasePriceExclusiveVat} /> {t('units.euro')} VAT{' '}
                  {item.purchasePriceVat}% &#41;
                </span>
              </div>
            </div>
            <div className={`${styles.itemContent}`}>
              <div>{headers[2]}</div>
              <div className={`${styles.itemContentInput}`}>
                <OfferPrice price={item.offerPrice} />
              </div>
            </div>
            <div className={`${styles.itemContent}`}>
              <div>{headers[3]}</div>
              <div>
                <Comment
                  comment={comments[commentableTypes[item.key]]}
                  onCommentChange={(comment) => onCommentChange(item.key, comment)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className={`${styles.totalText}`}>
        {clientTotalLabel} :{' '}
        <NumberTranslate value={items.reduce((sum, item) => sum + item.offerPrice, 0).toFixed(2)} />{' '}
        &#8364;
      </div>
    </div>
  );
}

OfferPrice.prototypes = {
  price: PropTypes.number.isRequired,
};

Comment.prototypes = {
  comment: PropTypes.string,
};

Comment.defaultProps = {
  comment: '',
};

OfferSummary.propTypes = {
  vatRate: PropTypes.number.isRequired,
};

export default OfferSummary;
