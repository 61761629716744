import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { TEST_EFFORT_TARGETS, TRIAL_EFFORT_WAYS } from './constants';
import styles from './index.module.scss';
import CompanyLogo from '../../assets/images/footerLogo.png';
import { ReactComponent as PenSvg } from '../../assets/svgs/Pen.svg';
import { userRoles, signatureTypes } from '../../constant';
import { useBasicInformationContext } from '../../pages/task-forms/context/basicInformationContext';
import { useServiceContext } from '../../pages/task-forms/context/service-context';
import {
  saveFloorHeatingTestReport,
  getFloorHeatingTestReport,
  saveFloorHeatingTestReportEmployeeSign,
  saveFloorHeatingTestReportCustomerSign,
} from '../../services/report';
import { generateBlobSrc } from '../../utils/common';
import { getOriginalErrors } from '../../utils/errorHandler';
import Button from '../button';
import CalendarControl from '../form-fields/calendar-control';
import Dropdown from '../form-fields/drop-down-control';
import TextControl from '../form-fields/text-control';
import SignatureModal from '../signature-modal';
import Translate from '../translate';

function CustomerInformation() {
  const { customerInformation, offerRequest } = useBasicInformationContext();
  return (
    <div className={styles.customerInfoContainer}>
      <div className={`d-flex align-items-center justify-content-between ${styles.flexContainer}`}>
        <div>
          <Translate id="components.user.name" />
        </div>
        <div>{`${customerInformation.firstName} ${customerInformation.lastName}`}</div>
      </div>
      <div className={`d-flex align-items-center justify-content-between ${styles.flexContainer}`}>
        <div>
          <Translate id="components.address.address" />
        </div>
        <div>{offerRequest.installationTargetAddress}</div>
      </div>
      <div className={`d-flex align-items-center justify-content-between ${styles.flexContainer}`}>
        <div>
          <Translate id="components.address.postal_code" />
        </div>
        <div>{offerRequest.installationTargetZipCode}</div>
      </div>
      <div className={`d-flex align-items-center justify-content-between ${styles.flexContainer}`}>
        <div>
          <Translate id="components.address.city" />
        </div>
        <div>{offerRequest.installationTargetCity}</div>
      </div>
      <div className={`d-flex align-items-center justify-content-between ${styles.flexContainer}`}>
        <div>
          <Translate id="components.user.phone" />
        </div>
        <div>{customerInformation.phone}</div>
      </div>
      <div className={`d-flex align-items-center justify-content-between ${styles.flexContainer}`}>
        <div>
          <Translate id="components.user.email" />
        </div>
        <div>{customerInformation.email}</div>
      </div>
    </div>
  );
}

function TestEffortTarget({ testEffort, setTestEffort }) {
  const { t } = useTranslation('lang');
  return (
    <Dropdown
      label="floor_heating_test_report.test_effort_target.title"
      options={TEST_EFFORT_TARGETS.map((item) => ({ ...item, label: t(item.label) }))}
      selected={testEffort}
      onChange={setTestEffort}
      selectionKey="value"
    />
  );
}

function ATestedCircuit({ testCircuit, setTestCircuit }) {
  return (
    <TextControl
      label="floor_heating_test_report.a_tested_circuit"
      placeholder="floor_heating_test_report.a_tested_circuit"
      value={testCircuit}
      onChange={(e) => setTestCircuit(e.target.value)}
    />
  );
}

function ATrialEffortWay({ trialEffortWay, setTrialEffortWay }) {
  const { t } = useTranslation('lang');
  return (
    <Dropdown
      label="floor_heating_test_report.trial_effort_way.title"
      options={TRIAL_EFFORT_WAYS.map((item) => ({ ...item, label: t(item.label) }))}
      selected={trialEffortWay}
      onChange={setTrialEffortWay}
      selectionKey="value"
    />
  );
}

function TestPressure({ testPressure, setTestPressure }) {
  return (
    <TextControl
      label="floor_heating_test_report.test_pressure"
      placeholder="floor_heating_test_report.test_pressure"
      value={testPressure}
      onChange={(e) => setTestPressure(e.target.value)}
    />
  );
}

function Probation({ probation, setProbation }) {
  return (
    <TextControl
      label="floor_heating_test_report.probation"
      placeholder="floor_heating_test_report.probation"
      value={probation}
      onChange={(e) => setProbation(e.target.value)}
    />
  );
}

function InstallationDate({ installationDate, setInstallationDate }) {
  return (
    <CalendarControl
      label="floor_heating_test_report.installation_date"
      selected={installationDate}
      onChange={setInstallationDate}
    />
  );
}

function Installer({ installer, setInstaller }) {
  return (
    <TextControl
      label="floor_heating_test_report.installer"
      placeholder="floor_heating_test_report.installer"
      value={installer}
      onChange={(e) => setInstaller(e.target.value)}
    />
  );
}

function SignaturePreview({ signature, label, onEdit }) {
  return signature.signatureType === signatureTypes.TYPE ? (
    <div>
      <p className={`${styles.signPreviewLabel} d-flex justify-content-between`}>
        <span>
          <Translate id={label} />:
        </span>
        <PenSvg className={styles.signEditButton} onClick={onEdit} />
      </p>
      <p>{signature.signature}</p>
    </div>
  ) : (
    <>
      <p className={`${styles.signPreviewLabel} d-flex justify-content-between`}>
        <span>
          <Translate id={label} />:
        </span>
        <PenSvg className={styles.signEditButton} onClick={onEdit} />
      </p>
      <img
        src={signature.signature}
        width={signature.signatureType === signatureTypes.UPLOAD ? 150 : '100%'}
        alt=""
      />
    </>
  );
}

const initialFloorHeatingInstallationProtocol = {
  testEffortTarget: '',
  testCircuit: '',
  trialEffortWay: '',
  testPressure: '',
  probation: '',
};

function FloorHeatingTestReport({ setLoading }) {
  const [userRole, setUserRole] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [customerSignature, setCustomerSignature] = useState({});
  const [employeeSignature, setEmployeeSignature] = useState({});
  const [showSignatureButtons, setShowSignatureButtons] = useState(true);
  const [oldFloorHeatingTestReport, setOldFloorHeatingTestReport] = useState(null);
  const [signatureUpdated, setSignatureUpdated] = useState(false);

  const [floorHeatingInstallationProtocols, setFloorHeatingInstallationProtocols] = useState([
    {
      ...initialFloorHeatingInstallationProtocol,
    },
  ]);
  const [installationDate, setInstallationDate] = useState(null);
  const [installer, setInstaller] = useState('');
  const { t } = useTranslation('lang');

  const { companyId, employeeId, adminId } = useParams();
  const { service } = useServiceContext();

  const [mainInitalState, setMainInitalState] = useState({});

  const enableSave = () =>
    !isEqual(mainInitalState, {
      floorHeatingInstallationProtocols,
      installationDate,
      installer,
      signatureUpdated,
    });

  useEffect(() => {
    if (userRole) {
      setShowModal(true);
    }
  }, [userRole]);

  const setField = (fieldName, value, protocolIdx) => {
    if (protocolIdx >= floorHeatingInstallationProtocols.length) return;
    const tempFloorHeatingInstallationProtocols = [...floorHeatingInstallationProtocols];
    tempFloorHeatingInstallationProtocols[protocolIdx][fieldName] = value;
    setFloorHeatingInstallationProtocols(tempFloorHeatingInstallationProtocols);
  };

  const onAddItem = () => {
    setFloorHeatingInstallationProtocols([
      ...floorHeatingInstallationProtocols,
      { ...initialFloorHeatingInstallationProtocol },
    ]);
  };

  const fetchFloorHeatingTestReport = async () => {
    setLoading(true);
    try {
      const {
        data: {
          data: { floorHeatingTestReport },
        },
      } = await getFloorHeatingTestReport(companyId, employeeId || adminId, service?.id);
      if (floorHeatingTestReport) {
        setFloorHeatingInstallationProtocols(
          floorHeatingTestReport.data.floorHeatingInstallationProtocols
        );
        setInstallationDate(floorHeatingTestReport.data.installationDate);
        setInstaller(floorHeatingTestReport.data.installer);
        setEmployeeSignature({
          signature: floorHeatingTestReport.employeeSignature,
          signatureType: floorHeatingTestReport.employeeSignatureType,
        });
        setCustomerSignature({
          signature: floorHeatingTestReport.customerSignature,
          signatureType: floorHeatingTestReport.customerSignatureType,
        });
        setMainInitalState({
          floorHeatingInstallationProtocols: JSON.parse(
            JSON.stringify(floorHeatingTestReport.data.floorHeatingInstallationProtocols)
          ),
          installationDate: floorHeatingTestReport.data.installationDate,
          installer: floorHeatingTestReport.data.installer,
          signatureUpdated: false,
        });
        setOldFloorHeatingTestReport(floorHeatingTestReport);
        setShowSignatureButtons(true);
        setSignatureUpdated(false);
      } else {
        setMainInitalState({
          floorHeatingInstallationProtocols: JSON.parse(
            JSON.stringify(floorHeatingInstallationProtocols)
          ),
          installationDate,
          installer,
          signatureUpdated: false,
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error);
      });
    }
  };

  const persistExistingSignature = () => {
    let existingSignaturesTemp = {};

    if (oldFloorHeatingTestReport) {
      const {
        employeeSignatureType,
        employeeSignature: existingEmployeeSignature,
        employeeSignedAt,
        signedEmployeeId,
        customerSignatureType,
        customerSignature: existingCustomerSignature,
        customerSignedAt,
      } = oldFloorHeatingTestReport;
      if (
        employeeSignatureType &&
        existingEmployeeSignature &&
        employeeSignedAt &&
        signedEmployeeId
      ) {
        existingSignaturesTemp = {
          ...existingSignaturesTemp,
          employeeSignatureType,
          employeeSignedAt,
          signedEmployeeId,
          employeeSignature: existingEmployeeSignature,
        };
      }

      if ((customerSignatureType && existingCustomerSignature, customerSignedAt)) {
        existingSignaturesTemp = {
          ...existingSignaturesTemp,
          customerSignatureType,
          customerSignature: existingCustomerSignature,
          customerSignedAt,
        };
      }
    }

    return existingSignaturesTemp;
  };

  const saveSignatureForFloorHeatingTestReport = async (reportId) => {
    if (!reportId) {
      return;
    }

    if (Object.keys(employeeSignature).length > 0 && employeeSignature.data) {
      await saveFloorHeatingTestReportEmployeeSign(
        companyId,
        employeeId || adminId,
        service?.id,
        employeeSignature.data
      );
    }

    if (Object.keys(customerSignature).length > 0 && customerSignature.data) {
      await saveFloorHeatingTestReportCustomerSign(
        companyId,
        employeeId || adminId,
        service?.id,
        customerSignature.data
      );
    }
  };

  const onSave = async () => {
    setLoading(true);
    try {
      const existingSignatures = persistExistingSignature();
      const res = await saveFloorHeatingTestReport(companyId, employeeId || adminId, service?.id, {
        data: {
          floorHeatingInstallationProtocols,
          installationDate,
          installer,
          ...existingSignatures,
        },
      });
      await saveSignatureForFloorHeatingTestReport(res?.data?.data?.floorHeatingTestReport?.id);
      toast.success(t('floor_heating_test_report.save_success_message'));
      await fetchFloorHeatingTestReport();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error);
      });
    }
  };

  const setSignature = async (data) => {
    setLoading(true);
    try {
      if (userRole.role === userRoles.CUSTOMER.role) {
        const signatureType = data.get('signatureType');

        if (signatureType === signatureTypes.TYPE) {
          setCustomerSignature({
            signature: data.get('signature'),
            signatureType: data.get('signatureType'),
            data,
          });
        }

        if (signatureType === signatureTypes.DRAW || signatureType === signatureTypes.UPLOAD) {
          const signatureBlob = generateBlobSrc(data.get('customerSignatureFile'));

          if (!signatureBlob) {
            toast.error('Invalid signature');
            return;
          }

          setCustomerSignature({
            signature: signatureBlob,
            signatureType: data.get('signatureType'),
            data,
          });
        }

        setSignatureUpdated(true);
        setLoading(false);
      }
      if (userRole.role === userRoles.INSTALLER.role) {
        const signatureType = data.get('signatureType');

        if (signatureType === signatureTypes.TYPE) {
          setEmployeeSignature({
            signature: data.get('signature'),
            signatureType: data.get('signatureType'),
            data,
          });
        }

        if (signatureType === signatureTypes.DRAW || signatureType === signatureTypes.UPLOAD) {
          const signatureBlob = generateBlobSrc(data.get('employeeSignatureFile'));

          if (!signatureBlob) {
            toast.error('Invalid signature');
            return;
          }

          setEmployeeSignature({
            signature: signatureBlob,
            signatureType: data.get('signatureType'),
            data,
          });
        }

        setSignatureUpdated(true);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const onCloseModal = () => {
    setUserRole(null);
    setShowModal(false);
  };

  useEffect(() => {
    if ((service?.id && employeeId) || adminId) {
      fetchFloorHeatingTestReport();
    }
  }, [service, employeeId, adminId]);

  return (
    <div className={styles.formMainContainer}>
      <div className={styles.imgContainer}>
        <img src={CompanyLogo} alt="company_logo" />
      </div>
      <div className={styles.formTitleContainer}>
        <h2 className={styles.formTitle}>
          <Translate id="floor_heating_test_report.title" />
        </h2>
      </div>
      <CustomerInformation />
      <div className={styles.formContentMainContainer}>
        {floorHeatingInstallationProtocols.map((protocol, index) => (
          <div className={styles.formContentContainer}>
            <div>
              <TestEffortTarget
                testEffort={protocol.testEffortTarget}
                setTestEffort={(newVal) => setField('testEffortTarget', newVal, index)}
              />
            </div>
            <div>
              <ATestedCircuit
                testCircuit={protocol.testCircuit}
                setTestCircuit={(newVal) => setField('testCircuit', newVal, index)}
              />
            </div>
            <div>
              <ATrialEffortWay
                trialEffortWay={protocol.trialEffortWay}
                setTrialEffortWay={(newVal) => setField('trialEffortWay', newVal, index)}
              />
            </div>
            <div>
              <TestPressure
                testPressure={protocol.testPressure}
                setTestPressure={(newVal) => setField('testPressure', newVal, index)}
              />
            </div>
            <div>
              <Probation
                probation={protocol.probation}
                setProbation={(newVal) => setField('probation', newVal, index)}
              />
            </div>
          </div>
        ))}
      </div>
      <div className={`${styles.addBtnContainer}`}>
        <Button className={`projectBtn greenBtn ${styles.addBtn}`} onClick={onAddItem}>
          <Translate id="components.button.add_item" />
        </Button>
      </div>
      <div className={styles.formContentMainContainer}>
        <div className={styles.formContentContainer}>
          <div>
            <InstallationDate
              installationDate={installationDate}
              setInstallationDate={setInstallationDate}
            />
          </div>
          <div>
            <Installer installer={installer} setInstaller={setInstaller} />
          </div>
        </div>
      </div>

      <div className={`${styles.reportMainContainer}`}>
        <div className={`${styles.contactDetailsContainer}`}>
          <div
            className={`d-flex align-items-center justify-content-between ${styles.contactFlexContainer}`}
          >
            <div>
              <Translate id="floor_heating_test_report.company.name" />
            </div>
            <div>Lannen Maalampo</div>
          </div>
          <div
            className={`d-flex align-items-center justify-content-between ${styles.contactFlexContainer}`}
          >
            <div>
              <Translate id="floor_heating_test_report.company.social_security_number" />
            </div>
            <div>165s1fsf</div>
          </div>
          <a
            className={`d-flex align-items-center justify-content-between ${styles.contactFlexContainer}`}
            href="tel:+15616184915"
          >
            <div>
              <Translate id="floor_heating_test_report.company.phone" />
            </div>
            <div>+15616184915</div>
          </a>
          <div
            className={`d-flex align-items-center justify-content-between ${styles.contactFlexContainer}`}
          >
            <div>
              <Translate id="floor_heating_test_report.company.address" />
            </div>
            <div>street, 16516, Helsinki</div>
          </div>
        </div>
      </div>

      {showSignatureButtons ? (
        <div className={`${styles.signBtnMainContainer}`}>
          <div className={`${styles.signBtnContainer}`}>
            {!employeeSignature.signature ? (
              <div
                className={`d-flex align-items-center justify-content-between ${styles.btnContainer}`}
              >
                <div>
                  <Translate id="floor_heating_test_report.installer" />
                </div>
                <div>
                  <Button
                    className={`projectBtn ${styles.signBtn}`}
                    onClick={() => setUserRole(userRoles.INSTALLER)}
                  >
                    <Translate id="components.signature.sign" />
                    <PenSvg className={`${styles.signBtnIcon}`} />
                  </Button>
                </div>
              </div>
            ) : (
              <SignaturePreview
                label="floor_heating_test_report.installer"
                signature={employeeSignature}
                onEdit={() => setUserRole(userRoles.INSTALLER)}
              />
            )}
            {!customerSignature.signature ? (
              <div
                className={`d-flex align-items-center justify-content-between ${styles.btnContainer}`}
              >
                <div>
                  <Translate id="floor_heating_test_report.customer" />
                </div>
                <div>
                  <Button
                    className={`projectBtn ${styles.signBtn}`}
                    onClick={() => setUserRole(userRoles.CUSTOMER)}
                  >
                    <Translate id="components.signature.sign" />
                    <PenSvg className={`${styles.signBtnIcon}`} />
                  </Button>
                </div>
              </div>
            ) : (
              <SignaturePreview
                label="floor_heating_test_report.customer"
                signature={customerSignature}
                onEdit={() => setUserRole(userRoles.CUSTOMER)}
              />
            )}
          </div>
          {showModal && (
            <SignatureModal
              userRole={userRole}
              onClose={onCloseModal}
              showModal={showModal}
              setSignature={setSignature}
              signatureFileName={
                userRole === userRoles.CUSTOMER ? 'customerSignatureFile' : 'employeeSignatureFile'
              }
            />
          )}
        </div>
      ) : null}

      <div className={`${styles.noteBtnContainer}`}>
        <Button
          className={`projectBtn ${enableSave() ? 'projectBtn greenBtn' : 'grayBtn'}`}
          disabled={!enableSave()}
          onClick={onSave}
        >
          <Translate id="components.button.save" />
        </Button>
      </div>
    </div>
  );
}

export default FloorHeatingTestReport;
