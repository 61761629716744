import apiClient from './api-client';

export const getServiceWells = (offerRequestId, offerId, offerServiceId) =>
  apiClient.get(
    `/v1/offer-requests/${offerRequestId}/offers/${offerId}/services/${offerServiceId}/wells`
  );

export const updateServiceWells = (offerRequestId, offerId, offerServiceId, payload) =>
  apiClient.put(
    `/v1/offer-requests/${offerRequestId}/offers/${offerId}/services/${offerServiceId}/wells`,
    payload
  );
