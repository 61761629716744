import PropTypes from 'prop-types'
import React from 'react'

function CircleArrowLeft({ className }) {
  return (
    <svg viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.08241 0.917969C13.8235 0.917969 17.6648 4.75911 17.6648 9.5C17.6648 14.2409 13.8235 18.082 9.08241 18.082C4.34133 18.082 0.5 14.2409 0.5 9.5C0.5 4.75911 4.34133 0.917969 9.08241 0.917969ZM13.7889 10.6073C14.2492 10.6073 14.6195 10.2371 14.6195 9.77681V9.22313C14.6195 8.76289 14.2492 8.39261 13.7889 8.39261H7.46976L10.0826 5.88721C10.4182 5.56538 10.4252 5.02901 10.0964 4.70026L9.71572 4.32306C9.39042 3.99778 8.8644 3.99778 8.54256 4.32306L3.95027 8.91168C3.62497 9.23697 3.62497 9.76297 3.95027 10.0848L8.54256 14.6769C8.86786 15.0022 9.39388 15.0022 9.71572 14.6769L10.0964 14.2997C10.4252 13.9709 10.4182 13.4346 10.0826 13.1127L7.46976 10.6073H13.7889Z"
        fill="#C2C2C2"
      />
    </svg>
  )
}

CircleArrowLeft.propTypes = {
  className: PropTypes.string,
}

CircleArrowLeft.defaultProps = {
  className: null,
}

export default CircleArrowLeft
