import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import styles from './index.module.scss';
import { paginationDataHandler } from '../../services/api-client';
import { getOfferProducts, getProductsDetails } from '../../services/offerProducts';
import ProductPriceFields from '../product-price-fields';
import ProductsList from '../products-list';
import Translate from '../translate';

function ProductsFlow({ selectedProducts, setSelectedProducts, strict }) {
  const [products, setProducts] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [countOfProductToDisplay, setCountOfProductToDisplay] = useState(0);
  const [pagination, setPagination] = useState({});
  const [totalCountOfProduct, setTotalCountOfProduct] = useState(0);

  const onProductRemove = (removableProduct) => {
    setProducts((prevProducts) =>
      prevProducts.map((p) => ({
        ...p,
        selected: p.netvisorId === removableProduct.netvisorId ? !p.selected : p.selected,
      }))
    );

    setSelectedProducts((prevProducts) =>
      prevProducts.filter((p) => p.netvisorId !== removableProduct.netvisorId)
    );
  };

  const roundToTwoDecimal = (num) => Number(num.toFixed(2));

  const calculateAmountInclusiveVat = (amountExclusiveVat, vat) =>
    roundToTwoDecimal(amountExclusiveVat * (1 + vat / 100));

  const calculateAmountExclusiveVat = (amountInclusiveVat, vat) =>
    roundToTwoDecimal(amountInclusiveVat / (1 + vat / 100));

  const onProductClick = async (product) => {
    const index = selectedProducts.findIndex((p) => p.netvisorId === product.netvisorId);
    if (index !== -1 && selectedProducts[index].alreadySelected) return;
    setProducts((prevProducts) =>
      prevProducts.map((p) => ({
        ...p,
        selected: p.netvisorId === product.netvisorId ? !p.selected : p.selected,
      }))
    );
    if (index === -1) {
      const productToAdd = { ...product };
      delete productToAdd.id;

      const { netvisorImageId, netvisorAttachmentIds } = productToAdd;
      const queryParams = {};
      if (netvisorImageId && netvisorImageId != null) {
        queryParams.netvisorImageId = netvisorImageId;
      }

      if (netvisorAttachmentIds && netvisorAttachmentIds.length > 0) {
        queryParams.netvisorAttachmentIds = netvisorAttachmentIds.join(',');
      }

      const {
        data: {
          data: { product: selectedProductData },
        },
      } = await getProductsDetails(productToAdd.netvisorId, queryParams);

      const sellingPrice = +selectedProductData.sellingPrice.replace('.', '').replace(',', '.');
      const purchasePrice = +selectedProductData.purchasePrice.replace('.', '').replace(',', '.');
      const vat = +selectedProductData.vat;

      const { attachments } = selectedProductData;

      setSelectedProducts((prevProducts) => [
        ...prevProducts,
        {
          ...productToAdd,
          purchasePrice,
          sellingPrice,
          sellingPriceExclusiveVat: sellingPrice,
          sellingPriceInclusiveVat: calculateAmountInclusiveVat(sellingPrice, vat),
          purchasePriceInclusiveVat: calculateAmountInclusiveVat(purchasePrice, vat),
          purchasePriceExclusiveVat: purchasePrice,
          amount: 1,
          rateExclusiveVat: sellingPrice,
          rateInclusiveVat: calculateAmountInclusiveVat(sellingPrice, vat),
          vat,
          attachments,
          unit: selectedProductData.unit,
          // id: undefined,
        },
      ]);
    } else {
      setSelectedProducts((prevProducts) =>
        prevProducts.filter((p) => p.netvisorId !== product.netvisorId)
      );
    }
  };

  const onChangeProductQuantity = (productId, quantity) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) => {
        if (product.netvisorId !== productId) {
          return product;
        }

        if (Number.isNaN(quantity) || quantity === 0) {
          return {
            ...product,
            amount: 0,
            sellingPriceInclusiveVat: 0,
            sellingPriceExclusiveVat: 0,
            purchasePriceInclusiveVat: 0,
            purchasePriceExclusiveVat: 0,
          };
        }

        const amount = quantity;
        const purchasePriceExclusiveVat = product.purchasePrice * amount;
        const purchasePriceInclusiveVat = calculateAmountInclusiveVat(
          purchasePriceExclusiveVat,
          product.vat
        );

        const sellingPriceInclusiveVat = product.rateInclusiveVat * amount;
        const sellingPriceExclusiveVat = calculateAmountExclusiveVat(
          sellingPriceInclusiveVat,
          product.vat
        );

        return {
          ...product,
          amount,
          purchasePriceExclusiveVat: roundToTwoDecimal(purchasePriceExclusiveVat),
          purchasePriceInclusiveVat: roundToTwoDecimal(purchasePriceInclusiveVat),
          sellingPriceInclusiveVat: roundToTwoDecimal(sellingPriceInclusiveVat),
          sellingPriceExclusiveVat: roundToTwoDecimal(sellingPriceExclusiveVat),
        };
      })
    );
  };

  const onChangeProductPrice = (productId, price) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) => {
        if (product.netvisorId !== productId) {
          return product;
        }

        if (Number.isNaN(price) || price === 0) {
          return {
            ...product,
            amount: 0,
            sellingPriceInclusiveVat: 0,
            sellingPriceExclusiveVat: 0,
            purchasePriceInclusiveVat: 0,
            purchasePriceExclusiveVat: 0,
            rateExclusiveVat: 0,
            rateInclusiveVat: 0,
          };
        }

        const rateInclusiveVat = price;
        const rateExclusiveVat = calculateAmountExclusiveVat(rateInclusiveVat, product.vat);
        const sellingPriceExclusiveVat = rateExclusiveVat * product.amount;
        const sellingPriceInclusiveVat = rateInclusiveVat * product.amount;

        return {
          ...product,
          rateInclusiveVat: roundToTwoDecimal(rateInclusiveVat),
          rateExclusiveVat: roundToTwoDecimal(rateExclusiveVat),
          sellingPriceExclusiveVat: roundToTwoDecimal(sellingPriceExclusiveVat),
          sellingPriceInclusiveVat: roundToTwoDecimal(sellingPriceInclusiveVat),
        };
      })
    );
  };

  const fetchProducts = async (q) => {
    try {
      const {
        data: {
          data: { products: components },
          pagination: paginationData,
          meta: { totalItems },
        },
      } = await getOfferProducts({ q });
      setProducts(
        components.map((component) => ({
          ...component,
          selected: selectedProducts.findIndex((p) => p.netvisorId === component.netvisorId) !== -1,
        }))
      );
      setPagination(paginationData);
      setCountOfProductToDisplay(components.length);
      setTotalCountOfProduct(+totalItems);
    } catch (error) {
      setProducts([]);
      setCountOfProductToDisplay(0);
      setTotalCountOfProduct(0);
    }
  };

  const showMoreOrLessProduct = async () => {
    if (countOfProductToDisplay === totalCountOfProduct) {
      const path = pagination.first.split('/api')[1];
      const {
        data: {
          data: { products: components },
          pagination: paginationData,
          meta: { totalItems },
        },
      } = await paginationDataHandler(path);
      setProducts(
        components.map((component) => ({
          ...component,
          selected: selectedProducts.findIndex((p) => p.netvisorId === component.netvisorId) !== -1,
        }))
      );
      setPagination(paginationData);
      setCountOfProductToDisplay(components.length);
      setTotalCountOfProduct(+totalItems);
      return;
    }

    if (pagination.next) {
      const path = pagination.next.split('/api')[1];
      const {
        data: {
          data: { products: components },
          pagination: paginationData,
        },
      } = await paginationDataHandler(path);
      setProducts((prev) => [
        ...prev,
        ...components.map((component) => ({
          ...component,
          selected: selectedProducts.findIndex((p) => p.netvisorId === component.netvisorId) !== -1,
        })),
      ]);
      setPagination(paginationData);
      setCountOfProductToDisplay((prev) => prev + components.length);
    }
  };

  useEffect(() => {
    if (keyword) {
      fetchProducts(keyword);
    } else {
      setProducts([]);
      setCountOfProductToDisplay(0);
      setTotalCountOfProduct(0);
    }
  }, [keyword]);

  return (
    <>
      <div>
        <ProductsList
          products={products}
          onProductClick={onProductClick}
          keyword={keyword}
          setKeyword={setKeyword}
          countOfProductToDisplay={countOfProductToDisplay}
          fetchMoreProducts={showMoreOrLessProduct}
          totalCountOfProduct={totalCountOfProduct}
        />
      </div>
      <div className={`${styles.productListContainer}`}>
        <h1 className={`${styles.headerTitle}`}>
          <Translate id="offer.products.selected_products" />
        </h1>
        <div className={`${styles.productFlexContainer}`}>
          {selectedProducts.length > 0 &&
            selectedProducts.map((product) => (
              <ProductPriceFields
                key={product.netvisorId}
                id={product.netvisorId}
                name={product.name}
                amount={product.amount}
                purchasePriceInclusiveVat={product.purchasePriceInclusiveVat}
                purchasePriceExclusiveVat={product.purchasePriceExclusiveVat}
                rateInclusiveVat={product.rateInclusiveVat}
                rateExclusiveVat={product.rateExclusiveVat}
                onRemove={() => onProductRemove(product)}
                sellingPriceInclusiveVat={product.sellingPriceInclusiveVat}
                sellingPriceExclusiveVat={product.sellingPriceExclusiveVat}
                onChangeProductQuantity={onChangeProductQuantity}
                onChangeProductPrice={onChangeProductPrice}
                productUnit={product.unit}
                vat={product.vat}
                alreadySelected={product.alreadySelected}
                strict={strict}
              />
            ))}
        </div>
      </div>
    </>
  );
}

ProductsFlow.propTypes = {
  selectedProducts: PropTypes.instanceOf(Array).isRequired,
  setSelectedProducts: PropTypes.func.isRequired,
  strict: PropTypes.bool,
};

ProductsFlow.defaultProps = {
  strict: false,
};

export default ProductsFlow;
