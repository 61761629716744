import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

function TextControl({ label, name, value, onChange, placeholder, type, register }) {
  const { t } = useTranslation('lang');
  return (
    <div
      className={`d-sm-flex align-items-sm-center justify-content-sm-between ${styles.textControlContainer}`}
    >
      <div>
        <p>{t(label)}</p>
      </div>
      <div>
        <input
          className={`inputField ${styles.inputField}`}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={t(placeholder)}
          {...(register ? register(name) : {})}
        />
      </div>
    </div>
  );
}

TextControl.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  register: PropTypes.func,
};

TextControl.defaultProps = {
  type: 'text',
  register: null,
};

export default TextControl;
