import PropTypes from 'prop-types';
import React, { useState, useMemo, useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './offer-request.module.scss';
import AccordionTabsContainer from '../../../components/accordion-tabs-container';
import Button from '../../../components/button';
import Layout from '../../../components/layout';
import TabsContainer from '../../../components/tabs-container';
import Translate from '../../../components/translate';
import AdditionalInformationForm from '../../../pages/offer-request-forms/additional-information';
import CustomerInformationForm from '../../../pages/offer-request-forms/customer-information';
import ItemInformationForm from '../../../pages/offer-request-forms/item-information';
import {
  getBasicInformationSessionInfo,
  setBasicInformationSessionInfo,
  saveBasicInformation,
} from '../../../services/basicInformation';
import CustomerService from '../../../services/customer';
import OfferRequestService from '../../../services/offerRequest';
import { getOriginalErrors } from '../../../utils/errorHandler';

function CustomerInformationFooter({ onClickNext }) {
  return (
    <Button className={`${styles.nextBtn}`} onClick={onClickNext}>
      <Translate id="components.button.next" />
    </Button>
  );
}

function ItemInformationFooter({ onClickNext }) {
  return (
    <Button className={`${styles.nextBtn}`} onClick={onClickNext}>
      <Translate id="components.button.next" />
    </Button>
  );
}

function AdditionalInformationFooter({ onClickNext }) {
  return (
    <Button className={`${styles.addBtn}`} onClick={onClickNext}>
      <Translate id="components.button.add" />
    </Button>
  );
}

function OfferRequestHeader() {
  return (
    <div>
      <h1>
        <Translate id="offer.request.new" />
      </h1>
    </div>
  );
}

function OfferRequest({ setLoading }) {
  const { t } = useTranslation('lang');
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('customer_information');
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [offerRequest, setOfferRequest] = useState(
    OfferRequestService.getOfferRequestSessionInfo()
  );
  const [customerDetails, setCustomerDetails] = useState(CustomerService.getCustomerSessionInfo());
  const [basicInformation, setBasicInformation] = useState(getBasicInformationSessionInfo());
  const [customerDetailsErrors, setCustomerDetailsErrors] = useState(null);
  const [basicInformationErrors, setBasicInformationErors] = useState(null);
  const [offerRequestErrors, setOfferRequestErrors] = useState(null);
  const [highestEnabledTabIndex, setHighestEnabledTabIndex] = useState(0);

  useEffect(() => {
    CustomerService.setCustomerSessionInfo(customerDetails);
    setCustomerDetailsErrors(null);
  }, [customerDetails]);

  useEffect(() => {
    setBasicInformationSessionInfo(basicInformation);
    setBasicInformationErors(null);
  }, [basicInformation]);

  useEffect(() => {
    OfferRequestService.setOfferRequestSessionInfo(offerRequest);
    setOfferRequestErrors(null);
  }, [offerRequest]);

  const offerRequestTabs = [
    {
      key: 'customer_information',
      title: 'offer.request.customer_info',
      disabled: false,
      component: (
        <CustomerInformationForm
          customerDetails={customerDetails}
          onUpdateCustomerDetails={setCustomerDetails}
          offerRequest={offerRequest}
          onUpdateOfferRequest={setOfferRequest}
          apiError={customerDetailsErrors}
        />
      ),
    },
    {
      key: 'item_information',
      title: 'offer.request.item_information',
      disabled: highestEnabledTabIndex < 1,
      component: (
        <ItemInformationForm
          basicInformation={basicInformation}
          onUpdateBasicInformation={setBasicInformation}
          apiError={basicInformationErrors}
        />
      ),
    },
    {
      key: 'additional_information',
      title: 'offer.request.additional_information',
      disabled: highestEnabledTabIndex < 2,
      component: (
        <AdditionalInformationForm
          offerRequest={offerRequest}
          onUpdateOfferRequest={setOfferRequest}
          apiError={offerRequestErrors}
        />
      ),
    },
  ];

  const onChangeTab = (tabKey) => {
    setActiveTab(tabKey);
    setActiveTabIndex(offerRequestTabs.findIndex((tab) => tab.key === tabKey));
  };

  const checkAndEnableTabs = () => {
    if (highestEnabledTabIndex < activeTabIndex) {
      setHighestEnabledTabIndex(activeTabIndex);
    }
  };

  useEffect(() => {
    checkAndEnableTabs();
  }, [activeTabIndex]);

  useEffect(() => {
    setLoading(false);
  }, []);

  const saveBasicInformationData = async (offerRequestId) => {
    try {
      await saveBasicInformation(offerRequestId, getBasicInformationSessionInfo());
      toast.success(t('offer.request.new_request_created'));

      // clear session data
      CustomerService.setCustomerSessionInfo(null);
      OfferRequestService.setOfferRequestSessionInfo(null);
      setBasicInformationSessionInfo(null);
      navigate('/');
    } catch (error) {
      onChangeTab('item_information');
      setBasicInformationErors(error);
    }
  };

  const saveOfferRequest = async (customerData) => {
    try {
      const offerRequestData = {
        ...OfferRequestService.getOfferRequestSessionInfo(),
        customerId: customerData.id,
      };

      if (offerRequestData.id) {
        saveBasicInformationData(offerRequestData.id);
      } else {
        const {
          data: {
            data: { offerRequest: request },
          },
        } = await OfferRequestService.saveOfferRequest(offerRequestData);

        saveBasicInformationData(request.id);
        setOfferRequest(request);
      }
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      if (originalErrors.some((e) => e.field.indexOf('installationTarget') !== -1)) {
        onChangeTab('customer_information');
        setCustomerDetailsErrors(originalErrors);
      } else {
        setOfferRequestErrors(originalErrors);
      }
    }
  };

  const onClickNext = async () => {
    try {
      if (activeTabIndex < offerRequestTabs.length - 1) {
        onChangeTab(offerRequestTabs[activeTabIndex + 1].key);
      } else {
        const customerData = CustomerService.getCustomerSessionInfo();

        if (customerData.id) {
          saveOfferRequest(customerData);
        } else {
          const {
            data: {
              data: { customer },
            },
          } = await CustomerService.saveCustomerInfo(customerData);
          setCustomerDetails(customer);
          saveOfferRequest(customer);
        }
      }
    } catch (error) {
      onChangeTab('customer_information');
      setCustomerDetailsErrors(error);
    }
  };

  const offerRequestTabFooters = [
    {
      key: 'customer_information',
      component: <CustomerInformationFooter onClickNext={onClickNext} />,
    },
    {
      key: 'item_information',
      component: <ItemInformationFooter onClickNext={onClickNext} />,
    },
    {
      key: 'additional_information',
      component: <AdditionalInformationFooter onClickNext={onClickNext} />,
    },
  ];

  const activeFooter = useMemo(
    () => offerRequestTabFooters.find(({ key }) => key === activeTab),
    [activeTab]
  );

  const offerRequestHeader = () => <OfferRequestHeader />;

  return (
    <Layout headerComponent={offerRequestHeader} footerComponent={() => activeFooter.component}>
      {/* FIXME: Currently, using this conditional rendering does not work well in browser developer tool when switching to resposive window and it works only after page refresh.  */}
      {isMobile || isTablet ? (
        <div>
          <AccordionTabsContainer
            activeTab={activeTab}
            tabs={offerRequestTabs}
            setActiveTab={onChangeTab}
          />
        </div>
      ) : (
        <div>
          <TabsContainer activeTab={activeTab} tabs={offerRequestTabs} setActiveTab={onChangeTab} />
        </div>
      )}
    </Layout>
  );
}

CustomerInformationFooter.propTypes = {
  onClickNext: PropTypes.func,
};

CustomerInformationFooter.defaultProps = {
  onClickNext: () => null,
};

ItemInformationFooter.propTypes = {
  onClickNext: PropTypes.func,
};

ItemInformationFooter.defaultProps = {
  onClickNext: () => null,
};

AdditionalInformationFooter.propTypes = {
  onClickNext: PropTypes.func,
};

AdditionalInformationFooter.defaultProps = {
  onClickNext: () => null,
};

export default OfferRequest;
