import apiClient from './api-client';

const getHeatingDevices = (params) => apiClient.get('/v2/devices/', { params });

const getHeatingDeviceDetails = (netvisorId, params) =>
  apiClient.get(`/v2/devices/${netvisorId}`, { params });

const addHeatingDevicestoOffer = (requestId, offerId, body) =>
  apiClient.post(`/v1/offer-requests/${requestId}/offers/${offerId}/heatingDevices/`, body);

const getOfferHeatingDevices = ({ requestId, offerId }) =>
  apiClient(`/v1/offer-requests/${requestId}/offers/${offerId}/heatingDevices/`);

const updateOfferHeatingDevices = (requestId, offerId, heatingDevices) =>
  apiClient.put(
    `/v1/offer-requests/${requestId}/offers/${offerId}/heatingDevices/`,
    heatingDevices
  );

const order = (offerRequestId, offerId, heatingDeviceId, orderedFrom) =>
  apiClient.put(
    `/v1/offer-requests/${offerRequestId}/offers/${offerId}/task/heating-devices/${heatingDeviceId}/order`,
    { orderedFrom }
  );

const getOfferHeatingDevicesCalculations = ({ requestId, offerId }) =>
  apiClient(`/v1/offer-requests/${requestId}/offers/${offerId}/heatingDevices/calculations`);

export default {
  getHeatingDevices,
  addHeatingDevicestoOffer,
  getOfferHeatingDevices,
  updateOfferHeatingDevices,
  getHeatingDeviceDetails,
  order,
  getOfferHeatingDevicesCalculations,
};
