import PropTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';
import Footer from '../../footer';

function TaskPageContentWrapper({ customFooter, children }) {
  return (
    <div className={`${styles.mainContainer}`}>
      {children}
      <div className={` ${styles.footerElement}`}>
        <Footer component={customFooter} />
      </div>
    </div>
  );
}

TaskPageContentWrapper.propTypes = {
  customFooter: PropTypes.func,
  children: PropTypes.node.isRequired,
};

TaskPageContentWrapper.defaultProps = {
  customFooter: null,
};

export default TaskPageContentWrapper;
