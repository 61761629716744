import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Button from '../../../../components/button'
import Layout from '../../../../components/layout'
import Translate from '../../../../components/translate'
import teamsConstants from '../teams.json'

function TeamDetailsHeader() {
  return (
    <div>
      <h1>
        <Translate id="team.header.title" />
      </h1>
    </div>
  )
}

function TeamDetailsFooter() {
  const navigate = useNavigate()
  return (
    <div>
      <Button type="button" onClick={() => navigate('./edit')}>
        <Translate id="team.footer.button.edit" />
      </Button>
    </div>
  )
}

const getTeamDetailsHeader = () => <TeamDetailsHeader />
const getTeamDetailsFooter = () => <TeamDetailsFooter />

function TeamDetails() {
  const [team, setTeam] = useState(null)
  const params = useParams()

  useEffect(() => {
    if (params.teamId) {
      // fetch team object
      setTeam(teamsConstants[0])
    }
  }, [])

  return (
    <Layout headerComponent={getTeamDetailsHeader} footerComponent={getTeamDetailsFooter}>
      {team ? (
        <div>
          <h2>
            <Translate id="team.details.title" />
          </h2>
          <div>
            <div>
              <Translate id="team.details.team_name" />
              <div>
                <Translate id="team.details.id" />: {team.id} - {team.name}
              </div>
            </div>
            <div>
              <Translate id="team.details.company_name" />
              {team.companyName}
            </div>
            <div>
              <Translate id="team.details.vat_number" />
              {team.vatNumber}
            </div>
            <div>
              <Translate id="team.details.address" />
              {team.address}
            </div>
            <div>
              <Translate id="team.details.employee_assigned" />
              {team.numberOfEmployeeAssigned.toString().padStart(2, '0')}
            </div>
            <div>
              <Translate id="team.details.company_logo" />
              <img src={team.teamLogo} alt="company-logo" />
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
    </Layout>
  )
}

export default TeamDetails
