import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import DesktopFooterIcon from '../../../assets/images/footerLogo.png';

function Header({ component: PropComponent }) {
  const { t } = useTranslation('lang');

  return (
    <div className={`${styles.headerMainContainer}`}>
      <div
        className={`d-flex align-items-center justify-content-between projectContainer ${styles.headerContainer}`}
      >
        <div>
          <img
            className={`d-none d-md-block ${styles.logoImg}`}
            src={DesktopFooterIcon}
            alt={t('Lannen Logo')}
          />
        </div>
        {PropComponent && <PropComponent />}
      </div>
    </div>
  );
}

Header.propTypes = {
  component: PropTypes.func,
};

Header.defaultProps = {
  component: null,
};

export default Header;
