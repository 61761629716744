import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import DropDown from '../../drop-down';

function DropDownControl({ label, options, selected, onChange, type, selectionKey }) {
  const { t } = useTranslation('lang');
  return (
    <div
      className={`d-sm-flex align-items-sm-center justify-content-sm-between ${styles.dropDownContainer}`}
    >
      <div>
        <p>{t(label)}</p>
      </div>
      <div>
        <DropDown
          options={options}
          type={type}
          selected={selected}
          onChange={onChange}
          selectionKey={selectionKey}
        />
      </div>
    </div>
  );
}

DropDownControl.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Array),
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  onChange: PropTypes.func,
  type: PropTypes.string,
  selectionKey: PropTypes.string,
};

DropDownControl.defaultProps = {
  type: 'object',
  selected: '',
  onChange() {},
  options: ['option 1', 'option 2'],
  selectionKey: '',
};

export default DropDownControl;
