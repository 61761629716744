import PropTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';
import Footer from '../footer';

function OfferPageContentWrapper({ customFooter, children }) {
  return (
    <div className={`${styles.mainContainer}`}>
      {children}
      <div className={`${styles.footerElement}`}>
        <Footer component={customFooter} />
      </div>
    </div>
  );
}

OfferPageContentWrapper.propTypes = {
  customFooter: PropTypes.func,
  children: PropTypes.node.isRequired,
};

OfferPageContentWrapper.defaultProps = {
  customFooter: null,
};

export default OfferPageContentWrapper;
