function getFormattedDateString(date) {
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString()

  return `${day}/${month}/${year}`
}

function compareDate(leftOperand, rightOperand) {
  return (
    getFormattedDateString(new Date(leftOperand)) ===
    getFormattedDateString(new Date(rightOperand))
  )
}

export default compareDate
