import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useParams, Link, useLocation } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './offerFormStep.module.scss';
import { offerRequestStatuses } from '../../constant';
import offerRequestService from '../../services/offerRequest';
import { getOriginalErrors } from '../../utils/errorHandler';
import Translate from '../translate';

function FormSteps({ offerFormStepsRefreshCount }) {
  const { offerRequestId } = useParams();

  const baseSteps = [
    {
      title: 'offer.tabs.basic_details',
      link: `/offer-requests/${offerRequestId}/basic-details`,
      active: false,
    },
    {
      title: 'offer.tabs.on_site_memo',
      link: `/offer-requests/${offerRequestId}/on-site-memo`,
      active: false,
    },
  ];

  const location = useLocation();
  const [offer, setOffer] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [highestEnabledTabIndex, setHighestEnabledTabIndex] = useState(0);

  const [steps, setSteps] = useState(baseSteps);

  const generateOfferSteps = (offerId) => [
    {
      title: 'offer.tabs.heating_device',
      link: `/offer-requests/${offerRequestId}/offers/${offerId}/heating-devices`,
      active: false,
    },
    {
      title: 'offer.tabs.products',
      link: `/offer-requests/${offerRequestId}/offers/${offerId}/products`,
      active: false,
    },
    {
      title: 'offer.tabs.services',
      link: `/offer-requests/${offerRequestId}/offers/${offerId}/services`,
      active: false,
    },
    {
      title: 'offer.tabs.calculations',
      link: `/offer-requests/${offerRequestId}/offers/${offerId}/calculations`,
      active: false,
    },
    {
      title: 'offer.tabs.offer_paragraph',
      link: `/offer-requests/${offerRequestId}/offers/${offerId}/offer-paragraph`,
      active: false,
    },
    {
      title: 'offer.tabs.offer_preview',
      link: `/offer-requests/${offerRequestId}/offers/${offerId}/offer-preview`,
      active: false,
      activationUrls: ['offer-preview', 'user-offer-preview'],
    },
  ];

  const fetchOfferByRequestIdAndCreateSteps = async () => {
    try {
      let offerId = null;
      if (offer) {
        offerId = offer.id;
      } else {
        const { data: offers } = await offerRequestService.getOffersByRequestId(offerRequestId);
        if (offers && offers.length) {
          offerId = offers[0].id;
          setOffer(offers[0]);
        }
      }
      const updatedSteps = [...baseSteps, ...generateOfferSteps(offerId)];

      const locations = location.pathname.split('/');
      const currentPageName = locations[locations.length - 1];
      const updatedStepsWithActive = updatedSteps.map((step) => {
        const updatedStep = { ...step };
        if (
          (step.activationUrls && step.activationUrls.includes(currentPageName)) ||
          step.link.endsWith(currentPageName)
        ) {
          updatedStep.active = true;
          setCurrentPage(updatedStep.title);
        }
        return updatedStep;
      });

      setSteps(updatedStepsWithActive);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchOfferRequest = async () => {
    try {
      const {
        data: {
          data: { offerRequestProgress },
        },
      } = await offerRequestService.getOfferRequestProgress(offerRequestId);
      const locations = location.pathname.split('/');
      const currentPageName = locations[locations.length - 1];
      const [currentStatus] = offerRequestProgress;
      if (currentPageName)
        switch (currentStatus.event) {
          case offerRequestStatuses.NEW.value:
            setHighestEnabledTabIndex(0);
            break;
          case offerRequestStatuses.ON_SITE_INSPECTION.value:
            setHighestEnabledTabIndex(1);
            break;
          default:
            setHighestEnabledTabIndex(8);
        }
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  useEffect(() => {
    fetchOfferByRequestIdAndCreateSteps();
    fetchOfferRequest();
  }, [offerRequestId, location, offerFormStepsRefreshCount]);

  useEffect(() => {
    fetchOfferRequest();
  }, [offerFormStepsRefreshCount]);

  return (
    <>
      {/* This below div is for desktop view */}
      <div className={`d-none d-md-block ${styles.tabLinkContainer}`}>
        <div className={`d-flex align-items-center ${styles.linkFlexContainer}`}>
          {steps.map(({ title, link, active }, index) => (
            <div>
              <Link
                key={link}
                to={link}
                className={` ${styles.tabLink}  ${active ? styles.tabLinkActive : ''} ${
                  index > highestEnabledTabIndex ? styles.disabled : ''
                }`}
              >
                <Translate id={title} />
              </Link>
            </div>
          ))}
        </div>
      </div>
      {/* The below div is for the mobile view */}
      <div className={`d-md-none ${styles.mobTabLinkContainer}`}>
        <Accordion className={`${styles.mobAccordion}`}>
          <Accordion.Item className={`${styles.mobAccordionItem}`} eventKey="0">
            <Accordion.Header className={`${styles.mobAccordionHeader}`}>
              <Translate id={currentPage} />
            </Accordion.Header>
            <Accordion.Body className={`${styles.mobAccordionBody}`}>
              {steps.map(({ title, link, active }, index) => (
                <div>
                  <Link
                    key={link}
                    to={link}
                    className={`${styles.mobTabLink} ${active ? styles.mobTabLinkActive : ''} ${
                      index > highestEnabledTabIndex ? styles.disabled : ''
                    }`}
                  >
                    <Translate id={title} />
                  </Link>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}

FormSteps.propTypes = {
  offerFormStepsRefreshCount: PropTypes.number.isRequired,
};

export default FormSteps;
