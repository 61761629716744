import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import Form from './Form';
import styles from './index.module.scss';
import Button from '../../../components/button';
import CustomerContact from '../../../components/customer-contact';
import OfferPageContentWrapper from '../../../components/offer-page-content-wrapper';
import Translate from '../../../components/translate';
import { getBasicInformationByOfferRequest } from '../../../services/basicInformation';
import { getCustomer } from '../../../services/customer';
import offerRequestService from '../../../services/offerRequest';
import { getOriginalErrors } from '../../../utils/errorHandler';

function BasicDetailsFooter({
  isInspectionScheduled,
  onButtonClick,
  isInspectionScheduleTriggered,
  taskTab,
}) {
  if (taskTab) {
    return (
      <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onButtonClick}>
        <Translate id="components.button.generate_invoice" />
      </Button>
    );
  }

  if (isInspectionScheduleTriggered) {
    return (
      <Button className="projectBtn offerTabFooterBtn offerTabOrangeBtn" onClick={onButtonClick}>
        <Translate id="components.button.open_onsite_memo" />
      </Button>
    );
  }

  if (isInspectionScheduled) {
    return (
      <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onButtonClick}>
        <Translate id="components.button.continue" />
      </Button>
    );
  }
  return null;
}

function BasicDetails({ setOfferFormStepsRefreshCount, setLoading, taskTab }) {
  const [basicInfo, setBasicInfo] = useState({});
  const [offerRequest, setOfferRequest] = useState({});
  const [customerInformation, setCustomerInformation] = useState({});
  const [isInspectionScheduled, setIsInspectionScheduled] = useState(false);
  const [isInspectionScheduleTriggered, setIsInspectionScheduleTriggered] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const fetchCustomerInformation = async (customerId) => {
    try {
      const {
        data: {
          data: { customer: customerResponse },
        },
      } = await getCustomer(customerId);
      setCustomerInformation(customerResponse);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchOfferRequest = async () => {
    setLoading(true);
    try {
      const {
        data: {
          data: { offerRequest: request },
        },
      } = await offerRequestService.getOfferRequestById(params.offerRequestId);
      setOfferRequest(request);
      setIsInspectionScheduled(request.freeViewingDateTime);
      await fetchCustomerInformation(request.customerId);
      setLoading(false);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const onScheduleSiteInspection = () => {
    setIsInspectionScheduleTriggered(true);
    fetchOfferRequest();
    setOfferFormStepsRefreshCount((prevCount) => prevCount + 1);
  };

  const onBasicDetailsButtonClick = () => {
    if (taskTab) {
      setIsInspectionScheduleTriggered(false);
      navigate(
        `/offer-requests/${params.offerRequestId}/offers/${params.offerId}/tasks/delivery-time`
      );
    } else {
      setIsInspectionScheduleTriggered(false);
      navigate(`/offer-requests/${params.offerRequestId}/on-site-memo`);
    }
  };

  const fetchBasicInformation = async () => {
    setLoading(true);
    try {
      const {
        data: {
          data: { basicInformation: information },
        },
      } = await getBasicInformationByOfferRequest(params.offerRequestId);
      if (information) {
        setBasicInfo(information);
      }
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
    setLoading(false);
  };

  const customFooter = () => (
    <BasicDetailsFooter
      isInspectionScheduled={isInspectionScheduled}
      isInspectionScheduleTriggered={isInspectionScheduleTriggered}
      onButtonClick={onBasicDetailsButtonClick}
      taskTab={taskTab}
    />
  );

  useEffect(() => {
    fetchOfferRequest();
    fetchBasicInformation();
  }, []);

  return (
    <OfferPageContentWrapper customFooter={customFooter}>
      <div className={`${styles.basicDetailsMainContainer}`}>
        <div
          className={`d-md-flex align-items-center justify-content-between ${styles.headerContainer}`}
        >
          <div className={`${styles.titleContainer}`}>
            <p>
              <Translate id="offer.basic_details.title" />
            </p>
          </div>
          <CustomerContact customerInformation={customerInformation} />
        </div>
        <div className={`${styles.formMainContainer}`}>
          <Form
            basicInfo={basicInfo}
            offerRequest={offerRequest}
            onScheduleSiteInspection={onScheduleSiteInspection}
            showSchedule
          />
        </div>
      </div>
    </OfferPageContentWrapper>
  );
}

BasicDetails.propTypes = {
  setOfferFormStepsRefreshCount: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  taskTab: PropTypes.bool,
};
BasicDetails.defaultProps = {
  taskTab: false,
};
BasicDetailsFooter.propTypes = {
  isInspectionScheduled: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  isInspectionScheduleTriggered: PropTypes.bool.isRequired,
  taskTab: PropTypes.bool,
};
BasicDetailsFooter.defaultProps = {
  taskTab: false,
};

export default BasicDetails;
