import PropTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';
import NavMenu from '../nav-menu';

function Header({ component: PropComponent, links }) {
  return (
    <div className={`${styles.headerMainContainer}`}>
      <div
        className={`d-flex align-items-center justify-content-between projectContainer ${styles.headerContainer}`}
      >
        {PropComponent && <PropComponent />}
        <div>
          <NavMenu links={links} />
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  component: PropTypes.func,
  links: PropTypes.instanceOf(Array).isRequired,
};

Header.defaultProps = {
  component: null,
};

export default Header;
