import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './offerParagraph.module.scss';
import Button from '../../../components/button';
import OfferPageContentWrapper from '../../../components/offer-page-content-wrapper';
import Translate from '../../../components/translate';
import { installationTypes, currentHeatingSystems } from '../../../constant';
import { getBasicInformationByOfferRequest } from '../../../services/basicInformation';
import heatingDevicesServices from '../../../services/heatingDevices';
import offerServices from '../../../services/offer';
import { getProductsByOffer } from '../../../services/offerProducts';
import offerRequestService from '../../../services/offerRequest';
import { getOfferServices } from '../../../services/offerService';
import { parseDescription } from '../../../utils/common';
import { getOriginalErrors } from '../../../utils/errorHandler';
import WithEnableDisableTab from '../withEnableDisableTab';

function IntroductionInput({ value, onValueChange }) {
  const { offerRequestId } = useParams();
  const { t } = useTranslation('lang');

  const generateContent = (basicInfo, offerRequest) => {
    const installationTypeText = basicInfo.installationType
      ? t(installationTypes[basicInfo.installationType])
      : 'N.A.';

    const heatingAreaText = basicInfo.heatingArea || 'N.A.';
    const currentHeatingSystemText = basicInfo.currentHeatingSystem
      ? t(currentHeatingSystems[basicInfo.currentHeatingSystem])
      : 'N.A.';
    const currentConsumptionText = basicInfo.currentConsumption || 'N.A.';

    const installationAddress = `${offerRequest.installationTargetAddress}, ${offerRequest.installationTargetCity}, ${offerRequest.installationTargetZipCode}`;

    const content = t('offer.offer_paragraph.introduction_content', {
      installationTarget: installationTypeText,
      heatingArea: heatingAreaText,
      currentHeatingSystem: currentHeatingSystemText,
      currentConsumption: currentConsumptionText,
      installationAddress,
    });

    onValueChange(content);
  };

  const fetchInitialContentForOfferIntroduction = async () => {
    try {
      if (value === '') {
        const {
          data: {
            data: { basicInformation },
          },
        } = await getBasicInformationByOfferRequest(offerRequestId);
        const {
          data: {
            data: { offerRequest },
          },
        } = await offerRequestService.getOfferRequestById(offerRequestId);
        if (basicInformation && offerRequest) {
          generateContent(basicInformation, offerRequest);
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    fetchInitialContentForOfferIntroduction();
  }, [value]);

  return (
    <div>
      <textarea
        className={`inputField ${styles.textArea}`}
        name="introduction"
        id="introduction"
        cols="30"
        rows="10"
        value={value}
        onChange={(e) => onValueChange(e.target.value)}
      />
    </div>
  );
}

function HeatingDeviceList() {
  const params = useParams();
  const [heatingDevices, setHeatingDevices] = useState([]);

  const fetchHeatingDevices = async () => {
    try {
      const {
        data: {
          data: { heatingDevices: fetchedHeatingDevices },
        },
      } = await heatingDevicesServices.getOfferHeatingDevices({
        requestId: params.offerRequestId,
        offerId: params.offerId,
      });
      setHeatingDevices(fetchedHeatingDevices);
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    fetchHeatingDevices();
  }, []);
  return (
    <div className={`${styles.offerParagraphDiv}`}>
      {heatingDevices.map((device) => (
        <div key={device.id}>
          <h3 className={`${styles.divTitle}`}>{device.name}</h3>
          {device.description && (
            <div className={`${styles.divContent}`}>
              <div dangerouslySetInnerHTML={{ __html: parseDescription(device.description) }} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

function ProductList() {
  const params = useParams();
  const [products, setProducts] = useState([]);
  const { t } = useTranslation('lang');

  const fetchProducts = async () => {
    try {
      const {
        data: {
          data: { components: fetchedProducts },
        },
      } = await getProductsByOffer(params.offerRequestId, params.offerId);
      setProducts(fetchedProducts);
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div className={`${styles.offerParagraphDiv}`}>
      <h2 className={`${styles.divTitle}`}>{t('offer.offer_paragraph.products_title')}</h2>
      {products && products.length > 0 && (
        <div className={`${styles.divContent} ${styles.divContentBullet}`}>
          {products.map((product) => (
            <div key={product.id}>
              <p>{product.name}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function ServiceList() {
  const params = useParams();
  const [services, setServices] = useState([]);
  const { t } = useTranslation('lang');

  const fetchServices = async () => {
    try {
      const {
        data: {
          data: { offerServices: fetchedOfferServices },
        },
      } = await getOfferServices(params.offerRequestId, params.offerId);
      setServices(fetchedOfferServices);
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  return (
    <div className={`${styles.offerParagraphDiv} ${styles.serviceDiv}`}>
      <h2 className={`${styles.divTitle} ${styles.serviceTitle}`}>
        {t('offer.offer_paragraph.services_title')}
      </h2>

      {services.map((service) => (
        <div key={service.id}>
          <h3 className={`${styles.divTitle}`}>
            {service.name} ({service.quantity} {service.unit})
          </h3>
          {service.description && (
            <div className={`${styles.divContent}`}>
              <div dangerouslySetInnerHTML={{ __html: parseDescription(service.description) }} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

function OfferParagraphFooter({
  hasOfferParagraphDataChanged,
  offer,
  offerIntroduction,
  offerRequestId,
  offerId,
  moveToNextTab,
  fetchOffer,
}) {
  const { t } = useTranslation('lang');
  const navigate = useNavigate();
  const onBack = () => navigate(`/offer-requests/${offerRequestId}/offers/${offerId}/calculations`);
  const onSave = async () => {
    try {
      await offerServices.updateOfferById(offerRequestId, offerId, {
        ...offer,
        introduction: offerIntroduction,
      });
      toast.success(t('offer.offer_paragraph.introduction_saved_successful_message'));
      fetchOffer();
      moveToNextTab();
    } catch (err) {
      toast.error(err);
    }
  };

  return hasOfferParagraphDataChanged ? (
    <div className={`d-flex align-items-center justify-content-end ${styles.btnContainer}`}>
      <div>
        <Button className="projectBtn offerTabFooterBtn offerTabGrayBtn" onClick={onBack}>
          <Translate id="components.button.back" />
        </Button>
      </div>
      <div>
        <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onSave}>
          <Translate id="components.button.save" />
        </Button>
      </div>
    </div>
  ) : (
    <div className={`d-flex align-items-center justify-content-end ${styles.btnContainer}`}>
      <div>
        <Button className="projectBtn offerTabFooterBtn offerTabGrayBtn" onClick={onBack}>
          <Translate id="components.button.back" />
        </Button>
      </div>
      <div>
        <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={moveToNextTab}>
          <Translate id="components.button.continue" />
        </Button>
      </div>
    </div>
  );
}

function OfferParagraph() {
  const [offer, setOffer] = useState(null);
  const [offerIntroductionForComparison, setofferIntroductionForComparison] = useState(null);
  const [offerIntroduction, setOfferIntroduction] = useState(null);
  const { t } = useTranslation('lang');
  const params = useParams();
  const navigate = useNavigate();

  const fetchOffer = async () => {
    try {
      const {
        data: {
          data: { offer: offerData },
        },
      } = await offerServices.getOfferById(params.offerRequestId, params.offerId);
      setOffer(offerData);
      setOfferIntroduction(offerData.introduction ?? '');
      setofferIntroductionForComparison(offerData.introduction ?? '');
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const customFooter = () => (
    <OfferParagraphFooter
      moveToNextTab={() =>
        navigate(`/offer-requests/${params.offerRequestId}/offers/${params.offerId}/offer-preview`)
      }
      offerId={params.offerId}
      offer={offer}
      fetchOffer={fetchOffer}
      offerIntroduction={offerIntroduction}
      hasOfferParagraphDataChanged={
        JSON.stringify(offerIntroduction) !== JSON.stringify(offerIntroductionForComparison)
      }
      offerRequestId={params.offerRequestId}
    />
  );

  useState(() => {
    fetchOffer();
  }, []);

  return (
    <OfferPageContentWrapper customFooter={customFooter}>
      <div className={`${styles.offerParagraphContainer}`}>
        <h1 className={`${styles.offerParagraphTitle}`}>{t('offer.offer_paragraph.title')}</h1>
        <h2 className={`${styles.introductionTitle}`}>{t('offer.offer_paragraph.introduction')}</h2>
        <IntroductionInput value={offerIntroduction} onValueChange={setOfferIntroduction} />
        <HeatingDeviceList />
        <ProductList />
        <ServiceList />
      </div>
    </OfferPageContentWrapper>
  );
}

OfferParagraphFooter.propTypes = {
  hasOfferParagraphDataChanged: PropTypes.bool.isRequired,
  offer: PropTypes.instanceOf(Object).isRequired,
  offerIntroduction: PropTypes.string.isRequired,
  offerRequestId: PropTypes.string.isRequired,
  offerId: PropTypes.string.isRequired,
  moveToNextTab: PropTypes.func.isRequired,
  fetchOffer: PropTypes.func.isRequired,
};

IntroductionInput.propTypes = {
  value: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
};

IntroductionInput.defaultProps = {
  value: '',
};

export default WithEnableDisableTab(OfferParagraph);
