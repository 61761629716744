import apiClient from './api-client';

export const getAssinmentByTask = (companyId, employeeId, taskId) =>
  apiClient.get(`v1/company/${companyId}/employee/${employeeId}/tasks/${taskId}/assignment`);

export const getOfferRequestByTask = (companyId, employeeId, taskId) =>
  apiClient.get(
    `v1/company/${companyId}/employee/${employeeId}/tasks/${taskId}/assignment/offer/offer-request`
  );

export const getAssignmentDetails = (companyId, assignmentId) =>
  apiClient.get(`/v1/company/${companyId}/assignments/${assignmentId}`);

export const getAssignmentCustomer = (companyId, assignmentId) =>
  apiClient.get(`/v1/company/${companyId}/assignments/${assignmentId}/customer-information`);

export const getOfferRequestByAssignment = (companyId, assignmentId) =>
  apiClient.get(`v1/company/${companyId}/assignments/${assignmentId}/offer/offer-request`);
