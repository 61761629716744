import PropTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';
import Translate from '../../translate';
import NumberControl from '../number-control';

function RadioControl({
  label,
  noLabel,
  yesLabel,
  distanceLabel,
  name,
  value,
  onChange,
  distanceValue,
  distanceChange,
}) {
  return (
    <div className={`${styles.radioControlContainer}`}>
      <div>
        <p>
          <Translate id={label} />
        </p>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <label className="radioContainer" htmlFor={`${name}_no`}>
              <Translate id={noLabel} />
              <input
                type="checkbox"
                name={name}
                value="no"
                checked={!value}
                onChange={onChange}
                id={`${name}_no`}
              />
              <span className="radioIcon" />
            </label>
          </div>
          <div>
            <label className="radioContainer" htmlFor={`${name}_yes`}>
              <Translate id={yesLabel} />
              <input
                type="checkbox"
                name={name}
                value="yes"
                checked={value}
                onChange={onChange}
                id={`${name}_yes`}
              />
              <span className="radioIcon" />
            </label>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <p>
              , <Translate id={distanceLabel} /> :
            </p>
          </div>
          {/*
              <input className={`inputField ${styles.inputField}`} type="number" />
              <p className={`${styles.centimeter}`}>m</p>
            */}
          <NumberControl
            unit="units.m"
            value={distanceValue}
            onChange={distanceChange}
            disable={!value}
          />
        </div>
      </div>
    </div>
  );
}

RadioControl.propTypes = {
  label: PropTypes.string.isRequired,
  noLabel: PropTypes.string.isRequired,
  yesLabel: PropTypes.string.isRequired,
  distanceLabel: PropTypes.string.isRequired,
};

export default RadioControl;
