import PropTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';
import { ReactComponent as FeatherArrowRight } from '../../assets/svgs/FeatherArrowRight.svg';
import Button from '../button';
import Dropdown from '../drop-down';

export function SortDropdown({
  sortOptions,
  selectedSort,
  sortOrder,
  onChange,
  className,
  selectionKey,
}) {
  return (
    <div className={`d-flex align-items-center ${styles.arrowContainer}`}>
      <Dropdown
        options={sortOptions}
        selected={selectedSort}
        onChange={(sortBy) => onChange({ sortBy, sortOrder })}
        className={className}
        selectionKey={selectionKey}
      />
      {sortOrder === 'ASC' ? (
        <Button onClick={() => onChange({ sortBy: selectedSort, sortOrder: 'DESC' })}>
          <FeatherArrowRight className={styles.sortArrowUp} />
        </Button>
      ) : (
        <Button onClick={() => onChange({ sortBy: selectedSort, sortOrder: 'ASC' })}>
          <FeatherArrowRight className={styles.sortArrowDown} />
        </Button>
      )}
    </div>
  );
}

SortDropdown.propTypes = {
  sortOptions: PropTypes.instanceOf(Array).isRequired,
  selectedSort: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  sortOrder: PropTypes.oneOf(['ASC', 'DESC']),
  className: PropTypes.string,
  selectionKey: PropTypes.string.isRequired,
};

SortDropdown.defaultProps = {
  className: null,
  sortOrder: 'ASC',
};
