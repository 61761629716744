import PropTypes from 'prop-types';
import React from 'react';

import styles from './BasicDetailsWrapper.module.scss';
import Footer from '../../../components/footer';

function BasicDetailsWrapper({ customFooter, children }) {
  return (
    <div className={`${styles.mainContainer}`}>
      {children}
      <div className={`${styles.footerElement}`}>
        <Footer component={customFooter} />
      </div>
    </div>
  );
}

BasicDetailsWrapper.propTypes = {
  customFooter: PropTypes.func,
  children: PropTypes.node.isRequired,
};

BasicDetailsWrapper.defaultProps = {
  customFooter: null,
};

export default BasicDetailsWrapper;
