import apiClient from './api-client';

export const saveInvoice = (offerRequestId, offerId, payload) =>
  apiClient.put(
    `/v1/offer-requests/${offerRequestId}/offers/${offerId}/task/invoices/current`,
    payload
  );

export const sendToNetvisor = (offerRequestId, offerId) =>
  apiClient.put(
    `/v1/offer-requests/${offerRequestId}/offers/${offerId}/task/invoices/current/send`,
    {}
  );

export const getCurrentInvoice = (offerRequestId, offerId) =>
  apiClient.get(`/v1/offer-requests/${offerRequestId}/offers/${offerId}/task/invoices/current`);
