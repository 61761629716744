import PropTypes from 'prop-types';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { getToken } from '../../services/authorization';

function NoAuth({ children }) {
  if (getToken()) {
    return <Navigate to="/" />;
  }

  return children;
}

NoAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export { NoAuth };
