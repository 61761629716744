/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import styles from './index.module.scss';
import { ReactComponent as UploadSvg } from '../../assets/svgs/Upload.svg';
import { ReactComponent as TrashSvg } from '../../assets/svgs/trash.svg';
import CaptureImage from '../capture-image';
import Translate from '../translate';

function UploadFiles({ label, icon, multiple, files, setFiles, readOnly, listOnly, capture }) {
  const inputRef = useRef(null);

  const removeFileFromList = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  const onChange = (e) => {
    setFiles((prev) =>
      multiple ? [...prev, ...Array.from(e.target.files)] : Array.from(e.target.files)
    );
  };

  return (
    <div className={`${styles.uploadFileContainer}`}>
      {!listOnly && (
        <div className={`d-flex justify-content-between ${styles.uploadBtnContainer}`}>
          <div className={`${styles.btnContainer}`}>
            <button
              type="button"
              className={`d-flex align-items-center justify-content-center projectBtn ${styles.uploadBtn}`}
            >
              <div className={`${styles.uploadIcon}`}>{icon || <UploadSvg />}</div>
              <div className={`${styles.uploadText}`}>
                {label ? (
                  <Translate id={label} />
                ) : (
                  <Translate
                    id={`components.capture_image.upload_${multiple ? 'files' : 'file'}`}
                  />
                )}
              </div>
            </button>
            <input
              ref={inputRef}
              type="file"
              name="file-upload"
              id="file-upload"
              onChange={onChange}
              multiple={multiple}
              readOnly={readOnly}
            />
          </div>
          <div>
            {capture && (
              <CaptureImage files={files} setFiles={setFiles} multiple onChange={onChange} />
            )}
          </div>
        </div>
      )}
      <div className={`${styles.uploadedFileContainer}`}>
        {files.map((file) => (
          <div key={file.name}>
            <div className={`${styles.mainContainer}`}>
              <div
                key={file.name}
                className={`d-flex align-items-center ${styles.uploadedFileItem}`}
              >
                <div className={`${styles.uploadedFileItemNameContainer}`}>
                  <p>{file.name}</p>
                </div>
                {!readOnly && (
                  <div className={`${styles.trashBtnContainer}`}>
                    <TrashSvg onClick={() => removeFileFromList(file)} />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

UploadFiles.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
  multiple: PropTypes.bool,
  files: PropTypes.instanceOf(Array).isRequired,
  setFiles: PropTypes.func,
  readOnly: PropTypes.bool,
  listOnly: PropTypes.bool,
  capture: PropTypes.bool,
};

UploadFiles.defaultProps = {
  multiple: false,
  icon: <UploadSvg />,
  label: null,
  readOnly: false,
  listOnly: false,
  setFiles: () => null,
  capture: false,
};

export default UploadFiles;
