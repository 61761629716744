import PropTypes from 'prop-types';
import React from 'react';

import styles from './typeSignature.module.scss';
import TextControl from '../form-fields/text-control';

function TypeSignature({ typedSignature, setTypedSignature }) {
  return (
    <div className={`${styles.typeSignatureContainer}`}>
      <TextControl
        label="components.signature.title"
        placeholder="components.signature.title"
        value={typedSignature}
        onChange={(e) => setTypedSignature(e.target.value)}
      />
    </div>
  );
}

TypeSignature.propTypes = {
  typedSignature: PropTypes.string.isRequired,
  setTypedSignature: PropTypes.func.isRequired,
};

export default TypeSignature;
