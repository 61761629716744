/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './index.module.scss';
import Button from '../../../components/button';
import CustomerContact from '../../../components/customer-contact';
import NumberTranslate from '../../../components/number-translate';
import OfferPageContentWrapper from '../../../components/offer-page-content-wrapper';
import OfferSummary from '../../../components/offer-summary';
import Translate from '../../../components/translate';
import UploadFiles from '../../../components/uploadFiles';
import { attachmentableTypes } from '../../../constant';
import { deleteAttachments, getAttachments, uploadAttachments } from '../../../services/attachment';
import { getBasicInformationByOfferRequest } from '../../../services/basicInformation';
import { getCustomer } from '../../../services/customer';
import HeatingDeviceServices from '../../../services/heatingDevices';
import offerService from '../../../services/offer';
import { getProductsByOffer } from '../../../services/offerProducts';
import offerRequestService from '../../../services/offerRequest';
import { getOfferServices } from '../../../services/offerService';
import { getTechnicalInformation } from '../../../services/onSiteMemo';
import { getOriginalErrors } from '../../../utils/errorHandler';
import Form from '../basic-details/Form';
import WithEnableDisableTab from '../withEnableDisableTab';

function Attachments({
  heatingDevices,
  files,
  setFiles,
  setOldFiles,
  fetchLatestAttachments,
  products,
  offerServices,
}) {
  const { offerRequestId, offerId } = useParams();

  const fetchTechnicalInformationAttachments = async () => {
    try {
      const {
        data: {
          data: { technicalInformation },
        },
      } = await getTechnicalInformation(offerRequestId);

      if (technicalInformation) {
        const {
          data: {
            data: { attachments },
          },
        } = await getAttachments(
          attachmentableTypes.TECHNICAL_INFORMATION,
          technicalInformation.id
        );
        return attachments;
      }

      return [];
    } catch (error) {
      return [];
    }
  };

  const fetchHeatingDevicesAttachments = async () => {
    try {
      if (Array.isArray(heatingDevices) && heatingDevices.length > 0) {
        const responses = await Promise.all(
          heatingDevices.map(({ id }) => getAttachments(attachmentableTypes.HEATING_DEVICE, id))
        );

        let heatingDevicesAttachments = [];
        responses.forEach(
          ({
            data: {
              data: { attachments },
            },
          }) => {
            heatingDevicesAttachments = [...heatingDevicesAttachments, ...attachments];
          }
        );

        return heatingDevicesAttachments;
      }

      return [];
    } catch (error) {
      return [];
    }
  };

  const fetchProductsAttachments = async () => {
    try {
      if (Array.isArray(products) && products.length > 0) {
        const responses = await Promise.all(
          products.map(({ id }) => getAttachments(attachmentableTypes.COMPONENT, id))
        );

        let productsAttachments = [];
        responses.forEach(
          ({
            data: {
              data: { attachments },
            },
          }) => {
            productsAttachments = [...productsAttachments, ...attachments];
          }
        );

        return productsAttachments;
      }

      return [];
    } catch (error) {
      return [];
    }
  };

  const fetchOfferServicesAttachments = async () => {
    try {
      if (Array.isArray(offerServices) && offerServices.length > 0) {
        const responses = await Promise.all(
          offerServices.map(({ id }) => getAttachments(attachmentableTypes.SERVICE, id))
        );

        let offerServicesAttachments = [];
        responses.forEach(
          ({
            data: {
              data: { attachments },
            },
          }) => {
            offerServicesAttachments = [...offerServicesAttachments, ...attachments];
          }
        );

        return offerServicesAttachments;
      }

      return [];
    } catch (error) {
      return [];
    }
  };

  const fetchOfferAttachments = async () => {
    try {
      const {
        data: {
          data: { attachments },
        },
      } = await getAttachments(attachmentableTypes.OFFER, offerId);
      return attachments;
    } catch (error) {
      return [];
    }
  };

  const fetchAllAttachments = async () => {
    const technicalAttachments = await fetchTechnicalInformationAttachments();
    const heatingDeviceAttachments = await fetchHeatingDevicesAttachments();
    const offerAttachments = await fetchOfferAttachments();
    const productAttachments = await fetchProductsAttachments();
    const offerServiceAttachments = await fetchOfferServicesAttachments();
    const allFiles = [
      ...technicalAttachments,
      ...heatingDeviceAttachments,
      ...offerAttachments,
      ...productAttachments,
      ...offerServiceAttachments,
    ];
    setFiles(allFiles);
    setOldFiles(allFiles);
  };

  const handleFileUpload = (newFiles) => {
    setFiles(newFiles);
  };

  useEffect(() => {
    fetchAllAttachments();
  }, [
    offerRequestId,
    heatingDevices.length,
    fetchLatestAttachments,
    products.length,
    offerServices.length,
  ]);

  return (
    <div className={`${styles.attachFileContainer}`}>
      <div className={`${styles.attachFileTitleContainer}`}>
        <p className={`${styles.attachFileTitle}`}>
          <Translate id="offer.offer_preview.attach_a_file" />
        </p>
      </div>
      <UploadFiles multiple files={files} setFiles={handleFileUpload} />
    </div>
  );
}

function OfferPreviewFooter({ showSave, onSave }) {
  const navigate = useNavigate();
  const { offerRequestId, offerId } = useParams();
  const onConfirmAndPreviewClick = () =>
    navigate(`/offer-requests/${offerRequestId}/offers/${offerId}/user-offer-preview`);
  const onBack = () =>
    navigate(`/offer-requests/${offerRequestId}/offers/${offerId}/offer-paragraph`);
  return (
    <div className={`d-flex align-items-center justify-content-end ${styles.btnContainer}`}>
      <div>
        <Button
          className={`projectBtn offerTabFooterBtn offerTabGrayBtn ${styles.closeBtn}`}
          onClick={onBack}
        >
          <Translate id="components.button.back" />
        </Button>
      </div>
      <div>
        {showSave ? (
          <Button
            className={`projectBtn offerTabFooterBtn offerTabGreenBtn ${styles.closeBtn}`}
            onClick={onSave}
          >
            <Translate id="components.button.save" />
          </Button>
        ) : (
          <Button
            className={`projectBtn offerTabFooterBtn offerTabGreenBtn ${styles.closeBtn}`}
            onClick={onConfirmAndPreviewClick}
          >
            <Translate id="components.button.confirm_and_preview" />
          </Button>
        )}
      </div>
    </div>
  );
}

function ProfitCalculation({ totalPurchasePrice, totalSellingPrice, profitMargin, vat }) {
  const { t } = useTranslation('lang');

  return (
    <div className={`${styles.calculationContainer}`}>
      <div>
        <Translate id="offer.profit_calculation.title" /> &#40;{' '}
        <Translate id="offer.profit_calculation.vat" /> {vat}% &#41;:
      </div>
      <div>
        <span>
          <Translate id="offer.profit_calculation.cost_in_total" />:
        </span>
        <span>
          {' '}
          <NumberTranslate value={totalPurchasePrice} /> {t('units.euro')}
        </span>
      </div>
      <div>
        <span>
          <Translate id="offer.profit_calculation.offer_total" />:
        </span>
        <span>
          {' '}
          <NumberTranslate value={totalSellingPrice} /> {t('units.euro')}
        </span>
      </div>
      <div>
        <span>
          <Translate id="offer.profit_calculation.profit_margin" />:
        </span>
        <span>
          <NumberTranslate value={profitMargin} />
          {t('units.euro')}
        </span>
      </div>
    </div>
  );
}

function OfferPreview() {
  const [customerInformation, setCustomerInformation] = useState({});
  const [basicInformation, setBasicInformation] = useState({});
  const [offerRequest, setOfferRequest] = useState({});
  const [selectedHeatingDevices, setSelectedHeatingDevices] = useState([]);
  const [products, setProducts] = useState([]);
  const [offerServices, setOfferServices] = useState([]);
  const [comments, setComments] = useState({});
  const [oldComments, setOldComments] = useState({});
  const [files, setFiles] = useState([]);
  const [oldFiles, setOldFiles] = useState([]);
  const [fetchLatestAttachments, setFetchLatestAttachments] = useState(false);
  const [summaryCalculation, setSummaryCalculation] = useState({});

  const { t } = useTranslation('lang');

  const params = useParams();

  const fetchCustomerInformation = async (customerId) => {
    try {
      const {
        data: {
          data: { customer: customerResponse },
        },
      } = await getCustomer(customerId);
      setCustomerInformation(customerResponse);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchOfferRequest = async () => {
    try {
      const {
        data: {
          data: { offerRequest: request },
        },
      } = await offerRequestService.getOfferRequestById(params.offerRequestId);
      setOfferRequest(request);
      await fetchCustomerInformation(request.customerId);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchBasicInformation = async () => {
    try {
      const {
        data: {
          data: { basicInformation: information },
        },
      } = await getBasicInformationByOfferRequest(params.offerRequestId);
      if (information) {
        setBasicInformation(information);
      }
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchOfferHeatingDevices = async () => {
    try {
      const {
        data: {
          data: { heatingDevices: offerHeatingDevices },
        },
      } = await HeatingDeviceServices.getOfferHeatingDevices({
        requestId: params.offerRequestId,
        offerId: params.offerId,
      });

      setSelectedHeatingDevices(offerHeatingDevices);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchProductsByOffer = async () => {
    try {
      const {
        data: {
          data: { components: offerProducts },
        },
      } = await getProductsByOffer(params.offerRequestId, params.offerId);
      setProducts(offerProducts);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchOfferServices = async () => {
    try {
      const {
        data: {
          data: { offerServices: associatedServices },
        },
      } = await getOfferServices(params.offerRequestId, params.offerId);
      setOfferServices(associatedServices);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchOfferDetails = async () => {
    try {
      const {
        data: {
          data: {
            offer: { heatingDevicesComment, servicesComment, productsComment },
          },
        },
      } = await offerService.getOfferById(params.offerRequestId, params.offerId);

      const commentsData = {
        heatingDevicesComment,
        servicesComment,
        productsComment,
      };

      setComments(commentsData);
      setOldComments(commentsData);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const onCommentsChange = (updatedComments) => {
    setComments(updatedComments);
  };

  const saveCommentsOnOffer = async () => {
    await offerService.updateOfferById(params.offerRequestId, params.offerId, { ...comments });
    setOldComments(comments);
  };

  const saveAttachments = async () => {
    const attachmentsToUpload = files.filter((file) => !file.id);
    const attachmentsToRemove = oldFiles.filter(
      (oldFile) => files.findIndex((file) => file.id === oldFile.id) === -1
    );

    if (attachmentsToUpload.length > 0) {
      await uploadAttachments(attachmentableTypes.OFFER, params.offerId, attachmentsToUpload);
    }

    if (attachmentsToRemove.length > 0) {
      await Promise.all(
        attachmentsToRemove.map(({ attachmentableType, attachmentableId, id }) =>
          deleteAttachments(attachmentableType, attachmentableId, [id])
        )
      );
    }
  };

  const onOfferPreviewChangesSave = async () => {
    try {
      if (JSON.stringify(comments) !== JSON.stringify(oldComments)) {
        saveCommentsOnOffer();
      }
      if (JSON.stringify(files) !== JSON.stringify(oldFiles)) {
        saveAttachments();
        setFetchLatestAttachments((prev) => !prev);
      }
      toast.success(t('offer.offer_preview.offer_updated'));
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };
  const getSummaryCalculations = async () => {
    try {
      const {
        data: {
          data: { calculations },
        },
      } = await offerService.getOfferPreviewSummeryCalculation(
        params.offerRequestId,
        params.offerId
      );
      setSummaryCalculation(calculations);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const customFooter = () => (
    <OfferPreviewFooter
      showSave={
        JSON.stringify(comments) !== JSON.stringify(oldComments) ||
        JSON.stringify(files) !== JSON.stringify(oldFiles)
      }
      onSave={onOfferPreviewChangesSave}
    />
  );

  useEffect(() => {
    fetchOfferRequest();
    fetchOfferDetails();
    fetchBasicInformation();
    fetchOfferHeatingDevices();
    fetchProductsByOffer();
    fetchOfferServices();
    getSummaryCalculations();
  }, []);

  return (
    <OfferPageContentWrapper customFooter={customFooter}>
      <div className={`${styles.offerPreviewContainer}`}>
        <div
          className={`d-md-flex align-items-center justify-content-between ${styles.headerContainer}`}
        >
          <div className={`${styles.titleContainer}`}>
            <p>
              <Translate id="offer.offer_preview.title" />
            </p>
          </div>
          <CustomerContact customerInformation={customerInformation} />
        </div>
        <div className={`${styles.formMainContainer}`}>
          <div>
            <Form
              basicInfo={basicInformation}
              offerRequest={offerRequest}
              attachments={
                <Attachments
                  heatingDevices={selectedHeatingDevices}
                  files={files}
                  setFiles={setFiles}
                  setOldFiles={setOldFiles}
                  fetchLatestAttachments={fetchLatestAttachments}
                  products={products}
                  offerServices={offerServices}
                />
              }
            />
          </div>

          <div>
            <OfferSummary
              vatRate={summaryCalculation?.offerPriceVat}
              comments={comments}
              setComments={onCommentsChange}
              summaryCalculation={summaryCalculation}
            />
          </div>
          <div>
            <ProfitCalculation
              totalPurchasePrice={summaryCalculation.total?.costInTotal}
              totalSellingPrice={summaryCalculation.total?.offerTotal}
              profitMargin={summaryCalculation.total?.profitMargin}
              vat={summaryCalculation?.offerPriceVat}
            />
          </div>
        </div>
      </div>
    </OfferPageContentWrapper>
  );
}

OfferPreviewFooter.propTypes = {
  showSave: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
};

OfferPreviewFooter.defaultProps = {
  showSave: false,
};

Attachments.propTypes = {
  heatingDevices: PropTypes.instanceOf(Array),
  files: PropTypes.instanceOf(Array),
  setFiles: PropTypes.func,
  setOldFiles: PropTypes.func,
  fetchLatestAttachments: PropTypes.bool,
  products: PropTypes.instanceOf(Array),
  offerServices: PropTypes.instanceOf(Array),
};

Attachments.defaultProps = {
  heatingDevices: [],
  files: [],
  setFiles() {},
  setOldFiles() {},
  fetchLatestAttachments: false,
  products: [],
  offerServices: [],
};

export default WithEnableDisableTab(OfferPreview);
