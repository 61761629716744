import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsFetching, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './index.module.scss';
import { ReactComponent as PenSvg } from '../../../assets/svgs/Pen.svg';
import Button from '../../../components/button';
import CompanyAdminContentWrapper from '../../../components/company-admin-content-wrapper';
import CalendarControl from '../../../components/form-fields/calendar-control';
import CheckBoxControl from '../../../components/form-fields/checkbox-control';
import DropDownControl from '../../../components/form-fields/drop-down-control';
import NumberControl from '../../../components/form-fields/number-control';
import SignatureModal from '../../../components/signature-modal';
import Translate from '../../../components/translate';
import {
  signatureTypes,
  userRoles,
  rockQualities,
  waterProductions,
  geothermalFluids,
  drillingWasteHandling,
  heatCollectionPipingOptions,
} from '../../../constant';
import AuthService from '../../../services/auth';
import { updateTaskComment } from '../../../services/offerService';
import {
  saveDrillingReport,
  saveDrillingReportCustomerSign,
  saveDrillingReportEmployeeSign,
} from '../../../services/report';
import { takServiceComplete } from '../../../services/task';
import { updateServiceWells } from '../../../services/well';
import { getOriginalErrors } from '../../../utils/errorHandler';
import { useBasicInformationContext } from '../context/basicInformationContext';
import { DrillingReportContext, useDrillingReportContext } from '../context/drillingReportContext';
import { useServiceContext } from '../context/service-context';
import { useWellContext, WellContext } from '../context/wellContext';

function FormControl({ title, children }) {
  return (
    <div>
      <div>
        <Translate id={title} />:
      </div>
      <div>{children}</div>
    </div>
  );
}

function WellNumberInput({ value, onChange }) {
  return (
    <NumberControl
      label="assign_teams.drilling_crew.well_no"
      value={value}
      name="heating_area"
      onChange={onChange}
      disable
      isControlRequired={false}
    />
  );
}

function DrillingDepthInput({ value, onChange }) {
  const { t } = useTranslation('lang');

  return (
    <NumberControl
      label="assign_teams.drilling_crew.drilling_depth"
      value={value}
      name="heating_area"
      unit={<span>{t('units.m')}</span>}
      onChange={onChange}
    />
  );
}
function CrustThicknessInput({ value, onChange }) {
  const { t } = useTranslation('lang');

  return (
    <NumberControl
      label="assign_teams.drilling_crew.earth_crust_thickness"
      value={value}
      name="heating_area"
      unit={<span>{t('units.mm')}</span>}
      onChange={onChange}
    />
  );
}

function PipeThicknessInput({ value, onChange }) {
  const { t } = useTranslation('lang');

  return (
    <NumberControl
      label="assign_teams.drilling_crew.steel_pipe_thickness"
      value={value}
      name="heating_area"
      unit={<span>{t('units.mm')}</span>}
      onChange={onChange}
    />
  );
}

function DiameterOfSteelPipeInput({ value, onChange }) {
  const { t } = useTranslation('lang');

  return (
    <NumberControl
      label="assign_teams.drilling_crew.the_diameter_of_the_steel_pipe"
      value={value}
      name="heating_area"
      unit={<span>{t('units.mm')}</span>}
      onChange={onChange}
    />
  );
}

function WellDiameterInput({ value, onChange }) {
  const { t } = useTranslation('lang');

  return (
    <NumberControl
      label="assign_teams.drilling_crew.well_diameter"
      value={value}
      name="heating_area"
      unit={<span>{t('units.mm')}</span>}
      onChange={onChange}
    />
  );
}

function DrillingAngleInput({ value, onChange }) {
  const { t } = useTranslation('lang');

  return (
    <NumberControl
      label="assign_teams.drilling_crew.drilling_angle"
      value={value}
      name="heating_area"
      unit={<span>{t('units.degree')}</span>}
      onChange={onChange}
    />
  );
}

function RockQualityInput({ value, onChange }) {
  const { t } = useTranslation('lang');
  const rockQualityOptions = Object.keys(rockQualities).map((key) => ({
    id: key,
    label: t(rockQualities[key]),
  }));

  return (
    <DropDownControl
      type="object"
      label="assign_teams.drilling_crew.rock_quality"
      options={rockQualityOptions}
      selected={value}
      onChange={onChange}
      selectionKey="id"
    />
  );
}

function WaterProductionInput({ value, onChange }) {
  const { t } = useTranslation('lang');
  const waterProductionOptions = Object.keys(waterProductions).map((key) => ({
    id: key,
    label: t(waterProductions[key]),
  }));

  return (
    <DropDownControl
      type="object"
      label="assign_teams.drilling_crew.water_production"
      options={waterProductionOptions}
      selected={value}
      onChange={onChange}
      selectionKey="id"
    />
  );
}

function WaterLevelInput({ value, onChange }) {
  const { t } = useTranslation('lang');

  return (
    <NumberControl
      label="assign_teams.drilling_crew.water_level_from_ground"
      value={value}
      name="heating_area"
      unit={<span>{t('units.m')}</span>}
      onChange={onChange}
    />
  );
}

function GeothermalFluidInput({ value, onChange }) {
  const { t } = useTranslation('lang');
  const geothermalFluidOptions = Object.keys(geothermalFluids).map((key) => ({
    id: key,
    label: t(geothermalFluids[key]),
  }));

  return (
    <DropDownControl
      type="object"
      label="assign_teams.drilling_crew.geothermal_fluid"
      options={geothermalFluidOptions}
      selected={value}
      onChange={onChange}
      selectionKey="id"
    />
  );
}

function HeatCollectionInput({ value, onChange }) {
  const { t } = useTranslation('lang');
  const heatCollectionOptions = Object.keys(heatCollectionPipingOptions).map((key) => ({
    id: key,
    label: t(heatCollectionPipingOptions[key]),
  }));
  return (
    <DropDownControl
      type="object"
      label="assign_teams.drilling_crew.heat_collection_piping"
      options={heatCollectionOptions}
      selected={value}
      onChange={onChange}
      selectionKey="id"
    />
  );
}

function HeatCollectionLengthInput({ value, onChange }) {
  const { t } = useTranslation('lang');

  return (
    <NumberControl
      label="assign_teams.drilling_crew.heat_collection_piping_length"
      value={value}
      name="heating_area"
      unit={<span>{t('units.m')}</span>}
      onChange={onChange}
    />
  );
}

function DrillingWasteInput({ value, onChange }) {
  const { t } = useTranslation('lang');
  const drillingWasteHandlingOptions = Object.keys(drillingWasteHandling).map((key) => ({
    id: key,
    label: t(drillingWasteHandling[key]),
  }));

  return (
    <DropDownControl
      type="object"
      label="assign_teams.drilling_crew.drilling_waste_handling"
      options={drillingWasteHandlingOptions}
      selected={value}
      onChange={onChange}
      selectionKey="id"
    />
  );
}

function DrillingDateInput({ value, onChange }) {
  return (
    <CalendarControl
      label="assign_teams.drilling_crew.drilling_date"
      selected={value}
      onChange={onChange}
    />
  );
}

function PipeInstallmentDateInput({ value, onChange }) {
  return (
    <CalendarControl
      label="assign_teams.drilling_crew.pipe_installment_date"
      selected={value}
      onChange={onChange}
    />
  );
}

function HeatCollectorInput({ value, onChange }) {
  const { t } = useTranslation('lang');

  return (
    <div className={`d-sm-flex flex-column   ${styles.textControlContainer}`}>
      <div>
        <p>{t('assign_teams.drilling_crew.heat_collector_circuit_testing')}</p>
      </div>
      <textarea cols="20" rows="3" value={value} onChange={(e) => onChange(e.target.value)} />
    </div>
  );
}
// TODO: we are not implementing it for now
// function InstalledByInput({ value, onChange }) {
//   const { t } = useTranslation('lang');

//   return (
//     <DropDownControl
//       type="object"
//       label="assign_teams.drilling_crew.installed_by"
//       options={options.map((item) => ({ ...item, label: t(item.label) }))}
//       selected={value}
//       onChange={onChange}
//       selectionKey="id"
//     />
//   );
// }

function SignaturePreview({ signature, label }) {
  return signature.signatureType === signatureTypes.TYPE ? (
    <div>
      <p className={styles.signPreviewLabel}>
        <Translate id={label} />:
      </p>
      <p>{signature.signature}</p>
    </div>
  ) : (
    <>
      <p className={styles.signPreviewLabel}>
        <Translate id={label} />:
      </p>
      <img
        src={signature.signature}
        width={signature.signatureType === signatureTypes.UPLOAD ? 150 : '100%'}
        alt=""
      />
    </>
  );
}

function SignatureControl({ setLoading }) {
  const { t } = useTranslation('lang');
  const { companyId } = useParams();
  const { drillingReport } = useDrillingReportContext();
  const { service } = useServiceContext();
  const queryClient = useQueryClient();

  const [showModal, setShowModal] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [customerSignature, setCustomerSignature] = useState({});
  const [employeeSignature, setEmployeeSignature] = useState({});

  const onCloseModal = () => {
    setUserRole(null);
    setShowModal(false);
  };

  useEffect(() => {
    if (userRole) {
      setShowModal(true);
    }
  }, [userRole]);

  const setSignature = async (formData) => {
    setLoading(true);
    try {
      const currentUser = await AuthService.getCurrentUser();
      if (userRole.role === userRoles.CUSTOMER.role) {
        await saveDrillingReportCustomerSign(companyId, currentUser.id, service?.id, formData);
        setLoading(false);
      }
      if (userRole.role === userRoles.DRILLER.role) {
        await saveDrillingReportEmployeeSign(companyId, currentUser.id, service?.id, formData);
        setLoading(false);
      }
      queryClient.invalidateQueries('serviceDrillingReport');
      toast.success(t('components.signature.success'));
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    setCustomerSignature({
      signature: drillingReport?.customerSignature,
      signatureType: drillingReport?.customerSignatureType,
    });
    setEmployeeSignature({
      signature: drillingReport?.employeeSignature,
      signatureType: drillingReport?.employeeSignatureType,
    });
  }, [drillingReport]);

  return (
    <>
      <div className={`${styles.signBtnContainer}`}>
        <div className={`d-md-none ${styles.btnContainer}`}>
          <div>
            {!employeeSignature.signature ? (
              <>
                <div className={`${styles.titleContainer}`}>
                  <p>
                    <Translate id="assign_teams.drilling_crew.driller_signature" />
                  </p>
                </div>
                <Button
                  className={`projectBtn ${styles.signBtn}`}
                  type="button"
                  onClick={() => setUserRole(userRoles.DRILLER)}
                >
                  {t('components.signature.sign')} <PenSvg className={`${styles.signBtnIcon}`} />
                </Button>
              </>
            ) : (
              <SignaturePreview
                label="heat_pump_test_report.customer_sign"
                signature={employeeSignature}
              />
            )}
          </div>
          <div>
            {!customerSignature.signature ? (
              <>
                <div className={`${styles.titleContainer}`}>
                  <p>
                    <Translate id="assign_teams.drilling_crew.customer_signature" />
                  </p>
                </div>
                <Button
                  className={`projectBtn ${styles.signBtn}`}
                  type="button"
                  onClick={() => setUserRole(userRoles.CUSTOMER)}
                >
                  {t('components.signature.sign')} <PenSvg className={`${styles.signBtnIcon}`} />
                </Button>
              </>
            ) : (
              <SignaturePreview
                label="heat_pump_test_report.customer_sign"
                signature={customerSignature}
              />
            )}
          </div>
        </div>
      </div>
      <div>
        {showModal && (
          <SignatureModal
            userRole={userRole}
            onClose={onCloseModal}
            showModal={showModal}
            setSignature={setSignature}
            signatureFileName={
              userRole === userRoles.CUSTOMER ? 'customerSignatureFile' : 'employeeSignatureFile'
            }
          />
        )}
      </div>
    </>
  );
}

function CompanyInfo() {
  const { service } = useServiceContext();

  if (!service) {
    return null;
  }

  const {
    companyName,
    companyAddressStreet,
    companyAddressZipCode,
    companyAddressCity,
    companyAdminPhoneNumber,
    companyAdminEmail,
  } = service;

  return (
    <div>
      {companyName && <p>{companyName}</p>}
      {companyAddressStreet && <p>{companyAddressStreet}</p>}
      {(companyAddressZipCode || companyAddressCity) && (
        <p>
          {companyAddressZipCode ?? ''} {companyAddressCity ?? ''}
        </p>
      )}
      {companyAdminPhoneNumber && (
        <a href={`tel:${companyAdminPhoneNumber}`}>{companyAdminPhoneNumber}</a>
      )}
      {companyAdminEmail && <p>{companyAdminEmail}</p>}
    </div>
  );
}

// function CustomerInfoHeader() {
//   const { customerInformation } = useBasicInformationContext();
//   return (
//     <div>
//       <h1>
//         {customerInformation.firstName} {customerInformation.lastName}
//       </h1>
//       <div>
//         <span>{customerInformation.address},</span>
//         <span>{customerInformation.zipCode},</span>
//         <span>{customerInformation.city}</span>
//       </div>
//     </div>
//   );
// }

function ServiceComment() {
  const { serviceComment, setServiceComment } = useServiceContext();

  const onChangeComment = (value) => {
    setServiceComment(value);
  };

  return (
    <textarea
      className={`${styles.textArea}`}
      cols="30"
      rows="10"
      value={serviceComment}
      onChange={(e) => onChangeComment(e.target.value)}
    />
  );
}

function DrillingTeamViewFooter({ setLoading }) {
  const { t } = useTranslation('lang');
  const { companyId } = useParams();
  const { service, serviceComment, serviceCommentComparison } = useServiceContext();
  const { offerRequest } = useBasicInformationContext();
  const { wells, wellsComparison } = useWellContext();
  const queryClient = useQueryClient();

  const onSave = async () => {
    setLoading(true);
    try {
      const currentUser = await AuthService.getCurrentUser();
      if (JSON.stringify(wells) !== JSON.stringify(wellsComparison)) {
        await updateServiceWells(offerRequest.id, service.offerId, service.id, wells);
        await saveDrillingReport(companyId, currentUser.id, service.id, {
          data: {},
          offerServiceId: service?.id,
        });
        toast.success(t('assign_teams.drilling_crew.wells_saved_success'));
        queryClient.invalidateQueries('serviceWells');
        queryClient.invalidateQueries('serviceDrillingReport');
      }
      if (serviceComment !== serviceCommentComparison) {
        await updateTaskComment(offerRequest.id, service.offerId, service.id, serviceComment);
        toast.success(t('assign_teams.drilling_crew.task_comment_saved_success'));
        queryClient.invalidateQueries('Assignment');
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const onMarkAsCompleted = async () => {
    setLoading(true);
    try {
      await takServiceComplete(offerRequest.id, service.offerId, service.id);
      toast.success(t('assign_teams.drilling_crew.task_completed'));
      queryClient.invalidateQueries('Assignment');
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
    setLoading(false);
  };

  return (
    <div className={`d-flex align-items-center ${styles.footerContainer}`}>
      <div>
        <Button
          className={
            serviceComment === serviceCommentComparison &&
            JSON.stringify(wells) === JSON.stringify(wellsComparison)
              ? ''
              : 'greenBtn'
          }
          onClick={() => onSave()}
          disabled={
            serviceComment === serviceCommentComparison &&
            JSON.stringify(wells) === JSON.stringify(wellsComparison)
          }
        >
          <Translate id="components.button.save" />
        </Button>
      </div>
      <div>
        <Button
          className={service?.completedAt ? '' : 'greenBtn'}
          onClick={() => onMarkAsCompleted()}
          type="button"
          disabled={service?.completedAt}
        >
          <Translate id="components.button.mark_as_completed" />
        </Button>
      </div>
    </div>
  );
}

function DepthInput() {
  const { t } = useTranslation('lang');
  const { service } = useServiceContext();
  const { wells } = useWellContext();

  return (
    <>
      <div className="mb-2">
        <NumberControl
          label="assign_teams.drilling_crew.requested_depth"
          value={service?.quantity}
          unit={t('units.m')}
          name="requested_depth"
          disable
          isControlRequired={false}
        />
      </div>
      {wells.map((well) => (
        <div className="mb-3">
          <NumberControl
            label="assign_teams.drilling_crew.actual_depth"
            value={well.drillingDepth}
            unit={<span>{t('units.m')}</span>}
            name="actual_depth"
            disable
            isControlRequired={false}
          />
        </div>
      ))}
      <div className={`d-flex flex-column gap-2 ${styles.connectionContainer}`}>
        <CheckBoxControl
          translationId="assign_teams.drilling_crew.pipe_install_completion"
          id="pipe_install_completion"
          isChecked={service?.insideConnectionRequired}
          disabled
          className="grayCheckBox"
        />
        <CheckBoxControl
          translationId="assign_teams.drilling_crew.drilling_waste_removal"
          id="drilling_waste_removal"
          isChecked={service?.removalOfDrillingWasteRequired}
          disabled
          className="grayCheckBox"
        />
      </div>
    </>
  );
}

function DrillingSiteInfoControl() {
  const { customerInformation, offerRequest } = useBasicInformationContext();
  return (
    <>
      <FormControl title="components.user.name">{`${customerInformation.firstName} ${customerInformation.lastName}`}</FormControl>
      <FormControl title="components.address.address">
        {offerRequest.installationTargetAddress}
      </FormControl>
      <FormControl title="components.address.postal_code">
        {offerRequest.installationTargetZipCode}
      </FormControl>
      <FormControl title="components.address.city">
        {offerRequest.installationTargetCity}
      </FormControl>
      <FormControl title="components.user.phone">{customerInformation.phone}</FormControl>
      <FormControl title="components.user.email">{customerInformation.email}</FormControl>
    </>
  );
}

function WellForm() {
  const { wells, setWells, isLoading } = useWellContext();

  const onAddWell = () => {
    setWells((prevWells) => [...prevWells, { wellNumber: prevWells.length + 1 }]);
  };

  const onChangeFieldValue = (wellNumber, field, value) => {
    setWells((prevWells) =>
      prevWells.map((well) => {
        if (well.wellNumber === wellNumber) return { ...well, [field]: value };
        return well;
      })
    );
  };

  useEffect(() => {
    if (!isLoading && !wells?.length) {
      onAddWell();
    }
  }, [wells]);

  return (
    !isLoading && (
      <>
        {wells.map((well) => (
          <div className={`d-md-flex mb-5 ${styles.flexContaner}`}>
            <div className={`${styles.theWellContainer}`}>
              <div className={`${styles.titleContainer}`}>
                <p>
                  <Translate id="assign_teams.drilling_crew.the_well" />
                </p>
              </div>
              <div className={`${styles.inputContainer}`}>
                <WellNumberInput
                  value={well.wellNumber}
                  onChange={(value) => onChangeFieldValue(well.wellNumber, 'wellNumber', value)}
                />
                <DrillingDepthInput
                  value={well.drillingDepth}
                  onChange={(value) => onChangeFieldValue(well.wellNumber, 'drillingDepth', value)}
                />
                <CrustThicknessInput
                  value={well.earthCrustThinkness}
                  onChange={(value) =>
                    onChangeFieldValue(well.wellNumber, 'earthCrustThinkness', value)
                  }
                />
                <PipeThicknessInput
                  value={well.steelPipeThinkness}
                  onChange={(value) =>
                    onChangeFieldValue(well.wellNumber, 'steelPipeThinkness', value)
                  }
                />
                <DiameterOfSteelPipeInput
                  value={well.steelPipeDiameter}
                  onChange={(value) =>
                    onChangeFieldValue(well.wellNumber, 'steelPipeDiameter', value)
                  }
                />
                <RockQualityInput
                  value={well.rockQuality}
                  onChange={(value) => onChangeFieldValue(well.wellNumber, 'rockQuality', value)}
                />
                <WellDiameterInput
                  value={well.wellDiameter}
                  onChange={(value) => onChangeFieldValue(well.wellNumber, 'wellDiameter', value)}
                />
                <WaterProductionInput
                  value={well.waterProduction}
                  onChange={(value) =>
                    onChangeFieldValue(well.wellNumber, 'waterProduction', value)
                  }
                />
                <DrillingAngleInput
                  value={well.drillingAngle}
                  onChange={(value) => onChangeFieldValue(well.wellNumber, 'drillingAngle', value)}
                />
              </div>
            </div>
            <div className={`${styles.collectorContainer}`}>
              <div className={`${styles.titleContainer}`}>
                <p>
                  <Translate id="assign_teams.drilling_crew.collector_cirquit" />
                </p>
              </div>
              <div className={`${styles.inputContainer}`}>
                <WaterLevelInput
                  value={well.waterLevelFromGround}
                  onChange={(value) =>
                    onChangeFieldValue(well.wellNumber, 'waterLevelFromGround', value)
                  }
                />
                <GeothermalFluidInput
                  value={well.geothermalFluid}
                  onChange={(value) =>
                    onChangeFieldValue(well.wellNumber, 'geothermalFluid', value)
                  }
                />
                <HeatCollectionInput
                  value={well.heatCollectionPiping}
                  onChange={(value) =>
                    onChangeFieldValue(well.wellNumber, 'heatCollectionPiping', value)
                  }
                />
                <HeatCollectionLengthInput
                  value={well.heatCollectionPipingLength}
                  onChange={(value) =>
                    onChangeFieldValue(well.wellNumber, 'heatCollectionPipingLength', value)
                  }
                />
                <DrillingWasteInput
                  value={well.drillingWasteHandling}
                  onChange={(value) =>
                    onChangeFieldValue(well.wellNumber, 'drillingWasteHandling', value)
                  }
                />
                <DrillingDateInput
                  value={well.drillingDate}
                  onChange={(value) => onChangeFieldValue(well.wellNumber, 'drillingDate', value)}
                />
                <PipeInstallmentDateInput
                  value={well.pipeInstallmentDate}
                  onChange={(value) =>
                    onChangeFieldValue(well.wellNumber, 'pipeInstallmentDate', value)
                  }
                />
                <HeatCollectorInput
                  value={well.heatCollectorCircuitTesting}
                  onChange={(value) =>
                    onChangeFieldValue(well.wellNumber, 'heatCollectorCircuitTesting', value)
                  }
                />
                {/* <InstalledByInput /> we are not implementing it for now */}
              </div>
            </div>
          </div>
        ))}
        <div className={`${styles.addWellBtnContainer}`}>
          <Button
            className={`projectBtn offerTabFooterBtn ${styles.addWellbtn}`}
            onClick={() => {
              onAddWell();
            }}
          >
            <Translate id="assign_teams.drilling_crew.add_another_well" />
          </Button>
        </div>
      </>
    )
  );
}

function DrillingTeamView({ setLoading }) {
  const isfetching = useIsFetching();

  useEffect(() => {
    setLoading(isfetching);
  }, [isfetching]);

  const customFooter = () => <DrillingTeamViewFooter setLoading={setLoading} />;

  return (
    <WellContext>
      <CompanyAdminContentWrapper customFooter={customFooter}>
        <div className={`${styles.mainContainer}`}>
          <div className={`d-flex flex-column ${styles.drillingTeamViewMainContainer}`}>
            <div className={`${styles.gridContaner}`}>
              <div className={`${styles.drillingDepthContainer}`}>
                <div className={`${styles.titleContainer}`}>
                  <p>
                    <Translate id="assign_teams.drilling_crew.drilling_depth" />
                  </p>
                </div>
                <div className={`${styles.depthInputContainer}`}>
                  <DepthInput />
                </div>
              </div>
              <div className={`${styles.siteInforMainContainer}`}>
                <div className={`${styles.titleContainer}`}>
                  <p>
                    <Translate id="assign_teams.drilling_crew.drilling_site" />
                  </p>
                </div>
                <div className={`${styles.siteInfoContainer}`}>
                  <DrillingSiteInfoControl />
                </div>
              </div>
              <div className={`${styles.commentMainContainer}`}>
                <div className={`${styles.titleContainer}`}>
                  <p>
                    <Translate id="assign_teams.drilling_crew.add_comments" />
                  </p>
                </div>
                <ServiceComment />
              </div>
            </div>
            <WellForm />
            <div className={`${styles.signatureAndCompanyContainer}`}>
              <DrillingReportContext>
                <SignatureControl setLoading={setLoading} />
              </DrillingReportContext>
              <div className="d-flex justify-content-between align-items-center mt-5">
                <CompanyInfo />
              </div>
            </div>
          </div>
        </div>
      </CompanyAdminContentWrapper>
    </WellContext>
  );
}

export default DrillingTeamView;
