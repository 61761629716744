import apiClient from './api-client';

export const saveFloorHeatingTestReport = (companyId, employeeId, offerServiceId, data) =>
  apiClient.put(
    `/v1/company/${companyId}/employee/${employeeId}/services/${offerServiceId}/floor-heating-test-report`,
    data
  );

export const getFloorHeatingTestReport = (companyId, employeeId, offerServiceId) =>
  apiClient.get(
    `/v1/company/${companyId}/employee/${employeeId}/services/${offerServiceId}/floor-heating-test-report`
  );

export const saveElectricityTestReport = (companyId, employeeId, offerServiceId, data) =>
  apiClient.put(
    `/v1/company/${companyId}/employee/${employeeId}/services/${offerServiceId}/electricity-test-report`,
    data
  );

export const getElectricityTestReport = (companyId, employeeId, offerServiceId) =>
  apiClient.get(
    `/v1/company/${companyId}/employee/${employeeId}/services/${offerServiceId}/electricity-test-report`
  );

export const saveHeatPumpTestReport = (companyId, employeeId, offerServiceId, data) =>
  apiClient.put(
    `/v1/company/${companyId}/employee/${employeeId}/services/${offerServiceId}/heating-pump-test-report`,
    data
  );

export const getHeatPumpTestReport = (companyId, employeeId, offerServiceId) =>
  apiClient.get(
    `/v1/company/${companyId}/employee/${employeeId}/services/${offerServiceId}/heating-pump-test-report`
  );

export const saveHeatPumpTestReportCustomerSign = (companyId, employeeId, offerServiceId, data) =>
  apiClient.put(
    `/v1/company/${companyId}/employee/${employeeId}/services/${offerServiceId}/heating-pump-test-report/customer-sign`,
    data
  );

export const saveHeatPumpTestReportEmployeeSign = (companyId, employeeId, offerServiceId, data) =>
  apiClient.put(
    `/v1/company/${companyId}/employee/${employeeId}/services/${offerServiceId}/heating-pump-test-report/employee-sign`,
    data
  );
export const saveFloorHeatingTestReportCustomerSign = (
  companyId,
  employeeId,
  offerServiceId,
  data
) =>
  apiClient.put(
    `/v1/company/${companyId}/employee/${employeeId}/services/${offerServiceId}/floor-heating-test-report/customer-sign`,
    data
  );

export const saveFloorHeatingTestReportEmployeeSign = (
  companyId,
  employeeId,
  offerServiceId,
  data
) =>
  apiClient.put(
    `/v1/company/${companyId}/employee/${employeeId}/services/${offerServiceId}/floor-heating-test-report/employee-sign`,
    data
  );

export const saveElectricityTestReportCustomerSign = (
  companyId,
  employeeId,
  offerServiceId,
  data
) =>
  apiClient.put(
    `/v1/company/${companyId}/employee/${employeeId}/services/${offerServiceId}/electricity-test-report/customer-sign`,
    data
  );

export const saveElectricityTestReportEmployeeSign = (
  companyId,
  employeeId,
  offerServiceId,
  data
) =>
  apiClient.put(
    `/v1/company/${companyId}/employee/${employeeId}/services/${offerServiceId}/electricity-test-report/employee-sign`,
    data
  );

export const saveDrillingReport = (companyId, employeeId, offerServiceId, data) =>
  apiClient.put(
    `/v1/company/${companyId}/employee/${employeeId}/services/${offerServiceId}/drilling-report`,
    data
  );

export const getDrillingReport = (companyId, employeeId, offerServiceId) =>
  apiClient.get(
    `/v1/company/${companyId}/employee/${employeeId}/services/${offerServiceId}/drilling-report`
  );

export const saveDrillingReportCustomerSign = (companyId, employeeId, offerServiceId, data) =>
  apiClient.put(
    `/v1/company/${companyId}/employee/${employeeId}/services/${offerServiceId}/drilling-report/customer-sign`,
    data
  );

export const saveDrillingReportEmployeeSign = (companyId, employeeId, offerServiceId, data) =>
  apiClient.put(
    `/v1/company/${companyId}/employee/${employeeId}/services/${offerServiceId}/drilling-report/employee-sign`,
    data
  );
