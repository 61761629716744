import apiClient from './api-client';

const setOfferRequestSessionInfo = (itemInfo) => {
  window.sessionStorage.setItem('offerRequest', JSON.stringify(itemInfo));
};

const getOfferRequestSessionInfo = () =>
  JSON.parse(window.sessionStorage.getItem('offerRequest')) || {};

const saveOfferRequest = (offerRequest) => apiClient.post('/v1/offer-requests', offerRequest);

const updateOfferRequestById = (offerRequestId, data) =>
  apiClient.put(`/v1/offer-requests/${offerRequestId}`, data);

const addOfferByRequestId = (offerRequestId) =>
  apiClient.post(`/v1/offer-requests/${offerRequestId}/offers/`);

const getOffersByRequestId = (offerRequestId) =>
  apiClient.get(`/v1/offer-requests/${offerRequestId}/offers/`);

const getOfferRequests = (params) =>
  apiClient.get('/v1/offer-requests/', {
    params,
  });

const getOfferRequestById = (offerRequestId) =>
  apiClient.get(`/v1/offer-requests/${offerRequestId}`);

const getOfferRequestAdditionalInfo = (offerRequestId) =>
  apiClient.get(`/v1/offer-requests/${offerRequestId}/additional-information`);

const updateOfferRequest = (offerRequestId, offerRequest) =>
  apiClient.put(`/v1/offer-requests/${offerRequestId}`, offerRequest);

const getOfferRequestProgress = (offerRequestId) =>
  apiClient.get(`/v1/offer-requests/${offerRequestId}/progress`);

export default {
  setOfferRequestSessionInfo,
  getOfferRequestSessionInfo,
  saveOfferRequest,
  addOfferByRequestId,
  getOffersByRequestId,
  updateOfferRequestById,
  getOfferRequests,
  getOfferRequestById,
  updateOfferRequest,
  getOfferRequestAdditionalInfo,
  getOfferRequestProgress,
};
