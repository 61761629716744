import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'sonner';

import { useBasicInformationContext } from './basicInformationContext';
import { useServiceContext } from './service-context';
import { getServiceWells } from '../../../services/well';

const Context = createContext();

export function WellContext({ children }) {
  const { service } = useServiceContext();
  const { offerRequest } = useBasicInformationContext();
  const [wells, setWells] = useState([]);
  const [wellsComparison, setWellsComparison] = useState([]);
  const { error } = useQuery(
    ['serviceWells'],
    async () => {
      const {
        data: {
          data: { wells: serviceWells },
        },
      } = await getServiceWells(offerRequest.id, service?.offerId, service?.id);
      setWells(serviceWells);
      setWellsComparison(serviceWells);
      return serviceWells;
    },
    {
      enabled: !!(service && offerRequest?.id),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  const contextValue = useMemo(
    () => ({
      wells,
      setWells,
      wellsComparison,
    }),
    [wells]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

export const useWellContext = () => useContext(Context);
