import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import styles from './Wrapper.module.scss';
import Header from '../../../components/header';
import Steps from '../../../components/shared-steps';
import Translate from '../../../components/translate';

function CustomerInfoHeader() {
  return (
    <div className={`${styles.customerHeaderContainer}`}>
      <div>
        <h1>
          <Translate id="company_admin.settings.title" />
        </h1>
      </div>
    </div>
  );
}

function Wrapper() {
  const [steps, setSteps] = useState([]);
  const [currentPage, setCurrentPage] = useState('');

  const { companyId, adminId } = useParams();

  const customerInfoHeader = () => <CustomerInfoHeader />;

  useEffect(() => {
    if (companyId && adminId) {
      const tempStep = [
        {
          title: 'company_admin.settings.manage_employees',
          link: `/company/${companyId}/admin/${adminId}/settings/employees`,
          active: true,
        },
      ];
      setSteps(tempStep);
      setCurrentPage(tempStep[0].title);
    }
  }, []);

  return (
    <div className={`${styles.appContainer}`}>
      <div className={`${styles.fixedElement}`}>
        <Header component={customerInfoHeader} />
      </div>
      <div className={`projectContainer ${styles.projectContent}`}>
        <Steps steps={steps} currentPage={currentPage} />
        <Outlet />
      </div>
    </div>
  );
}

export default Wrapper;
