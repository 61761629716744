import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import NumberTranslate from '../number-translate';
import Translate from '../translate';

function ProfitCalculation({
  items,
  purchasePriceFieldName,
  sellingPriceFieldName,
  previousPurchasePrice,
  previousSellingPrice,
}) {
  const { t } = useTranslation('lang');

  const totalPurchasePrice = useMemo(
    () =>
      items?.reduce(
        (totalPrice, currentItem) => totalPrice + currentItem[purchasePriceFieldName],
        previousPurchasePrice
      ),
    [items, purchasePriceFieldName, sellingPriceFieldName, previousPurchasePrice]
  );
  const totalSellingPrice = useMemo(
    () =>
      items?.reduce(
        (totalPrice, currentItem) => totalPrice + currentItem[sellingPriceFieldName],
        previousSellingPrice
      ),
    [items, purchasePriceFieldName, sellingPriceFieldName, previousSellingPrice]
  );

  return (
    <div className={`${styles.calculationContainer}`}>
      <div>
        <Translate id="offer.profit_calculation.title" /> &#40;{' '}
        <Translate id="offer.profit_calculation.vat" /> 0% &#41;:
      </div>
      <div>
        <span>
          <Translate id="offer.profit_calculation.cost_in_total" />:
        </span>
        <span>
          {' '}
          <NumberTranslate value={+totalPurchasePrice.toFixed(2)} /> {t('units.euro')}
        </span>
      </div>
      <div>
        <span>
          <Translate id="offer.profit_calculation.offer_total" />:
        </span>
        <span>
          {' '}
          <NumberTranslate value={+totalSellingPrice.toFixed(2)} /> {t('units.euro')}
        </span>
      </div>
      <div>
        <span>
          <Translate id="offer.profit_calculation.profit_margin" />:
        </span>
        <span>
          {Number.isNaN(totalSellingPrice - totalPurchasePrice) ? (
            0
          ) : (
            <NumberTranslate value={(totalSellingPrice - totalPurchasePrice).toFixed(2)} />
          )}
          {t('units.euro')}
        </span>
      </div>
    </div>
  );
}

ProfitCalculation.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  purchasePriceFieldName: PropTypes.string.isRequired,
  sellingPriceFieldName: PropTypes.string.isRequired,
  previousPurchasePrice: PropTypes.number,
  previousSellingPrice: PropTypes.number,
};

ProfitCalculation.defaultProps = {
  previousSellingPrice: 0,
  previousPurchasePrice: 0,
};

export default ProfitCalculation;
