import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';

import styles from './index.module.scss';
import Translate from '../translate';

function TaskSteps({ steps, currentPage }) {
  return (
    <>
      {/* This below div is for desktop view */}
      <div className={`d-none d-md-block ${styles.tabLinkContainer}`}>
        <div className={`d-flex align-items-center ${styles.linkFlexContainer}`}>
          {steps.map(({ title, link, active }) => (
            <div>
              <Link
                key={link}
                to={link}
                className={` ${styles.tabLink}  ${active ? styles.tabLinkActive : ''}`}
              >
                <Translate id={title} />
              </Link>
            </div>
          ))}
        </div>
      </div>
      {/* The below div is for the mobile view */}
      <div className={`d-md-none ${styles.mobTabLinkContainer}`}>
        <Accordion className={`${styles.mobAccordion}`}>
          <Accordion.Item className={`${styles.mobAccordionItem}`} eventKey="0">
            <Accordion.Header className={`${styles.mobAccordionHeader}`}>
              <Translate id={currentPage} />
            </Accordion.Header>
            <Accordion.Body className={`${styles.mobAccordionBody}`}>
              {steps.map(({ title, link, active }) => (
                <Link
                  key={link}
                  to={link}
                  className={`${styles.mobTabLink} ${active ? styles.mobTabLinkActive : ''}`}
                >
                  <Translate id={title} />
                </Link>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}

export default TaskSteps;
