/* eslint-disable react/no-array-index-key */
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './basicInformation.module.scss';

function FormField({ field }) {
  const { t } = useTranslation('lang');

  return field.value ? (
    <div
      className={`d-flex flex-wrap align-items-start justify-content-between ${styles.flexContainer}`}
    >
      <div>{t(field.label)} : </div>
      <div>{field.value}</div>
    </div>
  ) : null;
}

function AdditionalInformation({ additionalInformation }) {
  const [form, setForm] = useState([]);
  const { t } = useTranslation('lang');

  useEffect(() => {
    if (additionalInformation) {
      setForm([
        {
          name: 'RequestedDrillingTimeInput',
          label: 'offer.onsite_memo.requested_drilling_time',
          value: additionalInformation.requestedDrillingTime
            ? moment(additionalInformation.requestedDrillingTime).format('DD-MM-YYYY')
            : 'N/A',
        },
        {
          name: 'RequestedDeliveryTimeInput',
          label: 'offer.onsite_memo.requested_delivery_time',
          value: additionalInformation.requestedDeliveryTime
            ? moment(additionalInformation.requestedDeliveryTime).format('DD-MM-YYYY')
            : 'N/A',
        },
        {
          name: 'RequestedInstallationTime',
          label: 'offer.onsite_memo.requested_installation_time',
          value: additionalInformation.requestedInstallationTime
            ? moment(additionalInformation.requestedInstallationTime).format('DD-MM-YYYY')
            : 'N/A',
        },
        {
          name: 'ClientTypeInput',
          label: 'offer.onsite_memo.client_type',
          value: additionalInformation.clientType,
        },
        {
          name: 'CommentTextarea',
          label: 'offer.onsite_memo.additional_comments_for_additional_information',
          value: additionalInformation.comment,
        },
      ]);
    }
  }, [additionalInformation]);
  return additionalInformation ? (
    <div className={`${styles.basicDetailsFormContainer}`}>
      <h2 className={`${styles.basicDetailsFormTitle}`}>
        {t('offer.onsite_memo.additional_information')}
      </h2>
      {form.map((field, index) => (
        <FormField key={index} field={field} />
      ))}
    </div>
  ) : null;
}

export default AdditionalInformation;
