import React from 'react';
import { RotatingLines } from 'react-loader-spinner';

import styles from './index.module.scss';

function Loader() {
  return (
    <div className={styles.loaderContainer}>
      <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.75" width="60" />
    </div>
  );
}

export default Loader;
