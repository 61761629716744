import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/global.scss';
import { RotatingLines } from 'react-loader-spinner';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Toaster } from 'sonner';

import View from './views';

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const handleResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('load', handleResize);

    return () => {
      window.removeEventListener('load', handleResize);
    };
  }, []);
  return (
    <>
      <Toaster position="top-right" richColors />
      {loading ? (
        <div className="loader">
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="60"
            visible={loading}
          />
        </div>
      ) : null}
      <Router>
        <Routes>
          <Route path="/*" element={<View setLoading={setLoading} />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
