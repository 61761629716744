import * as moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'sonner';

import styles from './index.module.scss';
import { offerRequestStatuses, scrollableTargetId } from '../../constant';
import { getEmployees } from '../../services/employee';
import { assignOfferRequest } from '../../services/offerRequestAssignment';
import { getOriginalErrors } from '../../utils/errorHandler';
import DropDown from '../drop-down';
import Loader from '../loader';
import Status from '../status';

function OfferRequestStatus({ status }) {
  return <Status value={offerRequestStatuses[status]?.label} statusLabel={status} />;
}

function SubmittedBy({ customer }) {
  return (
    <div className={`${styles.submittedByContainer}`}>
      <p>{customer?.fullName}</p>
      <p>
        {customer?.address}, {customer?.city} {customer?.zipCode}
      </p>
    </div>
  );
}

function TimeStampAndStatus({ status, time }) {
  return (
    <div>
      {status && <p>{status}</p>}
      {time && (
        <>
          <p>{moment(time).format('DD.MM.yyyy')}</p>
          <p>{moment(time).format('HH:mm')}</p>
        </>
      )}
      {!time && <p>-</p>}
    </div>
  );
}

function AssignedEmployee({ employees, request, assignment, className }) {
  const { t } = useTranslation('lang');

  const [assignedEmployee, setAssignedEmployee] = useState(assignment || null);

  useEffect(() => {
    if (assignment) {
      setAssignedEmployee(assignment);
    }
  }, [assignment]);

  const onOfferRequestAssign = async (employeeId) => {
    try {
      // employee.value indicates employeeId from dropdown
      await assignOfferRequest({
        offerRequestId: request.id,
        employeeId,
      });
      setAssignedEmployee(employees.find((emp) => emp.id === employeeId));
      toast.success(t('offer.request.request_assigned'));
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  return (
    <div>
      <DropDown
        selected={assignedEmployee?.id}
        options={employees}
        placeholder="components.data_table.not_assigned"
        onChange={(value) => onOfferRequestAssign(value, request)}
        className={className}
        selectionKey="value"
        disabled={Object.keys(employees).length === 1} // TODO: <partially implemented needs to be change if required>
      />
    </div>
  );
}

function DataTable({
  responseData,
  showAssignedColumn,
  onRowClick,
  isOfferRequestType,
  hasNextPage,
  fetchNextPage,
}) {
  const offerRequests = responseData;

  const { t } = useTranslation('lang');
  const [employees, setEmployees] = useState([]);

  let headers = [];
  if (showAssignedColumn) {
    headers = [
      t('components.data_table.submitted_by'),
      t('components.data_table.received_time'),
      t('components.data_table.completed_time'),
      t('components.data_table.assigned_to'),
      t('components.data_table.status'),
    ];
  } else {
    headers = [
      t('components.data_table.submitted_by'),
      t('components.data_table.received_time'),
      t('components.data_table.completed_time'),
      t('components.data_table.status'),
    ];
  }

  const fetchEmployees = async () => {
    try {
      const {
        data: { data },
      } = await getEmployees();
      setEmployees(
        data.employees.map((emp) => ({
          ...emp,
          label: `${emp.firstName} ${emp.lastName}`,
          value: emp.id,
        }))
      );
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  useEffect(() => {
    if (showAssignedColumn) fetchEmployees();
  }, []);

  return (
    <div className={`${styles.dataTableMainContainer}`}>
      <div className={`${styles.tableHeadingContainer}`}>
        <div
          className={`d-flex align-items-center justify-content-between ${styles.flexContainer}`}
        >
          {headers.map((head) => (
            <div key={head}>{head}</div>
          ))}
        </div>
      </div>
      <InfiniteScroll
        dataLength={offerRequests.length}
        hasMore={hasNextPage}
        scrollableTarget={scrollableTargetId}
        loader={<Loader />}
        next={fetchNextPage}
      >
        {offerRequests.map((request, index) => (
          <div
            role="button"
            onClick={() => onRowClick(request, index)}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onRowClick(request, index);
              }
            }}
            className={`d-flex align-items-center justify-content-between ${styles.tableContentContainer} ${styles.flexContainer}`}
            key={request.taskId || request.id}
          >
            <div>
              <SubmittedBy customer={request.customer} />
            </div>
            <div>
              <p>{moment(request.createdAt).format('DD.MM.yyyy')}</p>
              <p>{moment(request.createdAt).format('HH:mm')}</p>
            </div>
            <div>
              <TimeStampAndStatus
                status={
                  isOfferRequestType
                    ? t(offerRequestStatuses[request.offerRequestProgress[1]?.event]?.readableLabel)
                    : ''
                }
                time={
                  isOfferRequestType
                    ? request.offerRequestProgress[0]?.createdAt
                    : request.completedAt
                }
              />
            </div>
            {showAssignedColumn && (
              <div
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <AssignedEmployee
                  employees={employees}
                  assignment={request.assignment?.employee}
                  request={request}
                  className={`${styles.assignedDropDown}`}
                />
              </div>
            )}
            <div className={styles.statusMainContainer}>
              <OfferRequestStatus status={request.offerRequestProgress[0].event} />
            </div>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
}

AssignedEmployee.propTypes = {
  employees: PropTypes.instanceOf(Array).isRequired,
  request: PropTypes.instanceOf(Object).isRequired,
};

TimeStampAndStatus.propTypes = {
  status: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

DataTable.propTypes = {
  responseData: PropTypes.instanceOf(Object).isRequired,
  showAssignedColumn: PropTypes.bool,
  onRowClick: PropTypes.func.isRequired,
  isOfferRequestType: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  fetchNextPage: PropTypes.func,
};

DataTable.defaultProps = {
  showAssignedColumn: true,
  isOfferRequestType: true,
  hasNextPage: false,
  fetchNextPage: () => {},
};

SubmittedBy.propTypes = {
  customer: PropTypes.instanceOf(Object).isRequired,
};

OfferRequestStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default DataTable;
