import PropTypes from 'prop-types'
import React from 'react'

function Calendar({ className }) {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.33333 8.4C3.14444 8.4 2.986 8.3328 2.858 8.1984C2.73044 8.06447 2.66667 7.89833 2.66667 7.7C2.66667 7.50167 2.73044 7.3353 2.858 7.2009C2.986 7.06697 3.14444 7 3.33333 7C3.52222 7 3.68067 7.06697 3.80867 7.2009C3.93622 7.3353 4 7.50167 4 7.7C4 7.89833 3.93622 8.06447 3.80867 8.1984C3.68067 8.3328 3.52222 8.4 3.33333 8.4ZM6 8.4C5.81111 8.4 5.65289 8.3328 5.52533 8.1984C5.39733 8.06447 5.33333 7.89833 5.33333 7.7C5.33333 7.50167 5.39733 7.3353 5.52533 7.2009C5.65289 7.06697 5.81111 7 6 7C6.18889 7 6.34733 7.06697 6.47533 7.2009C6.60289 7.3353 6.66667 7.50167 6.66667 7.7C6.66667 7.89833 6.60289 8.06447 6.47533 8.1984C6.34733 8.3328 6.18889 8.4 6 8.4ZM8.66667 8.4C8.47778 8.4 8.31956 8.3328 8.192 8.1984C8.064 8.06447 8 7.89833 8 7.7C8 7.50167 8.064 7.3353 8.192 7.2009C8.31956 7.06697 8.47778 7 8.66667 7C8.85556 7 9.01378 7.06697 9.14133 7.2009C9.26933 7.3353 9.33333 7.50167 9.33333 7.7C9.33333 7.89833 9.26933 8.06447 9.14133 8.1984C9.01378 8.3328 8.85556 8.4 8.66667 8.4ZM1.33333 14C0.966667 14 0.652667 13.863 0.391333 13.5891C0.130444 13.3147 0 12.985 0 12.6V2.8C0 2.415 0.130444 2.08553 0.391333 1.8116C0.652667 1.5372 0.966667 1.4 1.33333 1.4H2V0H3.33333V1.4H8.66667V0H10V1.4H10.6667C11.0333 1.4 11.3473 1.5372 11.6087 1.8116C11.8696 2.08553 12 2.415 12 2.8V12.6C12 12.985 11.8696 13.3147 11.6087 13.5891C11.3473 13.863 11.0333 14 10.6667 14H1.33333ZM1.33333 12.6H10.6667V5.6H1.33333V12.6Z"
        fill="#C5C5C5"
      />
    </svg>
  )
}

Calendar.propTypes = {
  className: PropTypes.string,
}

Calendar.defaultProps = {
  className: null,
}

export default Calendar
