import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import { ReactComponent as SearchSVG } from '../../assets/svgs/Search.svg';

function SearchBox({ value, onChange, name, placeholder }) {
  const { t } = useTranslation('lang');
  const [inputValue, setInputValue] = useState(value);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    onChange(newValue);
  };

  return (
    <div className={`${styles.searchBoxContainer}`}>
      <input
        className={`inputField ${styles.searchInput}`}
        type="text"
        name={name}
        id={name}
        value={inputValue}
        onChange={handleInputChange}
        placeholder={t(placeholder)}
      />
      <SearchSVG className={`${styles.searchIcon}`} />
    </div>
  );
}

SearchBox.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

SearchBox.defaultProps = {
  name: null,
  placeholder: 'components.search_box.placeholder',
};

export default SearchBox;
