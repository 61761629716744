import apiClient from './api-client';

export const getBasicInformationByOfferRequest = (offerRequestId) =>
  apiClient.get(`/v1/offer-requests/${offerRequestId}/basic-information`);

export const saveBasicInformation = (offerRequestId, basicInformation) =>
  apiClient.put(`/v1/offer-requests/${offerRequestId}/basic-information`, basicInformation);

export const setBasicInformationSessionInfo = (itemInfo) => {
  window.sessionStorage.setItem('basicInformation', JSON.stringify(itemInfo));
};

export const getBasicInformationSessionInfo = () =>
  JSON.parse(window.sessionStorage.getItem('basicInformation')) || {};
