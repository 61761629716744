import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import * as yup from 'yup';

import styles from './index.module.scss';
import Button from '../../../components/button';
import CompanyAdminContentWrapper from '../../../components/company-admin-content-wrapper';
import TextControl from '../../../components/form-fields/text-control';
import AuthService from '../../../services/auth';
import { getCompanyEmployees } from '../../../services/employee';
import { addUserToCompany, createUser } from '../../../services/user';
import { getOriginalErrors } from '../../../utils/errorHandler';

function NameInput({ register, error }) {
  return (
    <div>
      <TextControl
        label="components.user.first_name"
        placeholder="John"
        name="firstName"
        register={register}
      />
      {error && <div>{error}</div>}
    </div>
  );
}

function SurnameInput({ register, error }) {
  return (
    <div>
      <TextControl
        label="components.user.last_name"
        placeholder="Doe"
        name="lastName"
        register={register}
      />
      {error && <div>{error}</div>}
    </div>
  );
}

function EmailInput({ register, error }) {
  return (
    <div>
      <TextControl
        label="components.user.email"
        type="email"
        placeholder="johndoe@example.com"
        name="email"
        register={register}
      />
      {error && <div>{error}</div>}
    </div>
  );
}

function PhoneInput({ register, error }) {
  return (
    <div>
      <TextControl
        label="components.user.phone"
        placeholder="+000XXXXXXX"
        name="phone"
        register={register}
      />
      {error && <div>{error}</div>}
    </div>
  );
}

function CompanyUser({ setLoading }) {
  const customerSchema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required(),
    phone: yup.string().required(),
  });

  const { companyId } = useParams();
  const [companyUsers, setCompanyUsers] = useState([]);
  const { t } = useTranslation('lang');

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(customerSchema),
  });

  const fetchCompanyEmployees = async () => {
    try {
      setLoading(true);
      const {
        data: {
          data: { employees },
        },
      } = await getCompanyEmployees(companyId);
      setCompanyUsers(employees);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  useEffect(() => {
    fetchCompanyEmployees();
  }, []);

  const onSubmit = async (formValue) => {
    try {
      setLoading(true);
      const {
        data: {
          data: { user },
        },
      } = await createUser(formValue);
      if (user) {
        await addUserToCompany(companyId, user.id);
        await AuthService.sendResetPasswordLink({ email: user.email });
        fetchCompanyEmployees();
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  return (
    <CompanyAdminContentWrapper>
      <div className={`${styles.addNewWorkerPageContainer}`}>
        <div className={`${styles.workerFormMainContainer}`}>
          <div className={`${styles.workerFormTitleContainer}`}>
            <h2 className={`${styles.workerFormTitle}`}>{t('company_admin.workers.add')}</h2>
          </div>
          <div>
            <form className={`${styles.workerFormContainer}`} onSubmit={handleSubmit(onSubmit)}>
              <NameInput register={register} error={errors.firstName?.message} />
              <SurnameInput register={register} error={errors.lastName?.message} />
              <EmailInput register={register} error={errors.email?.message} />
              <PhoneInput register={register} error={errors.phone?.message} />
              <div className={`${styles.btnContainer}`}>
                <Button type="submit" className="projectBtn greenBtn">
                  Send Invitation
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className={`${styles.workerListContainer}`}>
          <div className={`${styles.workerListTitleContainer}`}>
            {t('company_admin.workers.list')}
          </div>
          <div className={`${styles.subListContainer}`}>
            {companyUsers.map((user) => (
              <div>
                <div>{`${user.firstName} ${user.lastName}`}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </CompanyAdminContentWrapper>
  );
}

CompanyUser.propTypes = {
  setLoading: PropTypes.bool.isRequired,
};

export default CompanyUser;
