import * as moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './index.module.scss';
import Button from '../../../components/button';
import CalendarControl from '../../../components/form-fields/calendar-control';
import NumberControl from '../../../components/form-fields/number-control';
import OfferPageContentWrapper from '../../../components/offer-page-content-wrapper';
import Translate from '../../../components/translate';
import { getBasicInformationByOfferRequest } from '../../../services/basicInformation';
import offerRequestService from '../../../services/offerRequest';
import { getOfferServices, patchOfferService } from '../../../services/offerService';
import { getOriginalErrors } from '../../../utils/errorHandler';

function DrillingCrewFooter({ onNext, onSave, isChanged }) {
  return isChanged ? (
    <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onSave}>
      <Translate id="components.button.save" />
    </Button>
  ) : (
    <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onNext}>
      <Translate id="components.button.next" />
    </Button>
  );
}

function DrillingTeam({ drillingCrew, onSelect }) {
  return (
    <div
      className={`${styles.drillingCrewListContainer} ${
        drillingCrew.selected ? styles.activeList : ''
      }`}
      role="button"
      tabIndex={0}
      onKeyUp={(e) => e.key === 'Enter' && onSelect(drillingCrew)}
      onClick={() => onSelect(drillingCrew)}
    >
      <div className={`${styles.drillingCrewList}`}>
        <div className="d-flex align-items-center ">
          <div>
            <p>
              <Translate id="common.id" /> : {drillingCrew.netvisorId}&nbsp;
            </p>
          </div>
          <div>
            <p className={`${styles.boldText}`}> - {drillingCrew.name}</p>
          </div>
        </div>
        <div>
          <div>
            <p className={`${styles.boldText}`}> {drillingCrew.company_name}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function DrillingDateInput({ requestedDate, setRequestedDate, disabled }) {
  return (
    <CalendarControl
      label="assign_teams.drilling_crew.drilling_date"
      selected={requestedDate}
      onChange={setRequestedDate}
      disabled={disabled}
    />
  );
}

function DrillingDepthInput({ depth, setDepth, readOnly }) {
  return (
    <NumberControl
      label="assign_teams.drilling_crew.drilling_depth"
      unit="units.m"
      value={depth}
      onChange={setDepth}
      disable={readOnly}
      isControlRequired={!readOnly}
    />
  );
}
function ConectionControl({
  id,
  onOrder,
  orderDisabled,
  insideConnectionRequired,
  removalOfDrillingWasteRequired,
}) {
  return (
    <div>
      <div className={`d-flex flex-column gap-2 ${styles.connectionContainer}`}>
        <label className="checkBoxContainer" htmlFor={`inside_connection_required_${id}`}>
          <input
            id={`inside_connection_required_${id}`}
            type="checkbox"
            name="inside_connection_required"
            checked={insideConnectionRequired}
            disabled
          />
          <Translate id="assign_teams.drilling_crew.inside_connection_required" />
          <span className="checkIcon grayCheckBox" />
        </label>
        <label className="checkBoxContainer" htmlFor={`removal_of_drilling_waste_${id}`}>
          <input
            id={`removal_of_drilling_waste_${id}`}
            type="checkbox"
            name="removal_of_drilling_waste"
            checked={removalOfDrillingWasteRequired}
            disabled
          />
          <Translate id="assign_teams.drilling_crew.removal_of_drilling_waste" />
          <span className="checkIcon grayCheckBox" />
        </label>
      </div>
      <div className={`${styles.odderContainer}`}>
        <Button
          className="projectBtn offerTabFooterBtn offerTabGreenBtn"
          onClick={onOrder}
          disabled={orderDisabled}
        >
          <Translate id="assign_teams.drilling_crew.order" />
        </Button>
      </div>
    </div>
  );
}

export function DrillingCrewFormFields({
  drillingCrew,
  setDepth,
  setRequestedDate,
  onOrder,
  readOnly,
}) {
  return (
    <>
      {' '}
      <div className={`${styles.drillingCrewFieldMainContainer}`}>
        <div className={`${styles.drillingCrewFieldContainer}`}>
          <div className={`d-flex align-items-center ${styles.headerContainer}`}>
            <div>
              <p>
                <Translate id="common.id" />: {drillingCrew.netvisorId} -{' '}
                <span className={`${styles.headerName}`}>{drillingCrew.name}</span>
              </p>
              <p>{drillingCrew.companyName}</p>
            </div>
          </div>
          <div className={`${styles.drillingInputFields}`}>
            <DrillingDateInput
              requestedDate={drillingCrew.requestedDate}
              setRequestedDate={(newDrillingDate) =>
                setRequestedDate(newDrillingDate, drillingCrew.id)
              }
              disabled={readOnly}
            />
            <DrillingDepthInput
              depth={drillingCrew.quantity}
              setDepth={(newDepth) => setDepth(newDepth, drillingCrew.id)}
              readOnly={readOnly}
            />
          </div>
        </div>
        {!readOnly && (
          <ConectionControl
            id={drillingCrew.id}
            onOrder={() => onOrder(drillingCrew)}
            // orderDisabled={drillingCrew.ordered}
            insideConnectionRequired={drillingCrew.insideConnectionRequired}
            removalOfDrillingWasteRequired={drillingCrew.removalOfDrillingWasteRequired}
          />
        )}
      </div>
    </>
  );
}

function DrillingCrew({ setLoading }) {
  const [selectedDrillingCrews, setSelectedDrillingCrews] = useState([]);
  const [defaultDepth, setDefaultDepth] = useState(0);
  const [defaultDrillingDate, setDefaultDrillingDate] = useState('');
  const { offerRequestId, offerId } = useParams();
  const { t } = useTranslation('lang');

  const navigate = useNavigate();

  const fetchAdditionalInfo = async () => {
    try {
      const {
        data: {
          data: { additionalInformation },
        },
      } = await offerRequestService.getOfferRequestAdditionalInfo(offerRequestId);
      setDefaultDrillingDate(
        additionalInformation?.requestedDrillingTime ?? moment().toISOString()
      );
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchSelectedDrillingCrews = async () => {
    setLoading(true);
    const {
      data: {
        data: { offerServices },
      },
    } = await getOfferServices(offerRequestId, offerId, { filter: { drilling: true } });
    setSelectedDrillingCrews(
      offerServices.map((drillingCrew) => {
        const crew = { ...drillingCrew };
        crew.ordered = !!drillingCrew.requestedDate;
        if (!drillingCrew.efforts) {
          crew.efforts = defaultDepth;
        }

        if (!drillingCrew.requestedDate) {
          crew.requestedDate = defaultDrillingDate;
        }
        return crew;
      })
    );
    setLoading(false);
  };

  const fetchOfferRequestBasicInformation = async () => {
    try {
      setLoading(true);
      const {
        data: {
          data: { basicInformation },
        },
      } = await getBasicInformationByOfferRequest(offerRequestId);
      await fetchAdditionalInfo();
      setDefaultDepth(basicInformation?.installSpaceDepth);
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    fetchSelectedDrillingCrews();
  }, [defaultDepth]);

  useEffect(() => {
    fetchOfferRequestBasicInformation();
  }, []);

  const setDepth = (newDepth, selectedDrillingCrewId) => {
    if (newDepth < 0) {
      return;
    }
    const tempSelectedDrillingCrews = [...selectedDrillingCrews];
    tempSelectedDrillingCrews.find(
      (selectedDrillingCrew) => selectedDrillingCrew.id === selectedDrillingCrewId
    ).efforts = newDepth;
    setSelectedDrillingCrews(tempSelectedDrillingCrews);
  };

  const setRequestedDate = (newDrillingDate, selectedDrillingCrewId) => {
    const tempSelectedDrillingCrews = [...selectedDrillingCrews];
    tempSelectedDrillingCrews.find(
      (selectedDrillingCrew) => selectedDrillingCrew.id === selectedDrillingCrewId
    ).requestedDate = newDrillingDate;
    setSelectedDrillingCrews(tempSelectedDrillingCrews);
  };

  const onNext = () =>
    navigate(`/offer-requests/${offerRequestId}/offers/${offerId}/tasks/installing-crew`);

  const customFooter = () => <DrillingCrewFooter onNext={onNext} />;

  const onOrder = async (selectedDrillingCrew) => {
    try {
      setLoading(true);
      await patchOfferService(offerRequestId, offerId, selectedDrillingCrew.id, {
        requestedDate: selectedDrillingCrew.requestedDate,
        efforts: selectedDrillingCrew.efforts,
      });
      setLoading(false);
      toast.success(t('assign_teams.drilling_crew.order_success_message'));
      fetchSelectedDrillingCrews();
    } catch (err) {
      setLoading(false);
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  return (
    <OfferPageContentWrapper customFooter={customFooter}>
      <div className={`d-flex flex-column ${styles.drillingCrewMainContainer}`}>
        <div className={`${styles.selectedCrewContainer}`}>
          <div className={`${styles.titleContainer}`}>
            <p>
              <Translate id="assign_teams.drilling_crew.selected_drilling_crew" />
            </p>
          </div>
          <div className={`${styles.selectedCrewFlexContainer}`}>
            {/* TODO: map here for selected drellingcrew */}
            {selectedDrillingCrews.map((selectedDrillingCrew) => (
              <DrillingCrewFormFields
                key={selectedDrillingCrew.id}
                drillingCrew={selectedDrillingCrew}
                setDepth={setDepth}
                setRequestedDate={setRequestedDate}
                onOrder={onOrder}
              />
            ))}
          </div>
        </div>
      </div>
    </OfferPageContentWrapper>
  );
}

DrillingTeam.propTypes = {
  drillingCrew: PropTypes.instanceOf(Object).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default DrillingCrew;
