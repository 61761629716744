import * as moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './index.module.scss';
import Button from '../../../components/button';
import CalendarControl from '../../../components/form-fields/calendar-control';
import OfferPageContentWrapper from '../../../components/offer-page-content-wrapper';
import Translate from '../../../components/translate';
import offerServices from '../../../services/offer';
import offerRequestService from '../../../services/offerRequest';
import { getOriginalErrors } from '../../../utils/errorHandler';

function DeliveryTimeFooter({ onButtonClick, onSave, isChanged }) {
  return isChanged ? (
    <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onSave}>
      <Translate id="components.button.save" />
    </Button>
  ) : (
    <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onButtonClick}>
      <Translate id="components.button.next" />
    </Button>
  );
}

function RequestedDeliveryDateInput({ requestedDeliveryTime }) {
  return (
    <div>
      <div>
        <Translate id="assign_teams.delivery_time.requested_delivery_date" />
      </div>
      <div>
        <div>
          <div>{moment(requestedDeliveryTime).format('DD-MM-yyyy HH:mm')}</div>{' '}
        </div>
      </div>
    </div>
  );
}
function EstimatedDeliveryDateInput({ estimatedDeliveryTime, onChange }) {
  return (
    <CalendarControl
      label="assign_teams.delivery_time.estimated_delivery_date"
      selected={estimatedDeliveryTime}
      onChange={onChange}
    />
  );
}

function DeliveryTime({ setLoading }) {
  const [requestedDeliveryTime, setRequestedDeliveryTime] = useState('');
  const [estimatedDeliveryTime, setEstimatedDeliveryTime] = useState('');
  const [estimatedDeliveryTimeComparison, setEstimatedDeliveryTimeComparison] = useState('');
  const [isChanged, setIsChanged] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('lang');

  const fetchRequestDeliveryTime = async () => {
    try {
      const {
        data: {
          data: { additionalInformation },
        },
      } = await offerRequestService.getOfferRequestAdditionalInfo(params.offerRequestId);
      setRequestedDeliveryTime(additionalInformation?.requestedDeliveryTime);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchEstimatedDeliveryTime = async () => {
    try {
      const {
        data: {
          data: { offer },
        },
      } = await offerServices.getOfferById(params.offerRequestId, params.offerId);
      setEstimatedDeliveryTime(offer?.estimatedDeliveryTime);
      setEstimatedDeliveryTimeComparison(offer?.estimatedDeliveryTime);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const onSave = async () => {
    try {
      setLoading(true);
      await offerServices.saveEstimatedDeliveryTime(params.offerRequestId, params.offerId, {
        estimatedDeliveryTime,
      });
      setLoading(false);
      toast.success(t('assign_teams.delivery_time.estimated_delivery_date_update_successfully'));
      navigate(
        `/offer-requests/${params.offerRequestId}/offers/${params.offerId}/tasks/heating-devices`
      );
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };
  const onNext = () => {
    navigate(
      `/offer-requests/${params.offerRequestId}/offers/${params.offerId}/tasks/heating-devices`
    );
  };

  const customFooter = () => (
    <DeliveryTimeFooter onButtonClick={onNext} onSave={onSave} isChanged={isChanged} />
  );

  useEffect(() => {
    if (estimatedDeliveryTimeComparison !== estimatedDeliveryTime) {
      setIsChanged(true);
    }
  }, [estimatedDeliveryTime]);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await fetchRequestDeliveryTime();
      await fetchEstimatedDeliveryTime();
      setLoading(false);
    };
    load();
  }, []);

  return (
    <OfferPageContentWrapper customFooter={customFooter}>
      <div className={`${styles.deliveryTimeContainer}`}>
        <div className={`${styles.headerContainer}`}>
          <h3 className={`${styles.headerText}`}>
            <Translate id="assign_teams.delivery_time.select_delivery_time" />
          </h3>
        </div>
        <div>
          <div className={`${styles.progressContent}`}>
            <RequestedDeliveryDateInput requestedDeliveryTime={requestedDeliveryTime} />
          </div>
          <EstimatedDeliveryDateInput
            estimatedDeliveryTime={estimatedDeliveryTime}
            onChange={(value) => setEstimatedDeliveryTime(moment(value).toISOString())}
          />
        </div>
      </div>
    </OfferPageContentWrapper>
  );
}

export default DeliveryTime;
