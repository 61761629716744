import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import Translate from '../../../../components/translate'

function TeamCard({ team }) {
  const navigate = useNavigate()

  const navigateToDetailsPage = () => navigate(`./${team.id}`)

  return (
    <div
      onClick={navigateToDetailsPage}
      role="button"
      tabIndex="0"
      onKeyUp={(e) => (e.key === 'Enter' ? navigateToDetailsPage() : null)}
    >
      <div>
        <Translate id="team.table.body.id" />:{team.id} -<div>{team.name}</div>
      </div>
      <div>{team.companyName}</div>
      <div>{team.numberOfEmployeeAssigned.toString().padStart(2, '0')}</div>
      <div>
        <img src={team.teamLogo} alt="company-logo" />
      </div>
    </div>
  )
}

TeamCard.propTypes = {
  team: PropTypes.instanceOf(Object).isRequired,
}

export default TeamCard
