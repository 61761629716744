import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './index.module.scss';
import { getCustomer } from '../../services/customer';
import offerRequestService from '../../services/offerRequest';
import { getOriginalErrors } from '../../utils/errorHandler';
import Header from '../header';
import OfferFormSteps from '../offer-form-steps';

function CustomerInfoHeader({ customerInformation }) {
  const customerFullName = `${customerInformation.firstName}  ${customerInformation.lastName}`;
  return (
    <div className={`${styles.customerHeaderContainer}`}>
      <div>
        <h1 title={`${customerFullName}`}>
          {customerFullName.length > 15 ? `${customerFullName.slice(0, 15)}...` : customerFullName}{' '}
        </h1>
      </div>
      <div>
        <span>{customerInformation.address},</span>
        <span>{customerInformation.zipCode},</span>
        <span>{customerInformation.city}</span>
      </div>
    </div>
  );
}

function OfferPageWrapper({ offerFormStepsRefreshCount }) {
  const [customerInformation, setCustomerInformation] = useState({});
  const params = useParams();

  const fetchCustomerInformation = async (customerId) => {
    try {
      const {
        data: {
          data: { customer: customerResponse },
        },
      } = await getCustomer(customerId);
      setCustomerInformation(customerResponse);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchOfferRequest = async () => {
    try {
      const {
        data: {
          data: { offerRequest: request },
        },
      } = await offerRequestService.getOfferRequestById(params.offerRequestId);
      await fetchCustomerInformation(request.customerId);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  useEffect(() => {
    fetchOfferRequest();
  }, []);

  const customerInfoHeader = () => <CustomerInfoHeader customerInformation={customerInformation} />;

  return (
    <div className={`${styles.appContainer}`}>
      <div className={`${styles.fixedElement}`}>
        <Header component={customerInfoHeader} />
      </div>
      <div className={`projectContainer ${styles.projectContent}`}>
        <OfferFormSteps offerFormStepsRefreshCount={offerFormStepsRefreshCount} />
        <Outlet />
      </div>
    </div>
  );
}

OfferPageWrapper.propTypes = {
  offerFormStepsRefreshCount: PropTypes.number.isRequired,
};

CustomerInfoHeader.propTypes = {
  customerInformation: PropTypes.instanceOf(Object).isRequired,
};

export default OfferPageWrapper;
