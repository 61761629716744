import { gql } from 'graphql-request';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { companyUserTaskStatusTranslations } from './constants';
import styles from './index.module.scss';
import DataTable from '../../../../components/data-table';
import DropDown from '../../../../components/drop-down';
import Layout from '../../../../components/layout';
import SearchBox from '../../../../components/search-box';
import Translate from '../../../../components/translate';
import { offerRequestStatuses, serviceTypes } from '../../../../constant';
import { fetchTasks as fetchTasksService } from '../../../../services/graphql/task';

function CompanyEmployeeTasksHeader() {
  return (
    <div>
      <h1>
        <Translate id="company.user.task_listing.header.title" />
      </h1>
    </div>
  );
}

function CompanyEmployeeTasks({ setLoading }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilterOption, setSelectedFilterOption] = useState('ALL');
  const navigate = useNavigate();
  const { companyId, employeeId } = useParams();
  const [queryParams, setQueryParams] = useState('');
  const [tasks, setTasks] = useState([]);
  const [offerReqeusts, setOfferRequests] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [nextCursor, setNextCursor] = useState(null);

  const { data, isLoading, error, refetch, fetchNextPage } = useInfiniteQuery(
    ['tasks', queryParams],
    async ({ pageParam = null }) => {
      const requestData = await fetchTasksService(
        gql`
          query (
            $companyId: ID!
            $employeeId: ID!
            $queryParams: String
            $first: Int!
            $after: String
          ) {
            tasks(
              companyId: $companyId
              employeeId: $employeeId
              queryParams: $queryParams
              first: $first
              after: $after
            ) {
              edges {
                node {
                  id
                  createdAt

                  service {
                    type
                    createdAt
                    completedAt

                    offer {
                      offerRequest {
                        id

                        customer {
                          fullName
                          address
                          city
                          zipCode
                        }
                      }
                    }
                  }
                }
                cursor
              }
              pageInfo {
                endCursor
                hasNextPage
              }
            }
          }
        `,
        {
          companyId,
          employeeId,
          queryParams,
          first: 10,
          after: nextCursor ? pageParam : null,
        }
      );
      setLoading(false);
      return requestData;
    },
    {
      getNextPageParam: (lastPage) => lastPage.pageInfo.endCursor,
    }
  );
  const { t } = useTranslation('lang');

  const translateObject = (objects, field = 'label') =>
    objects.map((object) => {
      const translatedObject = { ...object, [field]: t(object[field]) };
      return translatedObject;
    });

  const fetchTasks = async (q, status = selectedFilterOption) => {
    setNextCursor(null);
    let params = ``;
    if (q) {
      params = `q=${q}&`;
    }
    if (status && status !== 'ALL') {
      params += `status=${status}`;
    }
    setQueryParams(params);
    await refetch();
    setLoading(false);
  };

  const onSearch = (value) => {
    setSearchTerm(value);
    fetchTasks(value, selectedFilterOption);
  };

  const debouncedSearch = debounce((newValue) => {
    onSearch(newValue);
  }, 1000);

  const onFilterOptionSelect = (value) => {
    setSelectedFilterOption(value);
    fetchTasks(searchTerm, value);
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  useEffect(() => {
    if (tasks?.length) {
      setOfferRequests(
        tasks.map((task) => ({
          ...task?.service?.offer?.offerRequest,
          offerRequestProgress: [
            {
              event: task?.service?.completedAt
                ? offerRequestStatuses.OFFER_COMPLETION.value
                : offerRequestStatuses.NEW.value,
            },
          ],
          createdAt: task?.createdAt,
          completedAt: task?.service?.completedAt,
          taskId: task.id,
        }))
      );
    } else {
      setOfferRequests([]);
    }
  }, [tasks]);

  useEffect(() => {
    if (data?.pages?.length) {
      setTasks(
        [].concat(
          ...data.pages.map((page) =>
            page?.edges?.length ? page.edges.map((edge) => edge.node) : []
          )
        )
      );
      setHasNextPage(data?.pages[data.pages.length - 1]?.pageInfo?.hasNextPage);
      setNextCursor(data?.pages[data.pages.length - 1]?.pageInfo?.endCursor);
    }
  }, [data]);

  const onTaskSelect = (request, index) => {
    switch (tasks?.[index]?.service?.type) {
      case serviceTypes.DRILLING:
        navigate(
          // `/company/${companyId}/employee/${employeeId}/tasks/${tasks?.[index]?.id}/drilling-crew-view`
          `/company/${companyId}/employee/${employeeId}/tasks/${tasks?.[index]?.id}/basic-details`
        );
        break;
      case serviceTypes.INSTALLING:
        navigate(
          // `/company/${companyId}/employee/${employeeId}/tasks/${tasks?.[index]?.id}/installing-crew-view`
          `/company/${companyId}/employee/${employeeId}/tasks/${tasks?.[index]?.id}/basic-details`
        );
        break;
      default:
    }
  };

  return (
    <Layout headerComponent={CompanyEmployeeTasksHeader} links={[]}>
      <div className={styles.taskPageContainer}>
        <div
          className={`d-md-flex align-items-md-center justify-content-md-between ${styles.headerContainer}`}
        >
          <div>
            <SearchBox value={searchTerm} onChange={debouncedSearch} />
          </div>

          <div className="d-md-flex align-items-center justify-content-end">
            <div
              className={`d-flex align-items-center justify-content-end ${styles.filterDropDownParent}`}
            >
              <p className={`${styles.filterTitle}`}>{t('common.filter')}: </p>
              <DropDown
                className={`${styles.filterDropDown}`}
                options={translateObject(companyUserTaskStatusTranslations)}
                selected={selectedFilterOption}
                onChange={onFilterOptionSelect}
                selectionKey="value"
              />
            </div>
          </div>
        </div>
        {!isLoading && !error && (
          <DataTable
            responseData={offerReqeusts}
            onRowClick={onTaskSelect}
            showAssignedColumn={false}
            isOfferRequestType={false}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
          />
        )}
      </div>
    </Layout>
  );
}

CompanyEmployeeTasks.propTypes = {
  setLoading: PropTypes.func.isRequired,
};

export default CompanyEmployeeTasks;
