const formatFieldName = (name) => {
  const result = name.replace(/([A-Z])/g, ' $1');
  const fieldName = result.charAt(0).toUpperCase() + result.slice(1);
  return fieldName;
};

const getFormattedMessage = (message) => message.replace(',', '').replace("'", '');

export const getOriginalErrors = (errorObj) => {
  const errors = [];
  if (Array.isArray(errorObj)) {
    errorObj.forEach((err) => {
      if (err.location && err.param) {
        errors.push({ field: err.param, message: err.message || err.msg });
      } else if (Object.keys(err).length === 1 && err.message) {
        const errorsArray = err.message.split('\n');
        errorsArray.forEach((error) => {
          let errorMsg = error.split('.');
          if (errorMsg.length > 1) {
            const field = errorMsg[1].split(' ')[0];
            errors.push({
              field,
              message: getFormattedMessage(errorMsg[1].replace(field, formatFieldName(field))),
            });
          } else {
            errorMsg = error.startsWith('Column') ? error.split('Column ') : error.split('Field ');
            if (errorMsg.length > 1) {
              const field = getFormattedMessage(
                errorMsg[1].substring(1, errorMsg[1].indexOf(' ') - 1)
              );
              errors.push({
                field,
                message: `${errorMsg[1]
                  .replace(field, formatFieldName(field))
                  .substring(1)
                  .replace("' ", ' ')}`,
              });
            } else {
              errors.push({ message: errorMsg });
            }
          }
        });
      } else {
        errors.push(err);
      }
    });
  } else {
    errors.push({ message: errorObj.message || errorObj.msg });
  }
  return errors;
};
