import PropTypes from 'prop-types';
import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { userRoles } from '../../constant';
import { getCurrentSessionUser, getToken } from '../../services/authorization';

function RequireAuth({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  if (!getToken()) {
    return <Navigate to="/auth/login" />;
  }

  const currentUser = getCurrentSessionUser();
  if (
    currentUser &&
    currentUser.company &&
    !location.pathname.startsWith(`/company/${currentUser.company.id}`)
  ) {
    if (currentUser.roles.includes(userRoles.COMPANY_ADMIN)) {
      navigate(`/company/${currentUser.company.id}/admin/${currentUser.id}/assignments`);
    } else if (currentUser.roles.includes(userRoles.COMPANY_EMPLOYEE)) {
      navigate(`/company/${currentUser.company.id}/employee/${currentUser.id}/tasks`);
    }
  }

  return children;
}

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export { RequireAuth };
