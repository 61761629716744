import React from 'react';

import styles from './UserItem.module.scss';
// import Translate from '../../../components/translate';

function UserItem({ name, selected, onSelect }) {
  return (
    <div
      onClick={onSelect}
      tabIndex={0}
      role="button"
      onKeyDown={(e) => {
        if (e.key === 'ENTER') {
          onSelect();
        }
      }}
      className={`${styles.userItemContainer} ${selected ? styles.activeUserItem : ''}`}
    >
      <div className={`${styles.userItem}`}>
        <div className="d-flex align-items-center ">
          {/* <div>
            <p>
              <Translate id="user.id" /> : {id}
            </p>
          </div> */}
          <div>
            <p className={`${styles.boldText}`}>{name}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserItem;
