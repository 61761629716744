import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'

import styles from './index.module.scss'
import {offerRequestTypes} from '../../constant'
import Button from '../button'
import Translate from '../translate'

function OfferRequestTypeInput({ id, label, value, onChange, checked }) {
  return (
    <label className="radioContainer circleRadioContainer" htmlFor={id}>
      <input
        type="radio"
        name="offer_type"
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <p className="circleActiveText">
        <Translate id={label} />
      </p>
      <span className="radioIcon" />
      <div className="circleActiveBg" />
    </label>
  )
}

function OfferRequestTypesModal({ show, onHide, onNext }) {
  const offerRequestTypeConstants = Object.keys(offerRequestTypes).map((key) => ({id: key, name:offerRequestTypes[key], value: key}))
  const [selectedOfferRequestType, setSelectedOfferRequestType] = useState(
    offerRequestTypeConstants[0].value,
  )

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header>
        <Modal.Title className="text-center">
          <Translate id="offer.request.type_of_the_offer" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          {offerRequestTypeConstants.map(({ id, name, value }) => (
            <OfferRequestTypeInput
              key={id}
              id={id}
              label={name}
              value={value}
              checked={value === selectedOfferRequestType}
              onChange={(e) => setSelectedOfferRequestType(e.target.value)}
            />
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className={`${styles.footerBtn}`} onClick={() => onNext(selectedOfferRequestType)}>
          <Translate id="components.button.next" />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

OfferRequestTypeInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
}

OfferRequestTypesModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
}

export default OfferRequestTypesModal
