/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import { ReactComponent as ArrowDownSVG } from '../../assets/svgs/ArrowDown.svg';
import { ReactComponent as ArrowUPSVG } from '../../assets/svgs/ArrowUp.svg';
import useClickOutside from '../customHooks/useClickOutside';

function ToggleButton({ children, setToggle, disabled }) {
  const toggleDropDown = () => {
    setToggle((prev) => !prev);
  };
  return (
    <button
      className={`${styles.dropDownBtn} ${disabled ? styles.disabled : ''}`}
      type="button"
      onClick={toggleDropDown}
    >
      {children}
    </button>
  );
}

function Dropdown({
  options,
  isSearchable,
  placeholder,
  type,
  label,
  selected,
  onChange,
  className,
  selectionKey,
  disabled,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation('lang');
  const dropdownRef = useRef(null);

  useClickOutside(dropdownRef, () => setIsOpen(false));

  const filteredOptions = useMemo(
    () =>
      options.filter((item) =>
        (type.toLowerCase() === 'string' ? item : item[label])
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ),
    [options, searchTerm, label, type]
  );

  const handleItemClick = (item) => {
    onChange(item);
    setIsOpen(false);
    if (isSearchable) setSearchTerm('');
  };

  const handleIsOpen = () => {
    if (!disabled) {
      setIsOpen((prev) => !prev);
    }
  };

  return (
    <div
      className={`${styles.dropDown} ${disabled ? styles.containerDisabled : ''}`}
      ref={dropdownRef}
    >
      <div
        className={`d-flex align-items-center justify-content-between ${className} ${
          styles.dropDownBtnContainer
        } ${disabled ? styles.disabled : ''}`}
        onClick={handleIsOpen}
        onKeyDown={() => {}}
        role="button"
        tabIndex="0"
      >
        {isSearchable && isOpen ? (
          <input
            className={`inputField ${styles.inputField} `}
            type="text"
            placeholder={t('components.drop_down.search_placeholder')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        ) : (
          <ToggleButton disabled={disabled}>
            {selected && type.toLowerCase() === 'string' && selected}
            {selected &&
              selected.toString() &&
              type.toLowerCase() === 'object' &&
              options.find((o) => o[selectionKey] === selected)?.label}
            {(!selected || !selected.toString()) && t(placeholder)}
          </ToggleButton>
        )}

        {!disabled && (
          <ToggleButton className={`${styles.dropDownArrowBtn}`} disabled={disabled}>
            {isOpen ? (
              <ArrowUPSVG className={`${styles.dropDownArrow}`} />
            ) : (
              <ArrowDownSVG className={`${styles.dropDownArrow}`} />
            )}
          </ToggleButton>
        )}
      </div>

      {isOpen && (
        <div className={`${styles.dropDownMenu}`}>
          {filteredOptions.map((item) => (
            <button
              type="button"
              className={`${styles.dropDownItem}`}
              key={type === 'string' ? item : item.id}
              onClick={() => handleItemClick(type === 'string' ? item : item[selectionKey])}
            >
              {type.toLowerCase() === 'string' ? item : item[label]}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

ToggleButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  setToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
ToggleButton.defaultProps = {
  disabled: false,
};

Dropdown.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

Dropdown.defaultProps = {
  isSearchable: false,
  placeholder: 'components.drop_down.placeholder',
  type: 'object',
  label: 'label',
  selected: '',
  className: null,
  onChange() {},
  disabled: false,
};

export default Dropdown;
