import React from 'react';

import AdditionalInformation from './AdditionalInformation';
import Attachments from './Attachments';
import BasicInformation from './BasicInformation';
import ProductItems from './ProductItems';
import TechnicalInformation from './TechnicalInformation';
import styles from './basicDetails.module.scss';
import CustomerContact from '../../../components/customer-contact';
import Translate from '../../../components/translate';
import { RequestDetails } from '../../offer-forms/basic-details/Form';
import { useBasicInformationContext } from '../../task-forms/context/basicInformationContext';
import { useServiceContext } from '../../task-forms/context/service-context';

function BasicDetails() {
  const {
    basicInformation,
    heatingDevices,
    products,
    offerServices,
    technicalInformation,
    additionalInformation,
    attachments,
  } = useServiceContext();

  const { customerInformation } = useBasicInformationContext();

  return (
    <div className={`${styles.basicDetailsContainer}`}>
      <div className={`d-flex flex-column flex-md-row justify-content-start justify-content-md-between ${styles.contactContainer}`}>
      <div className={`${styles.titleContainer}`}>
            <p>
              <Translate id="offer.basic_details.title" />
            </p>
          </div>
        <CustomerContact customerInformation={customerInformation} />
        
      </div>
      <RequestDetails basicInfo={basicInformation} />
      <ProductItems heatingDevices={heatingDevices} products={products} services={offerServices} />
      <div className={`d-md-flex flex-wrap justify-content-between ${styles.flexContainer}`}>
        <div>
          <BasicInformation basicInformation={basicInformation} />
        </div>
        <div>
          <TechnicalInformation technicalInformation={technicalInformation} />
        </div>
        <div>
          <AdditionalInformation additionalInformation={additionalInformation} />
        </div>
      </div>
      <Attachments attachments={attachments || []} />
    </div>
  );
}

export default BasicDetails;
