/* eslint-disable no-param-reassign */
// This is disabled to handle the multiple errors and return only one error
// by param-reassign the error message
import axios from 'axios';
import { getI18n } from 'react-i18next';

import { authHeader, logout } from './authorization';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_API_URL}`,
});

apiClient.interceptors.request.use(
  (config) => {
    config.headers = authHeader();
    config.headers['Accept-Language'] = getI18n().language.toUpperCase();

    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response && error.response.data && error.response.data.errors) {
      error = error.response.data.errors;
    }
    if (error.response && error.response.status && error.response.status === 401) {
      logout();
    }
    if (error.response && error.response.status && error.response.status === 403) {
      window.location = '/';
    }
    return Promise.reject(error);
  }
);

export const paginationDataHandler = (path) => apiClient(path);

export default apiClient;
