import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Wrapper from './Wrapper';
import CompanyUsers from '../../../pages/company-admin/company-user';

function CompanyAdminSettingRoutes({ setLoading }) {
  return (
    <Routes>
      <Route path="" element={<Wrapper />}>
        <Route path="employees" element={<CompanyUsers setLoading={setLoading} />} />
      </Route>
    </Routes>
  );
}

export default CompanyAdminSettingRoutes;
