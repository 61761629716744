import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import * as yup from 'yup';

import styles from './index.module.scss';
import CalendarControl from '../../../components/form-fields/calendar-control';
import DropDownControl from '../../../components/form-fields/drop-down-control';
import NumberControl from '../../../components/form-fields/number-control';
import Translate from '../../../components/translate';
import { constructionMethods, installationTypes } from '../../../constant';
import { getOriginalErrors } from '../../../utils/errorHandler';

function SizeOfSpaceInput({ error, getValue, setValue }) {
  return (
    <>
      <NumberControl
        label="offer.request.size_of_spaces_to_be_heated"
        unit={
          <div>
            m<sup>2</sup>
          </div>
        }
        name="heatingArea"
        value={getValue('heatingArea')}
        onChange={(value) => setValue('heatingArea', value < 0 ? 0 : value)}
      />
      {error && <div className={`${styles.errorLabel}`}>{error}</div>}
    </>
  );
}
function TargetInput({ options, setValue, getValue, error }) {
  return (
    <>
      <DropDownControl
        label="offer.request.the_installation_target_is"
        options={options}
        selected={getValue('installationType')}
        onChange={(value) => setValue('installationType', value)}
        selectionKey="id"
        name="installationType"
      />
      {error && <div className={`${styles.errorLabel}`}>{error}</div>}
    </>
  );
}
function EstimatesTimeInput({ setValue, getValue, error }) {
  return (
    <>
      <CalendarControl
        label="offer.request.estimated_start_time"
        selected={getValue('renovationTime')}
        onChange={(value) => setValue('renovationTime', value)}
        name="renovationTime"
      />
      {error && <div className={`${styles.errorLabel}`}>{error}</div>}
    </>
  );
}
function ConstructionMethodInput({ options, getValue, setValue, error }) {
  return (
    <>
      <DropDownControl
        label="offer.request.construction_method"
        options={options}
        selected={getValue('constructionType')}
        onChange={(value) => setValue('constructionType', value)}
        selectionKey="id"
        name="constructionType"
      />
      {error && <div className={`${styles.errorLabel}`}>{error}</div>}
    </>
  );
}
function UnderFloorHeating({ options, getValue, setValue, error }) {
  const { t } = useTranslation('lang');

  return (
    <>
      <DropDownControl
        label="offer.request.offer_for_underfloor_heating"
        options={options}
        selected={getValue('underfloorHeatingOffered') ? t('common.yes') : t('common.no')}
        onChange={(value) => setValue('underfloorHeatingOffered', value === t('common.yes'))}
        selectionKey="id"
        name="underfloorHeatingOffered"
      />
      {error && <div className={`${styles.errorLabel}`}>{error}</div>}
    </>
  );
}

function ItemInformation({ basicInformation, onUpdateBasicInformation, apiError }) {
  const { t } = useTranslation('lang');

  const basicInformationSchema = yup.object({
    heatingArea: yup.number(),
    installationType: yup.string(),
    constructionType: yup.string(),
    underfloorHeatingOffered: yup.boolean().default(false),
    renovationTime: yup.string(),
  });

  const {
    register,
    formState: { errors },
    watch,
    reset,
    setError,
    clearErrors,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(basicInformationSchema),
  });

  watch(
    (
      { heatingArea, installationType, constructionType, underfloorHeatingOffered, renovationTime },
      name
    ) => {
      onUpdateBasicInformation((prev) => ({
        ...prev,
        heatingArea,
        installationType,
        constructionType,
        underfloorHeatingOffered,
        renovationTime,
      }));
      clearErrors(name.name);
    }
  );

  const installationTargetOptions = Object.keys(installationTypes).map((key) => ({
    id: key,
    label: t(installationTypes[key]),
  }));
  const constructionMethodOptions = Object.keys(constructionMethods).map((key) => ({
    id: key,
    label: t(constructionMethods[key]),
  }));
  const underfloorHeatingOptions = [
    { id: t('common.yes'), label: t('common.yes') },
    { id: t('common.no'), label: t('common.no') },
  ];

  useEffect(() => {
    reset({ ...basicInformation });
  }, []);

  useEffect(() => {
    if (apiError) {
      const originalErrors = getOriginalErrors(apiError);
      originalErrors.forEach((error) => {
        if (error.field) {
          setError(error.field, { message: error.message });
        } else {
          toast.error(error.message);
        }
      });
    }
  }, [apiError]);

  return (
    <div className={`${styles.itemInformationContainer}`}>
      <div className={`${styles.headerContainer}`}>
        <h1 className={`${styles.headerTitle}`}>
          <Translate id="offer.request.item_information" />
        </h1>
      </div>
      <div className={`${styles.contentContainer}`}>
        <SizeOfSpaceInput
          className={`${styles.sizeOfInputContainer}`}
          register={register}
          error={errors.heatingArea?.message}
          getValue={getValues}
          setValue={setValue}
        />
        <TargetInput
          options={installationTargetOptions}
          setValue={setValue}
          getValue={getValues}
          error={errors.installationType?.message}
        />
        <EstimatesTimeInput
          setValue={setValue}
          getValue={getValues}
          error={errors.renovationTime?.message}
        />
        <ConstructionMethodInput
          options={constructionMethodOptions}
          setValue={setValue}
          getValue={getValues}
          error={errors.constructionType?.message}
        />
        <UnderFloorHeating
          options={underfloorHeatingOptions}
          setValue={setValue}
          getValue={getValues}
          error={errors.underfloorHeatingOffered?.message}
        />
      </div>
    </div>
  );
}

SizeOfSpaceInput.propTypes = {
  setValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  error: PropTypes.string,
};

SizeOfSpaceInput.defaultProps = {
  error: '',
};

TargetInput.propTypes = {
  options: PropTypes.instanceOf(Array),
  setValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  error: PropTypes.string,
};

TargetInput.defaultProps = {
  options: ['Option 1', 'Option 1'],
  error: '',
};

EstimatesTimeInput.propTypes = {
  setValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  error: PropTypes.string,
};

EstimatesTimeInput.defaultProps = {
  error: '',
};

ConstructionMethodInput.propTypes = {
  options: PropTypes.instanceOf(Array),
  setValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  error: PropTypes.string,
};

ConstructionMethodInput.defaultProps = {
  options: ['Option 1', 'Option 1'],
  error: '',
};

UnderFloorHeating.propTypes = {
  options: PropTypes.instanceOf(Array),
  setValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  error: PropTypes.string,
};

UnderFloorHeating.defaultProps = {
  options: ['Option 1', 'Option 1'],
  error: '',
};

ItemInformation.propTypes = {
  basicInformation: PropTypes.instanceOf(Object),
  onUpdateBasicInformation: PropTypes.func,
  apiError: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.instanceOf(Object)])
    .isRequired,
};

ItemInformation.defaultProps = {
  basicInformation: {},
  onUpdateBasicInformation: () => {},
};

export default ItemInformation;
