import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import styles from './Wrapper.module.scss';
import TaskSteps from '../../../components/company-task-steps';
import Header from '../../../components/header';
import {
  BasicInformationContext,
  useBasicInformationContext,
} from '../../../pages/task-forms/context/basicInformationContext';
import { ServiceContext } from '../../../pages/task-forms/context/service-context';

function CustomerInfoHeader() {
  const { customerInformation } = useBasicInformationContext();
  const [customerFullName, setCustomerFullName] = useState('');

  useEffect(() => {
    setCustomerFullName(`${customerInformation?.firstName}  ${customerInformation?.lastName}`);
  }, [customerInformation]);

  return (
    <div className={`${styles.customerHeaderContainer}`}>
      <div>
        <h1 title={`${customerFullName}`}>
          {customerFullName.length > 15 ? `${customerFullName.slice(0, 15)}...` : customerFullName}{' '}
        </h1>
      </div>
      <div>
        <span>{customerInformation.address},</span>
        <span>{customerInformation.zipCode},</span>
        <span>{customerInformation.city}</span>
      </div>
    </div>
  );
}

function Wrapper() {
  const customerInfoHeader = () => <CustomerInfoHeader />;

  return (
    <ServiceContext>
      <BasicInformationContext>
        <div className={`${styles.appContainer}`}>
          <div className={`${styles.fixedElement}`}>
            <Header component={customerInfoHeader} />
          </div>
          <div className={`projectContainer ${styles.projectContent}`}>
            <TaskSteps />
            <Outlet />
          </div>
        </div>
      </BasicInformationContext>
    </ServiceContext>
  );
}

export default Wrapper;
