import PropTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';
import { scrollableTargetId } from '../../constant';
import Footer from '../footer';
import Header from '../header';

function Layout({ headerComponent, footerComponent, links, children }) {
  return (
    <div className={`${styles.appContainer}`}>
      <div className={`${styles.fixedElement}`}>
        <Header component={headerComponent} links={links} />
      </div>
      <div className={`projectContainer ${styles.projectContent}`} id={scrollableTargetId}>
        {children}
      </div>
      <div className={`${styles.fixedElement} ${styles.footerElement}`}>
        <Footer component={footerComponent} />
      </div>
    </div>
  );
}

Layout.propTypes = {
  headerComponent: PropTypes.func,
  footerComponent: PropTypes.func,
  children: PropTypes.node.isRequired,
  links: PropTypes.instanceOf(Array).isRequired,
};

Layout.defaultProps = {
  headerComponent: null,
  footerComponent: null,
};

export default Layout;
