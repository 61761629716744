import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import CalendarControl from '../../../../components/form-fields/calendar-control';
import DropDownControl from '../../../../components/form-fields/drop-down-control';
import NumberControl from '../../../../components/form-fields/number-control';
import {
  constructionMethods,
  installationTypes,
  offerRequestTypes,
  currentHeatingSystems,
} from '../../../../constant';

function HeatingAreaInput({ value, onChange }) {
  const { t } = useTranslation('lang');
  return (
    <NumberControl
      label="offer.onsite_memo.heating_area"
      value={value}
      unit={
        <span>
          {t('offer.onsite_memo.m')}
          <sup>2</sup>
        </span>
      }
      name="heating_area"
      onChange={onChange}
    />
  );
}

function ConstructionTypeInput({ value, onChange }) {
  const { t } = useTranslation('lang');
  const constructionTypeOptions = Object.keys(constructionMethods).map((key) => ({
    id: key,
    label: t(constructionMethods[key]),
  }));
  return (
    <DropDownControl
      type="object"
      label="offer.onsite_memo.basic_information.construction_type.title"
      options={constructionTypeOptions}
      selected={value}
      onChange={onChange}
      selectionKey="id"
    />
  );
}

function CurrentHeatingSystemInput({ value, onChange }) {
  const { t } = useTranslation('lang');
  const currentHeatingSystemOptions = Object.keys(currentHeatingSystems).map((key) => ({
    id: key,
    label: t(currentHeatingSystems[key]),
  }));
  return (
    <DropDownControl
      type="object"
      label="offer.onsite_memo.current_heating_system"
      options={currentHeatingSystemOptions}
      selected={value}
      onChange={onChange}
      selectionKey="id"
    />
  );
}

function CurrentConsumptionInput({ value, onChange }) {
  return (
    <NumberControl
      label="offer.onsite_memo.current_consumption"
      value={value}
      name="current_consumption"
      onChange={onChange}
    />
  );
}

function InstallationTypeInput({ value, onChange }) {
  const { t } = useTranslation('lang');
  const installationTypeOptions = Object.keys(installationTypes).map((key) => ({
    id: key,
    label: t(installationTypes[key]),
  }));
  return (
    <DropDownControl
      type="object"
      label="offer.onsite_memo.basic_information.installation_type.title"
      options={installationTypeOptions}
      selected={value}
      onChange={onChange}
      selectionKey="id"
    />
  );
}

function RequestedHeatingType({ value, onChange }) {
  const { t } = useTranslation('lang');
  const offerRequestTypeOptions = Object.keys(offerRequestTypes).map((key) => ({
    id: key,
    label: t(offerRequestTypes[key]),
  }));
  return (
    <DropDownControl
      type="object"
      label="offer.onsite_memo.requested_heating_type"
      options={offerRequestTypeOptions}
      selected={value}
      onChange={onChange}
      selectionKey="id"
    />
  );
}

function InstallSpaceHeightInput({ value, onChange }) {
  return (
    <NumberControl
      label="offer.onsite_memo.install_space_height"
      value={value}
      unit="offer.onsite_memo.cm"
      name="install_space_height"
      onChange={onChange}
    />
  );
}

function InstallSpaceWidthInput({ value, onChange }) {
  return (
    <NumberControl
      label="offer.onsite_memo.install_space_width"
      value={value}
      unit="offer.onsite_memo.cm"
      name="install_space_width"
      onChange={onChange}
    />
  );
}

function InstallSpaceDepthInput({ value, onChange }) {
  return (
    <NumberControl
      label="offer.onsite_memo.install_space_depth"
      value={value}
      unit="offer.onsite_memo.cm"
      name="install_space_depth"
      onChange={onChange}
    />
  );
}

function SmallestOpeningInput({ value, onChange }) {
  return (
    <NumberControl
      label="offer.onsite_memo.smallest_opening"
      value={value}
      unit="offer.onsite_memo.cm"
      name="smallest_opening"
      onChange={onChange}
    />
  );
}

function RenovationTimeInput({ value, onChange }) {
  return (
    <CalendarControl
      label="offer.onsite_memo.renovation_time"
      selected={value}
      onChange={onChange}
    />
  );
}

function BasicInformation({ basicInformation, onChangeBasicInformation }) {
  const { t } = useTranslation('lang');

  const onChangeHeatingArea = (heatingArea) => {
    onChangeBasicInformation({ ...basicInformation, heatingArea });
  };

  const onChangeInstallationType = (installationType) => {
    onChangeBasicInformation({ ...basicInformation, installationType });
  };

  const onChangeConstructionType = (constructionType) => {
    onChangeBasicInformation({ ...basicInformation, constructionType });
  };

  const onChangeCurrentHeatingSystem = (currentHeatingSystem) => {
    onChangeBasicInformation({ ...basicInformation, currentHeatingSystem });
  };

  const onChangeCurrentConsumption = (currentConsumption) => {
    onChangeBasicInformation({ ...basicInformation, currentConsumption });
  };

  const onChangeRequestedHeatingType = (requestedHeatingType) => {
    onChangeBasicInformation({ ...basicInformation, requestedHeatingType });
  };

  const onChangeInstallSpaceHeight = (installSpaceHeight) => {
    onChangeBasicInformation({ ...basicInformation, installSpaceHeight });
  };

  const onChangeInstallSpaceWidth = (installSpaceWidth) => {
    onChangeBasicInformation({ ...basicInformation, installSpaceWidth });
  };

  const onChangeInstallSpaceDepth = (installSpaceDepth) => {
    onChangeBasicInformation({ ...basicInformation, installSpaceDepth });
  };

  const onChangeSmallestOpening = (smallestOpening) => {
    onChangeBasicInformation({ ...basicInformation, smallestOpening });
  };

  const onChangeRenovationTime = (renovationTime) => {
    onChangeBasicInformation({ ...basicInformation, renovationTime });
  };

  const onChangeComment = (comment) => {
    onChangeBasicInformation({ ...basicInformation, comment });
  };

  return (
    <div className={`d-md-flex ${styles.basicDetailsMainContainer}`}>
      <div className={`${styles.basicDetailsContainer}`}>
        <p className={`${styles.basicDetailsTitle}`}>
          {t('offer.onsite_memo.basic_information.title')}
        </p>
        <div className={`${styles.inputContainer}`}>
          <HeatingAreaInput value={basicInformation.heatingArea} onChange={onChangeHeatingArea} />
          <InstallationTypeInput
            value={basicInformation.installationType}
            onChange={onChangeInstallationType}
          />
          <RenovationTimeInput
            value={basicInformation.renovationTime}
            onChange={onChangeRenovationTime}
          />
          <ConstructionTypeInput
            value={basicInformation.constructionType}
            onChange={onChangeConstructionType}
          />
          <CurrentHeatingSystemInput
            value={basicInformation.currentHeatingSystem}
            onChange={onChangeCurrentHeatingSystem}
          />
          <CurrentConsumptionInput
            value={basicInformation.currentConsumption}
            onChange={onChangeCurrentConsumption}
          />
          <RequestedHeatingType
            value={basicInformation.requestedHeatingType}
            onChange={onChangeRequestedHeatingType}
          />
          <InstallSpaceHeightInput
            value={basicInformation.installSpaceHeight}
            onChange={onChangeInstallSpaceHeight}
          />
          <InstallSpaceWidthInput
            value={basicInformation.installSpaceWidth}
            onChange={onChangeInstallSpaceWidth}
          />
          <InstallSpaceDepthInput
            value={basicInformation.installSpaceDepth}
            onChange={onChangeInstallSpaceDepth}
          />
          <SmallestOpeningInput
            value={basicInformation.smallestOpening}
            onChange={onChangeSmallestOpening}
          />
        </div>
      </div>
      <div className={`${styles.textAreaContainer}`}>
        <p className={`${styles.textAreaTitle}`}>
          {t('offer.onsite_memo.additional_comments_title')}
        </p>
        <textarea
          value={basicInformation.comment}
          className={`inputField ${styles.textArea}`}
          cols="60"
          rows="10"
          onChange={(e) => onChangeComment(e.target.value)}
        />
      </div>
    </div>
  );
}

HeatingAreaInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

ConstructionTypeInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

InstallationTypeInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

CurrentHeatingSystemInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

CurrentConsumptionInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

RequestedHeatingType.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

InstallSpaceHeightInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

InstallSpaceWidthInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

InstallSpaceDepthInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

RenovationTimeInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  onChange: PropTypes.func.isRequired,
};

SmallestOpeningInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

BasicInformation.propTypes = {
  basicInformation: PropTypes.instanceOf(Object).isRequired,
  onChangeBasicInformation: PropTypes.func.isRequired,
};

export default BasicInformation;
