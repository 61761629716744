import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import TopWrapper from './BasicDetailsWrapper';
import Button from '../../../components/button';
import Translate from '../../../components/translate';
import BasicDetails from '../../company-admin/basic-details/BasicDetails';

function CustomFooter() {
  const { offerRequestId, offerId } = useParams();
  const navigate = useNavigate();

  const onClick = () =>
    navigate(`/offer-requests/${offerRequestId}/offers/${offerId}/tasks/delivery-time`);

  return (
    <Button className="greenBtn" onClick={onClick}>
      <Translate id="components.button.continue" />
    </Button>
  );
}

function Wrapper({ setLoading }) {
  const customFooter = () => <CustomFooter />;

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <TopWrapper customFooter={customFooter}>
      <BasicDetails />
    </TopWrapper>
  );
}

export default Wrapper;
