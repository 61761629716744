import apiClient from './api-client';

export const getOfferServices = (offerRequestId, offerId, params) =>
  apiClient.get(`/v1/offer-requests/${offerRequestId}/offers/${offerId}/services`, { params });

export const updateOfferServices = (offerRequestId, offerId, services) =>
  apiClient.put(`/v1/offer-requests/${offerRequestId}/offers/${offerId}/services`, services);

export const patchOfferService = (offerRequestId, offerId, serviceId, body) =>
  apiClient.patch(
    `/v1/offer-requests/${offerRequestId}/offers/${offerId}/services/${serviceId}`,
    body
  );

export const updateTaskComment = (offerRequestId, offerId, serviceId, taskComment) =>
  apiClient.put(
    `/v1/offer-requests/${offerRequestId}/offers/${offerId}/services/${serviceId}/task-comment`,
    { taskComment }
  );
