import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function NotFound() {
  const { t } = useTranslation('lang');
  return (
    <div>
      <h1>{t('notfound.page_not_found')}</h1>
      <p>{t('notfound.sorry_page_does_not_exists')}</p>
      <Link to="/">{t('notfound.go_home')}</Link>
    </div>
  );
}

export default NotFound;
