const events = [
  {
    id: '1',
    title: 'Team Meeting',
    description: 'Weekly team meeting to discuss progress and goals',
    start: '2023-03-28T10:15:00-07:00',
    end: '2023-03-28T11:30:00-07:00',
    location: 'Conference Room A',
  },
  {
    id: '2',
    title: 'Lunch with Clients',
    description: 'Meeting with clients to discuss new project',
    start: '2023-03-29T12:45:00-07:00',
    end: '2023-03-29T13:45:00-07:00',
    location: 'Restaurant XYZ',
  },
  {
    id: '3',
    title: 'Product Demo',
    description: 'Demo of new product for potential customers',
    start: '2023-03-30T14:20:00-07:00',
    end: '2023-03-30T15:30:00-07:00',
    location: 'Conference Room B',
  },
  {
    id: '4',
    title: 'Marketing Meeting',
    description: 'Weekly marketing meeting to discuss strategy',
    start: '2023-03-31T09:45:00-07:00',
    end: '2023-03-31T10:45:00-07:00',
    location: 'Marketing Department',
  },
  {
    id: '5',
    title: 'Product Development Meeting',
    description:
      'Weekly product development meeting to review progress and discuss new features',
    start: '2023-03-28T14:30:00-07:00',
    end: '2023-03-28T15:45:00-07:00',
    location: 'Product Development Department',
  },
  {
    id: '6',
    title: 'Training Session',
    description:
      'Training session for new hires on company policies and procedures',
    start: '2023-03-28T09:30:00-07:00',
    end: '2023-03-28T12:30:00-07:00',
    location: 'Training Room',
  },
  {
    id: '7',
    title: 'Product Planning Meeting',
    description: 'Meeting to discuss future product plans and ideas',
    start: '2023-03-29T11:45:00-07:00',
    end: '2023-03-29T12:45:00-07:00',
    location: 'Product Planning Department',
  },
]

export default events
