import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';

import AboutUs from './about';
import CompanyEmployeeRoutes from './company/user/routing';
import CompanyAdminRoutes from './company-admin-routes';
import CompanyAdminSettingRoutes from './company-admin-setting-routes';
import Home from './home';
import OfferRequest from './offer-request';
import OfferRequestRouting from './offer-routing-routing';
import TaskRouting from './task-routing';
import Tasks from './tasks';
import Team from './team';
import TeamDetails from './team/team-details';
import OfferSignature from '../../components/offer-signature';
import NotFound from '../notfound';

function AppView({ setLoading }) {
  return (
    <div className="appMainContainer">
      <Routes>
        <Route path="" element={<Outlet />}>
          <Route path="/" element={<Home setLoading={setLoading} />} />
          <Route path="/task" element={<Tasks setLoading={setLoading} />} />
          <Route
            path="/offer-requests/:offerRequestId/offers/:offerId/tasks/*"
            element={<TaskRouting setLoading={setLoading} />}
          />
          <Route
            path="/offer-requests/:offerRequestId/*"
            element={<OfferRequestRouting setLoading={setLoading} />}
          />
          <Route path="/tasks/*" element={<TaskRouting setLoading={setLoading} />} />
          <Route
            path="/company/:companyId/employee/:employeeId/*"
            element={<CompanyEmployeeRoutes setLoading={setLoading} />}
          />
          <Route
            path="/company/:companyId/admin/:adminId/settings/*"
            element={<CompanyAdminSettingRoutes setLoading={setLoading} />}
          />
          <Route
            path="/company/:companyId/admin/:adminId/assignments/*"
            element={<CompanyAdminRoutes setLoading={setLoading} />}
          />
          <Route path="/about-us" element={<AboutUs />} />
          <Route
            path="/offer-request/new/:type"
            element={<OfferRequest setLoading={setLoading} />}
          />
          <Route path="/team/:teamId" element={<TeamDetails />} />
          <Route path="/team" element={<Team />} />
          <Route
            path="/offer-requests/:offerRequestId/offers/:offerId/signature"
            element={<OfferSignature />}
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export default AppView;
