import PropTypes from 'prop-types';
import React from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss'

function TabsContainer({ activeTab, tabs, setActiveTab }) {
  const { t } = useTranslation('lang')

  return (
    <Tabs className="tabContainer" activeKey={activeTab} onSelect={setActiveTab} >
      {tabs.map((tab) =>  (
        <Tab
          key={tab.key}
          eventKey={tab.key}
          title={t(tab.title)}
          tabClassName={`navLink ${tab.disabled ? 'disabled' : ''}  ${tab.key===activeTab ? styles.navLinkActive:''}`}
        >
          {tab.component}
        </Tab>
      ))}
    </Tabs>
  )
}

TabsContainer.propTypes = {
  activeTab: PropTypes.string.isRequired,
  tabs: PropTypes.instanceOf(Array).isRequired,
  setActiveTab: PropTypes.func.isRequired
}

export default TabsContainer
