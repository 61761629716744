import apiClient from './api-client';

export const saveCustomerSignature = (offerRequestId, offerId, payload) =>
  apiClient.post(
    `/v1/offer-requests/${offerRequestId}/offers/${offerId}/customer-signature`,
    payload
  );

export const getCustomerSignature = (offerRequestId, offerId) =>
  apiClient.get(`/v1/offer-requests/${offerRequestId}/offers/${offerId}/customer-signature`);

export const getSalesPersonSignature = (offerRequestId, offerId) =>
  apiClient.get(`/v1/offer-requests/${offerRequestId}/offers/${offerId}/authorization`);

export const saveSalespersonSignature = (offerRequestId, offerId, payload) =>
  apiClient.post(`/v1/offer-requests/${offerRequestId}/offers/${offerId}/authorization`, payload);

export const getCurrentIp = () => apiClient.get('/v1/current-ip');
