import React from 'react';

import styles from './ProductItems.module.scss';
import Translate from '../../../components/translate';
import { HeatingDevice, Product, Service } from '../../task-forms/heating-devices';

function ServiceLabel({ service }) {
  return (
    <div className={`${styles.serviceTextContainer}`}>
      <Service service={service} />
      <div>{service.comment}</div>
    </div>
  );
}

function HeatingDeviceLabel({ device }) {
  const statuses = {
    WAREHOUSE: 'company_admin.basic_details.order_status.warehouse',
    MANUFACTURER: 'company_admin.basic_details.order_status.sent',
    DEFAULT: 'company_admin.basic_details.order_status.waiting',
  };
  return (
    <div>
      <HeatingDevice device={device} cardOnly />
      <p className={`${styles.statusText}`}>
        <Translate id="company_admin.basic_details.order_status.title" /> :{' '}
        <span className={statuses[device.orderedFrom] ? styles.green : styles.red}>
          <Translate id={statuses[device.orderedFrom] || statuses.DEFAULT} />
        </span>
      </p>
    </div>
  );
}

function ProductItems({ heatingDevices, products, services }) {
  return (
    <div className={`${styles.mainPageContainer}`}>
      <div className={`${styles.heatingDeviceContainer}`}>
        <h3 className={`${styles.deviceTitle}`}>
          <Translate id="offer.tabs.heating_device" />
        </h3>
        <div className="d-lg-flex justify-content-between flex-wrap">
          {heatingDevices?.map((device) => (
            <HeatingDeviceLabel key={device.id} device={device} />
          ))}
        </div>
      </div>
      <div className={`${styles.productDeviceContainer}`}>
        <h3 className={`${styles.deviceTitle}`}>
          <Translate id="offer.tabs.products" />
        </h3>
        <div className="d-lg-flex">
          {products?.map((product) => (
            <Product key={product.id} product={product} />
          ))}
        </div>
      </div>
      <div className={`${styles.productDeviceContainer}`}>
        <h3 className={`${styles.deviceTitle}`}>
          <Translate id="offer.tabs.services" />
        </h3>
        <div className="d-lg-flex">
          {services?.map((service) => (
            <ServiceLabel key={service.id} service={service} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProductItems;
