/* eslint-disable no-nested-ternary */
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import * as yup from 'yup';

import styles from './index.module.scss';
import { ReactComponent as BoxShadowSvg } from '../../../assets/svgs/BoxShadow.svg';
import LannenLogoSvg from '../../../assets/svgs/Lannen_Full_logo.svg';
import { ReactComponent as ShadowOneSvg } from '../../../assets/svgs/Shadow1.svg';
import { ReactComponent as ShadowTwoSvg } from '../../../assets/svgs/Shadow2.svg';
import { ReactComponent as ShadowThreeSvg } from '../../../assets/svgs/Shadow3.svg';
import { ReactComponent as ShadowFourSvg } from '../../../assets/svgs/Shadow4.svg';
import { ReactComponent as ShadowFiveSvg } from '../../../assets/svgs/Shadow5.svg';
import Button from '../../../components/button';
import authService from '../../../services/auth';
import { getOriginalErrors } from '../../../utils/errorHandler';

function ForgotPassword() {
  const { t } = useTranslation('lang');
  const forgotPasswordSchema = yup.object({
    email: yup
      .string()
      .email(t('authentication.errors.email_invalid'))
      .required(t('authentication.errors.email_required')),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });

  const navigate = useNavigate();

  const submit = async (data) => {
    try {
      await authService.sendResetPasswordLink(data);
      toast.success(t(`authentication.success.reset_link_success`));
      navigate('/auth/login');
    } catch (err) {
      if (err.response) {
        toast.error(t(`authentication.errors.user_not_exist`));
      } else {
        const originalErrors = getOriginalErrors(err);
        originalErrors.forEach((error) => {
          toast.error(error.message);
        });
      }
    }
  };

  return (
    <div className={`${styles.forgotPasswordMainContainer}`}>
      <div className={`${styles.bgImgContainer} ${styles.topLeftImg}`}>
        <ShadowOneSvg />
      </div>
      <div className={`${styles.bgImgContainer} ${styles.topRightImg}`}>
        <ShadowTwoSvg />
      </div>
      <div className={`${styles.bgImgContainer} ${styles.middleLeftImg}`}>
        <ShadowThreeSvg />
      </div>
      <div className={`${styles.bgImgContainer} ${styles.middleRightImg}`}>
        <ShadowFourSvg />
      </div>
      <div className={`${styles.bgImgContainer} ${styles.bottomLeftImg}`}>
        <ShadowFiveSvg />
      </div>
      <div className={`${styles.bgImgContainer} ${styles.bottomRightImg}`}>
        <BoxShadowSvg />
      </div>
      <div
        className={`d-flex flex-column align-items-center justify-content-center ${styles.forgotPasswordContainer}`}
      >
        <div className={`${styles.logoContainer}`}>
          <img className="imgFluid" src={LannenLogoSvg} alt="Lannen Maalampo" />
        </div>
        <div className={`${styles.forgotPasswordContentContainer}`}>
          <p className={`${styles.pageTitle}`}>{t('authentication.forgot_password.title')}</p>
          <form className={`${styles.forgotPasswordForm}`} onSubmit={handleSubmit(submit)}>
            <div className={`${styles.formGroup}`}>
              <input
                className={`inputField ${styles.inputField} form-control`}
                type="text"
                placeholder={t('authentication.login.email_id_placeholder')}
                id="email"
                {...register('email')}
              />
              <div>{errors.email?.message}</div>
            </div>
            <Link className={`${styles.login}`} to="/auth/login">
              {t('authentication.login.title')}
            </Link>
            <div className={`${styles.resetPasswordFormBtnContainer}`}>
              <Button className="projectBtn logInBtn" type="submit">
                {t('authentication.forgot_password.send_link')}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className={`${styles.forgotPasswordFormDec}`}>
        <p>Lännen Maalämpö Oy</p>
      </div>
    </div>
  );
}

export default ForgotPassword;
