import PropTypes from 'prop-types';
import React from 'react';

// import ServiceInstallmentPricing from '../service-installment-pricing';
import styles from './index.module.scss';
import ServicePricing from '../service-pricing';
import Translate from '../translate';

const roundToTwoDecimal = (num) => Number(num.toFixed(2));

const calculateAmountInclusiveVat = (amountExclusiveVat, vat) =>
  roundToTwoDecimal(amountExclusiveVat * (1 + vat / 100));

function Comment({ comment, setComment }) {
  return (
    <div>
      <div className={`${styles.commentHeader}`}>
        <Translate id="offer.services.add_comment" />
      </div>
      <div className={`${styles.commentAreaContainer}`}>
        <textarea
          className={`inputField ${styles.commentArea}`}
          name="comment"
          id="serviceComment"
          cols="30"
          rows="10"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>
    </div>
  );
}

function ServicePriceDetailRow({ service, onServiceRemove, setService, index }) {
  const setSellingPrice = (sellingPriceInclusiveVat) => {
    const serviceCopy = { ...service };
    serviceCopy.sellingPriceInclusiveVat = sellingPriceInclusiveVat;
    serviceCopy.sellingPriceExclusiveVat = sellingPriceInclusiveVat
      ? Number(Number((sellingPriceInclusiveVat * 100) / (serviceCopy.vat + 100)).toFixed(2))
      : 0;
    setService(serviceCopy, index);
  };

  const setPurchasePrice = (purchasePriceInclusiveVat) => {
    const serviceCopy = { ...service };
    serviceCopy.purchasePriceInclusiveVat = purchasePriceInclusiveVat;
    serviceCopy.purchasePriceExclusiveVat = purchasePriceInclusiveVat
      ? Number(Number((purchasePriceInclusiveVat * 100) / (serviceCopy.vat + 100)).toFixed(2))
      : 0;
    setService(serviceCopy, index);
  };

  const changeQuantity = (quantity) => {
    const serviceCopy = { ...service };
    const rate = Number(Number(service.sellingPriceInclusiveVat / service.quantity).toFixed(2));
    serviceCopy.quantity = quantity;
    serviceCopy.purchasePriceExclusiveVat = Number(
      Number(service.purchasePrice * quantity).toFixed(2)
    );

    serviceCopy.purchasePriceInclusiveVat = calculateAmountInclusiveVat(
      serviceCopy.purchasePriceExclusiveVat,
      service.vat
    );

    if (Number.isNaN(quantity)) {
      serviceCopy.sellingPriceInclusiveVat = 0;
      serviceCopy.sellingPriceExclusiveVat = 0;
      serviceCopy.purchasePriceInclusiveVat = 0;
      serviceCopy.purchasePriceExclusiveVat = 0;
    } else if (Number.isNaN(rate)) {
      serviceCopy.sellingPriceExclusiveVat = Number((service.sellingPrice * quantity).toFixed(2));
      serviceCopy.sellingPriceInclusiveVat = Number(
        Number((serviceCopy.sellingPriceExclusiveVat * (service.vat + 100)) / 100).toFixed(2)
      );
    } else {
      serviceCopy.sellingPriceInclusiveVat = Number((rate * quantity).toFixed(2));
      serviceCopy.sellingPriceExclusiveVat = serviceCopy.sellingPriceInclusiveVat
        ? Number(
            Number((serviceCopy.sellingPriceInclusiveVat * 100) / (serviceCopy.vat + 100)).toFixed(
              2
            )
          )
        : 0;
    }

    setService(serviceCopy, index);
  };

  const onChangeServicePrice = (price) => {
    if (Number.isNaN(price) || price === 0) {
      setService(
        {
          ...service,
          amount: 0,
          sellingPriceInclusiveVat: 0,
          sellingPriceExclusiveVat: 0,
          purchasePriceInclusiveVat: 0,
          purchasePriceExclusiveVat: 0,
          rateExclusiveVat: 0,
          rateInclusiveVat: 0,
        },
        index
      );
    }
    const serviceCopy = { ...service };

    serviceCopy.rateInclusiveVat = price;
    serviceCopy.rateExclusiveVat = serviceCopy.rateInclusiveVat
      ? Number(Number((serviceCopy.rateInclusiveVat * 100) / (serviceCopy.vat + 100)).toFixed(2))
      : 0;
    serviceCopy.sellingPriceExclusiveVat = serviceCopy.rateExclusiveVat * serviceCopy.quantity;
    serviceCopy.sellingPriceInclusiveVat = serviceCopy.rateInclusiveVat * serviceCopy.quantity;

    setService(serviceCopy, index);
  };

  return (
    <div className={`d-lg-flex ${styles.selectedServiceContainer}`}>
      <div>
        <ServicePricing
          key={service.netvisorId}
          id={service.netvisorId}
          name={service.name}
          quantity={service.quantity}
          totalPurchasePriceExclusiveVat={service.purchasePriceExclusiveVat}
          totalPurchasePriceInclusiveVat={service.purchasePriceInclusiveVat}
          totalSellingPriceExclusiveVat={service.sellingPriceExclusiveVat}
          totalSellingPriceInclusiveVat={service.sellingPriceInclusiveVat}
          setTotalSellingPriceInclusiveVat={(newPrice) =>
            setSellingPrice(newPrice < 0 ? 0 : newPrice)
          }
          setTotalPurchasePriceInclVat={(newPrice) => setPurchasePrice(newPrice < 0 ? 0 : newPrice)}
          setWorkshare={(workshare) =>
            setService({ ...service, workshare: workshare < 0 ? 0 : workshare || 0 }, index)
          }
          setQuantity={(newVal) => changeQuantity(newVal < 1 ? 1 : newVal)}
          workshare={service.workshare}
          onRemove={() => onServiceRemove(service, index)}
          vat={service.vat}
          onChangeServicePrice={onChangeServicePrice}
          rateExclusiveVat={service.rateExclusiveVat}
          rateInclusiveVat={service.rateInclusiveVat}
          unit={service.unit}
        />
      </div>
      <div className={`${styles.commentContainer}`}>
        <Comment
          comment={service.comment}
          setComment={(comment) => setService({ ...service, comment }, index)}
        />
      </div>
    </div>
  );
}

ServicePriceDetailRow.propTypes = {
  service: PropTypes.instanceOf(Object).isRequired,
  onServiceRemove: PropTypes.func.isRequired,
  comment: PropTypes.string,
  setService: PropTypes.func.isRequired,
};

ServicePriceDetailRow.defaultProps = {
  comment: '',
};

Comment.propTypes = {
  comment: PropTypes.string,
  setComment: PropTypes.func.isRequired,
};

Comment.defaultProps = {
  comment: '',
};

export default ServicePriceDetailRow;
