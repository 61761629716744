import PropTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';
import { ReactComponent as EnvelopeSVG } from '../../assets/svgs/Envelope.svg';
import { ReactComponent as PhoneSVG } from '../../assets/svgs/Phone.svg';

function CustomerContact({ customerInformation }) {
  return (
    <div className={`d-md-flex align-items-center justify-content-between ${styles.headerContent}`}>
      <div className="d-flex align-items-center">
        <a className={`${styles.contentLink}`} href={`tel:${customerInformation.phone}`}>
          <PhoneSVG className={styles.phoneIcon} />
        </a>
        <p>{customerInformation.phone}</p>
      </div>
      <div className="d-flex align-items-center">
        <a className={`${styles.contentLink}`} href={`mailto:${customerInformation.email}`}>
          <EnvelopeSVG className={styles.phoneIcon}/>
        </a>
        <p>{customerInformation.email}</p>
      </div>
    </div>
  );
}

CustomerContact.propTypes = {
  customerInformation: PropTypes.instanceOf(Object).isRequired,
};

export default CustomerContact;
