import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Wrapper from './Wrapper';
import AssignWorkerOnTask from '../../../pages/company-admin/assign-worker-on-task';
import BasicDetails from '../../../pages/company-admin/basic-details';
import DrillingTeamView from '../../../pages/task-forms/drilling-team-view';
import InstalltionCrewTaskView from '../../../pages/task-forms/installation-team-view';
import CompanyAdminAssignments from '../company/admin';

function CompanyAdminRoutes({ setLoading }) {
  return (
    <Routes>
      <Route path="" element={<CompanyAdminAssignments setLoading={setLoading} />} />
      <Route path="" element={<Wrapper />}>
        <Route path=":assignmentId">
          <Route path="basic-details" element={<BasicDetails setLoading={setLoading} taskTab />} />
          <Route
            path="report/drilling-report"
            element={<DrillingTeamView setLoading={setLoading} />}
          />
          <Route
            path="report/installing-report"
            element={<InstalltionCrewTaskView setLoading={setLoading} />}
          />
          <Route path="tasks" element={<AssignWorkerOnTask setLoading={setLoading} />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default CompanyAdminRoutes;
