import PropTypes from 'prop-types'
import React from 'react'

function CircleArrowRight({ className }) {
  return (
    <svg viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.36171 18.082C4.62062 18.082 0.779297 14.2409 0.779297 9.5C0.779297 4.75911 4.62062 0.917969 9.36171 0.917969C14.1028 0.917969 17.9441 4.75911 17.9441 9.5C17.9441 14.2409 14.1028 18.082 9.36171 18.082ZM4.65503 8.39217C4.19475 8.39217 3.82446 8.76244 3.82446 9.22268V9.77636C3.82446 10.2366 4.19475 10.6069 4.65503 10.6069H10.9742L8.36138 13.1123C8.0257 13.4341 8.01878 13.9705 8.34753 14.2992L8.7282 14.6764C9.05351 15.0017 9.57952 15.0017 9.90137 14.6764L14.4937 10.0878C14.819 9.76252 14.819 9.23653 14.4937 8.9147L9.90137 4.31916C9.57606 3.99388 9.05005 3.99388 8.7282 4.31916L8.34753 4.69635C8.01878 5.02857 8.0257 5.56494 8.36138 5.88677L10.9742 8.39217H4.65503Z"
        fill="#C2C2C2"
      />
    </svg>
  )
}

CircleArrowRight.propTypes = {
  className: PropTypes.string,
}

CircleArrowRight.defaultProps = {
  className: null,
}

export default CircleArrowRight
