import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BasicDetails from './BasicDetails';
import Button from '../../../components/button';
import CompanyAdminContentWrapper from '../../../components/company-admin-content-wrapper';
import Translate from '../../../components/translate';
import AuthService from '../../../services/auth';
import { useServiceContext } from '../../task-forms/context/service-context';

function CustomFooter() {
  const { assignmentId, taskId } = useParams();
  const navigate = useNavigate();
  const { service } = useServiceContext();

  const onClick = async () => {
    const currentUser = await AuthService.getCurrentUser();
    navigate(
      `/company/${service.companyName}/${assignmentId ? 'admin' : 'employee'}/${currentUser.id}/${
        assignmentId ? `assignments/${assignmentId}` : `tasks/${taskId}`
      }/report/${service.type === 'INSTALLING' ? 'installing-report' : 'drilling-report'}`
    );
  };

  return (
    <Button className="greenBtn" onClick={onClick}>
      <Translate id="components.button.open_report" />
    </Button>
  );
}

function BasicDetailsWrapper({ setLoading }) {
  const customFooter = () => <CustomFooter />;

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <CompanyAdminContentWrapper customFooter={customFooter}>
      <BasicDetails />
    </CompanyAdminContentWrapper>
  );
}

export default BasicDetailsWrapper;
