import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';
import Button from '../button';
import SearchBox from '../search-box';
import Translate from '../translate';

function ProductItem({ id, name, selected, onSelect }) {
  return (
    <div
      onClick={onSelect}
      tabIndex={0}
      role="button"
      onKeyDown={(e) => {
        if (e.key === 'ENTER') {
          onSelect();
        }
      }}
      className={`${styles.productListContainer} ${selected ? styles.activeList : ''}`}
    >
      <div className={`${styles.productList}`}>
        <div className="d-flex align-items-center ">
          <div>
            <p>
              <Translate id="offer.products.id" /> : {id}
            </p>
          </div>
          <div>
            <p className={`${styles.boldText}`}> - {name}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function ShowMoreAndLessButton({ toggle, setToggle }) {
  return (
    <Button className={`loadBtn ${styles.loadBtn}`} onClick={setToggle}>
      <Translate id={toggle ? 'components.button.less' : 'components.button.load_more'} />
      {!toggle && '...'}
    </Button>
  );
}

function ProductsList({
  products,
  onProductClick,
  keyword,
  setKeyword,
  fetchMoreProducts,
  countOfProductToDisplay,
  totalCountOfProduct,
}) {
  const debouncedSearch = debounce((newValue) => {
    setKeyword(newValue);
  }, 1000);
  return (
    <div className={`${styles.productListMainContainer}`}>
      <div className={`d-md-flex align-items-md-center ${styles.productListHeader}`}>
        <div className={`${styles.headerTitle}`}>
          <Translate id="offer.products.choose_products" />
        </div>
        <SearchBox value={keyword} onChange={debouncedSearch} name="search_products" />
        <div className={`${styles.headerDec}`}>
          <Translate id="offer.products.showing" />: {countOfProductToDisplay}/{totalCountOfProduct}
        </div>
      </div>
      <div className={`d-md-flex align-items-md-center flex-md-wrap ${styles.flexContainer}`}>
        {products.length > 0 &&
          products.map((product) => (
            <ProductItem
              key={product.netvisorId}
              id={product.netvisorId}
              name={product.name}
              selected={product.selected}
              onSelect={() => onProductClick(product)}
            />
          ))}
        {totalCountOfProduct >= countOfProductToDisplay && totalCountOfProduct > 10 && (
          <div className={`${styles.btnContainer}`}>
            <ShowMoreAndLessButton
              toggle={totalCountOfProduct === countOfProductToDisplay}
              setToggle={fetchMoreProducts}
            />
          </div>
        )}
      </div>
    </div>
  );
}

ProductItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

ShowMoreAndLessButton.propTypes = {
  toggle: PropTypes.bool.isRequired,
  setToggle: PropTypes.func.isRequired,
};

ProductsList.propTypes = {
  products: PropTypes.instanceOf(Array),
  onProductClick: PropTypes.func,
};

ProductsList.defaultProps = {
  products: [],
  onProductClick() {},
};

export default ProductsList;
