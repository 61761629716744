/* eslint-disable no-nested-ternary */
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import * as yup from 'yup';

import styles from './index.module.scss';
import { ReactComponent as BoxShadowSvg } from '../../../assets/svgs/BoxShadow.svg';
import LannenLogoSvg from '../../../assets/svgs/Lannen_Full_logo.svg';
import { ReactComponent as ShadowOneSvg } from '../../../assets/svgs/Shadow1.svg';
import { ReactComponent as ShadowTwoSvg } from '../../../assets/svgs/Shadow2.svg';
import { ReactComponent as ShadowThreeSvg } from '../../../assets/svgs/Shadow3.svg';
import { ReactComponent as ShadowFourSvg } from '../../../assets/svgs/Shadow4.svg';
import { ReactComponent as ShadowFiveSvg } from '../../../assets/svgs/Shadow5.svg';
import Button from '../../../components/button';
import authService from '../../../services/auth';
import { getOriginalErrors } from '../../../utils/errorHandler';

function ResetPassword() {
  const { t } = useTranslation('lang');

  const forgotPasswordSchema = yup.object({
    password: yup
      .string()
      .required(t('authentication.errors.password_required'))
      .min(8, t('authentication.errors.password_length', { length: 8 })),
    confirmPassword: yup
      .string()
      .required(t('authentication.errors.confirm_password_required'))
      .oneOf([yup.ref('password'), null], t('authentication.errors.password_match')),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });

  const params = useParams();
  const navigate = useNavigate();

  const submit = async (data) => {
    try {
      await authService.updatePassword(params.userId, params.token, data);
      toast.success(t(`authentication.success.password_reset_success`));
      navigate('/auth/login');
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.errors) {
          toast.error(t('authentication.errors.password_length', { length: 8 }));
        } else {
          toast.error(t(`authentication.errors.token_expired`));
        }
      } else {
        const originalErrors = getOriginalErrors(err);
        originalErrors.forEach((error) => {
          toast.error(error.message);
        });
      }
    }
  };

  return (
    <div className={`${styles.resetPasswordMainContainer}`}>
      <div className={`${styles.bgImgContainer} ${styles.topLeftImg}`}>
        <ShadowOneSvg />
      </div>
      <div className={`${styles.bgImgContainer} ${styles.topRightImg}`}>
        <ShadowTwoSvg />
      </div>
      <div className={`${styles.bgImgContainer} ${styles.middleLeftImg}`}>
        <ShadowThreeSvg />
      </div>
      <div className={`${styles.bgImgContainer} ${styles.middleRightImg}`}>
        <ShadowFourSvg />
      </div>
      <div className={`${styles.bgImgContainer} ${styles.bottomLeftImg}`}>
        <ShadowFiveSvg />
      </div>
      <div className={`${styles.bgImgContainer} ${styles.bottomRightImg}`}>
        <BoxShadowSvg />
      </div>
      <div
        className={`d-flex flex-column align-items-center justify-content-center ${styles.resetPasswordContainer}`}
      >
        <div className={`${styles.logoContainer}`}>
          <img className="imgFluid" src={LannenLogoSvg} alt="Lannen Maalampo" />
        </div>
        <div className={`${styles.resetPasswordContentContainer}`}>
          <p className={`${styles.pageTitle}`}>{t('authentication.reset_password.title')}</p>
          <form className={`${styles.resetPasswordForm}`} onSubmit={handleSubmit(submit)}>
            <div className={`${styles.formGroup}`}>
              <input
                className={`inputField ${styles.inputField} form-control`}
                type="password"
                placeholder={t('authentication.login.password')}
                id="email"
                {...register('password')}
              />
              <div>{errors.password?.message}</div>
            </div>
            <div>
              <input
                className={`inputField ${styles.inputField} form-control`}
                type="password"
                placeholder={t('authentication.reset_password.confirm_password')}
                id="email"
                {...register('confirmPassword')}
              />
              <div>{errors.confirmPassword?.message}</div>
            </div>
            <div className={`${styles.resetPasswordFormBtnContainer}`}>
              <Button className="projectBtn logInBtn" type="submit">
                {t('authentication.reset_password.title')}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className={`${styles.resetPasswordFormDec}`}>
        <p>Lännen Maalämpö Oy</p>
      </div>
    </div>
  );
}

export default ResetPassword;
