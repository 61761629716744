import * as moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './form.module.scss';
import Button from '../../../components/button';
import CalendarControl from '../../../components/calendar';
import Translate from '../../../components/translate';
import {
  constructionMethods,
  installationTypes,
  offerRequestTypes,
  currentHeatingSystems,
} from '../../../constant';
import OfferRequestService from '../../../services/offerRequest';

function FormControl({ title, children }) {
  return (
    <div>
      <div>
        <Translate id={title} />
      </div>
      <div>{children}</div>
    </div>
  );
}

function OnSiteScheduleControl({ scheduledDate, onSchedule, showSchedule }) {
  const [showCalendar, setShowCalendar] = useState(false);
  const params = useParams();
  const { t } = useTranslation('lang');

  const onSave = async (value) => {
    try {
      if (value === null) {
        toast.error(t('offer.basic_details.please_enter_valid_date_and_time'));
        return;
      }
      await OfferRequestService.updateOfferRequestById(params.offerRequestId, {
        freeViewingDateTime: moment(value).toISOString(),
      });

      onSchedule();
      toast.success(t('offer.basic_details.onsite_inspection_scheduled'));
    } catch (error) {
      toast.error(error[0].message);
    }
    setShowCalendar(false);
  };
  return (
    <div>
      <div>
        <Translate id="offer.basic_details.onsite_inspection" />
      </div>
      <div>
        {scheduledDate && (
          <div>
            <div>{moment(scheduledDate).format('DD-MM-yyyy HH:mm')}</div>{' '}
          </div>
        )}
      </div>
      {showSchedule && (
        <div>
          {!showCalendar && (
            <Button onClick={() => setShowCalendar(true)} className="schedualeBtn">
              <Translate id="offer.basic_details.onsite_inspection_schedule" />
            </Button>
          )}
          {showCalendar && (
            <CalendarControl
              show={showCalendar}
              hideInput
              onNewValue={onSave}
              setShowCalendar={setShowCalendar}
            />
          )}
        </div>
      )}
    </div>
  );
}

export function RequestDetails({ basicInfo }) {
  const { t } = useTranslation('lang');

  return (
    <>
      <div className={`${styles.requestTitleContainer}`}>
        <p className={`${styles.requestTitle}`}>
          <Translate id="offer.basic_details.request_details" />
        </p>
      </div>
      <div className={`d-flex flex-wrap ${styles.requestContent}`}>
        <div>
          <p>
            <Translate id="offer.onsite_memo.heating_area" />
          </p>
          <p>
            {basicInfo.heatingArea ? (
              <span>
                {basicInfo.heatingArea} m<sup>2</sup>
              </span>
            ) : (
              'N/A'
            )}
          </p>
        </div>
        <div>
          <p>
            <Translate id="offer.request.installation_type.title" />
          </p>
          <p>
            {basicInfo.installationType ? t(installationTypes[basicInfo.installationType]) : 'N/A'}
          </p>
        </div>
        <div>
          <p>
            {basicInfo.installationType === 'NEW_BUILD' ? (
              <Translate id="offer.onsite_memo.estimated_start_time" />
            ) : (
              <Translate id="offer.onsite_memo.renovation_time" />
            )}
          </p>
          <p>
            {basicInfo.renovationTime
              ? moment(basicInfo.renovationTime).format('yyyy-MM-DD')
              : 'N/A'}
          </p>
        </div>
        <div>
          <p>
            <Translate id="offer.request.construction_type.title" />
          </p>
          <p>
            {basicInfo.constructionType
              ? t(constructionMethods[basicInfo.constructionType])
              : 'N/A'}
          </p>
        </div>
        <div>
          <p>
            <Translate id="offer.onsite_memo.requested_heating_type" />
          </p>
          <p>
            {basicInfo.requestedHeatingType
              ? t(offerRequestTypes[basicInfo.requestedHeatingType])
              : 'N/A'}
          </p>
        </div>
        <div>
          <p>
            <Translate id="offer.onsite_memo.current_heating_system" />
          </p>
          <p>
            {basicInfo.currentHeatingSystem
              ? t(currentHeatingSystems[basicInfo.currentHeatingSystem])
              : 'N/A'}
          </p>
        </div>
        <div>
          <p>
            <Translate id="offer.onsite_memo.current_consumption" />
          </p>
          <p>{basicInfo.currentConsumption ? basicInfo.currentConsumption : 'N/A'}</p>
        </div>
      </div>
    </>
  );
}

function ProgressSummary({
  offerRequest,
  onScheduleSiteInspection,
  offerApprovedStatus,
  offerSentStatus,
  showSchedule,
}) {
  return (
    <div className={`${styles.progressContainer} `}>
      <div className={`${styles.progressTitleContainer}`}>
        <p className={`${styles.progressTitle}`}>
          <Translate id="offer.basic_details.progress_summary" />
        </p>
      </div>
      <div className={`${styles.progressContent}`}>
        <FormControl title="offer.basic_details.offer_request_date">
          {moment(offerRequest.createdAt).format('DD.MM.yyyy')}
        </FormControl>
        <OnSiteScheduleControl
          scheduledDate={offerRequest.freeViewingDateTime}
          onSchedule={onScheduleSiteInspection}
          showSchedule={showSchedule}
        />
        <FormControl title="offer.basic_details.offer_sent">{offerSentStatus}</FormControl>
        <FormControl title="offer.basic_details.offer_approved">{offerApprovedStatus}</FormControl>
        <FormControl title="offer.basic_details.installation_date">N/A</FormControl>
      </div>
    </div>
  );
}

function ClientComment({ offerRequest }) {
  return (
    <div className={`${styles.summaryContainer} `}>
      <div className={`${styles.summaryTitleContainer}`}>
        <p className={`${styles.summaryTitle}`}>
          <Translate id="offer.basic_details.client_comments" />
        </p>
      </div>
      <textarea
        className={`${styles.summaryTextArea}`}
        cols="30"
        rows="10"
        value={offerRequest.comment}
        readOnly
      />
    </div>
  );
}

function Form({ basicInfo, offerRequest, onScheduleSiteInspection, attachments, showSchedule }) {
  const [offerSentStatus, setOfferSentStatus] = useState('N/A');
  const [offerApprovedStatus, setOfferApprovedStatus] = useState('N/A');

  const fetchOfferSatuses = async () => {
    try {
      const {
        data: {
          data: { offerRequestProgress },
        },
      } = await OfferRequestService.getOfferRequestProgress(offerRequest.id);

      const offerSentOn = offerRequestProgress.find(
        ({ event }) => event?.toLowerCase() === 'offer_sent'
      );
      const offerApprovedOn = offerRequestProgress.find(
        ({ event }) => event?.toLowerCase() === 'task'
      );

      if (offerSentOn) {
        setOfferSentStatus(moment(offerSentOn.createdAt).format('DD.MM.yyyy'));
      }

      if (offerApprovedOn) {
        setOfferApprovedStatus(moment(offerApprovedOn.createdAt).format('DD.MM.yyyy'));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (offerRequest && offerRequest.id) {
      fetchOfferSatuses();
    }
  }, [offerRequest]);

  return (
    <div className={`${styles.formContainer}`}>
      <div className={`${styles.requestDetailsContainer}`}>
        <RequestDetails basicInfo={basicInfo} />
      </div>
      <div className={`${styles.progressSummaryContainer}`}>
        <ProgressSummary
          offerRequest={offerRequest}
          onScheduleSiteInspection={onScheduleSiteInspection}
          offerApprovedStatus={offerApprovedStatus}
          offerSentStatus={offerSentStatus}
          showSchedule={showSchedule}
        />
        <ClientComment offerRequest={offerRequest} />
        {attachments && <div>{attachments}</div>}
      </div>
    </div>
  );
}

FormControl.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

Form.propTypes = {
  basicInfo: PropTypes.instanceOf(Object),
  offerRequest: PropTypes.instanceOf(Object),
  onScheduleSiteInspection: PropTypes.func.isRequired,
  attachments: PropTypes.element,
};
Form.defaultProps = {
  basicInfo: {},
  offerRequest: {},
  attachments: null,
};

ProgressSummary.propTypes = {
  offerRequest: PropTypes.instanceOf(Object),
  onScheduleSiteInspection: PropTypes.func.isRequired,
  offerApprovedStatus: PropTypes.string.isRequired,
  offerSentStatus: PropTypes.string.isRequired,
  showSchedule: PropTypes.bool,
};
ProgressSummary.defaultProps = {
  offerRequest: {},
  showSchedule: false,
};
ClientComment.propTypes = {
  offerRequest: PropTypes.instanceOf(Object),
};
ClientComment.defaultProps = {
  offerRequest: {},
};

OnSiteScheduleControl.propTypes = {
  scheduledDate: PropTypes.oneOfType(PropTypes.string, PropTypes.instanceOf(Date)),
  onSchedule: PropTypes.func,
  showSchedule: PropTypes.bool,
};

OnSiteScheduleControl.defaultProps = {
  scheduledDate: null,
  onSchedule: () => null,
  showSchedule: false,
};

RequestDetails.propTypes = {
  basicInfo: PropTypes.instanceOf(Object),
};

RequestDetails.defaultProps = {
  basicInfo: {},
};

export default Form;
