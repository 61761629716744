/* eslint-disable import/prefer-default-export */
// this rule is disabled as we will have more exports in future
import apiClient from './api-client';

export const getEmployees = () => apiClient.get('/v1/employees');

export const getCompanyEmployees = (companyId, q) =>
  apiClient(`/v1/company/${companyId}/employee`, { params: { q } });

export const getEmployeesByAssignment = (companyId, assignmentId) =>
  apiClient(`/v1/company/${companyId}/assignments/${assignmentId}/assigned-employees`);

export const assignEmployeesOnAssignment = (companyId, offerServiceId, employees) =>
  apiClient.put(`/v1/company/${companyId}/tasks`, { offerServiceId, employees });
