import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';
import Button from '../button';
import SearchBox from '../search-box';
import Translate from '../translate';

function ServiceItem({ id, name, selected, onSelect }) {
  return (
    <div
      onClick={onSelect}
      tabIndex={0}
      role="button"
      onKeyDown={(e) => {
        if (e.key === 'ENTER') {
          onSelect();
        }
      }}
      className={`${styles.serviceListContainer} ${selected ? styles.activeList : ''}`}
    >
      <div className={`${styles.serviceList}`}>
        <div className="d-flex align-items-center ">
          <div>
            <p>
              <Translate id="offer.services.id" /> : {id}&nbsp;
            </p>
          </div>
          <div>
            <p className={`${styles.boldText}`}> - {name}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function ShowMoreAndLessButton({ toggle, setToggle }) {
  return (
    <Button className={`loadBtn ${styles.loadBtn}`} onClick={setToggle}>
      <Translate id={toggle ? 'components.button.less' : 'components.button.load_more'} />
      {!toggle && '...'}
    </Button>
  );
}

function ServiceList({
  services,
  onServiceClick,
  keyword,
  setKeyword,
  countOfServiceToDisplay,
  showMoreOrLessServices,
  totalCountOfService,
}) {
  const debouncedSearch = debounce((newValue) => {
    setKeyword(newValue);
  }, 1000);
  return (
    <div className={`${styles.serviceListMainContainer}`}>
      <div className={`d-md-flex align-items-md-center ${styles.serviceListHeader}`}>
        <div className={`${styles.headerTitle}`}>
          <Translate id="offer.services.choose_services" />
        </div>
        <SearchBox value={keyword} onChange={debouncedSearch} name="search_services" />
        <div className={`${styles.headerDec}`}>
          <Translate id="offer.services.showing" />: {countOfServiceToDisplay}/{totalCountOfService}
        </div>
      </div>
      <div className={`d-md-flex align-items-md-center flex-md-wrap ${styles.flexContainer}`}>
        {services.length > 0 &&
          services.map((service) => (
            <ServiceItem
              key={service.netvisorId}
              id={service.netvisorId}
              name={service.name}
              selected={service.selected ?? false}
              onSelect={() => onServiceClick(service)}
            />
          ))}
        {totalCountOfService >= countOfServiceToDisplay && totalCountOfService > 10 && (
          <div className={`${styles.btnContainer}`}>
            <ShowMoreAndLessButton
              toggle={totalCountOfService === countOfServiceToDisplay}
              setToggle={showMoreOrLessServices}
            />
          </div>
        )}
      </div>
    </div>
  );
}

ServiceItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

ShowMoreAndLessButton.propTypes = {
  toggle: PropTypes.bool.isRequired,
  setToggle: PropTypes.func.isRequired,
};

ServiceList.propTypes = {
  services: PropTypes.instanceOf(Array).isRequired,
  onServiceClick: PropTypes.func.isRequired,
  keyword: PropTypes.string.isRequired,
  setKeyword: PropTypes.func.isRequired,
};

export default ServiceList;
