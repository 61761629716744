import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

import { offerRequestStatuses } from '../../constant';
import offerRequestServices from '../../services/offerRequest';

function WithEnableDisableTab(Component) {
  function NewComponent(props) {
    const { setLoading } = props;

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      setLoading(true);
      const goBack = () => navigate(-1);
      const fetchOfferRequest = async () => {
        const {
          data: {
            data: { offerRequestProgress },
          },
        } = await offerRequestServices.getOfferRequestProgress(params.offerRequestId);
        const locations = location.pathname.split('/');
        const currentPageName = locations[locations.length - 1];
        const [currentStatus] = offerRequestProgress;
        if (
          (currentStatus.event === offerRequestStatuses.NEW.value &&
            currentPageName !== 'basic-details') ||
          (currentStatus.event === offerRequestStatuses.ON_SITE_INSPECTION.value &&
            !['basic-details', 'on-site-memo'].includes(currentPageName))
        ) {
          goBack();
        } else {
          setLoading(false);
        }
      };
      fetchOfferRequest();
    }, []);

    return (
      <div>
        <Component {...props} />
      </div>
    );
  }
  return NewComponent;
}

export default WithEnableDisableTab;
