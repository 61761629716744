/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import styles from './index.module.scss';
import { ReactComponent as CameraSVG } from '../../assets/svgs/Camera.svg';
import Translate from '../translate';

function CaptureImage({ onChange }) {
  return (
    <div className={`${styles.cameraContainer}`}>
      <div className={`${styles.uploadBtnContainer}`}>
        <button type="button" className="uploadBtn projectBtn">
          <div className="cameraIcon">
            <CameraSVG />
          </div>
          <div className="btnText">
            <Translate id="components.capture_image.title" />
          </div>
        </button>
        <input type="file" capture="environment" onChange={onChange} />
      </div>
    </div>
  );
}

export default CaptureImage;
