import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { getOfferRequestByAssignment, getOfferRequestByTask } from '../../../services/assignment';
import AuthService from '../../../services/auth';
import { getCustomer } from '../../../services/customer';
import offerRequestService from '../../../services/offerRequest';
import { getOriginalErrors } from '../../../utils/errorHandler';

const Context = createContext();

export function BasicInformationContext({ children }) {
  const { companyId, taskId, assignmentId, offerRequestId } = useParams();
  const [customerInformation, setCustomerInformation] = useState({});
  const [offerRequest, setOfferRequest] = useState({});
  const { t } = useTranslation('lang');

  const fetchCustomerInformation = async (customerId) => {
    try {
      const {
        data: {
          data: { customer: customerResponse },
        },
      } = await getCustomer(customerId);
      setCustomerInformation(customerResponse);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchOfferRequest = async () => {
    try {
      const currentUser = await AuthService.getCurrentUser();
      if (assignmentId) {
        const {
          data: {
            data: { offerRequest: request },
          },
        } = await getOfferRequestByAssignment(companyId, assignmentId);
        if (request) {
          setOfferRequest(request);
          await fetchCustomerInformation(request.customerId);
        } else {
          toast.error(t('assign_teams.offer_request_not_found'));
        }
      } else if (taskId) {
        const {
          data: {
            data: { offerRequest: request },
          },
        } = await getOfferRequestByTask(companyId, currentUser.id, taskId);
        if (request) {
          setOfferRequest(request);
          await fetchCustomerInformation(request.customerId);
        } else {
          toast.error(t('assign_teams.offer_request_not_found'));
        }
      } else if (offerRequestId) {
        const {
          data: {
            data: { offerRequest: request },
          },
        } = await offerRequestService.getOfferRequestById(offerRequestId);
        if (request) {
          setOfferRequest(request);
          await fetchCustomerInformation(request.customerId);
        } else {
          toast.error(t('assign_teams.offer_request_not_found'));
        }
      }
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  useEffect(() => {
    fetchOfferRequest();
  }, []);

  const contextValue = useMemo(
    () => ({
      customerInformation,
      offerRequest,
    }),
    [customerInformation, offerRequest]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

export const useBasicInformationContext = () => useContext(Context);
