import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

function Translate({ id, options }) {
  const { t } = useTranslation('lang')
  return <>{t(id, options)}</>
}

Translate.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object),
}

Translate.defaultProps = {
  options: null,
}

export default Translate
