import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import Form from './Form';
import styles from './index.module.scss';
import Button from '../../../components/button';
import CustomerContact from '../../../components/customer-contact';
import OfferPageContentWrapper from '../../../components/offer-page-content-wrapper';
import Translate from '../../../components/translate';
import { attachmentableTypes } from '../../../constant';
import { uploadAttachments, getAttachments, deleteAttachments } from '../../../services/attachment';
import {
  getBasicInformationByOfferRequest,
  saveBasicInformation,
} from '../../../services/basicInformation';
import { getCustomer } from '../../../services/customer';
import offerRequestService from '../../../services/offerRequest';
import {
  getTechnicalInformation,
  saveOfferRequestAdditionalInfo,
  saveTechnicalInformation,
} from '../../../services/onSiteMemo';
import { getOriginalErrors } from '../../../utils/errorHandler';
import WithEnableDisableTab from '../withEnableDisableTab';

function OnSiteMemoFooter({ onContinue, isChangedOnSiteMemoData, onSiteMemoSave }) {
  return isChangedOnSiteMemoData ? (
    <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onSiteMemoSave}>
      <Translate id="components.button.save" />
    </Button>
  ) : (
    <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onContinue}>
      <Translate id="components.button.continue" />
    </Button>
  );
}

function OnSiteMemo({ setOfferFormStepsRefreshCount }) {
  const [basicInformation, setBasicInformation] = useState({});
  const [basicInformationComparison, setBasicInformationComparison] = useState({});
  const [technicalInformationAttachments, setTechnicalInformationAttachments] = useState([]);
  const [
    technicalInformationAttachmentsForComparison,
    setTechnicalInformationAttachmentsForComparison,
  ] = useState([]);
  const [technicalInformation, setTechnicalInformation] = useState({
    isWaterWellOnProperty: false,
    isSewerWellOnProperty: false,
  });
  const [technicalInformationComparison, setTechnicalInformationComparison] = useState({});
  const [additionalInformation, setAdditionalInformation] = useState({});
  const [additionalInformationComparison, setAdditionalInformationComparison] = useState({});
  const [onSiteMemoActiveTab, setOnSiteMemoActiveTab] = useState('BASIC_INFORMATION');
  const [customerInformation, setCustomerInformation] = useState({});
  const [isChangedOnSiteMemoData, setIsChangedOnSiteMemoData] = useState(false);
  const [offer, setOffer] = useState(null);

  const { t } = useTranslation('lang');

  const params = useParams();
  const navigate = useNavigate();

  const onChangeBasicInformation = (information) => {
    setBasicInformation(information);
  };

  const fetchBasicInformation = async () => {
    try {
      const {
        data: {
          data: { basicInformation: information },
        },
      } = await getBasicInformationByOfferRequest(params.offerRequestId);
      if (information) {
        setBasicInformationComparison(information);
        setBasicInformation(information);
      }
      setIsChangedOnSiteMemoData(false);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchAdditionalInfo = async () => {
    try {
      const {
        data: {
          data: { additionalInformation: additionalInformationData },
        },
      } = await offerRequestService.getOfferRequestAdditionalInfo(params.offerRequestId);
      setAdditionalInformation({
        requestedDrillingTime: new Date(),
        requestedDeliveryTime: new Date(),
        requestedInstallationTime: new Date(),
        ...additionalInformationData,
      });
      setAdditionalInformationComparison({
        requestedDrillingTime: new Date(),
        requestedDeliveryTime: new Date(),
        requestedInstallationTime: new Date(),
        ...additionalInformationData,
      });
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchTechnicalInformationAttachments = async (technicalInformationId) => {
    try {
      const {
        data: {
          data: { attachments },
        },
      } = await getAttachments(attachmentableTypes.TECHNICAL_INFORMATION, technicalInformationId);
      setTechnicalInformationAttachments([...attachments]);
      setTechnicalInformationAttachmentsForComparison([...attachments]);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchTechnicalInformation = async () => {
    try {
      const {
        data: {
          data: { technicalInformation: techInfoData },
        },
      } = await getTechnicalInformation(params.offerRequestId);

      setTechnicalInformation(
        techInfoData || { isWaterWellOnProperty: false, isSewerWellOnProperty: false }
      );
      if (techInfoData) fetchTechnicalInformationAttachments(techInfoData.id);
      setTechnicalInformationComparison(
        techInfoData || { isWaterWellOnProperty: false, isSewerWellOnProperty: false }
      );
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchCustomerInformation = async (customerId) => {
    try {
      const {
        data: {
          data: { customer: customerResponse },
        },
      } = await getCustomer(customerId);
      setCustomerInformation(customerResponse);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchOfferRequest = async () => {
    try {
      const {
        data: {
          data: { offerRequest: request },
        },
      } = await offerRequestService.getOfferRequestById(params.offerRequestId);

      const { data: offers } = await offerRequestService.getOffersByRequestId(
        params.offerRequestId
      );

      setOffer(offers[0]);
      await fetchCustomerInformation(request.customerId);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const updateBasicInformationData = async () => {
    try {
      await saveBasicInformation(params.offerRequestId, basicInformation);
      await fetchBasicInformation();
      toast.success(t('offer.onsite_memo.save_basic_info_update_success_message'));
      setIsChangedOnSiteMemoData(false);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const updateAdditionalInformationData = async () => {
    try {
      await saveOfferRequestAdditionalInfo(params.offerRequestId, additionalInformation);
      await fetchAdditionalInfo();
      toast.success(t('offer.onsite_memo.save_additional_info_update_success_message'));
      setIsChangedOnSiteMemoData(false);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const getAttachmentIdsToRemove = () => {
    const attachmentIdsToRemove = [];
    technicalInformationAttachmentsForComparison.forEach((oldAttachment) => {
      if (!technicalInformationAttachments.find((file) => file?.id === oldAttachment.id)) {
        attachmentIdsToRemove.push(oldAttachment.id);
      }
    });
    return attachmentIdsToRemove;
  };

  const updateTechnicalInformationData = async () => {
    try {
      let body = { ...technicalInformation };

      if (!body.isWaterWellOnProperty) {
        const { waterWellOnPropertyDistance, ...rest } = body;
        body = rest;
      }
      if (!body.isSewerWellOnProperty) {
        const { sewerWellOnPropertyDistance, ...rest } = body;
        body = rest;
      }

      const {
        data: {
          data: { technicalInformation: newTechnicalInformation },
        },
      } = await saveTechnicalInformation(params.offerRequestId, body);
      const technicalInformationId = technicalInformation?.id ?? newTechnicalInformation.id;
      const attachmentIdsToRemove = getAttachmentIdsToRemove();
      if (attachmentIdsToRemove.length > 0) {
        await deleteAttachments(
          attachmentableTypes.TECHNICAL_INFORMATION,
          technicalInformationId,
          attachmentIdsToRemove
        );
      }
      const newAttachments = technicalInformationAttachments.filter((attachment) => !attachment.id);
      if (newAttachments) {
        await uploadAttachments(
          attachmentableTypes.TECHNICAL_INFORMATION,
          technicalInformationId,
          newAttachments
        );
      }
      setTechnicalInformation(body);
      await fetchTechnicalInformation();
      toast.success(t('offer.onsite_memo.save_technical_info_update_success_message'));
      setIsChangedOnSiteMemoData(false);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const checkAndCreateOffer = async () => {
    if (!offer) {
      const { data: offers } = await offerRequestService.getOffersByRequestId(
        params.offerRequestId
      );
      if (offers && offers[0]) {
        setOffer(offers[0]);
        return offers[0];
      }
      const { data: newOffer } = await offerRequestService.addOfferByRequestId(
        params.offerRequestId
      );
      setOffer(newOffer);
      return newOffer;
    }
    return offer;
  };

  const onContinue = async () => {
    const existingOffer = await checkAndCreateOffer();
    setTimeout(() => {
      setOfferFormStepsRefreshCount((prevCount) => prevCount + 1);
    }, 1000);
    if (existingOffer) {
      navigate(
        `/offer-requests/${params.offerRequestId}/offers/${existingOffer.id}/heating-devices`
      );
    }
  };

  const onSaveSiteMemoData = async () => {
    if (onSiteMemoActiveTab === 'BASIC_INFORMATION') {
      updateBasicInformationData();
      setOnSiteMemoActiveTab('TECHNICAL_INFORMATION');
    } else if (onSiteMemoActiveTab === 'ADDITIONAL_INFORMATION') {
      updateAdditionalInformationData();
      const existingOffer = await checkAndCreateOffer();
      setTimeout(() => {
        setOfferFormStepsRefreshCount((prevCount) => prevCount + 1);
      }, 1000);
      if (existingOffer) {
        navigate(
          `/offer-requests/${params.offerRequestId}/offers/${existingOffer.id}/heating-devices`
        );
      }
    } else if (onSiteMemoActiveTab === 'TECHNICAL_INFORMATION') {
      updateTechnicalInformationData();
      setOnSiteMemoActiveTab('ADDITIONAL_INFORMATION');
    }
    await checkAndCreateOffer();
    setTimeout(() => {
      setOfferFormStepsRefreshCount((prevCount) => prevCount + 1);
    }, 1000);
  };

  const customFooter = () => (
    <OnSiteMemoFooter
      onContinue={onContinue}
      onSiteMemoSave={onSaveSiteMemoData}
      isChangedOnSiteMemoData={isChangedOnSiteMemoData}
    />
  );

  useEffect(() => {
    if (
      JSON.stringify(basicInformationComparison) !== JSON.stringify(basicInformation) ||
      JSON.stringify(technicalInformationComparison) !== JSON.stringify(technicalInformation) ||
      JSON.stringify(additionalInformationComparison) !== JSON.stringify(additionalInformation) ||
      JSON.stringify(technicalInformationAttachments) !==
        JSON.stringify(technicalInformationAttachmentsForComparison)
    ) {
      setIsChangedOnSiteMemoData(() => true);
    } else {
      setIsChangedOnSiteMemoData(() => false);
    }
  }, [
    basicInformation,
    technicalInformation,
    technicalInformationAttachments,
    additionalInformation,
    technicalInformationAttachmentsForComparison,
    additionalInformationComparison,
    technicalInformationComparison,
    basicInformationComparison,
  ]);

  useEffect(() => {
    fetchOfferRequest();
    fetchBasicInformation();
    fetchAdditionalInfo();
    fetchTechnicalInformation();
  }, []);

  return (
    <OfferPageContentWrapper customFooter={customFooter}>
      <div className={`${styles.siteMemoMainContainer}`}>
        <div
          className={`d-md-flex align-items-center justify-content-between ${styles.headerContainer}`}
        >
          <div className={`${styles.titleContainer}`}>
            <p>
              <Translate id="offer.onsite_memo.title" />
            </p>
          </div>
          <CustomerContact customerInformation={customerInformation} />
        </div>
        <div className={`${styles.formMainContainer}`}>
          <Form
            basicInformation={basicInformation}
            additionalInformation={additionalInformation}
            setAdditionalInformation={setAdditionalInformation}
            onChangeBasicInformation={onChangeBasicInformation}
            onSiteMemoActiveTab={onSiteMemoActiveTab}
            setOnSiteMemoActiveTab={setOnSiteMemoActiveTab}
            technicalInformation={technicalInformation}
            technicalInformationAttachments={technicalInformationAttachments}
            setTechnicalInformationAttachments={setTechnicalInformationAttachments}
            setTechnicalInformation={setTechnicalInformation}
          />
        </div>
      </div>
    </OfferPageContentWrapper>
  );
}

OnSiteMemo.propTypes = {
  setOfferFormStepsRefreshCount: PropTypes.func.isRequired,
};

OnSiteMemoFooter.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onSiteMemoSave: PropTypes.func.isRequired,
  isChangedOnSiteMemoData: PropTypes.bool.isRequired,
};

export default WithEnableDisableTab(OnSiteMemo);
