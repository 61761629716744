export const getToken = () => localStorage.getItem('authToken');
export const setToken = (token) => localStorage.setItem('authToken', token);
const removeToken = () => localStorage.removeItem('authToken');

export const setCurrentSessionUser = (user) =>
  sessionStorage.setItem('currentUser', JSON.stringify(user));
export const getCurrentSessionUser = () =>
  JSON.parse(sessionStorage.getItem('currentUser')) ?? null;

export const logout = () => {
  removeToken();
  setCurrentSessionUser(null);
  window.location.reload();
};

export const authHeader = () => (getToken() ? { Authorization: `Bearer ${getToken()}` } : {});
