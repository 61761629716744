const settings = [
  { name: 'components.settings.product_information', link: '/' },
  { name: 'components.settings.account_information', link: '/' },
  { name: 'components.settings.manage_admin', link: '/' },
];

const languages = [
  { name: 'English', value: 'en' },
  { name: 'Finnish', value: 'fi' },
];

export { settings, languages };
