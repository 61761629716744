import PropTypes from 'prop-types';
import React from 'react';
import SignatureCanvas from 'react-signature-canvas';

import styles from './signaturePad.module.scss';

function SignaturePad({ setSignaturePad }) {
  return (
    <div className={`${styles.signaturePadContainer}`}>
      <div className={`${styles.signaturePad}`}>
        <SignatureCanvas
          ref={(ref) => {
            setSignaturePad(ref);
          }}
          canvasProps={{ width: 1280, height: 200, className: 'sigCanvas' }}
        />
      </div>
    </div>
  );
}

SignaturePad.propTypes = {
  setSignaturePad: PropTypes.func.isRequired,
};

export default SignaturePad;
