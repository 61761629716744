import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import NumberControl from '../form-fields/number-control';
import NumberTranslate from '../number-translate';
import { RemoveButton } from '../product-price-fields';
import Translate from '../translate';

function QuantityInput({ quantity, onChange, readOnly }) {
  return (
    <NumberControl
      label="offer.heating_device.quantity"
      name="quantity"
      value={quantity}
      onChange={onChange}
      isControlRequired={!readOnly}
      disable={readOnly}
    />
  );
}

function PurchasePrice({ purchasePrice, purchasePriceExclusiveVat, inclusiveOnly }) {
  const { t } = useTranslation('lang');

  return (
    <div className={`d-sm-flex align-items-sm-center ${styles.labelContainer}`}>
      <div>{t('offer.heating_device.purchase_price')}</div>
      {!inclusiveOnly ? (
        <div className="d-flex align-items-center text-break">
          <NumberTranslate value={purchasePriceExclusiveVat} />
          <div>{t('units.euro')}</div>
        </div>
      ) : (
        <div />
      )}
      <div>
        <NumberControl
          name="purchase_price_inclusive_vat"
          unit="units.euro"
          value={purchasePrice}
          // onChange={priceChange}
          disable
          isControlRequired={false}
          isPrice
        />
      </div>
    </div>
  );
}
function SellingPrice({
  sellingPriceInclusiveVat,
  sellingPriceExclusiveVat,
  readOnly,
  onChangeSellingPriceInclusiveVat,
  inclusiveOnly,
}) {
  const { t } = useTranslation('lang');

  return (
    <div className={`d-sm-flex align-items-sm-center ${styles.labelContainer}`}>
      <div>
        {!inclusiveOnly
          ? t('offer.heating_device.client_price')
          : t('offer.products.selling_price_incl_vat')}
      </div>

      {!inclusiveOnly ? (
        <div className="d-flex align-items-center text-break">
          <NumberTranslate value={sellingPriceExclusiveVat} />
          <div>{t('units.euro')}</div>
        </div>
      ) : (
        <div />
      )}
      <div>
        <ClientPriceInput
          value={sellingPriceInclusiveVat}
          onChange={onChangeSellingPriceInclusiveVat}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
}

function ClientPriceInput({ value, onChange, readOnly }) {
  const { i18n } = useTranslation('lang');
  const [price, setPrice] = useState(value);
  const priceChange = (number) => {
    setPrice(number);
    if (i18n.language !== 'en') {
      onChange(number ? +number.toString().replace(',', '.') : 0);
    } else {
      onChange(+number || 0);
    }
  };

  useEffect(() => {
    if (value !== Number(price)) {
      setPrice(value);
    }
  }, [value]);
  return (
    <NumberControl
      name="client_price"
      unit="units.euro"
      value={price}
      onChange={priceChange}
      disable={readOnly}
      isControlRequired={!readOnly}
      isPrice
    />
  );
}

function HeatingDevicePriceFields({
  id,
  name,
  purchasePriceExclusiveVat,
  purchasePriceInclusiveVat,
  number,
  quantity,
  onChangeQuantity,
  sellingPriceInclusiveVat,
  sellingPriceExclusiveVat,
  onChangeSellingPriceInclusiveVat,
  image,
  readOnly,
  vat,
  onRemove,
  inclusiveOnly,
}) {
  const { t } = useTranslation('lang');
  return (
    <div className={`${styles.priceFieldContainer}`}>
      <div
        className={`${styles.labelTitleContainer} d-flex align-items-center justify-content-between`}
      >
        <div>
          <span className={styles.labelIdContainer}>{`${t('offer.products.id')}:${id} - `}</span>
          <span>{t(name)}</span>
        </div>
        <div>{!readOnly && <RemoveButton onClick={() => onRemove(id)} />}</div>
        {readOnly && image && (
          <div className={`${styles.imageContainer}`}>
            <img className="imgFluid" src={image} alt={`${number} ${t(name)}`} />
          </div>
        )}
      </div>
      <div className={`${styles.contentContainer}`}>
        <QuantityInput
          quantity={quantity}
          onChange={(value) => onChangeQuantity(id, value < 1 ? 1 : value)}
          readOnly={readOnly}
        />
        <div>
          {!inclusiveOnly ? (
            <div className={`${styles.vatFieldContainer}`}>
              <div>
                <Translate id="offer.services.exclusive_vat" />
              </div>
              <div>{t('offer.products.inclusive_vat', { vatPercentage: vat })}</div>
            </div>
          ) : (
            <div />
          )}
          <PurchasePrice
            purchasePrice={purchasePriceInclusiveVat}
            purchasePriceExclusiveVat={purchasePriceExclusiveVat}
            inclusiveOnly={inclusiveOnly}
          />
          <SellingPrice
            sellingPriceInclusiveVat={sellingPriceInclusiveVat}
            sellingPriceExclusiveVat={sellingPriceExclusiveVat}
            onChangeSellingPriceInclusiveVat={(value) =>
              onChangeSellingPriceInclusiveVat(id, value < 0 ? 0 : value)
            }
            readOnly={readOnly}
            inclusiveOnly={inclusiveOnly}
          />
        </div>
      </div>
    </div>
  );
}
HeatingDevicePriceFields.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  purchasePriceInclusiveVat: PropTypes.number.isRequired,
  sellingPriceInclusiveVat: PropTypes.number.isRequired,
  purchasePriceExclusiveVat: PropTypes.number.isRequired,
  sellingPriceExclusiveVat: PropTypes.number.isRequired,
  number: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  onChangeSellingPriceInclusiveVat: PropTypes.func.isRequired,
  image: PropTypes.string,
  readOnly: PropTypes.bool,
  vat: PropTypes.number,
  onRemove: PropTypes.func,
};

HeatingDevicePriceFields.defaultProps = {
  image: '',
  readOnly: false,
  vat: 0,
  onRemove: () => {},
};
PurchasePrice.propTypes = {
  purchasePrice: PropTypes.number.isRequired,
  purchasePriceExclusiveVat: PropTypes.number.isRequired,
};

SellingPrice.propTypes = {
  sellingPriceInclusiveVat: PropTypes.number.isRequired,
  sellingPriceExclusiveVat: PropTypes.number.isRequired,
  onChangeSellingPriceInclusiveVat: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

QuantityInput.propTypes = {
  quantity: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

ClientPriceInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default HeatingDevicePriceFields;
