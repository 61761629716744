import { yupResolver } from '@hookform/resolvers/yup';
import * as moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import * as yup from 'yup';

import styles from './index.module.scss';
import CalendarControl from '../../../components/form-fields/calendar-control';
import Translate from '../../../components/translate';
import { getOriginalErrors } from '../../../utils/errorHandler';

function ClientWantsToBookAFreeViewing({ value, setValue }) {
  return (
    <label className="switch" htmlFor="client_wants_to_book_a_free_viewing">
      <Translate id="offer.request.client_wants_to_book_a_free_viewing" />
      <input
        className="switchInput"
        type="checkbox"
        checked={value}
        id="client_wants_to_book_a_free_viewing"
        onChange={(event) => setValue('freeViewingBooked', event.target.checked)}
        name="freeViewingBooked"
      />
      <span className="slider round">
        <Translate id="common.yes" />
      </span>
    </label>
  );
}

function DateAndTimePicker({ value, setValue, error }) {
  const { t } = useTranslation('lang');

  const setTimeValue = (fieldName, changedValue) => {
    let timeValue = Number(changedValue);
    if (Number.isNaN(timeValue)) {
      timeValue = 0;
    }
    if (fieldName === 'HOUR') {
      timeValue = timeValue < 0 ? 0 : timeValue;
      timeValue = timeValue > 23 ? 23 : timeValue;
      setValue(
        'freeViewingDateTime',
        moment(value).hours(timeValue).minutes(moment(value).get('minutes')).toISOString()
      );
    } else {
      timeValue = timeValue < 0 ? 0 : timeValue;
      timeValue = timeValue > 59 ? 59 : timeValue;
      setValue(
        'freeViewingDateTime',
        moment(value).hours(moment(value).get('hours')).minutes(timeValue).toISOString()
      );
    }
  };

  const setChangedDate = (changedDate) => {
    setValue(
      'freeViewingDateTime',
      moment(changedDate)
        .hours(moment(value).get('hours'))
        .minutes(moment(value).get('minutes'))
        .toISOString()
    );
  };

  return (
    <>
      <CalendarControl
        label="offer.request.select_date"
        onChange={setChangedDate}
        selected={value}
        showTimeInput={false}
      />
      <div className={`d-flex align-items-center ${styles.timeContainer}`}>
        <div className={`${styles.timeLabel}`}>{t('offer.request.select_viewing_time')}</div>
        <div className={`${styles.timeData}`}>
          <input
            className={`${styles.inputField}`}
            type="text"
            size="3"
            value={moment(value).isValid() ? moment(value).format('HH') : ''}
            placeholder="HH"
            onChange={(e) => setTimeValue('HOUR', e.target.value)}
          />
          :
          <input
            className={`${styles.inputField}`}
            type="text"
            value={moment(value).isValid() ? moment(value).format('mm') : ''}
            size="3"
            placeholder="mm"
            onChange={(e) => setTimeValue('MINUTE', e.target.value)}
          />
        </div>
      </div>
      {error && <div className={`${styles.errorLabel}`}>{error}</div>}
    </>
  );
}

function AdditionalInformation({ offerRequest, onUpdateOfferRequest, apiError }) {
  const additionalInformationSchema = yup.object({
    freeViewingDateTime: yup.string(),
    freeViewingBooked: yup.boolean().default(false),
    comment: yup.string(),
  });

  const {
    register,
    formState: { errors },
    getValues,
    watch,
    reset,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    resolver: yupResolver(additionalInformationSchema),
  });

  watch(({ freeViewingDateTime, freeViewingBooked, comment }, name) => {
    onUpdateOfferRequest((prev) => ({
      ...prev,
      freeViewingDateTime: freeViewingBooked ? freeViewingDateTime : null,
      freeViewingBooked,
      comment,
    }));
    clearErrors(name.name);
  });

  useEffect(() => {
    reset({ ...offerRequest });
  }, []);

  useEffect(() => {
    if (apiError) {
      const originalErrors = getOriginalErrors(apiError);
      originalErrors.forEach((error) => {
        if (error.field) {
          setError(error.field, { message: error.message });
        } else {
          toast.error(error.message);
        }
      });
    }
  }, [apiError]);

  return (
    <div className={`${styles.additionalInformationContainer}`}>
      <div className={`${styles.headerContainer}`}>
        <h1 className={`${styles.headerTitle}`}>
          <Translate id="offer.request.additional_information" />
        </h1>
      </div>
      <div className={`${styles.contentContainer}`}>
        <div>
          <ClientWantsToBookAFreeViewing
            value={offerRequest.freeViewingBooked}
            setValue={setValue}
          />
        </div>
        {offerRequest.freeViewingBooked && (
          <DateAndTimePicker
            value={getValues('freeViewingDateTime')}
            setValue={setValue}
            error={errors.freeViewingDateTime?.message}
          />
        )}
        <div className={`${styles.textAreaContainer}`}>
          <h3 className={` ${styles.textAreaTitle}`}>
            <Translate id="offer.request.add_comments" />
          </h3>
          <textarea
            className={`inputField ${styles.textArea}`}
            cols="30"
            rows="10"
            name="comment"
            {...register('comment')}
          />
          <div className={styles.errorLabel}>{errors.comment?.message}</div>
        </div>
      </div>
    </div>
  );
}

ClientWantsToBookAFreeViewing.propTypes = {
  value: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
};

AdditionalInformation.propTypes = {
  offerRequest: PropTypes.instanceOf(Object),
  onUpdateOfferRequest: PropTypes.func,
  apiError: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.instanceOf(Object)])
    .isRequired,
};

AdditionalInformation.defaultProps = {
  offerRequest: {},
  onUpdateOfferRequest: () => null,
};

DateAndTimePicker.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  error: PropTypes.string,
};

DateAndTimePicker.defaultProps = {
  value: null,
  error: '',
};

export default AdditionalInformation;
