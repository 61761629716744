import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { getAssignmentDetails, getAssinmentByTask } from '../../../services/assignment';
import AuthService from '../../../services/auth';
import offerService from '../../../services/offer';

const Context = createContext();

export function ServiceContext({ children }) {
  const { companyId, taskId, assignmentId, offerRequestId, offerId } = useParams();
  const [serviceComment, setServiceComment] = useState('');
  const [serviceCommentComparison, setServiceCommentComparison] = useState('');
  const { t } = useTranslation('lang');
  const navigate = useNavigate();

  const { data, error } = useQuery(
    ['Assignment'],
    async () => {
      const currentUser = await AuthService.getCurrentUser();
      if (assignmentId) {
        const {
          data: { data: response },
        } = await getAssignmentDetails(companyId, assignmentId);
        if (response) {
          setServiceComment(response.assignment.taskComment);
          setServiceCommentComparison(response.assignment.taskComment);
          return response;
        }
        toast.error(t('assign_teams.assignment_not_found'));
        navigate(`/company/${companyId}/admin/${currentUser.id}/assignments`);
        return {};
      }
      if (taskId) {
        const {
          data: { data: response },
        } = await getAssinmentByTask(companyId, currentUser.id, taskId);
        if (response) {
          setServiceComment(response.assignment.taskComment);
          setServiceCommentComparison(response.assignment.taskComment);
          return response;
        }
        toast.error(t('assign_teams.assignment_not_found'));
        navigate(`/company/${companyId}/employee/${currentUser.id}/tasks`);
        return {};
      }

      if (offerRequestId && offerId) {
        const {
          data: { data: response },
        } = await offerService.getBasicDetailsByOfferId(offerRequestId, offerId);
        if (response) {
          return response;
        }
        toast.error(t('common.something_went_wrong'));
        navigate(`/task`);
        return {};
      }

      return {};
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  const contextValue = useMemo(
    () => ({
      serviceComment,
      setServiceComment,
      serviceCommentComparison,
      service: data?.assignment,
      offerRequest: data?.offerRequest,
      offerServices: data?.offerServices,
      basicInformation: data?.basicInformation,
      attachments: data?.attachments,
      heatingDevices: data?.heatingDevices,
      products: data?.products,
      additionalInformation: data?.additionalInformation,
      technicalInformation: data?.technicalInformation,
    }),
    [data, serviceComment]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

export const useServiceContext = () => useContext(Context);
