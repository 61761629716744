/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './basicInformation.module.scss';
import {
  groundFloorStructureMethod,
  heatCirculationMethod,
  secondFloorStructureMethod,
} from '../../../constant';

function FormField({ field }) {
  const { t } = useTranslation('lang');

  return field.value ? (
    <div
      className={`d-flex flex-wrap align-items-start justify-content-between ${styles.flexContainer}`}
    >
      <div>{t(field.label)} :</div>
      <div>{field.options ? t(field.options[field.value]) : field.value || 'N/A'}</div>
    </div>
  ) : null;
}

function TechnicalInformation({ technicalInformation }) {
  const [form, setForm] = useState([]);
  const { t } = useTranslation('lang');

  useEffect(() => {
    if (technicalInformation) {
      setForm([
        {
          name: 'HeatCirculationInput',
          label: 'offer.technical_information.heat_circulation',
          value: technicalInformation.heatCirculation,
          options: heatCirculationMethod,
        },
        {
          name: 'GroundFloorStructureInput',
          label: 'offer.technical_information.ground_floor_structure',
          value: technicalInformation.groundFloorStructure,
          options: groundFloorStructureMethod,
        },
        {
          name: 'SecondFloorStructureInput',
          label: 'offer.technical_information.2nd_floor_structure',
          value: technicalInformation.secondFloorStructure,
          options: secondFloorStructureMethod,
        },
        {
          name: 'ElectricityAvailableInput',
          label: 'offer.technical_information.electricity_available',
          value: technicalInformation.electricityAvailable,
        },
        {
          name: 'DemolitionNeedsInput',
          label: 'offer.technical_information.demolition_needs',
          value: technicalInformation.demolitionNeeds,
        },
      ]);
    }
  }, [technicalInformation]);
  return technicalInformation ? (
    <div className={`${styles.basicDetailsFormContainer}`}>
      <h2 className={`${styles.basicDetailsFormTitle}`}>
        {t('offer.onsite_memo.technical_information')}
      </h2>
      {form.map((field, index) => (
        <FormField key={index} field={field} />
      ))}
      <div className={`d-flex align-items-start justify-content-between ${styles.flexContainer}`}>
        <div>{t('offer.technical_information.water_well_on_the_property')} : </div>{' '}
        <div>
          {technicalInformation?.isWaterWellOnProperty ? (
            <div>
              {t('common.yes')}, {t('offer.technical_information.distance')}:{' '}
              {technicalInformation?.waterWellOnPropertyDistance}
            </div>
          ) : (
            t('common.no')
          )}
        </div>
      </div>
      <div className={`d-flex align-items-start justify-content-between ${styles.flexContainer}`}>
        <div>{t('offer.technical_information.sewer_well_on_the_property')} : </div>{' '}
        <div>
          {technicalInformation?.isSewerWellOnProperty ? (
            <div>
              {t('common.yes')}, {t('offer.technical_information.distance')}:{' '}
              {technicalInformation?.sewerWellOnPropertyDistance}
            </div>
          ) : (
            t('common.no')
          )}
        </div>
      </div>
      <FormField
        field={{
          name: 'CommentTextarea',
          label: 'offer.technical_information.additional_comments_for_technical_information',
          value: technicalInformation?.comment,
        }}
      />
    </div>
  ) : null;
}

export default TechnicalInformation;
