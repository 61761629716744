import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'sonner';

import styles from './attachments.module.scss';
import AttachmentList from '../../../components/attachment-list';
import { downloadAttachment } from '../../../services/attachment';
import { downloadFile } from '../../../utils/common';
import { getOriginalErrors } from '../../../utils/errorHandler';

function Attachments({ attachments }) {
  const [selectedAttachment, setSelectedAttachment] = React.useState(null);
  const [showModal, setShowModal] = useState(false);

  const onDownloadClick = async ({ attachmentableType, attachmentableId, id, name }) => {
    try {
      const res = await downloadAttachment(attachmentableType, attachmentableId, id);
      downloadFile(res, name);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const onAttachmentClick = (attachment) => {
    setSelectedAttachment(attachment);
    setShowModal(true);
  };

  const onClosePreview = () => {
    setSelectedAttachment(null);
    setShowModal(false);
  };

  const filteredAttachments = attachments
    ? attachments.filter(({ mimetype }) => !mimetype.includes('image'))
    : [];

  const images = attachments
    ? attachments.filter(({ mimetype }) => mimetype.includes('image'))
    : [];

  return (
    <div className={`${styles.attachmentContainer}`}>
      <div className={`${styles.flexContainer}`}>
        {images.map(({ id, src, name }) => (
          <div
            role="button"
            tabIndex="-1"
            className={`${styles.imgContainer}`}
            onClick={() => onAttachmentClick({ id, src, name })}
            onKeyDown={(e) => (e.key === 'Enter' ? onAttachmentClick({ id, src, name }) : '')}
          >
            <img className={`${styles.attachmentImg}`} key={id} src={src} alt={name} />
          </div>
        ))}
      </div>
      <AttachmentList attachments={filteredAttachments} onDownloadClick={onDownloadClick} />
      <Modal
        show={showModal}
        onHide={onClosePreview}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body className={`${styles.modalBody}`}>
          <img
            src={selectedAttachment && selectedAttachment.src}
            alt={selectedAttachment && selectedAttachment.name}
            className={`${styles.modalImage}`}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Attachments;
