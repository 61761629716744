/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link, useLocation, useParams } from 'react-router-dom';

import styles from './index.module.scss';
import { useServiceContext } from '../../pages/task-forms/context/service-context';
import AuthService from '../../services/auth';
import Translate from '../translate';

function TaskSteps() {
  const [currentPage, setCurrentPage] = useState(null);
  const [steps, setSteps] = useState([]);
  const { service } = useServiceContext();
  const { assignmentId, taskId } = useParams();

  const location = useLocation();

  const updateSteps = async () => {
    const currentUser = await AuthService.getCurrentUser();
    const tempSteps = [
      {
        title: 'company_admin.tabs.basic_details',
        link: `/company/${service.companyName}/${assignmentId ? 'admin' : 'employee'}/${
          currentUser.id
        }/${assignmentId ? `assignments/${assignmentId}` : `tasks/${taskId}`}/basic-details`,
        active: location.pathname.includes('basic-details'),
      },
      {
        title: 'company_admin.tabs.report',
        link: `/company/${service.companyName}/${assignmentId ? 'admin' : 'employee'}/${
          currentUser.id
        }/${assignmentId ? `assignments/${assignmentId}` : `tasks/${taskId}`}/report/${
          service.type === 'INSTALLING' ? 'installing-report' : 'drilling-report'
        }`,
        active: location.pathname.includes('report'),
      },
    ];
    if (assignmentId) {
      tempSteps.push({
        title: 'company_admin.tabs.assign_workers',
        link: `/company/${service.companyName}/admin/${currentUser.id}/assignments/${assignmentId}/tasks`,
        active: location.pathname.includes('tasks'),
      });
    }
    setSteps(tempSteps);
    setCurrentPage(tempSteps[0].title);
  };

  useEffect(() => {
    if (service) {
      updateSteps();
    }
  }, [service, location, assignmentId]);

  return (
    <>
      {/* This below div is for desktop view */}
      <div className={`d-none d-md-block ${styles.tabLinkContainer}`}>
        <div className={`d-flex align-items-center ${styles.linkFlexContainer}`}>
          {steps.map(({ title, link, active }) => (
            <div>
              <Link
                key={link}
                to={link}
                className={` ${styles.tabLink}  ${active ? styles.tabLinkActive : ''}`}
              >
                <Translate id={title} />
              </Link>
            </div>
          ))}
        </div>
      </div>
      {/* The below div is for the mobile view */}
      <div className={`d-md-none ${styles.mobTabLinkContainer}`}>
        <Accordion className={`${styles.mobAccordion}`}>
          <Accordion.Item className={`${styles.mobAccordionItem}`} eventKey="0">
            <Accordion.Header className={`${styles.mobAccordionHeader}`}>
              <Translate id={currentPage} />
            </Accordion.Header>
            <Accordion.Body className={`${styles.mobAccordionBody}`}>
              {steps.map(({ title, link, active }) => (
                <Link
                  key={link}
                  to={link}
                  className={`${styles.mobTabLink} ${active ? styles.mobTabLinkActive : ''}`}
                >
                  <Translate id={title} />
                </Link>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}

export default TaskSteps;
