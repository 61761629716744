import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react'

import styles from './index.module.scss'
import { ReactComponent as CircleArrowDownSvg } from '../../assets/svgs/CircleArrowDown.svg'
import { ReactComponent as CircleArrowUpSvg } from '../../assets/svgs/CircleArrowUp.svg'
import Translate from '../translate'

function AccordionTabsContainer({ activeTab, tabs, setActiveTab }) {
  const [toggle, setToggle] = useState(false)

  const { filteredTabs, activeTabContent } = useMemo(() => ({
        filteredTabs: tabs.filter((tab)=> tab.key !== activeTab),
        activeTabContent: tabs.find((tab)=> tab.key === activeTab)
  }), [activeTab, tabs])

  const onTabSelect = (tab) => {
    setActiveTab(tab.key)
    setToggle(false)
  }

  const onToggle = () => setToggle((prev) => !prev)


  return (
    <div>
      <div className={`${styles.accordionTabsContainer}`}>
        <div className={`d-flex align-items-center justify-content-between ${styles.accordionBtn}`} role="button" tabIndex={0} onKeyDown={(e)=>{
          if(e.key === 'Enter'){
            onToggle()
          }
        }} onClick={onToggle}>
          <div className={`${styles.btnTitle}`}>
            <Translate id={activeTabContent.title} />
          </div>
          <div className={`${styles.btnIcon}`}>{toggle ? <CircleArrowUpSvg /> : <CircleArrowDownSvg />}</div>
        </div>
        {toggle && (
          <div className={`${styles.tabContentContainer}`}>
            {filteredTabs.map((tab) => (
              <div
                key={tab.key}
                className={`${styles.accordionBtn} ${tab.disabled ? 'disabled' : ''} `}
                role="button"
                tabIndex={-1}
                onKeyDown={(e) => e.key==='Enter' && onTabSelect(tab)}
                onClick={() => onTabSelect(tab)}
              >
                <Translate id={tab.title} />
              </div>
            ))}
          </div>
        )}
      </div>
      <div>
        {activeTabContent.component}
      </div>
    </div>
  )
}

AccordionTabsContainer.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  tabs: PropTypes.instanceOf(Array).isRequired
}

export default AccordionTabsContainer
