import { gql } from 'graphql-request';
import { debounce } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.scss';
import statuses from './statuses.json';
import DataTable from '../../../components/data-table';
import DropDown from '../../../components/drop-down';
import Layout from '../../../components/layout';
import SearchBox from '../../../components/search-box';
import Translate from '../../../components/translate';
import { fetchOfferRequests as fetchTaskServices } from '../../../services/graphql/offer-request';
import offerRequestService from '../../../services/offerRequest';

function TasksHeader() {
  return (
    <div>
      <div>
        <p className={`${styles.taskTitle}`}>
          <Translate id="components.navbar.tasks" />
        </p>
      </div>
    </div>
  );
}

function Tasks({ setLoading }) {
  const { t } = useTranslation('lang');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilterOption, setSelectedFilterOption] = useState('ALL');
  const navigate = useNavigate();
  const [offerRequests, setOfferRequests] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [nextCursor, setNextCursor] = useState(null);
  const [queryParams, setQueryParams] = useState(`progress=TASK`);
  const { data, isLoading, error, refetch, fetchNextPage } = useInfiniteQuery(
    ['offerRequests', queryParams],
    async ({ pageParam = null }) => {
      const taskData = await fetchTaskServices(
        gql`
          query ($queryParams: String, $first: Int!, $after: String, $limit: Int) {
            offerRequests(queryParams: $queryParams, first: $first, after: $after) {
              edges {
                node {
                  id
                  createdAt
                  updatedAt
                  customer {
                    address
                    fullName
                    city
                    zipCode
                  }
                  assignment {
                    employee {
                      id
                      fullName
                    }
                  }
                  offerRequestProgress(limit: $limit) {
                    event
                    createdAt
                  }
                }
                cursor
              }
              pageInfo {
                endCursor
                hasNextPage
              }
            }
          }
        `,
        queryParams,
        10, // TODO: should be a dynamic value in case of change page limit
        nextCursor ? pageParam : null,
        2
      );
      setLoading(false);
      return taskData;
    },
    {
      getNextPageParam: (lastPage) => lastPage.pageInfo.endCursor,
    }
  );

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (data?.pages?.length) {
      setOfferRequests(
        [].concat(
          ...data.pages.map((page) =>
            page?.edges?.length ? page.edges.map((edge) => edge.node) : []
          )
        )
      );
      setHasNextPage(data.pages[data.pages.length - 1]?.pageInfo?.hasNextPage);
      setNextCursor(data.pages[data.pages.length - 1]?.pageInfo?.endCursor);
    }
  }, [data]);

  const translateObject = (objects, field = 'label') =>
    objects.map((object) => {
      const translatedObject = { ...object, [field]: t(object[field]) };
      return translatedObject;
    });
  const translatedStatuses = translateObject(statuses);

  const fetchTasks = async (status = selectedFilterOption, q = searchTerm) => {
    setNextCursor(null);
    let params = 'progress=TASK';
    if (q) {
      params += `&q=${q}`;
    }

    if (status && status !== 'ALL') {
      params += `&status=${status}`;
    }
    setQueryParams(params);
    await refetch();
    setLoading(false);
  };

  const onFilterOptionSelect = (selectedObj) => {
    setSelectedFilterOption(selectedObj);
    fetchTasks(selectedObj);
  };
  const onSearch = (value) => {
    setSearchTerm(value);
    fetchTasks(undefined, value);
  };

  const debouncedSearch = debounce((newValue) => {
    onSearch(newValue);
  }, 1000);

  const fetchOfferId = async (requestId) => {
    const { data: offers } = await offerRequestService.getOffersByRequestId(requestId);
    return offers[0].id;
  };

  const onTaskSelect = async (task) => {
    setLoading(true);
    const offerId = await fetchOfferId(task.id);
    setLoading(false);
    navigate(`/offer-requests/${task.id}/offers/${offerId}/tasks/basic-details`);
  };

  return (
    <Layout headerComponent={TasksHeader}>
      <div className={`${styles.taskPageContainer}`}>
        <div
          className={`d-md-flex align-items-md-center justify-content-md-between ${styles.headerContainer}`}
        >
          <div>
            <SearchBox value={searchTerm} onChange={debouncedSearch} />
          </div>

          <div
            className={`d-flex align-items-center justify-content-end ${styles.filterDropDownParent}`}
          >
            <p className={`${styles.filterTitle}`}>{t('common.filter')}: </p>
            <DropDown
              className={`${styles.filterDropDown}`}
              options={translatedStatuses}
              selected={selectedFilterOption}
              onChange={onFilterOptionSelect}
              selectionKey="value"
            />
          </div>
        </div>
        {!isLoading && !error && (
          <div className={`${styles.contentContainer}`}>
            {Object.keys(offerRequests).length > 0 && (
              <DataTable
                responseData={offerRequests}
                onRowClick={onTaskSelect}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
              />
            )}
          </div>
        )}
      </div>
    </Layout>
  );
}

export default Tasks;
