import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.scss';
import DesktopFooterIcon from '../../assets/images/footerLogo.png';
import MobileFooterIcon from '../../assets/images/logoIcon.png';

function Footer({ component: PropComponent }) {
  const { t } = useTranslation('lang');
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate('/');
  };

  return (
    <div className={`${styles.footerContainer}`}>
      <div className="projectContainer d-flex align-items-center justify-content-between">
        <div className={`${styles.logoContainer}`}>
          <button type="button" className={`btn ${styles.logoBtn}`} onClick={navigateToHome}>
            <img
              className={`d-none d-md-block ${styles.logoImg}`}
              src={DesktopFooterIcon}
              alt={t('Lannen Logo')}
            />
            <img
              className={`d-md-none ${styles.logoImg}`}
              src={MobileFooterIcon}
              alt={t('Lannen Logo')}
            />
          </button>
        </div>
        <div className={`${styles.contentContainer}`}>{PropComponent && <PropComponent />}</div>
      </div>
    </div>
  );
}

Footer.propTypes = {
  component: PropTypes.func,
};

Footer.defaultProps = {
  component: null,
};

export default Footer;
