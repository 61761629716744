import { graphqlClient } from './graphql-client';

export const fetchOfferRequests = async (
  query,
  queryParams,
  first,
  after,
  offerRequestProgressLimit
) => {
  const { offerRequests } = await graphqlClient.request(query, {
    queryParams,
    first,
    after,
    limit: offerRequestProgressLimit,
  });
  return offerRequests;
};
