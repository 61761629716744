import PropTypes from 'prop-types';
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import CompanyEmployeeTasks from '..';
import BasicDetails from '../../../../../pages/company-admin/basic-details';
import DrillingTeamView from '../../../../../pages/task-forms/drilling-team-view';
import InstalltionCrewTaskView from '../../../../../pages/task-forms/installation-team-view';
import Wrapper from '../../../company-admin-routes/Wrapper';

function CompanyEmployeeRoutes({ setLoading }) {
  return (
    <Routes>
      <Route path="/tasks" element={<CompanyEmployeeTasks setLoading={setLoading} />} />
      <Route path="" element={<Wrapper />}>
        <Route
          path="/tasks/:taskId/basic-details"
          element={<BasicDetails setLoading={setLoading} taskTab />}
        />
        <Route
          path="/tasks/:taskId/report/drilling-report"
          element={<DrillingTeamView setLoading={setLoading} />}
        />
        <Route
          path="/tasks/:taskId/report/installing-report"
          element={<InstalltionCrewTaskView setLoading={setLoading} />}
        />
      </Route>
    </Routes>
  );
}

CompanyEmployeeRoutes.propTypes = {
  setLoading: PropTypes.func.isRequired,
};

export default CompanyEmployeeRoutes;
