import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './login.module.scss';
import { ReactComponent as BoxShadowSvg } from '../../../assets/svgs/BoxShadow.svg';
import LannenLogoSvg from '../../../assets/svgs/Lannen_Full_logo.svg';
import { ReactComponent as ShadowOneSvg } from '../../../assets/svgs/Shadow1.svg';
import { ReactComponent as ShadowTwoSvg } from '../../../assets/svgs/Shadow2.svg';
import { ReactComponent as ShadowThreeSvg } from '../../../assets/svgs/Shadow3.svg';
import { ReactComponent as ShadowFourSvg } from '../../../assets/svgs/Shadow4.svg';
import { ReactComponent as ShadowFiveSvg } from '../../../assets/svgs/Shadow5.svg';
import Button from '../../../components/button';
import { userRoles } from '../../../constant';
import authService from '../../../services/auth';
import { authHeader, setToken } from '../../../services/authorization';
import { graphqlClient } from '../../../services/graphql/graphql-client';
import { getOriginalErrors } from '../../../utils/errorHandler';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { t } = useTranslation('lang');

  const submit = async (e) => {
    e.preventDefault();
    try {
      const {
        data: {
          data: { token },
        },
      } = await authService.login(email, password);
      setToken(token);
      graphqlClient.setHeaders(authHeader());
      const currentUser = await authService.getCurrentUser();

      if (currentUser?.company?.id) {
        if (currentUser.roles.includes(userRoles.COMPANY_ADMIN.role)) {
          navigate(`/company/${currentUser.company.id}/admin/${currentUser.id}/assignments`, {
            replace: true,
          });
        } else if (currentUser.roles.includes(userRoles.COMPANY_EMPLOYEE.role)) {
          navigate(`/company/${currentUser.company.id}/employee/${currentUser.id}/tasks`, {
            replace: true,
          });
        }
        return;
      }
      navigate('/', { replace: true });
    } catch (err) {
      if (err.response) {
        toast.error(t(`authentication.errors.email_or_password_not_match`));
      } else {
        const originalErrors = getOriginalErrors(err);
        originalErrors.forEach((error) => {
          toast.error(error.message);
        });
      }
    }
  };

  const validate = (target) => {
    if (target.value) {
      setErrors((prev) => {
        const errs = { ...prev };
        errs[target.id] = null;
        return errs;
      });
    } else {
      setErrors((prev) => {
        const errs = { ...prev };
        errs[target.id] = t(`authentication.errors.${target.id}_required`);
        return errs;
      });
    }
  };

  return (
    <div className={`${styles.loginMainContainer}`}>
      <div className={`${styles.bgImgContainer} ${styles.topLeftImg}`}>
        <ShadowOneSvg />
      </div>
      <div className={`${styles.bgImgContainer} ${styles.topRightImg}`}>
        <ShadowTwoSvg />
      </div>
      <div className={`${styles.bgImgContainer} ${styles.middleLeftImg}`}>
        <ShadowThreeSvg />
      </div>
      <div className={`${styles.bgImgContainer} ${styles.middleRightImg}`}>
        <ShadowFourSvg />
      </div>
      <div className={`${styles.bgImgContainer} ${styles.bottomLeftImg}`}>
        <ShadowFiveSvg />
      </div>
      <div className={`${styles.bgImgContainer} ${styles.bottomRightImg}`}>
        <BoxShadowSvg />
      </div>
      <div
        className={`d-flex flex-column align-items-center justify-content-center ${styles.logInContainer}`}
      >
        <div className={`${styles.logoContainer}`}>
          <img className="imgFluid" src={LannenLogoSvg} alt="Lannen Maalampo" />
        </div>
        <div className={`${styles.logInContentContainer}`}>
          <p className={`${styles.pageTitle}`}>{t('authentication.login.title')}</p>
          <form className={`${styles.logInForm}`} onSubmit={submit}>
            <div className={`${styles.formGroup}`}>
              <input
                className={`inputField ${styles.inputField} form-control ${
                  errors.email ? 'is-invalid' : ''
                }`}
                type="text"
                placeholder={t('authentication.login.email_id_placeholder')}
                id="email"
                value={email}
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                  validate(e.target);
                }}
                onBlur={(e) => validate(e.target)}
              />
              <div className="invalid-feedback">{errors.email}</div>
            </div>
            <div className={`${styles.formGroup}`}>
              <input
                className={`inputField ${styles.inputField} form-control ${
                  errors.password ? 'is-invalid' : ''
                }`}
                type="password"
                placeholder={t('authentication.login.password')}
                id="password"
                value={password}
                required
                onChange={(e) => {
                  setPassword(e.target.value);
                  validate(e.target);
                }}
                onBlur={(e) => validate(e.target)}
              />
              <div className="invalid-feedback">{errors.password}</div>
            </div>
            <Link className={`${styles.forgotPassword}`} to="/auth/forgot-password">
              {t('authentication.login.forgot_password')}
            </Link>
            <div className={`${styles.logInFormBtnContainer}`}>
              <Button className="projectBtn logInBtn" type="submit">
                {t('authentication.login.title')}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className={`${styles.logInFormDec}`}>
        <p>Lännen Maalämpö Oy</p>
      </div>
    </div>
  );
}

export default Login;
