import PropTypes from 'prop-types';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import { ReactComponent as ArrowDownSvg } from '../../../assets/svgs/ArrowDown.svg';
import { ReactComponent as ArrowUpSvg } from '../../../assets/svgs/ArrowUp.svg';

function IncrementButton({ onClick }) {
  return (
    <button className={`projectBtn ${styles.arrowBtn}`} type="button" onClick={onClick}>
      <ArrowUpSvg className={`${styles.arrowBtnIcon}`} />
    </button>
  );
}

function DecrementButton({ onClick }) {
  return (
    <button className={`projectBtn ${styles.arrowBtn}`} type="button" onClick={onClick}>
      <ArrowDownSvg className={`${styles.arrowBtnIcon}`} />
    </button>
  );
}

function NumberControl({
  label,
  name,
  unit,
  value,
  onChange,
  className,
  disable,
  isPrice,
  isControlRequired,
}) {
  const { t, i18n } = useTranslation('lang');

  const getNumberFormat = (language) => {
    switch (language) {
      case 'en':
        return {
          groupSeparator: ',',
          decimalSeparator: '.',
        };
      case 'fi':
        return {
          groupSeparator: '.',
          decimalSeparator: ',',
        };
      default:
        return {
          groupSeparator: ',',
          decimalSeparator: '.',
        };
    }
  };

  const { groupSeparator, decimalSeparator } = getNumberFormat(i18n.language);

  return (
    <div
      className={`d-sm-flex align-items-sm-center justify-content-sm-between ${
        styles.numberControlContainer
      } ${label ? '' : styles.noLabelControl} ${className ?? ''} ${
        isControlRequired ? '' : styles.hiddenArrow
      }`}
    >
      <div>
        <p>{t(label)}</p>
      </div>
      <div>
        {isPrice ? (
          <CurrencyInput
            name={name}
            groupSeparator={groupSeparator}
            decimalSeparator={decimalSeparator}
            decimalScale={2}
            onValueChange={(amount) => {
              onChange(amount);
            }}
            value={value}
            className={`inputField ${styles.inputField} ${disable ? styles.disableInput : ' '}`}
            readOnly={!!disable}
          />
        ) : (
          <input
            className={`inputField ${styles.inputField} ${disable ? styles.disableInput : ' '}`}
            name={name}
            type="number"
            value={disable && !value ? '' : value?.toString()} // value.toString() is used to remove leading zero
            readOnly={!!disable}
            onChange={(e) => onChange(e.target.valueAsNumber)}
          />
        )}
        {isControlRequired && (
          <div className={`d-flex align-items-center d-md-block ${styles.arrowBtnContainer}`}>
            <div>
              <IncrementButton onClick={() => onChange((parseFloat(value || 0) ?? 0) + 1)} />
            </div>
            <div>
              <DecrementButton onClick={() => onChange((parseFloat(value || 0) ?? 0) - 1)} />
            </div>
          </div>
        )}
        <p className={`${styles.centimeter}`}>{unit && unit instanceof Object ? unit : t(unit)}</p>
      </div>
    </div>
  );
}

NumberControl.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  unit: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string.isRequired,
  disable: PropTypes.bool,
  isControlRequired: PropTypes.bool,
};

NumberControl.defaultProps = {
  isControlRequired: true,
  unit: null,
  label: null,
  disable: false,
};

IncrementButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

DecrementButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default NumberControl;
