import PropTypes from 'prop-types';
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { settings, languages } from './constants';
import styles from './menuList.module.scss';
import { ReactComponent as EmployeesSVG } from '../../assets/svgs/Employees.svg';
import { ReactComponent as ExitSVG } from '../../assets/svgs/Exit.svg';
import { ReactComponent as GlobeSVG } from '../../assets/svgs/Globe.svg';
import { ReactComponent as PieChartSVG } from '../../assets/svgs/PieChart.svg';
import { ReactComponent as SettingsSVG } from '../../assets/svgs/Settings.svg';
import { ReactComponent as TeamsSVG } from '../../assets/svgs/Team.svg';
import { logout } from '../../services/authorization';
import Button from '../button';
import Translate from '../translate';

function MenuList({ onSelectOption, currentUser }) {
  const navigate = useNavigate();
  const { i18n } = useTranslation('lang');

  const onLanguageChange = (value) => {
    i18n.changeLanguage(value);
  };

  const onNavigate = (link) => {
    console.log('file: MenuList.jsx:28  onNavigate  link:', link);
    onSelectOption();
    navigate(link);
  };

  const onLogout = () => {
    onSelectOption();
    logout();
  };

  return (
    <div className={`${styles.menuListContainer}`}>
      <div className={`${styles.menuContentContainer}`}>
        {currentUser?.roles?.includes('ADMIN') && (
          <>
            <Button className={`${styles.menuBtn}`} onClick={() => onNavigate('/')}>
              <span className={`${styles.menuName}`}>
                <PieChartSVG className={`${styles.menuBtnIcon}`} />
                <Translate id="components.nav_menu.summary" />
              </span>
            </Button>
            <Button className={`${styles.menuBtn}`} onClick={() => onNavigate('/')}>
              <span className={`${styles.menuName}`}>
                <EmployeesSVG className={`${styles.menuBtnIcon}`} />
                <Translate id="components.nav_menu.employees" />
              </span>
            </Button>
            <Button className={`${styles.menuBtn}`} onClick={() => onNavigate('/')}>
              <span className={`${styles.menuName}`}>
                <TeamsSVG className={`${styles.menuBtnIcon}`} />
                <Translate id="components.nav_menu.teams" />
              </span>
            </Button>
          </>
        )}
        {currentUser?.roles?.includes('COMPANY_ADMIN') && (
          <Button
            className={`${styles.menuBtn}`}
            onClick={() =>
              onNavigate(
                `/company/${currentUser.company.id}/admin/${currentUser.id}/settings/employees`
              )
            }
          >
            <span className={`${styles.menuName}`}>
              <EmployeesSVG className={`${styles.menuBtnIcon}`} />
              <Translate id="components.nav_menu.employees" />
            </span>
          </Button>
        )}
        <Accordion className={`${styles.menuAccordion}`}>
          {currentUser?.roles?.includes('ADMIN') ? (
            <Accordion.Item className={`${styles.menuAccordionItem}`} eventKey="0">
              <Accordion.Header className={`${styles.menuAccordionHeader}`}>
                <span className={`${styles.menuName}`}>
                  <SettingsSVG className={`${styles.menuAccordionIcon}`} />
                  <Translate id="components.nav_menu.settings" />
                </span>
              </Accordion.Header>
              <Accordion.Body className={`${styles.menuAccordionBody}`}>
                {settings.map(({ name, link }) => (
                  <Button
                    className={`${styles.menuAccordionBtn}`}
                    key={name}
                    onClick={() => onNavigate(link)}
                  >
                    <Translate id={name} />
                  </Button>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          ) : null}
          <Accordion.Item className={`${styles.menuAccordionItem}`} eventKey="1">
            <Accordion.Header className={`${styles.menuAccordionHeader}`}>
              <span className={`${styles.menuName}`}>
                <GlobeSVG className={`${styles.menuAccordionIcon}`} />
                <Translate id="components.nav_menu.languages" />
              </span>
            </Accordion.Header>
            <Accordion.Body className={`${styles.menuAccordionBody}`}>
              {languages.map(({ name, value }) => (
                <Button
                  className={`${styles.menuAccordionBtn} ${
                    i18n.language === value ? 'active' : ''
                  }`}
                  key={value}
                  onClick={() => onLanguageChange(value)}
                >
                  {name}
                </Button>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className={`${styles.logOutBtnContainer}`}>
        <Button
          className={`d-flex align-items-center justify-content-between ${styles.logOutBtn}`}
          onClick={onLogout}
        >
          <span className={`${styles.logOutMenu}`}>
            <Translate id="components.nav_menu.logout" />
          </span>
          <ExitSVG className={`${styles.logOutBtnIcon}`} />
        </Button>
      </div>
    </div>
  );
}

MenuList.propTypes = {
  onSelectOption: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
};

export default MenuList;
