import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import CustomCalendar from '../../calendar';

function CalendarControl({ label, selected, onChange, showTimeInput, disabled }) {
  const { t } = useTranslation('lang');

  return (
    <div
      className={`d-sm-flex align-items-sm-center justify-content-sm-between ${styles.calendarControlContainer}`}
    >
      <div className={`${styles.calendarControlLabel}`}>{t(label)}</div>
      <div className={`${styles.calendarContainer}`}>
        <CustomCalendar
          onNewValue={onChange}
          selected={selected}
          showTimeInput={showTimeInput}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

CalendarControl.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
  showTimeInput: PropTypes.bool,
  disabled: PropTypes.bool,
};

CalendarControl.defaultProps = {
  selected: new Date(),
  onChange() {},
  showTimeInput: true,
  disabled: false,
};

export default CalendarControl;
