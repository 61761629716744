import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function NumberTranslate({ value }) {
  const { i18n } = useTranslation('lang');
  return <> {Number(value).toLocaleString(i18n.language === 'en' ? 'en' : 'DE')} </>;
}

NumberTranslate.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default NumberTranslate;
