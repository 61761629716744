/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React from 'react';

function Button({ type, onClick, className, children, disabled }) {
  return (
    <button
      className={`projectBtn ${className} ${disabled ? 'grayBtn' : ''}`}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  onClick() {},
  children: null,
  className: null,
  disabled: false,
};

export default Button;
