import apiClient from './api-client';

const getOfferCalculations = (
  offerRequestId,
  offerId,
  householdDeductionRate,
  isUpgradeFromOilHeating
) =>
  apiClient.get(`v1/offer-requests/${offerRequestId}/offers/${offerId}/calculations`, {
    params: {
      householdDeductionRate,
      isUpgradeFromOilHeating,
    },
  });

const getOfferPreviewSummeryCalculation = (offerRequestId, offerId) =>
  apiClient.get(`v1/offer-requests/${offerRequestId}/offers/${offerId}/summary-calculations`);

const getOfferById = (offerRequestId, offerId) =>
  apiClient.get(`v1/offer-requests/${offerRequestId}/offers/${offerId}`);

const updateOfferById = (offerRequestId, offerId, offer) =>
  apiClient.put(`v1/offer-requests/${offerRequestId}/offers/${offerId}`, offer);

export const declineOffer = (offerRequestId, offerId) =>
  apiClient.put(`v1/offer-requests/${offerRequestId}/offers/${offerId}/decline`);

export const sendOfferToCustomer = (offerRequestId, offerId) =>
  apiClient.put(`v1/offer-requests/${offerRequestId}/offers/${offerId}/send`);

const saveEstimatedDeliveryTime = (offerRequestId, offerId, offer) =>
  apiClient.put(
    `v1/offer-requests/${offerRequestId}/offers/${offerId}/task/estimating-time`,
    offer
  );

const getBasicDetailsByOfferId = (offerRequestId, offerId) =>
  apiClient.get(`/v1/offer-requests/${offerRequestId}/offers/${offerId}/basic-details`);

export default {
  getOfferCalculations,
  getOfferById,
  updateOfferById,
  getOfferPreviewSummeryCalculation,
  saveEstimatedDeliveryTime,
  getBasicDetailsByOfferId,
};
