import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'sonner';

import MenuList from './MenuList';
import styles from './index.module.scss';
import { ReactComponent as ArrowDownSVG } from '../../assets/svgs/ArrowDown.svg';
import { ReactComponent as ArrowUPSVG } from '../../assets/svgs/ArrowUp.svg';
import { ReactComponent as CloseSVG } from '../../assets/svgs/Close.svg';
import { ReactComponent as HamburgerSVG } from '../../assets/svgs/Hamburger.svg';
import { ReactComponent as UserSettingsSVG } from '../../assets/svgs/UserSettings.svg';
import AuthService from '../../services/auth';
import { getOriginalErrors } from '../../utils/errorHandler';
import Button from '../button';
import Translate from '../translate';

function NavMenu({ links }) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const location = useLocation();
  const onMenuToggle = () => setToggleMenu((prev) => !prev);

  const fetchCurrentUser = async () => {
    try {
      setCurrentUser(await AuthService.getCurrentUser());
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const getTaskLink = (className) => {
    let to = '/task';

    if (currentUser?.roles?.includes('COMPANY_ADMIN')) {
      to = `/company/${currentUser?.company?.id}/admin/${currentUser?.id}/assignments`;
    }

    if (currentUser?.roles?.includes('COMPANY_EMPLOYEE')) {
      to = `/company/${currentUser?.company?.id}/employee/${currentUser?.id}/tasks`;
    }

    return (
      <Link
        className={`${className} ${
          location.pathname.includes('/task') || location.pathname.includes('/assignments')
            ? styles.active
            : ''
        }`}
        to={to}
      >
        <Translate id="components.navbar.tasks" />
      </Link>
    );
  };

  let nameToDisplay = '';
  if (currentUser) {
    if (currentUser.roles[0]?.includes('COMPANY_ADMIN')) {
      nameToDisplay = 'Admin';
    } else {
      nameToDisplay = `${currentUser.firstName} ${currentUser.lastName}`;
    }
  }

  useEffect(() => {
    fetchCurrentUser();
    // FIXME: Use links props in this component
    console.log({ links });
  }, []);

  return (
    <div className={`${styles.navMenuContainer}`}>
      <div className={`d-md-none ${styles.openCloseContainer}`}>
        {toggleMenu ? (
          <Button onClick={onMenuToggle}>
            <CloseSVG className={`${styles.openCloseIcon}`} />
          </Button>
        ) : (
          <Button onClick={onMenuToggle}>
            <HamburgerSVG className={`${styles.openCloseIcon}`} />
          </Button>
        )}
      </div>
      <div className={`d-none d-md-block ${styles.headerMainContent}`}>
        <div className={`d-flex align-items-center ${styles.headerContent}`}>
          <div className={`${styles.contentLinkContainer}`}>
            {currentUser?.roles?.includes('ADMIN') ||
            currentUser?.roles?.includes('SALES_PERSON') ? (
              <Link
                className={`${styles.contentLink} ${
                  location.pathname === '/' || !location.pathname.includes('/task')
                    ? styles.active
                    : ''
                }`}
                to="/"
              >
                <Translate id="components.navbar.offers" />
              </Link>
            ) : null}
            {getTaskLink(styles.contentLink)}
          </div>
          <div className={`${styles.dropDownContainer}`}>
            <Button className={`${styles.dropDownBtn}`} onClick={onMenuToggle}>
              <UserSettingsSVG className={`${styles.dropDownBtnIcon}`} />
              {nameToDisplay}
              {toggleMenu ? (
                <ArrowUPSVG className={`${styles.arrowIcon}`} />
              ) : (
                <ArrowDownSVG className={`${styles.arrowIcon}`} />
              )}
            </Button>
          </div>
        </div>
      </div>
      {toggleMenu ? (
        <div className={`${styles.menuListMainContainer}`}>
          <div className={`d-md-none ${styles.menuTitleContainer}`}>
            {currentUser?.roles?.includes('ADMIN') ||
            currentUser?.roles?.includes('SALES_PERSON') ? (
              <Link
                className={`${styles.menuTitleLink} ${
                  location.pathname === '/' || !location.pathname.includes('/task')
                    ? styles.active
                    : ''
                }`}
                to="/"
              >
                <Translate id="components.navbar.offers" />
              </Link>
            ) : null}
            {getTaskLink(styles.menuTitleLink)}
          </div>
          <div className={`${styles.menuList}`}>
            <MenuList onSelectOption={onMenuToggle} currentUser={currentUser} />
          </div>
        </div>
      ) : null}
    </div>
  );
}

NavMenu.propTypes = {
  links: PropTypes.instanceOf(Object),
};

NavMenu.defaultProps = {
  links: [
    {
      to: '/',
      translationId: 'components.navbar.offers',
    },
    {
      to: '/task',
      translationId: 'components.navbar.tasks',
    },
  ],
};

export default NavMenu;
