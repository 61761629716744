import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

import { attachmentableTypes } from '../../../constant';
import { uploadAttachments } from '../../../services/attachment';
import { updateOfferProducts } from '../../../services/offerProducts';
import { convertBase64ToFile } from '../../../utils/common';
import { getOriginalErrors } from '../../../utils/errorHandler';

function useSaveProduct(offerRequestId, offerId) {
  const { t } = useTranslation('lang');

  const saveProducts = async (allSelectedProducts) => {
    const productsToAdd = allSelectedProducts.map((product) => {
      const { attachments, ...rest } = product;
      return rest;
    });

    const {
      data: {
        data: { components: updatedProducts },
      },
    } = await updateOfferProducts({
      offerRequestId,
      offerId,
      offerComponents: productsToAdd,
    });

    updatedProducts.map(async (updatedProduct) => {
      const product = allSelectedProducts.find((d) => d.netvisorId === updatedProduct.netvisorId);
      if (product) {
        const { attachments } = product;
        if (Array.isArray(attachments) && attachments.length > 0) {
          const productAttachments = [];

          attachments.forEach((attachment) => {
            const attachmentFile = convertBase64ToFile(
              attachment.$t,
              attachment.name,
              false,
              attachment.mimeType
            );

            productAttachments.push(attachmentFile);
          });

          await uploadAttachments(
            attachmentableTypes.COMPONENT,
            updatedProduct.id,
            productAttachments
          );
        }
      }
    });
  };

  const onProductSave = async (selectedProducts) => {
    try {
      if (selectedProducts.length === 0) {
        await updateOfferProducts({
          offerRequestId,
          offerId,
          offerComponents: [],
        });
      } else {
        for (let index = 0; index < selectedProducts.length; index += 1) {
          if (selectedProducts[index].amount === 0) {
            throw new Error(
              selectedProducts[index].name + t('offer.products.can_not_have_amount_zero')
            );
          }
        }
        await saveProducts(selectedProducts);
      }
      toast.success(t('offer.products.products_updated_to_offer'));
      return true;
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
      return false;
    }
  };

  return { onProductSave };
}

export default useSaveProduct;
