import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './index.module.scss';
import Button from '../../../components/button';
import TaskPageContentWrapper from '../../../components/task-wrappers/task-page-content-wrapper';
import Translate from '../../../components/translate';
import { heatingDeviceOrderedFrom } from '../../../constant';
import heatingDevicesService from '../../../services/heatingDevices';
import { getProductsByOffer } from '../../../services/offerProducts';
import { getOfferServices } from '../../../services/offerService';
import { getOriginalErrors } from '../../../utils/errorHandler';

export function HeatingDevice({ onOrderClick, device, cardOnly }) {
  return (
    <div
      className={`${styles.deviceContainer} ${styles.activeItems} ${
        device.orderedAt ? `${styles.disabledItems}` : ''
      }`}
    >
      <div
        className={`d-flex align-items-center justify-content-between ${styles.deviceItemsContainer} `}
      >
        <div>
          <p className={`${styles.deviceItemsText}`}>{device.name}</p>
        </div>
        <div>
          {device.image && <img className="imgFluid" src={device.image} alt={device.name} />}
        </div>
      </div>
      {!cardOnly ? (
        <div className={`${styles.btnContainer}`}>
          <Button
            className={`${styles.deviceBtn} ${
              device.orderedFrom === heatingDeviceOrderedFrom.MANUFACTURER ? styles.selectedBtn : ''
            }`}
            disabled={device.orderedAt}
            onClick={() => onOrderClick(device.id, heatingDeviceOrderedFrom.MANUFACTURER)}
          >
            <Translate id="task.heating_devices.order_item_from_manufacturing" />
          </Button>
          <Button
            className={`${styles.deviceBtn} ${
              device.orderedFrom === heatingDeviceOrderedFrom.WAREHOUSE ? styles.selectedBtn : ''
            }`}
            disabled={device.orderedAt}
            onClick={() => onOrderClick(device.id, heatingDeviceOrderedFrom.WAREHOUSE)}
          >
            <Translate id="task.heating_devices.use_inventory_from_warehouse" />
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export function Product({ product }) {
  return (
    <div className={`${styles.product}`}>
      <Translate id="common.id" /> : {product.netvisorId} - <span>{product.name}</span>
    </div>
  );
}

export function Service({ service }) {
  return (
    <div className={`${styles.product}`}>
      <Translate id="common.id" /> : {service.netvisorId} - <span>{service.name}</span>
    </div>
  );
}

function HeatingDevicesFooter({ onButtonClick }) {
  return (
    <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onButtonClick}>
      <Translate id="components.button.continue" />
    </Button>
  );
}

function HeatingDevices({ setLoading }) {
  const { t } = useTranslation('lang');
  const navigate = useNavigate();
  const { offerRequestId, offerId } = useParams();

  const [heatingDevices, setHeatingDevices] = useState([]);
  const [products, setProducts] = useState([]);
  const params = useParams();
  const [services, setServices] = useState([]);

  const fetchHeatingDevices = async () => {
    try {
      const {
        data: { data },
      } = await heatingDevicesService.getOfferHeatingDevices({
        requestId: offerRequestId,
        offerId,
      });
      setHeatingDevices(data.heatingDevices);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const getOfferProducts = async () => {
    try {
      const {
        data: {
          data: { components },
        },
      } = await getProductsByOffer(params.offerRequestId, params.offerId);
      setProducts(components);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchServices = async () => {
    try {
      const {
        data: { data },
      } = await getOfferServices(offerRequestId, offerId);
      setServices(data.offerServices);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await fetchHeatingDevices();
      await getOfferProducts();
      await fetchServices();
      setLoading(false);
    };
    load();
  }, []);

  const onClickNext = () =>
    navigate(
      `/offer-requests/${params.offerRequestId}/offers/${params.offerId}/tasks/drilling-crew`
    );

  const onOrderClick = async (heatingDeviceId, orderedFrom) => {
    try {
      setLoading(true);
      await heatingDevicesService.order(offerRequestId, offerId, heatingDeviceId, orderedFrom);
      if (orderedFrom === heatingDeviceOrderedFrom.WAREHOUSE) {
        toast.success(t('task.heating_devices.use_from_inventory_success_message'));
      } else {
        toast.success(t('task.heating_devices.order_from_manufacturer_success_message'));
      }
      setLoading(false);
      fetchHeatingDevices();
    } catch (err) {
      setLoading(false);
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const customFooter = () => <HeatingDevicesFooter onButtonClick={onClickNext} />;
  return (
    <TaskPageContentWrapper customFooter={customFooter}>
      <div>
        <div className={`d-none d-md-block ${styles.heatingDesktopTitle}`}>
          {t('task.heating_devices.title')}
        </div>
        <div className={`${styles.heatingDeviceContainer}`}>
          <div>
            <p className={`${styles.heatingTitle}`}>
              <Translate id="task.heating_devices.heating_device" />
            </p>
          </div>
          <div className={`${styles.heatingDeviceContent}`}>
            {heatingDevices.map((device) => (
              <HeatingDevice key={device.id} device={device} onOrderClick={onOrderClick} />
            ))}
          </div>
          <div className={`${styles.productContainer}`}>
            <div className={`${styles.productTitleContainer}`}>
              <p>
                <Translate id="task.heating_devices.products" />
              </p>
            </div>
            <div className={`${styles.productDeviceContent}`}>
              {products.length > 0 && products.map((product) => <Product product={product} />)}
            </div>
          </div>
          <div className={`${styles.productContainer}`}>
            <div className={`${styles.productTitleContainer}`}>
              <p>
                <Translate id="task.heating_devices.services" />
              </p>
            </div>
            <div className={`${styles.productDeviceContent}`}>
              {services.map((service) => (
                <Service key={service.id} service={service} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </TaskPageContentWrapper>
  );
}

HeatingDevicesFooter.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
};

HeatingDevice.propTypes = {
  onOrderClick: PropTypes.func.isRequired,
  device: PropTypes.instanceOf(Object).isRequired,
  cardOnly: PropTypes.bool,
};

HeatingDevice.defaultProps = {
  cardOnly: false,
};

Product.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

Service.propTypes = {
  service: PropTypes.instanceOf(Object).isRequired,
};

HeatingDevices.propTypes = {
  setLoading: PropTypes.func.isRequired,
};

export default HeatingDevices;
