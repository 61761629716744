import PropTypes from 'prop-types'
import React from 'react'

import Translate from '../../../../components/translate'
import TeamCard from '../team-card'

function TeamList({ teams }) {
  return (
    <div>
      <div>
        <div>
          <Translate id="team.table.header.team_name" />
          <div />
        </div>
        <div>
          <Translate id="team.table.header.company_name" />
          <div />
        </div>
        <div>
          <Translate id="team.table.header.employee_assigned" />
          <div />
        </div>
        <div>
          <Translate id="team.table.header.assigned_teams" />
        </div>
      </div>
      <div>
        {teams.map((team) => (
          <TeamCard key={team.id} team={team} />
        ))}
      </div>
    </div>
  )
}

TeamList.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
}

export default TeamList
