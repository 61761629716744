import React from 'react';

import styles from './uploadSignature.module.scss';
import UploadFiles from '../uploadFiles';

function UploadSignature({ signatureFiles, setSignatureFiles }) {
  return (
    <div className={`${styles.uploadSignatureContainer}`}>
      <UploadFiles files={signatureFiles} setFiles={setSignatureFiles} />
    </div>
  );
}

export default UploadSignature;
