import apiClient from './api-client';

export const getOfferProducts = (params) => apiClient.get('/v2/products/', { params });

export const getProductsDetails = (netvisorId, params) =>
  apiClient.get(`/v2/products/${netvisorId}`, { params });

export const addProductsToOffer = ({ offerRequestId, offerId, offerComponents }) =>
  apiClient.post(`/v1/offer-requests/${offerRequestId}/offers/${offerId}/components/`, {
    offerComponents,
  });

export const getProductsByOffer = (offerRequestId, offerId) =>
  apiClient.get(`/v1/offer-requests/${offerRequestId}/offers/${offerId}/components`);

export const getProductsCalculationsByOffer = (offerRequestId, offerId) =>
  apiClient.get(`/v1/offer-requests/${offerRequestId}/offers/${offerId}/components/calculations`);


export const updateOfferProducts = ({ offerRequestId, offerId, offerComponents }) =>
  apiClient.put(
    `/v1/offer-requests/${offerRequestId}/offers/${offerId}/components/`,
    offerComponents
  );
