import * as moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './invoicePreview.module.scss';
import DesktopFooterIcon from '../../../assets/images/footerLogo.png';
import { ReactComponent as WebSvg } from '../../../assets/svgs/Web.svg';
import Button from '../../../components/button';
import NumberTranslate from '../../../components/number-translate';
import TaskPageContentWrapper from '../../../components/task-wrappers/task-page-content-wrapper';
import Translate from '../../../components/translate';
import { getCustomer } from '../../../services/customer';
import { getCurrentInvoice, sendToNetvisor } from '../../../services/invoice';
import offerRequestService from '../../../services/offerRequest';
import { translateNumber } from '../../../utils/common';
import { getOriginalErrors } from '../../../utils/errorHandler';

function InvoicePreviewFooter({ onButtonClick, disabled }) {
  return (
    <Button
      className={`projectBtn offerTabFooterBtn ${!disabled ? 'offerTabGreenBtn' : ''}`}
      onClick={onButtonClick}
      disabled={disabled}
    >
      <Translate id="task.invoice_preview.send_to_netvisor" />
    </Button>
  );
}

function CompanyDetails({ companyDetails }) {
  const { t } = useTranslation('lang');
  return (
    <div className={`${styles.headerContainer}`}>
      <div className={`${styles.logoContainer}`}>
        <img className={`${styles.logoImg}`} src={DesktopFooterIcon} alt={t('Lannen Logo')} />
      </div>
      <div
        className={`d-flex align-items-center justify-content-between ${styles.detailsContainer}`}
      >
        <div>
          <p className={`${styles.boldText}`}>{companyDetails.name}</p>
          <p>{companyDetails.address}</p>
        </div>
        <div>
          <p>
            Date:
            <span className={`${styles.boldText}`}> {moment().format('DD MMMM yyyy')}</span>
          </p>
          {/* TODO: Need to check which invoice number to be display */}
          {/* <p>
            Invoice Number:<span className={`${styles.boldText}`}> #123456</span>
          </p> */}
        </div>
      </div>
    </div>
  );
}

function FormattedDetails({ title, customerInformation, offerRequest }) {
  const [address, setAddress] = useState('');

  useEffect(() => {
    if (offerRequest && offerRequest.id) {
      setAddress(
        `${offerRequest.installationTargetAddress} ${offerRequest.installationTargetZipCode} ${offerRequest.installationTargetCity}`
      );
    } else {
      setAddress(
        `${customerInformation.address} ${customerInformation.zipCode} ${customerInformation.city}`
      );
    }
  }, [customerInformation, offerRequest]);
  return (
    <div className={`${styles.billContainer}`}>
      <div>
        <p>{title}</p>
      </div>
      <div>
        <p>{`${customerInformation.firstName} ${customerInformation.lastName}`}</p>
        <p>{address}</p>
      </div>
    </div>
  );
}

function CustomerInformation({ offerRequest, customerInformation }) {
  const { t } = useTranslation('lang');

  return (
    <div className={`d-flex justify-content-between ${styles.billFlexContainer}`}>
      <div>
        <FormattedDetails
          title={t('task.invoice_preview.bill_to')}
          customerInformation={customerInformation}
        />
      </div>
      <div>
        <FormattedDetails
          title={t('task.invoice_preview.ship_to')}
          offerRequest={offerRequest}
          customerInformation={customerInformation}
        />
      </div>
    </div>
  );
}

function ItemsTable({ invoice }) {
  const { t, i18n } = useTranslation('lang');

  const [tableItems, setTableItems] = useState([]);
  const [total, setTotal] = useState(0);

  const tableHeaders = [
    t('task.invoice_preview.table.name'),
    t('task.invoice_preview.table.unit_price'),
    t('task.invoice_preview.table.amount'),
    t('task.invoice_preview.table.total'),
  ];

  useEffect(() => {
    setTotal(0);
    if (invoice && invoice.id) {
      setTableItems([
        ...invoice.heatingDevices.map((device) => {
          setTotal((prevTotal) =>
            Number(Number(prevTotal + device.sellingPriceInclusiveVat).toFixed(2))
          );
          return {
            id: device.id,
            [t('task.invoice_preview.table.name')]: device.name,
            [t('task.invoice_preview.table.unit_price')]: `${translateNumber(
              Number(Number(device.sellingPriceInclusiveVat / device.quantity).toFixed(2)),
              i18n.language
            )} ${t('units.euro')}`,
            [t('task.invoice_preview.table.amount')]: device.quantity,
            [t('task.invoice_preview.table.total')]: `${translateNumber(
              device.sellingPriceInclusiveVat,
              i18n.language
            )} ${t('units.euro')}`,
          };
        }),
        ...invoice.products.map((product) => {
          setTotal((prevTotal) =>
            Number(Number(prevTotal + product.sellingPriceInclusiveVat).toFixed(2))
          );
          return {
            id: product.id,
            [t('task.invoice_preview.table.name')]: `ID: ${product.netvisorId} - ${product.name}`,
            [t('task.invoice_preview.table.unit_price')]: `${translateNumber(
              Number(Number(product.sellingPriceInclusiveVat / product.amount).toFixed(2)),
              i18n.language
            )} ${t('units.euro')}/${product.unit}`,
            [t('task.invoice_preview.table.amount')]: `${product.amount} ${product.unit}`,
            [t('task.invoice_preview.table.total')]: `${translateNumber(
              product.sellingPriceInclusiveVat,
              i18n.language
            )} ${t('units.euro')}`,
          };
        }),
        ...invoice.services.map((service) => {
          setTotal((prevTotal) =>
            Number(Number(prevTotal + service.sellingPriceInclusiveVat).toFixed(2))
          );
          return {
            id: service.id,
            [t('task.invoice_preview.table.name')]: `ID: ${service.netvisorId} - ${service.name}`,
            [t('task.invoice_preview.table.unit_price')]: `${translateNumber(
              Number(Number(service.sellingPriceInclusiveVat / service.quantity).toFixed(2)),
              i18n.language
            )} ${t('units.euro')}`,
            [t('task.invoice_preview.table.amount')]: service.quantity,
            [t('task.invoice_preview.table.total')]: `${translateNumber(
              service.sellingPriceInclusiveVat,
              i18n.language
            )} ${t('units.euro')}`,
          };
        }),
      ]);
    }
  }, [invoice, i18n.language]);

  return (
    tableItems &&
    tableItems.length > 0 && (
      <div className={`${styles.billContentContainer}`}>
        <div className={`${styles.tableContainer}`}>
          <div className={`d-flex ${styles.tableFlexContainer} ${styles.tableFlexHeader}`}>
            {tableHeaders.map((header) => (
              <div>
                <div key={header}>{header}</div>
              </div>
            ))}
          </div>
          <div>
            {tableItems.map((item) => (
              <div
                className={`d-flex ${styles.tableFlexContainer} ${styles.tableFlexData}`}
                key={item.id}
              >
                {tableHeaders.map((header) => (
                  <div>
                    <div key={`${item.id}_${header}`}>{item[header]}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className={`${styles.totalContainer}`}>
          <p>
            <span>{t('task.invoice_preview.table.total')}</span> <NumberTranslate value={total} /> €
          </p>
        </div>
      </div>
    )
  );
}

function InvoicePreview({ setLoading }) {
  const { t } = useTranslation('lang');
  const params = useParams();

  const [offerRequest, setOfferRequest] = useState({});
  const [customerInformation, setCustomerInformation] = useState({});
  const [isNextEnabled, setIsNextEnabled] = useState(true);

  // FIXME: company details should be dynamically loaded
  const companyDetails = {
    name: 'Lännen Maalämpö Oy',
    address: 'Puurtajantie 18, 60100 Seinäjoki',
  };

  const [invoice, setInvoice] = useState({});

  const fetchCustomerInformation = async (customerId) => {
    try {
      const {
        data: {
          data: { customer: customerResponse },
        },
      } = await getCustomer(customerId);
      setCustomerInformation(customerResponse);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchCurrentInvoice = async () => {
    try {
      const {
        data: {
          data: { invoice: currentInvoice },
        },
      } = await getCurrentInvoice(params.offerRequestId, params.offerId);
      setInvoice(currentInvoice);
      setIsNextEnabled(currentInvoice && currentInvoice.id);
    } catch (err) {
      setIsNextEnabled(false);
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchOfferRequest = async () => {
    try {
      setLoading(true);
      const {
        data: {
          data: { offerRequest: request },
        },
      } = await offerRequestService.getOfferRequestById(params.offerRequestId);
      setOfferRequest(request);
      await fetchCustomerInformation(request.customerId);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchData = async () => {
    try {
      await fetchCurrentInvoice();
      await fetchOfferRequest();
      setLoading(false);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSendToNetvisor = async () => {
    setLoading(true);
    try {
      setLoading(true);
      await sendToNetvisor(params.offerRequestId, params.offerId);
      toast.success(t('task.invoice_preview.sent_to_netvisor'));
      setIsNextEnabled(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
      setLoading(false);
    }
  };

  const customFooter = () => (
    <InvoicePreviewFooter onButtonClick={onSendToNetvisor} disabled={!isNextEnabled} />
  );

  return (
    <TaskPageContentWrapper customFooter={customFooter}>
      <div className={`${styles.billParentContainer}`}>
        <div className={`d-none d-md-block ${styles.titleContainer}`}>
          {invoice && invoice.id
            ? t('task.invoice_preview.title')
            : t('task.invoice_preview.no_invoice')}
        </div>
        {invoice && invoice.id && (
          <div className={`d-flex flex-column ${styles.billMainContainer}`}>
            <div className={`d-none d-md-block ${styles.titleContainer}`}>
              <Translate id="task.invoice_preview.title" />
            </div>
            <div>
              <CompanyDetails companyDetails={companyDetails} />
            </div>
            <div>
              <CustomerInformation
                offerRequest={offerRequest}
                customerInformation={customerInformation}
              />
            </div>
            <div>
              <ItemsTable invoice={invoice} />
            </div>
            <div
              className={`d-flex align-items-center justify-content-end ${styles.billFooterContainer}`}
            >
              <div>
                <WebSvg />
              </div>
              <div>
                <p>{t('lannenmaalampo.fi')}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </TaskPageContentWrapper>
  );
}

InvoicePreviewFooter.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

FormattedDetails.propTypes = {
  title: PropTypes.string.isRequired,
  customerInformation: PropTypes.instanceOf(Object).isRequired,
  offerRequest: PropTypes.instanceOf(Object).isRequired,
};

ItemsTable.propTypes = {
  invoice: PropTypes.instanceOf(Object).isRequired,
};

InvoicePreview.propTypes = {
  setLoading: PropTypes.func.isRequired,
};

CustomerInformation.propTypes = {
  customerInformation: PropTypes.instanceOf(Object).isRequired,
  offerRequest: PropTypes.instanceOf(Object).isRequired,
};

CompanyDetails.propTypes = {
  companyDetails: PropTypes.instanceOf(Object).isRequired,
};

export default InvoicePreview;
