import apiClient from "./api-client";

const setCustomerSessionInfo = (userDetails) => window.sessionStorage.setItem("userDetails", JSON.stringify(userDetails))

const getCustomerSessionInfo = () => JSON.parse(window.sessionStorage.getItem("userDetails")) || {}

const saveCustomerInfo = (userDetails) =>  apiClient.post('/v1/customers', userDetails)

export const getCustomer = (customerId) =>  apiClient.get(`/v1/customers/${customerId}`)

export default {
  saveCustomerInfo,
  setCustomerSessionInfo,
  getCustomerSessionInfo
}

