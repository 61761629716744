/* eslint-disable react/no-array-index-key */
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './basicInformation.module.scss';
import {
  constructionMethods,
  installationTypes,
  offerRequestTypes,
  currentHeatingSystems,
} from '../../../constant';

function FormField({ field }) {
  const { t } = useTranslation('lang');
  const getUnit = () => {
    if (!field.unit) return null;
    if (field.unit === 'units.m') {
      return (
        <span>
          {t(field.unit)}
          <sup>2</sup>
        </span>
      );
    }
    return t(field.unit);
  };
  return field.value ? (
    <div
      className={`d-flex flex-wrap align-items-start justify-content-between ${styles.flexContainer}`}
    >
      <div>{t(field.label)} : </div>{' '}
      <div>
        {field.options ? t(field.options[field.value]) : field.value} {getUnit()}
      </div>
    </div>
  ) : null;
}

function BasicInformation({ basicInformation }) {
  const [form, setForm] = useState([]);
  const { t } = useTranslation('lang');

  useEffect(() => {
    if (basicInformation) {
      setForm([
        {
          name: 'HeatingAreaInput',
          label: 'offer.onsite_memo.heating_area',
          value: basicInformation.heatingArea,
          unit: 'units.m',
        },
        {
          name: 'InstallationTypeInput',
          label: 'offer.onsite_memo.basic_information.installation_type.title',
          value: basicInformation.installationType,
          options: installationTypes,
        },
        {
          name: 'RenovationTimeInput',
          label: 'offer.onsite_memo.renovation_time',
          value: basicInformation.renovationTime
            ? moment(basicInformation.renovationTime).format('DD-MM-YYYY')
            : 'N/A',
        },
        {
          name: 'ConstructionTypeInput',
          label: 'offer.onsite_memo.basic_information.construction_type.title',
          value: basicInformation.constructionType,
          options: constructionMethods,
        },
        {
          name: 'CurrentHeatingSystemInput',
          label: 'offer.onsite_memo.current_heating_system',
          value: basicInformation.currentHeatingSystem,
          options: currentHeatingSystems,
        },
        {
          name: 'CurrentConsumptionInput',
          label: 'offer.onsite_memo.current_consumption',
          value: basicInformation.currentConsumption,
        },
        {
          name: 'RequestedHeatingType',
          label: 'offer.onsite_memo.requested_heating_type',
          value: basicInformation.requestedHeatingType,
          options: offerRequestTypes,
        },
        {
          name: 'InstallSpaceHeightInput',
          label: 'offer.onsite_memo.install_space_height',
          value: basicInformation.installSpaceHeight,
          unit: 'units.cm',
        },
        {
          name: 'InstallSpaceWidthInput',
          label: 'offer.onsite_memo.install_space_width',
          value: basicInformation.installSpaceWidth,
          unit: 'units.cm',
        },
        {
          name: 'InstallSpaceDepthInput',
          label: 'offer.onsite_memo.install_space_depth',
          value: basicInformation.installSpaceDepth,
          unit: 'units.cm',
        },
        {
          name: 'SmallestOpeningInput',
          label: 'offer.onsite_memo.smallest_opening',
          value: basicInformation.smallestOpening,
          unit: 'units.cm',
        },
        {
          name: 'CommentTextarea',
          label: 'offer.onsite_memo.additional_comments_title',
          value: basicInformation.comment,
        },
      ]);
    }
  }, [basicInformation]);
  return basicInformation ? (
    <div className={`${styles.basicDetailsFormContainer}`}>
      <h2 className={`${styles.basicDetailsFormTitle}`}>
        {t('offer.onsite_memo.basic_information.title')}
      </h2>
      {form.map((field, index) => (
        <FormField key={index} field={field} />
      ))}
    </div>
  ) : null;
}

export default BasicInformation;
