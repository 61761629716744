import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './attachmentList.module.scss';
import { ReactComponent as AttachmentSvg } from '../../assets/svgs/Attachment.svg';

function AttachmentList({ attachments, onDownloadClick }) {
  const { t } = useTranslation('lang');

  return (
    <div className={`${styles.attachmentMainContainer}`}>
      <div className={`d-flex align-items-center ${styles.flexContainer}`}>
        <div>
          <AttachmentSvg />
        </div>
        <div>{t('components.attachment_list.title')}</div>
      </div>
      <div className={`${styles.attachmentContainer}`}>
        {attachments.map((attachment) => (
          <div
            key={attachment.id}
            onClick={() => onDownloadClick(attachment)}
            role="button"
            tabIndex="0"
            onKeyUp={(e) => (e.key === 'Enter' ? onDownloadClick(attachment) : '')}
          >
            <p>{attachment.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

AttachmentList.propTypes = {
  attachments: PropTypes.instanceOf(Array).isRequired,
  onDownloadClick: PropTypes.func.isRequired,
};

export default AttachmentList;
