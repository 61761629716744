const TEST_EFFORT_TARGETS = [
  {
    id: 1,
    label: 'floor_heating_test_report.test_effort_target.options.underfloor_heating_piping',
    value: 'UNDERFLOOR_HEATING_PIPING',
  },
  {
    id: 2,
    label: 'floor_heating_test_report.test_effort_target.options.radiator_piping',
    value: 'RADIATOR_PIPING',
  },
  {
    id: 3,
    label: 'floor_heating_test_report.test_effort_target.options.hot_water_piping',
    value: 'HOT_WATER_PIPING',
  },
];

const TRIAL_EFFORT_WAYS = [
  {
    id: 1,
    label: 'floor_heating_test_report.trial_effort_way.options.air',
    value: 'AIR',
  },
  {
    id: 2,
    label: 'floor_heating_test_report.trial_effort_way.options.water',
    value: 'WATER',
  },
];

export { TEST_EFFORT_TARGETS, TRIAL_EFFORT_WAYS };
