import PropTypes from 'prop-types';
import React from 'react';

import Translate from '../../translate';

function CheckBoxControl({ translationId, id, className, isChecked, disabled, onChange }) {
  return (
    <div>
      <label className="checkBoxContainer" htmlFor={id}>
        <input
          id={id}
          type="checkbox"
          name={id}
          checked={isChecked}
          disabled={disabled}
          onChange={(e) => onChange(e.target.checked)}
        />
        <Translate id={translationId} />
        <span className={`checkIcon ${className}`} />
      </label>
    </div>
  );
}

CheckBoxControl.propTypes = {
  id: PropTypes.string.isRequired,
  translationId: PropTypes.string.isRequired,
  className: PropTypes.string,
  isChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

CheckBoxControl.defaultProps = {
  className: ' ',
  isChecked: false,
  disabled: false,
  onChange: () => {},
};

export default CheckBoxControl;
