import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import UserItem from './UserItem';
import styles from './index.module.scss';
import { ReactComponent as TrashIcon } from '../../../assets/svgs/trash.svg';
import Button from '../../../components/button';
import CompanyAdminContentWrapper from '../../../components/company-admin-content-wrapper';
import SearchBox from '../../../components/search-box';
import Translate from '../../../components/translate';
import {
  getCompanyEmployees,
  getEmployeesByAssignment,
  assignEmployeesOnAssignment,
} from '../../../services/employee';
import { getOriginalErrors } from '../../../utils/errorHandler';

function SelectedUserItem({ onRemove, children }) {
  return (
    <div className={`d-flex align-items-center ${styles.workerBtnContainer}`}>
      <div>{children}</div>
      <button
        className={`projectBtn d-flex align-items-center ${styles.removeBtn}`}
        type="button"
        onClick={onRemove}
      >
        <div>
          <TrashIcon />
        </div>
        <div>
          <Translate id="components.button.remove" />
        </div>
      </button>
    </div>
  );
}

function CustomFooter({ isFormDirty, onSave }) {
  return (
    <Button className={isFormDirty ? 'greenBtn' : ''} disabled={!isFormDirty} onClick={onSave}>
      <Translate id="components.button.save" />
    </Button>
  );
}

function AssignWorkerOnTask({ setLoading }) {
  const { t } = useTranslation('lang');
  const { companyId, assignmentId: offerServiceId } = useParams();

  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [query, setQuery] = useState('');
  const [oldSelectedEmployees, setOldSelectedEmployees] = useState([]);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const fetchEmployees = async (searchQuery) => {
    try {
      const {
        data: { data },
      } = await getCompanyEmployees(companyId, searchQuery);
      setEmployees(data.employees);
    } catch (error) {
      setEmployees([]);
    }
  };

  const fetchAssignedEmployees = async () => {
    try {
      const {
        data: { data },
      } = await getEmployeesByAssignment(companyId, offerServiceId);
      setSelectedEmployees(data.employees);
      setOldSelectedEmployees(data.employees);
    } catch (error) {
      setSelectedEmployees([]);
    }
  };

  const onSelectWorker = (id) => {
    const employeeAlreadySelected = selectedEmployees.findIndex((employee) => employee.id === id);
    if (employeeAlreadySelected === -1) {
      const employeeToBeAdded = employees.find(({ id: userId }) => userId === id);
      if (employeeToBeAdded) {
        setSelectedEmployees((prev) => [...prev, { ...employeeToBeAdded }]);
      }
    }
  };
  const onRemoveWorker = (id) =>
    setSelectedEmployees((prev) => prev.filter((selectedEmployee) => selectedEmployee.id !== id));

  const isEmployeeSelected = (id) => selectedEmployees.some((employee) => employee.id === id);

  const debouncedSearch = debounce((newValue) => {
    setQuery(newValue);
    fetchEmployees(newValue);
  }, 1000);

  const onSave = async () => {
    try {
      await assignEmployeesOnAssignment(
        companyId,
        offerServiceId,
        selectedEmployees.map(({ id }) => id)
      );
      await fetchAssignedEmployees();
      toast.success(t('company_admin.assign_worker.assignment_successful'));
      setIsFormDirty(false);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const formatUserName = (firstName, lastName) => `${firstName} ${lastName || ''}`;

  const employeeList = employees.map(({ id, firstName, lastName }) => ({
    id,
    name: formatUserName(firstName, lastName),
  }));

  const selectedEmployeeList = selectedEmployees.map(({ id, firstName, lastName }) => ({
    id,
    name: formatUserName(firstName, lastName),
  }));

  const customFooter = () => <CustomFooter isFormDirty={isFormDirty} onSave={onSave} />;

  useEffect(() => {
    const fetchAllData = async () => {
      if (companyId) {
        await Promise.all([fetchEmployees(), fetchAssignedEmployees()]);
      }
    };

    fetchAllData();
  }, [companyId, offerServiceId]);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (JSON.stringify(selectedEmployees) === JSON.stringify(oldSelectedEmployees)) {
      setIsFormDirty(false);
    } else {
      setIsFormDirty(true);
    }
  }, [JSON.stringify(selectedEmployees)]);

  return (
    <CompanyAdminContentWrapper customFooter={customFooter}>
      <div className={`${styles.assignWorkerPage}`}>
        <div className={`d-sm-flex align-items-center ${styles.assignHeader}`}>
          <div className={`${styles.headerTitleContainer}`}>
            <h2 className={`${styles.headerTitle}`}>
              <Translate id="company_admin.assign_worker.assign_workers" />
            </h2>
          </div>
          <div>
            <SearchBox value={query} onChange={debouncedSearch} />
          </div>
          <div>
            <p>
              <Translate id="company_admin.assign_worker.showing" />
              {`${employeeList.length} / ${employeeList.length}`}
            </p>
          </div>
        </div>

        <div className={`d-flex flex-wrap align-items-start ${styles.headerContentContainer}`}>
          {employeeList.map(({ id, name }) => (
            <UserItem
              key={id}
              name={name}
              onSelect={() => onSelectWorker(id)}
              selected={isEmployeeSelected(id)}
            />
          ))}
        </div>

        <div className={`${styles.selectWorkerContainer}`}>
          <div className={`${styles.titleContainer}`}>
            <h3 className={`${styles.workerTitle}`}>
              <Translate id="company_admin.assign_worker.selected_workers" />
            </h3>
          </div>

          <div
            className={`d-sm-flex align-items-center flex-wrap ${styles.workerContentContainer}`}
          >
            {selectedEmployeeList.map(({ id, name }) => (
              <SelectedUserItem key={id} onRemove={() => onRemoveWorker(id)}>
                <UserItem name={name} selected />
              </SelectedUserItem>
            ))}
          </div>
        </div>
      </div>
    </CompanyAdminContentWrapper>
  );
}

SelectedUserItem.propTypes = {
  onRemove: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

AssignWorkerOnTask.propTypes = {
  setLoading: PropTypes.func.isRequired,
};

export default AssignWorkerOnTask;
