import PropTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';

function DeviceCard({ id, src, name, onClick, disabled, selected }) {
  return (
    <div
      className={`d-flex align-items-center justify-content-between flex-md-column justify-content-md-center ${
        styles.cardContainer
      } ${selected ? styles.activeCard : ' '} ${disabled ? styles.disabledCard : ''}`}
      onClick={() => !disabled && onClick(id)}
      role="button"
      tabIndex={0}
      onKeyDown={() => !disabled && onClick(id)}
    >
      <div>
        <p>{name}</p>
      </div>
      {src && (
        <div>
          <img className={`imgFluid ${styles.imgFluidOverride}`} src={src} alt={name} />
        </div>
      )}
    </div>
  );
}

DeviceCard.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default DeviceCard;
