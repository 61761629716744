import * as moment from 'moment-timezone';
import React from 'react';

import styles from './customTimePicker.module.scss';
import ClockIcon from '../../assets/svgs/Clock';
import Translate from '../translate';

function CustomTimePicker({ onChange, date }) {
  const setTimeValue = (fieldName, changedValue) => {
    let timeValue = Number(changedValue);
    if (Number.isNaN(timeValue)) {
      timeValue = 0;
    }
    if (fieldName === 'HOUR') {
      timeValue = timeValue < 0 ? 0 : timeValue;
      timeValue = timeValue > 23 ? 23 : timeValue;
      onChange(moment(date).hours(timeValue).minutes(moment(date).get('minutes')).format('HH:mm'));
    } else {
      timeValue = timeValue < 0 ? 0 : timeValue;
      timeValue = timeValue > 59 ? 59 : timeValue;
      onChange(moment(date).hours(moment(date).get('hours')).minutes(timeValue).format('HH:mm'));
    }
  };

  return (
    <div
      className={`d-flex align-items-center justify-content-between ${styles.customTimeContainer}`}
    >
      <div className={`d-flex align-items-center ${styles.clockDiv}`}>
        <div className={`${styles.clockContainer}`}>
          <ClockIcon className={`${styles.clockIcon}`} />
        </div>
        <div className={`${styles.textContainer}`}>
          <p>
            <Translate id="components.calendar.time_placeholder" />
          </p>
        </div>
      </div>
      <div className={`d-flex align-items-center ${styles.inputDiv}`}>
        <div>
          <input
            className={`inputField ${styles.inputField}`}
            value={moment(date).isValid() ? moment(date).format('HH') : ''}
            type="text"
            size="3"
            placeholder="HH"
            onChange={(e) => setTimeValue('HOUR', e.target.value)}
          />
        </div>
        <div>:</div>
        <div>
          <input
            className={`inputField ${styles.inputField}`}
            value={moment(date).isValid() ? moment(date).format('mm') : ''}
            type="text"
            size="3"
            placeholder="MM"
            onChange={(e) => setTimeValue('MINUTE', e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

export default CustomTimePicker;
