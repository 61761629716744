import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Pagination } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import TeamList from './team-list';
import teamsConstant from './teams.json';
import { ReactComponent as PlusSVG } from '../../../assets/svgs/Plus.svg';
import Button from '../../../components/button';
import DropDown from '../../../components/drop-down';
import Layout from '../../../components/layout';
import SearchBox from '../../../components/search-box';
import Translate from '../../../components/translate';

function TeamHeader({ onClick }) {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div>
        <p>
          <Translate id="team.header.title" />
        </p>
      </div>
      <div>
        <Button className="createNewBtn" onClick={onClick}>
          <PlusSVG className="createNewBtnIcon" /> <Translate id="team.header.button" />
        </Button>
      </div>
    </div>
  );
}

const getTeamHeader = () => <TeamHeader onClick={() => {}} />;

function Team() {
  const { t } = useTranslation('lang');
  const [selectedFilterOption, setSelectedFilterOption] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [teams] = useState(teamsConstant);
  const [pagination] = useState({
    page: 1,
    limit: 20,
    total: 75,
  });

  const onFilterOptionSelect = (value) => {
    setSelectedFilterOption(value);
  };

  const onSearch = (value) => {
    setSearchTerm(value);
  };

  const debouncedSearch = debounce((newValue) => {
    onSearch(newValue);
  }, 1000);

  const changePage = () => {
    // call API for listing teams
  };

  return (
    <Layout headerComponent={getTeamHeader}>
      <div>
        <SearchBox value={searchTerm} onChange={debouncedSearch} />
        <div>
          <p>{t('common.filter')}: </p>
          <DropDown options={[]} selected={selectedFilterOption} onChange={onFilterOptionSelect} />
        </div>
      </div>
      <TeamList teams={teams} />
      {Math.ceil(pagination.total / pagination.limit) > 1 ? (
        <div>
          <Pagination>
            <Pagination.Prev
              onClick={() => changePage(pagination.page - 1)}
              disabled={pagination.page === 1}
            />
            {Array(Math.ceil(pagination.total / pagination.limit))
              .fill(0)
              .map((_, index) => {
                const newUuid = uuid();
                return (
                  <Pagination.Item
                    key={newUuid}
                    active={index + 1 === pagination.page - '0'}
                    onClick={() => changePage(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                );
              })}
            <Pagination.Next
              onClick={() => changePage(pagination.page + 1)}
              disabled={Math.ceil(pagination.total / pagination.limit) === pagination.page}
            />
          </Pagination>
        </div>
      ) : null}
    </Layout>
  );
}

TeamHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Team;
