import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import FinalOfferPreview from './index';
import Button from '../../../components/button';
import OfferPageContentWrapper from '../../../components/offer-page-content-wrapper';
import Translate from '../../../components/translate';
import { sendOfferToCustomer } from '../../../services/offer';

function AdminOfferPreviewFooter({ disabled, onClick }) {
  return (
    <Button
      className={`${!disabled ? 'greenBtn' : ''} `}
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      <Translate id="offer.final_offer_preview.send_offer" />
    </Button>
  );
}

function AdminOfferPreview({ setLoading }) {
  const [customerSignature, setCustomerSignature] = useState(null);
  const [offerPreview, setOfferPreview] = useState(null);

  const params = useParams();
  const { t } = useTranslation('lang');

  const sendOffer = async () => {
    try {
      await sendOfferToCustomer(params.offerRequestId, params.offerId);
      toast.success(t('offer.final_offer_preview.offer_sent_success_message'));
    } catch (err) {
      toast.error(err);
    }
  };

  const customFooter = () => (
    <AdminOfferPreviewFooter
      disabled={!offerPreview?.salesPersonSignature || customerSignature}
      onClick={sendOffer}
    />
  );

  return (
    <OfferPageContentWrapper customFooter={customFooter}>
      <FinalOfferPreview
        customerSignature={customerSignature}
        setCustomerSignature={setCustomerSignature}
        offerPreview={offerPreview}
        setOfferPreview={setOfferPreview}
        offerRequestId={params.offerRequestId}
        offerId={params.offerId}
        setLoading={setLoading}
      />
    </OfferPageContentWrapper>
  );
}

AdminOfferPreviewFooter.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
AdminOfferPreview.propTypes = {
  setLoading: PropTypes.func.isRequired,
};

export default AdminOfferPreview;
