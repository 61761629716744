import React from 'react';
import { Routes, Route } from 'react-router-dom';

import TaskPageWrapper from '../../../components/task-wrappers/task-page-wrapper';
import BasicDetails from '../../../pages/task-forms/basic-details';
import DeliveryTime from '../../../pages/task-forms/delivery-time';
import DrillingCrew from '../../../pages/task-forms/drilling-crew';
import HeatingDevices from '../../../pages/task-forms/heating-devices';
import InstallingCrew from '../../../pages/task-forms/installing-crew';
import InvoiceItems from '../../../pages/task-forms/invoice-items';
import InvoicePreview from '../../../pages/task-forms/invoice-preview';

function TaskRouting({ setLoading }) {
  return (
    <Routes>
      <Route path="" element={<TaskPageWrapper />}>
        <Route path="basic-details" element={<BasicDetails setLoading={setLoading} />} />
        <Route path="delivery-time" element={<DeliveryTime setLoading={setLoading} />} />
        <Route path="heating-devices" element={<HeatingDevices setLoading={setLoading} />} />
        <Route path="drilling-crew" element={<DrillingCrew setLoading={setLoading} />} />
        <Route path="installing-crew" element={<InstallingCrew setLoading={setLoading} />} />
        <Route path="invoice-items" element={<InvoiceItems setLoading={setLoading} />} />
        <Route path="invoice-preview" element={<InvoicePreview setLoading={setLoading} />} />
      </Route>
    </Routes>
  );
}

export default TaskRouting;
