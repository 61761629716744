import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link, useLocation, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './index.module.scss';
import offerRequestService from '../../services/offerRequest';
import { getOriginalErrors } from '../../utils/errorHandler';
import Translate from '../translate';

function TaskSteps() {
  const [offer, setOffer] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const location = useLocation();
  const { offerRequestId } = useParams();
  const [steps, setSteps] = useState([]);

  const generateTaskSteps = (offerId) => [
    {
      title: 'task.tabs.basic_details',
      link: `/offer-requests/${offerRequestId}/offers/${offerId}/tasks/basic-details`,
      active: false,
    },
    {
      title: 'task.tabs.delivery_time',
      link: `/offer-requests/${offerRequestId}/offers/${offerId}/tasks/delivery-time`,
      active: false,
    },
    {
      title: 'task.tabs.heating_devices',
      link: `/offer-requests/${offerRequestId}/offers/${offerId}/tasks/heating-devices`,
      active: false,
    },
    {
      title: 'task.tabs.drilling_crew',
      link: `/offer-requests/${offerRequestId}/offers/${offerId}/tasks/drilling-crew`,
      active: false,
    },
    {
      title: 'task.tabs.installing_crew',
      link: `/offer-requests/${offerRequestId}/offers/${offerId}/tasks/installing-crew`,
      active: false,
    },
    {
      title: 'task.tabs.select_item_to_invoice',
      link: `/offer-requests/${offerRequestId}/offers/${offerId}/tasks/invoice-items`,
      active: false,
    },
    {
      title: 'task.tabs.invoice_preview',
      link: `/offer-requests/${offerRequestId}/offers/${offerId}/tasks/invoice-preview`,
      active: false,
    },
  ];

  const updateCurrentStep = async () => {
    try {
      let offerId = null;
      if (offer) {
        offerId = offer.id;
      } else {
        const { data: offers } = await offerRequestService.getOffersByRequestId(offerRequestId);
        if (offers && offers.length) {
          offerId = offers[0].id;
          setOffer(offers[0]);
        }
      }
      const updatedSteps = [...generateTaskSteps(offerId)];
      const locations = location.pathname.split('/');
      const currentPageName = locations[locations.length - 1];
      const updatedStepsWithActive = updatedSteps.map((step) => {
        const updatedStep = { ...step };
        if (step.link.endsWith(currentPageName)) {
          updatedStep.active = true;
          setCurrentPage(updatedStep.title);
        }
        return updatedStep;
      });

      setSteps(updatedStepsWithActive);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  useEffect(() => {
    updateCurrentStep();
  }, [location]);

  return (
    <>
      {/* This below div is for desktop view */}
      <div className={`d-none d-md-block ${styles.tabLinkContainer}`}>
        <div className={`d-flex align-items-center ${styles.linkFlexContainer}`}>
          {steps.map(({ title, link, active }) => (
            <div>
              <Link
                key={link}
                to={link}
                className={` ${styles.tabLink}  ${active ? styles.tabLinkActive : ''}`}
              >
                <Translate id={title} />
              </Link>
            </div>
          ))}
        </div>
      </div>
      {/* The below div is for the mobile view */}
      <div className={`d-md-none ${styles.mobTabLinkContainer}`}>
        <Accordion className={`${styles.mobAccordion}`}>
          <Accordion.Item className={`${styles.mobAccordionItem}`} eventKey="0">
            <Accordion.Header className={`${styles.mobAccordionHeader}`}>
              <Translate id={currentPage} />
            </Accordion.Header>
            <Accordion.Body className={`${styles.mobAccordionBody}`}>
              {steps.map(({ title, link, active }) => (
                <Link
                  key={link}
                  to={link}
                  className={`${styles.mobTabLink} ${active ? styles.mobTabLinkActive : ''}`}
                >
                  <Translate id={title} />
                </Link>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}

export default TaskSteps;
