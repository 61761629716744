import apiClient from './api-client';

export const uploadAttachments = (attachmentableType, attachmentableId, attachments) => {
  const formData = new FormData();
  attachments.forEach((attachment) => formData.append('files', attachment));
  return apiClient.post(`/v1/attachments/${attachmentableType}/${attachmentableId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getAttachments = (attachmentableType, attachmentableId) =>
  apiClient.get(`/v1/attachments/${attachmentableType}/${attachmentableId}`);

export const deleteAttachments = (attachmentableType, attachmentableId, attachmentIds) => {
  const params = attachmentIds.reduce((acc, id) => `${acc}id=${id}&`, '');
  return apiClient.delete(
    `/v1/attachments/${attachmentableType}/${attachmentableId}?${params.substring(
      0,
      params.length - 1
    )}`
  );
};

export const downloadAttachment = (attachmentableType, attachmentableId, attachmentId) =>
  apiClient.get(`/v1/attachments/${attachmentableType}/${attachmentableId}/${attachmentId}`, {
    responseType: 'blob',
  });
