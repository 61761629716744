import PropTypes from 'prop-types';
import React from 'react';
// import { useParams } from 'react-router-dom';

import AdditionalInformation from './additional-information';
import BasicInformation from './basic-information';
import styles from './form.module.scss';
import TechnicalInformation from './technical-information';
import Translate from '../../../components/translate';

const tabs = [
  {
    id: 'BASIC_INFORMATION',
    title: 'offer.onsite_memo.basic_information.title',
  },
  {
    id: 'TECHNICAL_INFORMATION',
    title: 'offer.onsite_memo.technical_information',
  },
  {
    id: 'ADDITIONAL_INFORMATION',
    title: 'offer.onsite_memo.additional_information',
  },
];

function Form({
  basicInformation,
  onChangeBasicInformation,
  additionalInformation,
  setAdditionalInformation,
  onSiteMemoActiveTab,
  setOnSiteMemoActiveTab,
  technicalInformation,
  technicalInformationAttachments,
  setTechnicalInformationAttachments,
  setTechnicalInformation,
}) {
  return (
    <div className={`${styles.formContainer}`}>
      <div className={`${styles.siteMemoHeaderContainer}`}>
        {tabs.map(({ id, title }) => (
          <div
            className={`${onSiteMemoActiveTab === id ? styles.activeTab : ''} ${styles.headerTab}
            }`}
            role="button"
            onClick={() => setOnSiteMemoActiveTab(id)}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setOnSiteMemoActiveTab(id);
              }
            }}
          >
            <Translate id={title} />
          </div>
        ))}
      </div>
      <div>
        {onSiteMemoActiveTab === 'BASIC_INFORMATION' && (
          <BasicInformation
            basicInformation={basicInformation}
            onChangeBasicInformation={onChangeBasicInformation}
          />
        )}
        {onSiteMemoActiveTab === 'TECHNICAL_INFORMATION' && (
          <TechnicalInformation
            technicalInformation={technicalInformation}
            setTechnicalInformation={setTechnicalInformation}
            technicalInformationAttachments={technicalInformationAttachments}
            setTechnicalInformationAttachments={setTechnicalInformationAttachments}
          />
        )}
        {onSiteMemoActiveTab === 'ADDITIONAL_INFORMATION' && (
          <AdditionalInformation
            additionalInformation={additionalInformation}
            setAdditionalInformation={setAdditionalInformation}
          />
        )}
      </div>
    </div>
  );
}

Form.propTypes = {
  basicInformation: PropTypes.instanceOf(Object).isRequired,
  additionalInformation: PropTypes.instanceOf(Object).isRequired,
  setAdditionalInformation: PropTypes.func.isRequired,
  onChangeBasicInformation: PropTypes.func.isRequired,
  onSiteMemoActiveTab: PropTypes.string.isRequired,
  setOnSiteMemoActiveTab: PropTypes.func.isRequired,
  technicalInformation: PropTypes.instanceOf(Object).isRequired,
  technicalInformationAttachments: PropTypes.instanceOf(Array).isRequired,
  setTechnicalInformationAttachments: PropTypes.func.isRequired,
  setTechnicalInformation: PropTypes.func.isRequired,
};

export default Form;
