import apiClient from './api-client';
import { getCurrentSessionUser, setCurrentSessionUser } from './authorization';

const login = (email, password) => apiClient.post('/v1/auth', { email, password });

const sendResetPasswordLink = (payload) => apiClient.post('/v1/auth/password-reset', payload);

const updatePassword = (userId, token, payload) =>
  apiClient.post(`/v1/auth/password-reset/${userId}/${token}`, payload);

const getCurrentUser = async () => {
  if (getCurrentSessionUser()) return getCurrentSessionUser();
  const {
    data: {
      data: { user },
    },
  } = await apiClient.get('v1/users/me');
  setCurrentSessionUser(user);
  return user;
};

export default {
  login,
  sendResetPasswordLink,
  updatePassword,
  getCurrentUser,
};
