import React from 'react';
import { Routes, Route } from 'react-router-dom';

import AppView from './app';
import AuthView from './auth';
import ForgotPassword from './auth/forgot-password';
import Login from './auth/login';
import ResetPassword from './auth/reset-password';
import { NoAuth } from '../components/no-auth';
import { RequireAuth } from '../components/require-auth';
import CustomerOfferPreview from '../pages/public/offer-preview';

function View({ setLoading }) {
  return (
    <Routes>
      <Route
        path="auth"
        element={
          <NoAuth>
            <AuthView />
          </NoAuth>
        }
      >
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="password-reset/:userId/:token" element={<ResetPassword />} />
      </Route>
      <Route
        path="/*"
        element={
          <RequireAuth>
            <AppView setLoading={setLoading} />
          </RequireAuth>
        }
      />
      <Route path="offer-preview" element={<CustomerOfferPreview setLoading={setLoading} />} />
    </Routes>
  );
}

export default View;
