import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useIsFetching, useQueryClient } from 'react-query';
import { toast } from 'sonner';

import styles from './index.module.scss';
import { ReactComponent as MarkAsCompleteIcon } from '../../../assets/svgs/MarkAsCompleteIcon.svg';
import Button from '../../../components/button';
import CompanyAdminContentWrapper from '../../../components/company-admin-content-wrapper';
import ElectricityTestReportForm from '../../../components/electricity-test-report-form';
import FloorHeatingTestReport from '../../../components/floor-heating-test-report';
import HeatPumpTestReport from '../../../components/heat-pump-test-report';
import ProductsFlow from '../../../components/products-flow';
import Translate from '../../../components/translate';
import { getProductsByOffer } from '../../../services/offerProducts';
import { updateTaskComment } from '../../../services/offerService';
import { takServiceComplete } from '../../../services/task';
import { getOriginalErrors } from '../../../utils/errorHandler';
import useProductSave from '../../offer-forms/products/useSaveProduct';
import { useBasicInformationContext } from '../context/basicInformationContext';
import { useServiceContext } from '../context/service-context';

function InstallationCrewViewAccordion({ setLoading }) {
  return (
    <Accordion defaultActiveKey="0" className={styles.accordion}>
      <Accordion.Item eventKey="0" className={styles.accordionItem}>
        <Accordion.Header className={styles.accordionHeader}>
          <div className={styles.accordionHeaderContainer}>
            <p className={styles.accordionHeaderText}>
              <Translate id="task.installation_crew.heat_pump_test_report" />
            </p>
            <MarkAsCompleteIcon />
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <HeatPumpTestReport setLoading={setLoading} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" className={styles.accordionItem}>
        <Accordion.Header className={styles.accordionHeader}>
          <div className={styles.accordionHeaderContainer}>
            <p className={styles.accordionHeaderText}>
              <Translate id="task.installation_crew.floor_heating_test_report" />
            </p>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <FloorHeatingTestReport setLoading={setLoading} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2" className={styles.accordionItem}>
        <Accordion.Header className={styles.accordionHeader}>
          <div className={styles.accordionHeaderContainer}>
            <p className={styles.accordionHeaderText}>
              <Translate id="task.installation_crew.electricity_test_report" />
            </p>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <ElectricityTestReportForm setLoading={setLoading} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

function ServiceComment() {
  const { serviceComment, setServiceComment } = useServiceContext();
  return (
    <div className={styles.commentContainer}>
      <div className={styles.commentTitleContainer}>
        <p className={styles.commentTitle}>
          <Translate id="offer.services.add_comment" />
        </p>
      </div>
      <textarea
        className={styles.commentTextArea}
        value={serviceComment}
        onChange={(e) => setServiceComment(e.target.value === '' ? null : e.target.value)}
      />
    </div>
  );
}

function InstallationCrewViewFooter({
  setLoading,
  selectedProductsChanged,
  selectedProducts,
  refresh,
}) {
  const { service, serviceComment, serviceCommentComparison } = useServiceContext();
  const { t } = useTranslation('lang');
  const { offerRequest } = useBasicInformationContext();
  const queryClient = useQueryClient();
  const { onProductSave } = useProductSave(offerRequest.id, service?.offerId);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const onSave = async () => {
    setLoading(true);
    try {
      if (selectedProductsChanged) {
        const success = await onProductSave(selectedProducts);
        if (success) refresh();
      }
      if (serviceComment !== serviceCommentComparison) {
        await updateTaskComment(offerRequest.id, service.offerId, service.id, serviceComment);
        queryClient.invalidateQueries('Assignment');
        toast.success(t('assign_teams.drilling_crew.task_comment_saved_success'));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
    setShow(false);
  };
  const handleShow = () => {
    if (selectedProductsChanged) {
      setShow(true);
    } else {
      onSave();
    }
  };

  const onMarkAsCompleted = async () => {
    setLoading(true);
    try {
      await takServiceComplete(offerRequest.id, service.offerId, service.id);
      toast.success(t('assign_teams.drilling_crew.task_completed'));
      queryClient.invalidateQueries('Assignment');
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
    setLoading(false);
  };

  let saveDisabled = true;
  if (serviceComment !== serviceCommentComparison || selectedProductsChanged) {
    saveDisabled = false;
  }

  return (
    <>
      {show && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton className="d-flex">
            <Modal.Title>
              <Translate id="task.installation_crew.modal_title" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Translate id="task.installation_crew.sure_message" />
          </Modal.Body>
          <Modal.Footer className="justify-content-end">
            <div>
              <Button className="greenBtn" onClick={() => onSave()}>
                <Translate id="components.button.save" />
              </Button>
            </div>
            <div>
              <Button className="grayBtn" onClick={handleClose}>
                <Translate id="components.button.close" />
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      <div className={`d-flex align-items-center ${styles.footerContainer}`}>
        <div>
          <Button
            className={saveDisabled ? '' : 'greenBtn'}
            onClick={handleShow}
            disabled={saveDisabled}
          >
            <Translate id="components.button.save" />
          </Button>
        </div>
        <div>
          <Button
            className={service?.completedAt ? '' : 'greenBtn'}
            onClick={() => onMarkAsCompleted()}
            type="button"
            disabled={service?.completedAt}
          >
            <Translate id="components.button.mark_as_completed" />
          </Button>
        </div>
      </div>
    </>
  );
}

function ProductItem({ id, name }) {
  return (
    <div className={`${styles.productListContainer}`}>
      <div className={`${styles.productList}`}>
        <div className="d-flex align-items-center ">
          <div>
            <p>
              <Translate id="offer.products.id" /> : {id}
            </p>
          </div>
          <div>
            <p className={`${styles.boldText}`}> - {name}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function AddProductsBtn({ onClick, selectedProducts }) {
  return (
    <div>
      <div className={styles.productTitleContainer}>
        <div className={styles.productTitle}>
          <Translate id="task.installation_crew.add_parts" />
        </div>
      </div>
      <Button onClick={onClick} className={`projectBtn ${styles.addProductsBtn}`}>
        + <Translate id="task.installation_crew.click_to_add_products" />
      </Button>
      <div className="mt-4">
        <div className={styles.productTitleContainer}>
          <div className={styles.productTitle}>
            <Translate id="task.installation_crew.sent_products" />
          </div>
        </div>
        <div className={`d-md-flex align-items-md-center flex-md-wrap ${styles.flexContainer}`}>
          {selectedProducts.map((product) => (
            <ProductItem key={product.id} id={product.netvisorId} name={product.name} />
          ))}
        </div>
      </div>
    </div>
  );
}

function InstalltionCrewTaskView({ setLoading }) {
  const isfetching = useIsFetching();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductsForComparison, setSelectedProductsForComparison] = useState([]);
  const [addProductsClicked, setAddProductsClicked] = useState(false);
  const { service } = useServiceContext();
  const { offerRequest } = useBasicInformationContext();

  const fetchProductsByOffer = async () => {
    try {
      const {
        data: {
          data: { components: offerProducts },
        },
      } = await getProductsByOffer(offerRequest?.id, service?.offerId);
      const tempOfferProducts = offerProducts.map((product) => ({
        ...product,
        alreadySelected: true,
      }));
      setSelectedProducts(tempOfferProducts);
      setSelectedProductsForComparison(tempOfferProducts);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const refresh = () => {
    fetchProductsByOffer();
    setAddProductsClicked(false);
  };

  const customFooter = () => (
    <InstallationCrewViewFooter
      setLoading={setLoading}
      selectedProductsChanged={
        JSON.stringify(selectedProducts) !== JSON.stringify(selectedProductsForComparison)
      }
      selectedProducts={selectedProducts}
      refresh={refresh}
    />
  );

  useEffect(() => {
    setLoading(isfetching);
  }, [isfetching]);

  useEffect(() => {
    if (service?.offerId && offerRequest?.id) {
      fetchProductsByOffer();
    }
  }, [service, offerRequest]);

  return (
    <CompanyAdminContentWrapper customFooter={customFooter}>
      <div className={styles.section1Container}>
        <InstallationCrewViewAccordion setLoading={setLoading} />
        <ServiceComment />
      </div>
      <div className={styles.section2Container}>
        {!addProductsClicked ? (
          <AddProductsBtn
            onClick={() => setAddProductsClicked(true)}
            selectedProducts={selectedProducts}
          />
        ) : (
          <ProductsFlow
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            strict
          />
        )}
      </div>
    </CompanyAdminContentWrapper>
  );
}

InstalltionCrewTaskView.propTypes = {
  setLoading: PropTypes.func.isRequired,
};

AddProductsBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
  selectedProducts: PropTypes.instanceOf(Array).isRequired,
};

InstallationCrewViewFooter.propTypes = {
  setLoading: PropTypes.func.isRequired,
  selectedProductsChanged: PropTypes.bool.isRequired,
  selectedProducts: PropTypes.instanceOf(Array).isRequired,
  refresh: PropTypes.func.isRequired,
};

export default InstalltionCrewTaskView;
