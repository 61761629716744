import React, { createContext, useContext, useMemo, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { useServiceContext } from './service-context';
import AuthService from '../../../services/auth';
import { getDrillingReport } from '../../../services/report';

const Context = createContext();

export function DrillingReportContext({ children }) {
  const { companyId } = useParams();
  const { service } = useServiceContext();
  const { data: drillingReport, error } = useQuery(
    ['serviceDrillingReport'],
    async () => {
      const currentUser = await AuthService.getCurrentUser();
      const {
        data: {
          data: { drillingReport: report },
        },
      } = await getDrillingReport(companyId, currentUser.id, service?.id);
      return report;
    },
    {
      enabled: !!service,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  const contextValue = useMemo(
    () => ({
      drillingReport,
    }),
    [drillingReport]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

export const useDrillingReportContext = () => useContext(Context);
