import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import OfferPageWrapper from '../../../components/offer-page-wrapper';
import BasicDetailsPage from '../../../pages/offer-forms/basic-details';
import CalculationsPage from '../../../pages/offer-forms/calculations';
import AdminOfferPreview from '../../../pages/offer-forms/final-offer-preview/AdminOfferPreview';
import HeatingDevicesPage from '../../../pages/offer-forms/heating-device';
import OfferParagraphPage from '../../../pages/offer-forms/offer-paragraph';
import OfferPreviewPage from '../../../pages/offer-forms/offer-preview';
import OnSiteMemoPage from '../../../pages/offer-forms/on-site-memo';
import ProductsPage from '../../../pages/offer-forms/products';
import ServicesPage from '../../../pages/offer-forms/services';

function OfferRequestRouting({ setLoading }) {
  const [offerFormStepsRefreshCount, setOfferFormStepsRefreshCount] = useState(0);

  return (
    <Routes>
      <Route
        path=""
        element={<OfferPageWrapper offerFormStepsRefreshCount={offerFormStepsRefreshCount} />}
      >
        <Route
          path="basic-details"
          element={
            <BasicDetailsPage
              setOfferFormStepsRefreshCount={setOfferFormStepsRefreshCount}
              setLoading={setLoading}
            />
          }
        />
        <Route
          path="on-site-memo"
          element={
            <OnSiteMemoPage
              setOfferFormStepsRefreshCount={setOfferFormStepsRefreshCount}
              setLoading={setLoading}
            />
          }
        />
        <Route
          path="offers/:offerId/heating-devices"
          element={<HeatingDevicesPage setLoading={setLoading} />}
        />
        <Route path="offers/:offerId/products" element={<ProductsPage setLoading={setLoading} />} />
        <Route path="offers/:offerId/services" element={<ServicesPage setLoading={setLoading} />} />
        <Route
          path="offers/:offerId/calculations"
          element={<CalculationsPage setLoading={setLoading} />}
        />
        <Route
          path="offers/:offerId/offer-paragraph"
          element={<OfferParagraphPage setLoading={setLoading} />}
        />
        <Route
          path="offers/:offerId/offer-preview"
          element={<OfferPreviewPage setLoading={setLoading} />}
        />
        <Route
          path="offers/:offerId/user-offer-preview"
          element={<AdminOfferPreview setLoading={setLoading} />}
        />
      </Route>
    </Routes>
  );
}

export default OfferRequestRouting;
