const INSTALLATION_DEVICE = [
  {
    id: 1,
    label: 'heat_pump_test_report.installation_device.options.ground_source_heat_pump',
    value: 'GROUND_SOURCE_HEAT_PUMP',
  },
  {
    id: 2,
    label: 'heat_pump_test_report.installation_device.options.air_water_heat_pump',
    value: 'AIR_WATER_HEAT_HUMP',
  },
  {
    id: 3,
    label: 'heat_pump_test_report.installation_device.options.air_heat_pump',
    value: 'AIR_HEAT_PUMP',
  },
];

export { INSTALLATION_DEVICE };
