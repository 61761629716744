/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './index.module.scss';
import Button from '../../../components/button';
import HeatingDevicePriceFields from '../../../components/heating-device-price-fields';
import ProductPriceFields from '../../../components/product-price-fields';
import TaskPageContentWrapper from '../../../components/task-wrappers/task-page-content-wrapper';
import Translate from '../../../components/translate';
import { offerServiceTypes } from '../../../constant';
import HeatingDevicesService from '../../../services/heatingDevices';
import { getCurrentInvoice, saveInvoice } from '../../../services/invoice';
import { getProductsByOffer } from '../../../services/offerProducts';
import { getOfferServices } from '../../../services/offerService';
import { getOriginalErrors } from '../../../utils/errorHandler';
import { DrillingCrewFormFields } from '../drilling-crew';
import { InstallingCrewFormFields } from '../installing-crew';

function InvoiceItemsFooter({
  onSendToNetvisor,
  onClickNext,
  isNextEnabled,
  isSendToNetvisorEnabled,
}) {
  return (
    <div className={`d-flex align-items-center ${styles.btnContainer}`}>
      <div>
        <Button
          className={`projectBtn offerTabFooterBtn ${
            isSendToNetvisorEnabled ? 'offerTabGreenBtn' : ''
          }`}
          onClick={onSendToNetvisor}
          disabled={!isSendToNetvisorEnabled}
        >
          <Translate id="components.button.save" />
        </Button>
      </div>
      <div>
        <Button
          className={`projectBtn offerTabFooterBtn ${isNextEnabled ? 'offerTabGreenBtn' : ''}`}
          onClick={onClickNext}
          disabled={!isNextEnabled}
        >
          <Translate id="components.button.next" />
        </Button>
      </div>
    </div>
  );
}

function InvoiceItems({ setLoading }) {
  const { t } = useTranslation('lang');
  const navigate = useNavigate();
  const params = useParams();
  const [heatindDevices, setHeatingDevice] = useState([]);
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [isSendToNetvisorEnabled, setIsSendToNetvisorEnabled] = useState(false);

  const onClickNext = () => {
    navigate(
      `/offer-requests/${params.offerRequestId}/offers/${params.offerId}/tasks/invoice-preview`
    );
  };

  const onHeatingDeviceClick = (device, index) => {
    if (!device.invoiceId) {
      setHeatingDevice((prevDevices) => {
        const devices = [...prevDevices];
        devices[index].selected = !devices[index].selected;
        return devices;
      });
    }
  };

  const onProductClick = (product, index) => {
    if (!product.invoiceId) {
      setProducts((prevProducts) => {
        const prods = [...prevProducts];
        prods[index].selected = !prods[index].selected;
        return prods;
      });
    }
  };

  const onServiceClick = (service, index) => {
    if (!service.invoiceId) {
      setServices((prevServices) => {
        const s = [...prevServices];
        s[index].selected = !s[index].selected;
        return s;
      });
    }
  };

  const fetchOrderedHeatingDevices = async () => {
    try {
      const {
        data: {
          data: { heatingDevices: devices },
        },
      } = await HeatingDevicesService.getOfferHeatingDevices({
        requestId: params.offerRequestId,
        offerId: params.offerId,
      });
      setHeatingDevice(devices);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchOrderedProducts = async () => {
    try {
      const {
        data: {
          data: { components },
        },
      } = await getProductsByOffer(params.offerRequestId, params.offerId);
      setProducts(components);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchOrderedServices = async () => {
    try {
      const {
        data: {
          data: { offerServices },
        },
      } = await getOfferServices(params.offerRequestId, params.offerId);
      setServices(offerServices);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchCurrentInvoice = async () => {
    try {
      const {
        data: {
          data: { invoice },
        },
      } = await getCurrentInvoice(params.offerRequestId, params.offerId);
      setIsNextEnabled(invoice && invoice.id);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await fetchOrderedProducts();
      await fetchOrderedServices();
      await fetchOrderedHeatingDevices();
      await fetchOrderedServices();
      await fetchCurrentInvoice();
      setLoading(false);
    };
    load();
  }, []);

  const onSendToNetvisor = async () => {
    setLoading(true);
    try {
      setLoading(true);
      const payload = {
        heatingDevices: heatindDevices.filter((d) => d.selected),
        components: products.filter((p) => p.selected),
        offerServices: services.filter((s) => s.selected),
      };
      await saveInvoice(params.offerRequestId, params.offerId, payload);
      await fetchOrderedHeatingDevices();
      await fetchOrderedProducts();
      await fetchOrderedServices();
      setLoading(false);
      setIsNextEnabled(true);
      setLoading(false);
      toast.success(t('task.invoice_items.invoice_saved'));
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
      setLoading(false);
    }
  };

  const customFooter = () => (
    <InvoiceItemsFooter
      onClickNext={onClickNext}
      onSendToNetvisor={onSendToNetvisor}
      isNextEnabled={isNextEnabled}
      isSendToNetvisorEnabled={isSendToNetvisorEnabled}
    />
  );

  useEffect(() => {
    setIsSendToNetvisorEnabled(
      heatindDevices.some((device) => device.selected) ||
        products.some((product) => product.selected) ||
        services.some((service) => service.selected)
    );
  }, [products, heatindDevices, services]);

  return (
    <TaskPageContentWrapper customFooter={customFooter}>
      <div className={`${styles.invoiceMainContainer}`}>
        <div className={`d-none d-md-block ${styles.titleContainer}`}>
          <p>
            <Translate id="task.invoice_items.title" />
          </p>
        </div>
        <div className={`${styles.invoiceItemContainer}`}>
          {heatindDevices && heatindDevices.length > 0 && (
            <div className={`${styles.deviceMainContainer}`}>
              <div className={`${styles.itemTitleContainer}`}>
                <p className={`${styles.title}`}>
                  <Translate id="task.tabs.heating_devices" />
                </p>
              </div>

              <div>
                {heatindDevices.map((device, index) => (
                  <div
                    className={`${styles.deviceContainer} ${
                      device.selected ? styles.activeCard : ' '
                    } ${device.invoiceId ? styles.disabledCard : ''}`}
                    onClick={() => onHeatingDeviceClick(device, index)}
                    key={device.id}
                  >
                    <HeatingDevicePriceFields
                      name={device.name}
                      purchasePriceInclusiveVat={device.purchasePriceInclusiveVat}
                      purchasePriceExclusiveVat={device.purchasePriceExclusiveVat}
                      id={device.netvisorId}
                      quantity={device.quantity}
                      sellingPriceInclusiveVat={device.sellingPriceInclusiveVat}
                      sellingPriceExclusiveVat={device.sellingPriceExclusiveVat}
                      image={device.image}
                      readOnly
                      inclusiveOnly
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {products && products.length > 0 && (
            <div className={`${styles.productMainContainer}`}>
              <div className={`${styles.itemTitleContainer}`}>
                <p className={`${styles.title}`}>
                  <Translate id="task.heating_devices.products" />
                </p>
              </div>
              <div>
                {products.map((product, index) => (
                  <div
                    className={`${styles.productContainer} ${
                      product.selected ? styles.activeCard : ' '
                    } ${product.invoiceId ? styles.disabledCard : ''}`}
                    onClick={() => onProductClick(product, index)}
                    key={product.id}
                  >
                    <ProductPriceFields
                      id={product.netvisorId}
                      name={product.name}
                      amount={product.amount}
                      purchasePriceInclusiveVat={product.purchasePriceInclusiveVat}
                      rateInclusiveVat={product.rateInclusiveVat}
                      sellingPriceInclusiveVat={product.sellingPriceInclusiveVat}
                      productUnit={product.unit}
                      readOnly
                      inclusiveOnly
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {services && services.length > 0 && (
            <div className={`${styles.serviceMainContainer}`}>
              <div className={`${styles.itemTitleContainer}`}>
                <p className={`${styles.title}`}>
                  <Translate id="task.heating_devices.services" />
                </p>
              </div>
              <div>
                {services.map((service, index) => (
                  <div
                    className={`${styles.serviceContainer} ${
                      service.selected ? styles.activeCard : ' '
                    } ${service.invoiceId ? styles.disabledCard : ''}`}
                    onClick={() => onServiceClick(service, index)}
                    key={service.id}
                  >
                    {service.type === offerServiceTypes.DRILLING && (
                      <DrillingCrewFormFields drillingCrew={service} readOnly />
                    )}
                    {service.type === offerServiceTypes.INSTALLING && (
                      <InstallingCrewFormFields installingCrew={service} readOnly />
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </TaskPageContentWrapper>
  );
}

InvoiceItems.propTypes = {
  setLoading: PropTypes.func.isRequired,
};

InvoiceItemsFooter.propTypes = {
  onSendToNetvisor: PropTypes.func.isRequired,
  onClickNext: PropTypes.func.isRequired,
  isNextEnabled: PropTypes.bool.isRequired,
  isSendToNetvisorEnabled: PropTypes.bool.isRequired,
};

export default InvoiceItems;
