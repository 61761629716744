import * as moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useCallback, memo, forwardRef, useEffect, useRef } from 'react';
import ReactDatePicker, { CalendarContainer } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import CustomTimePicker from './CustomTimePicker';
import eventsConstant from './constants';
import styles from './index.module.scss';
import CalendarIcon from '../../assets/svgs/Calendar';
import CircleArrowLeft from '../../assets/svgs/CircleArrowLeft';
import CircleArrowRight from '../../assets/svgs/CircleArrowRight';
import ClockIcon from '../../assets/svgs/Clock';
import compareDate from '../../utils/date';
import Button from '../button';

const CustomInput = forwardRef(({ value, onClick, onChange, placeholder, hide, disabled }, ref) => {
  const { t } = useTranslation('lang');
  return (
    <div className={`d-flex align-items-center ${styles.calendarInputIconContainer}`}>
      <div className={`${styles.inputContainer} ${hide ? 'd-none' : ''}`}>
        <input
          className={`inputField ${styles.inputField} ${disabled ? styles.disabledInput : ''}`}
          type="text"
          placeholder={t(placeholder)}
          value={value}
          onChange={onChange}
          onClick={onClick}
          ref={ref}
          readOnly={disabled}
        />
      </div>
      {!disabled && (
        <div className={`${styles.iconContainer}`}>
          <button className={`projectBtn ${styles.btnIcon}`} type="button" onClick={onClick}>
            <CalendarIcon />
          </button>
        </div>
      )}
    </div>
  );
});

const CustomDay = memo(({ day, events }) => {
  const [isSelected, setIsSelected] = useState(false);
  const toggleSelection = () => {
    setIsSelected((prev) => !prev);
  };

  return (
    <div
      className={`customDay ${isSelected ? 'selected' : ''}`}
      onClick={toggleSelection}
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}
    >
      <div>{day}</div>
      {events.length > 0 ? (
        <div>
          {events.slice(0, 2).map((event) => (
            <span key={event.id} className={styles.event} />
          ))}
          <span>{events.length > 2 ? `+${events.length - 2}` : null}</span>
        </div>
      ) : null}
    </div>
  );
});

function CustomHeader({ date, decreaseMonth, increaseMonth }) {
  return (
    <div
      className={`d-flex align-items-center justify-content-between ${styles.calendarArrowContainer}`}
    >
      <div className={`${styles.btnContainer}`}>
        <button className={`projectBtn ${styles.arrowBtn}`} type="button" onClick={decreaseMonth}>
          <CircleArrowLeft />
        </button>
      </div>
      <div className={`${styles.titleContainer}`}>
        <h2>{date.toLocaleString('default', { month: 'long', year: 'numeric' })}</h2>
      </div>
      <div className={`${styles.btnContainer}`}>
        <button className={`projectBtn ${styles.arrowBtn}`} type="button" onClick={increaseMonth}>
          <CircleArrowRight />
        </button>
      </div>
    </div>
  );
}

function EventItem({ title, startTime }) {
  const date = new Date(startTime);

  const formattedHours = `${date.getHours().toString().padStart(2, 0)}:${date
    .getMinutes()
    .toString()
    .padStart(2, 0)}`;

  return (
    <div className="EventListItem">
      {title}
      <ClockIcon />
      <span>{formattedHours}</span>
    </div>
  );
}

function TimePickerWrapper({
  filteredEvents,
  saveAndSyncWithGoogle,
  showTimeInput,
  value,
  onChange,
  date,
}) {
  const { t } = useTranslation('lang');
  return (
    <div>
      {showTimeInput && <CustomTimePicker value={value} onChange={onChange} date={date} />}
      <div>
        {filteredEvents.length > 0 ? (
          <div>
            {filteredEvents.map((event) => (
              <EventItem key={event.id} title={event.title} startTime={event.start} />
            ))}
          </div>
        ) : null}
        <div className={`${styles.saveSyncBtnContainer}`}>
          <Button className={`projectBtn ${styles.saveSyncBtn}`} onClick={saveAndSyncWithGoogle}>
            {t('components.button.save')}
          </Button>
        </div>
      </div>
    </div>
  );
}

function CustomCalendar({
  placeholder,
  selected,
  onNewValue,
  show,
  hideInput,
  showTimeInput,
  setShowCalendar,
  disabled,
}) {
  const [value, setValue] = useState(null);
  const [selectedValue, setSelectedValue] = useState(selected ? new Date(selected) : null);
  const [isOpen, setIsOpen] = useState(false);

  const calendarRef = useRef();

  useEffect(() => {
    setSelectedValue(selected ? new Date(selected) : null);
  }, [selected]);

  const filterEvents = (events, dateObject) =>
    events.filter(
      (event) => compareDate(event.start, dateObject) || compareDate(event.end, dateObject)
    );

  const renderDayContents = useCallback((day, date) => {
    const events = filterEvents(eventsConstant, date);
    return <CustomDay day={day} events={events} />;
  }, []);

  const filteredEvents = useMemo(() => {
    const date = moment(value).toDate();

    return filterEvents(eventsConstant, date);
  }, [value]);

  const onChange = (newValue) => {
    setSelectedValue(newValue);
    setValue(newValue);
  };

  const saveAndSyncWithGoogle = () => {
    calendarRef.current.setOpen(false);
    if (value === null) {
      setSelectedValue(new Date());
      onNewValue(new Date());
      setIsOpen(false);
      return;
    }
    setSelectedValue(value);
    onNewValue(value);
    setIsOpen(false);
  };

  useEffect(() => {
    if (show !== null || show !== undefined) {
      setIsOpen(show);
    }
  }, [show]);

  return (
    <div className={`${styles.projectCalendar}`}>
      <ReactDatePicker
        ref={calendarRef}
        open={isOpen}
        selected={selectedValue}
        onChange={onChange}
        timeInputLabel=""
        dateFormat="dd.MM.yyyy"
        showTimeInput
        shouldCloseOnSelect={!isOpen}
        customTimeInput={
          <TimePickerWrapper
            filteredEvents={filteredEvents}
            saveAndSyncWithGoogle={saveAndSyncWithGoogle}
            showTimeInput={showTimeInput}
          />
        }
        onCalendarOpen={() => setIsOpen(true)}
        onClickOutside={() => {
          setIsOpen(false);
          if (typeof setShowCalendar === 'function') {
            setShowCalendar(false);
          }
        }}
        renderDayContents={renderDayContents}
        calendarContainer={CalendarContainer}
        customInput={
          <CustomInput
            value={selectedValue}
            onClick={() => setIsOpen(true)}
            onChange={(e) => setSelectedValue(e.target.value)}
            placeholder={placeholder}
            hide={hideInput}
            disabled={disabled}
          />
        }
        calendarButton={<span />}
        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
          <CustomHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />
        )}
        disabled={disabled}
      />
    </div>
  );
}

CustomDay.propTypes = {
  day: PropTypes.number.isRequired,
  events: PropTypes.instanceOf(Array),
};

CustomDay.defaultProps = {
  events: [],
};

EventItem.propTypes = {
  title: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
};

CustomInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.func,
  hide: PropTypes.bool,
};

CustomInput.defaultProps = {
  value: null,
  placeholder: 'components.calendar.placeholder',
  hide: false,
};

CustomCalendar.propTypes = {
  placeholder: PropTypes.string,
  selected: PropTypes.oneOfType(PropTypes.string, PropTypes.instanceOf(Date)),
  onNewValue: PropTypes.func,
  show: PropTypes.bool,
  hideInput: PropTypes.bool,
  showTimeInput: PropTypes.bool,
  setShowCalendar: PropTypes.func,
  disabled: PropTypes.bool,
};

CustomCalendar.defaultProps = {
  placeholder: null,
  selected: null,
  onNewValue: (value) => value,
  show: null,
  hideInput: false,
  showTimeInput: true,
  setShowCalendar: null,
  disabled: false,
};

CustomHeader.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  increaseMonth: PropTypes.func.isRequired,
  decreaseMonth: PropTypes.func.isRequired,
};

TimePickerWrapper.propTypes = {
  filteredEvents: PropTypes.instanceOf(Array).isRequired,
  saveAndSyncWithGoogle: PropTypes.func.isRequired,
  showTimeInput: PropTypes.func.isRequired,
};

export default CustomCalendar;
