import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import * as yup from 'yup';

import styles from './index.module.scss';
import CompanyLogo from '../../assets/images/footerLogo.png';
import { ReactComponent as PenSvg } from '../../assets/svgs/Pen.svg';
import {
  signatureTypes,
  userRoles,
  installedDevice as installedDeviceConstants,
} from '../../constant';
import { useBasicInformationContext } from '../../pages/task-forms/context/basicInformationContext';
import { useServiceContext } from '../../pages/task-forms/context/service-context';
import {
  getElectricityTestReport,
  saveElectricityTestReport,
  saveElectricityTestReportCustomerSign,
  saveElectricityTestReportEmployeeSign,
} from '../../services/report';
import { generateBlobSrc } from '../../utils/common';
import { getOriginalErrors } from '../../utils/errorHandler';
import Button from '../button';
import CalendarControl from '../form-fields/calendar-control';
import CheckBoxControl from '../form-fields/checkbox-control';
import DropDownControl from '../form-fields/drop-down-control';
import NumberControl from '../form-fields/number-control';
import TextControl from '../form-fields/text-control';
import SignatureModal from '../signature-modal';
import Translate from '../translate';

function InstalledDeviceDropDown({ getValue, setValue }) {
  const { t } = useTranslation('lang');
  const installedDeviceOptions = Object.keys(installedDeviceConstants).map((key) => ({
    id: key,
    label: t(installedDeviceConstants[key]),
  }));

  return (
    <DropDownControl
      label="assign_teams.test_report.installed_device"
      options={installedDeviceOptions}
      selected={getValue('installedDevice')}
      onChange={(value) => setValue('installedDevice', value, { shouldDirty: true })}
      selectionKey="id"
    />
  );
}

function CustomerInformation() {
  const { customerInformation, offerRequest } = useBasicInformationContext();
  return (
    <div className={styles.customerInfoContainer}>
      <div className={`d-flex align-items-center justify-content-between ${styles.flexContainer}`}>
        <div>
          <Translate id="components.user.name" />
        </div>
        <div>{`${customerInformation.firstName} ${customerInformation.lastName}`}</div>
      </div>
      <div className={`d-flex align-items-center justify-content-between ${styles.flexContainer}`}>
        <div>
          <Translate id="components.address.address" />
        </div>
        <div>{offerRequest.installationTargetAddress}</div>
      </div>
      <div className={`d-flex align-items-center justify-content-between ${styles.flexContainer}`}>
        <div>
          <Translate id="components.address.postal_code" />
        </div>
        <div>{offerRequest.installationTargetZipCode}</div>
      </div>
      <div className={`d-flex align-items-center justify-content-between ${styles.flexContainer}`}>
        <div>
          <Translate id="components.address.city" />
        </div>
        <div>{offerRequest.installationTargetCity}</div>
      </div>
      <div className={`d-flex align-items-center justify-content-between ${styles.flexContainer}`}>
        <div>
          <Translate id="components.user.phone" />
        </div>
        <div>{customerInformation.phone}</div>
      </div>
      <div className={`d-flex align-items-center justify-content-between ${styles.flexContainer}`}>
        <div>
          <Translate id="components.user.email" />
        </div>
        <div>{customerInformation.email}</div>
      </div>
    </div>
  );
}

function MakeAndModelField({ register }) {
  return (
    <TextControl
      label="assign_teams.test_report.make_and_model"
      name="makeAndModel"
      type="text"
      register={register}
    />
  );
}

function MeasuringDeviceField({ register }) {
  return (
    <TextControl
      label="assign_teams.test_report.measuring_device"
      name="measuringDevice"
      type="text"
      register={register}
    />
  );
}

function EarthContinuityField({ getValue, setValue }) {
  return (
    <div className={`${styles.whiteBackgroundInput}`}>
      <NumberControl
        label="assign_teams.test_report.earth_continuity"
        name="earthContinuity"
        type="number"
        value={getValue('earthContinuity')}
        onChange={(value) => setValue('earthContinuity', value, { shouldDirty: true })}
      />
    </div>
  );
}
function ShortCircuitCurrentField({ getValue, setValue }) {
  return (
    <div className={`${styles.whiteBackgroundInput}`}>
      <NumberControl
        label="assign_teams.test_report.short_circuit_current"
        name="shortCircuitCurrent"
        type="number"
        value={getValue('shortCircuitCurrent')}
        onChange={(value) => setValue('shortCircuitCurrent', value, { shouldDirty: true })}
      />
    </div>
  );
}

function HeatPumpFusesField({ getValue, setValue }) {
  return (
    <CheckBoxControl
      id="heatPumpFuses"
      translationId="assign_teams.test_report.heat_pump_fuses"
      isChecked={getValue('heatPumpFuses')}
      onChange={(value) => setValue('heatPumpFuses', value, { shouldDirty: true })}
      name="heatPumpFuses"
    />
  );
}
function PhaseSequenceField({ getValue, setValue }) {
  return (
    <CheckBoxControl
      id="phaseSequenceFuses"
      translationId="assign_teams.test_report.phase_sequence"
      isChecked={getValue('phaseSequenceFuses')}
      onChange={(value) => setValue('phaseSequenceFuses', value, { shouldDirty: true })}
      name="heatPumpFuses"
    />
  );
}
function SafetySwitchField({ getValue, setValue }) {
  return (
    <CheckBoxControl
      id="safetySwitch"
      translationId="assign_teams.test_report.safety_switch"
      isChecked={getValue('safetySwitch')}
      onChange={(value) => setValue('safetySwitch', value, { shouldDirty: true })}
      name="safetySwitch"
    />
  );
}
function SupplyWaterSensorsField({ getValue, setValue }) {
  return (
    <CheckBoxControl
      id="supplyWaterSensor"
      translationId="assign_teams.test_report.supply_water_sensor"
      isChecked={getValue('supplyWaterSensor')}
      onChange={(value) => setValue('supplyWaterSensor', value, { shouldDirty: true })}
      name="supplyWaterSensor"
    />
  );
}
function OutdoorTemperatureSensorField({ getValue, setValue }) {
  return (
    <CheckBoxControl
      id="outdoorTemperatureSensor"
      translationId="assign_teams.test_report.outdoor_temperature_sensor"
      isChecked={getValue('outdoorTemperatureSensor')}
      onChange={(value) => setValue('outdoorTemperatureSensor', value, { shouldDirty: true })}
      name="outdoorTemperatureSensor"
    />
  );
}

function DateOfMeasurementField({ getValue, setValue }) {
  return (
    <CalendarControl
      label="assign_teams.test_report.date_of_measurement"
      selected={getValue('dateOfMeasurement')}
      onChange={(value) => setValue('dateOfMeasurement', value, { shouldDirty: true })}
    />
  );
}

function ElectricalInstallationContractorField({ register }) {
  return (
    <TextControl
      label="assign_teams.test_report.electrical_installation_contractor"
      name="electricalInstallationContractor"
      type="text"
      register={register}
    />
  );
}

function PersonPerformingMeasurementField({ register }) {
  return (
    <TextControl
      label="assign_teams.test_report.the_person_performing_the_measurement"
      name="personPerformingMeasurement"
      type="text"
      register={register}
    />
  );
}

function DirectorOfElectricalWorksField({ register }) {
  return (
    <TextControl
      label="assign_teams.test_report.director_of_electrical_works"
      name="directorOfElectricalWorks"
      type="text"
      register={register}
    />
  );
}

function SignaturePreview({ signature, label, onEdit }) {
  return signature.signatureType === signatureTypes.TYPE ? (
    <div>
      <p className={`${styles.signPreviewLabel} d-flex justify-content-between`}>
        <span>
          <Translate id={label} />:
        </span>
        <PenSvg className={styles.signEditButton} onClick={onEdit} />
      </p>
      <p>{signature.signature}</p>
    </div>
  ) : (
    <>
      <p className={`${styles.signPreviewLabel} d-flex justify-content-between`}>
        <span>
          <Translate id={label} />:
        </span>
        <PenSvg className={styles.signEditButton} onClick={onEdit} />
      </p>
      <img
        src={signature.signature}
        width={signature.signatureType === signatureTypes.UPLOAD ? 150 : '100%'}
        alt=""
      />
    </>
  );
}

function Render({ when, children }) {
  return when ? children : null;
}

function ElectricityTestReportForm({ setLoading }) {
  const { t } = useTranslation('lang');
  const [userRole, setUserRole] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [customerSignature, setCustomerSignature] = useState({});
  const [employeeSignature, setEmployeeSignature] = useState({});
  const [oldElectricityReport, setOldElectricityReport] = useState(null);

  const dataInformationSchema = yup.object({
    makeAndModel: yup.string(),
    measuringDevice: yup.string(),
    earthContinuity: yup.number().default(0),
    shortCircuitCurrent: yup.number().default(0),
    electricalInstallationContractor: yup.string(),
    personPerformingMeasurement: yup.string(),
    directorOfElectricalWorks: yup.string(),
    installedDevice: yup.string(),
    dateOfMeasurement: yup.string(),
    heatPumpFuses: yup.boolean().default(false),
    phaseSequenceFuses: yup.boolean().default(false),
    safetySwitch: yup.boolean().default(false),
    supplyWaterSensor: yup.boolean().default(false),
    outdoorTemperatureSensor: yup.boolean().default(false),
    customerSignatureAdded: yup.boolean().default(false),
    employeeSignatureAdded: yup.boolean().default(false),
  });
  const [electricityTestReport, setElectricityTestReport] = useState({});
  const [showSignatureButtons] = useState(true);

  const {
    register,

    handleSubmit,
    watch,
    clearErrors,
    setValue,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm({
    resolver: yupResolver(dataInformationSchema),
  });

  const { companyId, employeeId, adminId } = useParams();
  const { service } = useServiceContext();

  watch(
    (
      {
        makeAndModel,
        measuringDevice,
        earthContinuity,
        shortCircuitCurrent,
        electricalInstallationContractor,
        personPerformingMeasurement,
        directorOfElectricalWorks,
        installedDevice,
        dateOfMeasurement,
        heatPumpFuses,
        phaseSequenceFuses,
        safetySwitch,
        supplyWaterSensor,
        outdoorTemperatureSensor,
        customerSignatureAdded,
        employeeSignatureAdded,
      },
      name
    ) => {
      setElectricityTestReport((prev) => ({
        ...prev,
        makeAndModel,
        measuringDevice,
        earthContinuity,
        shortCircuitCurrent,
        electricalInstallationContractor,
        personPerformingMeasurement,
        directorOfElectricalWorks,
        installedDevice,
        dateOfMeasurement,
        heatPumpFuses,
        phaseSequenceFuses,
        safetySwitch,
        supplyWaterSensor,
        outdoorTemperatureSensor,
        customerSignatureAdded,
        employeeSignatureAdded,
      }));
      clearErrors(name.name);
    }
  );

  const fetchElectricityTestReport = async () => {
    try {
      setLoading(true);
      const {
        data: {
          data: { electricityTestReport: report },
        },
      } = await getElectricityTestReport(companyId, employeeId || adminId, service?.id);
      if (report) {
        const reportData = {
          ...report.data,
          heatPumpFuses: !!report.data.heatPumpFuses,
          phaseSequenceFuses: !!report.data.phaseSequenceFuses,
          outdoorTemperatureSensor: !!report.data.outdoorTemperatureSensor,
          safetySwitch: !!report.data.safetySwitch,
          supplyWaterSensor: !!report.data.supplyWaterSensor,
          installedDevice: report.data.installedDevice ?? '',
          dateOfMeasurement: report.data.dateOfMeasurement ?? new Date().toISOString(),
          earthContinuity: report.data.earthContinuity ?? 0,
          shortCircuitCurrent: report.data.shortCircuitCurrent ?? 0,
          employeeSignatureType: report.employeeSignatureType,
          employeeSignature: report.employeeSignature,
          employeeSignedAt: report.employeeSignedAt,
          signedEmployeeId: report.signedEmployeeId,
          customerSignatureType: report.customerSignatureType,
          customerSignature: report.customerSignature,
          customerSignedAt: report.customerSignedAt,
        };
        setElectricityTestReport(reportData);
        setOldElectricityReport(reportData);
        reset(reportData);
        setEmployeeSignature({
          signature: report.employeeSignature,
          signatureType: report.employeeSignatureType,
        });
        setCustomerSignature({
          signature: report.customerSignature,
          signatureType: report.customerSignatureType,
        });
      } else {
        const reportData = {
          dateOfMeasurement: new Date().toISOString(),
          directorOfElectricalWorks: '',
          earthContinuity: 0,
          electricalInstallationContractor: '',
          heatPumpFuses: false,
          installedDevice: '',
          makeAndModel: '',
          measuringDevice: '',
          outdoorTemperatureSensor: false,
          personPerformingMeasurement: '',
          phaseSequenceFuses: false,
          safetySwitch: false,
          shortCircuitCurrent: 0,
          supplyWaterSensor: false,
        };
        reset(reportData);
      }
      setLoading(false);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error);
      });
      setLoading(false);
    }
  };

  const saveSignatureForElectricityReport = async (reportId) => {
    if (!reportId) {
      return;
    }

    if (Object.keys(employeeSignature).length > 0 && employeeSignature.data) {
      await saveElectricityTestReportEmployeeSign(
        companyId,
        employeeId || adminId,
        service?.id,
        employeeSignature.data
      );
    }

    if (Object.keys(customerSignature).length > 0 && customerSignature.data) {
      await saveElectricityTestReportCustomerSign(
        companyId,
        employeeId || adminId,
        service?.id,
        customerSignature.data
      );
    }
  };

  const persistExistingSignature = () => {
    if (!oldElectricityReport) return;
    const {
      employeeSignatureType,
      employeeSignature: existingEmployeeSignature,
      employeeSignedAt,
      signedEmployeeId,
      customerSignatureType,
      customerSignature: existingCustomerSignature,
      customerSignedAt,
    } = oldElectricityReport;
    if (
      employeeSignatureType &&
      existingEmployeeSignature &&
      employeeSignedAt &&
      signedEmployeeId
    ) {
      setElectricityTestReport((prev) => ({
        ...prev,
        employeeSignatureType,
        employeeSignedAt,
        signedEmployeeId,
        employeeSignature: existingEmployeeSignature,
      }));
    }

    if ((customerSignatureType && existingCustomerSignature, customerSignedAt)) {
      setElectricityTestReport((prev) => ({
        ...prev,
        customerSignatureType,
        customerSignature: existingCustomerSignature,
        customerSignedAt,
      }));
    }
  };

  const onSave = async () => {
    setLoading(true);
    try {
      persistExistingSignature();
      const res = await saveElectricityTestReport(companyId, employeeId || adminId, service?.id, {
        data: electricityTestReport,
      });
      await saveSignatureForElectricityReport(res?.data?.data?.electricityTestReport?.id);
      toast.success(t('assign_teams.test_report.electricity_test_report_save_success'));
      await fetchElectricityTestReport();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error);
      });
    }
  };

  const setSignature = async (data) => {
    setLoading(true);

    try {
      if (userRole.role === userRoles.CUSTOMER.role) {
        const signatureType = data.get('signatureType');

        if (signatureType === signatureTypes.TYPE) {
          setCustomerSignature({
            signature: data.get('signature'),
            signatureType: data.get('signatureType'),
            data,
          });
        }

        if (signatureType === signatureTypes.DRAW || signatureType === signatureTypes.UPLOAD) {
          const signatureBlob = generateBlobSrc(data.get('customerSignatureFile'));

          if (!signatureBlob) {
            toast.error('Invalid signature');
            return;
          }

          setCustomerSignature({
            signature: signatureBlob,
            signatureType: data.get('signatureType'),
            data,
          });
        }

        setValue('customerSignatureAdded', !getValues('customerSignatureAdded'), {
          shouldDirty: true,
        });
        setLoading(false);
      }
      if (userRole.role === userRoles.INSTALLER.role) {
        const signatureType = data.get('signatureType');

        if (signatureType === signatureTypes.TYPE) {
          setEmployeeSignature({
            signature: data.get('signature'),
            signatureType: data.get('signatureType'),
            data,
          });
        }

        if (signatureType === signatureTypes.DRAW || signatureType === signatureTypes.UPLOAD) {
          const signatureBlob = generateBlobSrc(data.get('employeeSignatureFile'));

          if (!signatureBlob) {
            toast.error('Invalid signature');
            return;
          }

          setEmployeeSignature({
            signature: signatureBlob,
            signatureType: data.get('signatureType'),
            data,
          });
        }
        setValue('employeeSignatureAdded', !getValues('employeeSignatureAdded'), {
          shouldDirty: true,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const onCloseModal = () => {
    setUserRole(null);
    setShowModal(false);
  };

  useEffect(() => {
    if (userRole) {
      setShowModal(true);
    }
  }, [userRole]);

  useEffect(() => {
    if ((service?.id && employeeId) || adminId) {
      fetchElectricityTestReport();
    }
  }, [service, employeeId, adminId]);

  return (
    <div className={styles.formMainContainer}>
      <div className={styles.imgContainer}>
        <img src={CompanyLogo} alt="company_logo" />
      </div>
      <div className={styles.formTitleContainer}>
        <h6 className={styles.formTitle}>
          <Translate id="assign_teams.test_report.electricity_test_report" />
        </h6>
      </div>
      <form onSubmit={handleSubmit(onSave)}>
        <CustomerInformation />
        <div className={styles.formContentContainer}>
          <div className={styles.formField}>
            <InstalledDeviceDropDown getValue={getValues} setValue={setValue} />
          </div>
          <div className={styles.formField}>
            <MakeAndModelField register={register} />
          </div>
          <div className={styles.formField}>
            <MeasuringDeviceField register={register} />
          </div>
          <div className={styles.formField}>
            <EarthContinuityField getValue={getValues} setValue={setValue} />
          </div>
          <div className={styles.formField}>
            <ShortCircuitCurrentField getValue={getValues} setValue={setValue} />
          </div>
        </div>
        <div className={styles.formContentContainer}>
          <div className={styles.formField}>
            <HeatPumpFusesField getValue={getValues} setValue={setValue} />
          </div>
          <div className={styles.formField}>
            <PhaseSequenceField getValue={getValues} setValue={setValue} />
          </div>
          <div className={styles.formField}>
            <SafetySwitchField getValue={getValues} setValue={setValue} />
          </div>
          <div className={styles.formField}>
            <SupplyWaterSensorsField getValue={getValues} setValue={setValue} />
          </div>
          <div className={styles.formField}>
            <OutdoorTemperatureSensorField getValue={getValues} setValue={setValue} />
          </div>
        </div>
        <div className={styles.formContentContainer}>
          <div className={styles.formField}>
            <DateOfMeasurementField getValue={getValues} setValue={setValue} />
          </div>
          <div className={styles.formField}>
            <ElectricalInstallationContractorField register={register} />
          </div>
          <div className={styles.formField}>
            <PersonPerformingMeasurementField register={register} />
          </div>
          <div className={styles.formField}>
            <DirectorOfElectricalWorksField register={register} />
          </div>
        </div>
        <div className={`${styles.signBtnMainContainer}`}>
          <div className={`${styles.signBtnContainer}`}>
            <div className={`${styles.btnContainer}`}>
              <Button
                className={`projectBtn ${isDirty ? styles.submitBtn : ''}`}
                disabled={!isDirty}
                type="submit"
              >
                <Translate id="components.button.save" />
              </Button>
            </div>
            <Render when={showSignatureButtons}>
              <Render when={!employeeSignature.signature}>
                <div
                  className={`d-flex align-items-center justify-content-between ${styles.btnContainer}`}
                >
                  <div>
                    <Translate id="assign_teams.test_report.installer_sign" />
                  </div>
                  <div>
                    <Button
                      className={`projectBtn ${styles.signBtn}`}
                      onClick={() => setUserRole(userRoles.INSTALLER)}
                    >
                      <Translate id="components.signature.sign" />
                      <PenSvg className={`${styles.signBtnIcon}`} />
                    </Button>
                  </div>
                </div>
              </Render>
              <Render when={employeeSignature.signature}>
                <SignaturePreview
                  label="assign_teams.test_report.installer_sign"
                  signature={employeeSignature}
                  onEdit={() => setUserRole(userRoles.INSTALLER)}
                />
              </Render>
            </Render>

            <Render when={showSignatureButtons}>
              <Render when={!customerSignature.signature}>
                <div
                  className={`d-flex align-items-center justify-content-between ${styles.btnContainer}`}
                >
                  <div>
                    <Translate id="assign_teams.test_report.customer_sign" />
                  </div>
                  <div>
                    <Button
                      className={`projectBtn ${styles.signBtn}`}
                      onClick={() => setUserRole(userRoles.CUSTOMER)}
                    >
                      <Translate id="components.signature.sign" />
                      <PenSvg className={`${styles.signBtnIcon}`} />
                    </Button>
                  </div>
                </div>
              </Render>
              <Render when={customerSignature.signature}>
                <SignaturePreview
                  label="assign_teams.test_report.customer_sign"
                  signature={customerSignature}
                  onEdit={() => setUserRole(userRoles.CUSTOMER)}
                />
              </Render>
            </Render>
          </div>

          {showModal && (
            <SignatureModal
              userRole={userRole}
              onClose={onCloseModal}
              showModal={showModal}
              setSignature={setSignature}
              signatureFileName={
                userRole === userRoles.CUSTOMER ? 'customerSignatureFile' : 'employeeSignatureFile'
              }
            />
          )}
        </div>
      </form>
    </div>
  );
}

InstalledDeviceDropDown.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};
MakeAndModelField.propTypes = {
  register: PropTypes.func.isRequired,
};
MeasuringDeviceField.propTypes = {
  register: PropTypes.func.isRequired,
};
EarthContinuityField.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};
ShortCircuitCurrentField.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};
HeatPumpFusesField.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};
PhaseSequenceField.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};
SafetySwitchField.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};
SupplyWaterSensorsField.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};
OutdoorTemperatureSensorField.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};
DateOfMeasurementField.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};
ElectricalInstallationContractorField.propTypes = {
  register: PropTypes.func.isRequired,
};
PersonPerformingMeasurementField.propTypes = {
  register: PropTypes.func.isRequired,
};
DirectorOfElectricalWorksField.propTypes = {
  register: PropTypes.func.isRequired,
};
SignaturePreview.propTypes = {
  signature: PropTypes.instanceOf(Object).isRequired,
};
ElectricityTestReportForm.propTypes = { setLoading: PropTypes.func.isRequired };

export default ElectricityTestReportForm;
