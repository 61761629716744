const constructionMethods = {
  LOG_HOUSE: 'offer.request.construction_type.log_house',
  WOODEN_FRAME_BOARD_CLADDING: 'offer.request.construction_type.wooden_frame_board_cladding',
  WOODEN_FRAME_BRICK_CLADDING: 'offer.request.construction_type.wooden_frame_brick_cladding',
  FULL_BRICK_HOUSE: 'offer.request.construction_type.full_brick_house',
  STONE_HOUSE_INGOT_SIPOREX: 'offer.request.construction_type.stone_house_ingot_siporex',
  SHEET_METAL_ELEMENT: 'offer.request.construction_type.sheet_metal_element',
  WOODEN_FRAMED_SHEET_METAL_ELEMENT:
    'offer.request.construction_type.wooden_framed_sheet_metal_element',
  STEEL_FRAMED_SHEET_METAL_ELEMENT:
    'offer.request.construction_type.steel_framed_sheet_metal_element',
  WOODEN_FRAME_WITH_CLAPBOARD_SIDING:
    'offer.request.construction_type.wooden_frame_with_clapboard_siding',
  WOODEN_FRAME_WITH_BRICK_SIDING: 'offer.request.construction_type.wooden_frame_with_brick_siding',
  STONE_HOUSE_CONCRETE_OR_AERATED_CONCRETE_BLOCKS:
    'offer.request.construction_type.stone_house_concrete_or_aerated_concrete_blocks',
  METAL_PANEL: 'offer.request.construction_type.metal_panel',
  WOODEN_FRAME_METAL_PANEL: 'offer.request.construction_type.wooden_frame_metal_panel',
  STEEL_FRAME_METAL_PANEL: 'offer.request.construction_type.steel_frame_metal_panel',
};
Object.freeze(constructionMethods);

const installationTypes = {
  NEW_BUILD: 'offer.request.installation_type.new_build',
  RENOVATION_SITE: 'offer.request.installation_type.renovation_site',
};
Object.freeze(installationTypes);

const offerRequestTypes = {
  GROUND_HEATING: 'offer.request.ground_heating',
  AIR_HEATING: 'offer.request.air_heating',
  FLOOR_HEATING: 'offer.request.floor_heating',
  HEAT_CIRCULATION: 'offer.request.heat_circulation',
  GROUND_SOURCE_HEAT_PUMP: 'offer.request.ground_source_heat_pump',
  GROUND_SOURCE_HEAT_PUMP_UNDERFLOOR_HEATING:
    'offer.request.ground_source_heat_pump_underfloor_heating',
  GROUND_SOURCE_HEAT_PUMP_RADIATOR_SYSTEM: 'offer.request.ground_source_heat_pump_radiator_system',
  AIR_SOURCE_HEAT_PUMP: 'offer.request.air_source_heat_pump',
  AIR_SOURCE_HEAT_PUMP_UNDERFLOOR_HEATING: 'offer.request.air_source_heat_pump_underfloor_heating',
  UNDERFLOOR_HEATING: 'offer.request.underfloor_heating',
  RADIATOR_SYSTEM: 'offer.request.radiator_system',
  DOMESTIC_WATER_PIPE_SYSTEM: 'offer.request.domestic_water_pipe_system',
};
Object.freeze(offerRequestTypes);

const offerRequestStatuses = {
  ALL: {
    label: 'components.status.all',
    value: 'ALL',
  },
  NEW: {
    label: 'components.status.new',
    value: 'NEW',
    readableLabel: 'home.new',
  },
  OFFER_CREATION: {
    label: 'components.status.offer_creation',
    value: 'OFFER_CREATION',
    readableLabel: 'home.offer_creation',
  },
  OFFER_CALCULATION: {
    label: 'components.status.offer_calculation',
    value: 'OFFER_CALCULATION',
    readableLabel: 'home.offer_creation',
  },
  OFFER_SENT: {
    label: 'components.status.offer_sent',
    value: 'OFFER_SENT',
    readableLabel: 'home.offer_sent',
  },
  ASSIGNED: {
    label: 'components.status.assigned',
    value: 'ASSIGNED',
    readableLabel: 'home.assigned',
  },
  NOT_ASSIGNED: {
    label: 'components.status.not_assigned',
    value: 'NOT_ASSIGNED',
    readableLabel: '',
  },
  RECEIVED_TIME: {
    label: 'components.status.received_time',
    value: 'RECEIVED_TIME',
    readableLabel: 'home.received_time',
  },
  ON_SITE_INSPECTION: {
    label: 'components.status.on_site_inspection',
    value: 'ON_SITE_INSPECTION',
    readableLabel: 'home.on_site_inspection',
  },
  TASK: {
    label: 'components.status.task',
    value: 'TASK',
    readableLabel: 'home.task',
  },
  ACCEPTED: {
    label: 'components.status.accepted',
    value: 'ACCEPTED',
    readableLabel: 'home.accepted',
  },
  DECLINED: {
    label: 'components.status.declined',
    value: 'DECLINED',
    readableLabel: 'home.declined',
  },
  OFFER_COMPLETION: {
    label: 'components.status.completed',
    value: 'OFFER_COMPLETION',
    readableLabel: 'home.completed',
  },
};
Object.freeze(offerRequestStatuses);

const heatCirculationMethod = {
  WATER_CIRCULATION_FLOOR_HEATING: 'offer.technical_information.water_circulation_floor_heating',
  WATER_CIRCULATING_RADIATOR_HEATING:
    'offer.technical_information.water_circulating_radiator_heating',
  WATER_CIRCULATION_FLOOR_RADIATOR_HEATING:
    'offer.technical_information.water_circulation_floor_radiator_heating',
  AIR_HEATING: 'offer.technical_information.air_heating',
};
Object.freeze(heatCirculationMethod);

const groundFloorStructureMethod = {
  UNDERGROUND_CONCRETE_CASTING: 'offer.technical_information.underground_concrete_casting',
  ROSS_BASE_WOOD: 'offer.technical_information.ross_base_wood',
  ROSS_BASE_CONCRETE_CAVITY: 'offer.technical_information.ross_base_concrete_cavity',
};
Object.freeze(groundFloorStructureMethod);

const secondFloorStructureMethod = {
  TREE: 'offer.technical_information.tree',
  CONCRETE_CAST: 'offer.technical_information.concrete_cast',
  CONCRETE_HOLLOW_SLAB: 'offer.technical_information.concrete_hollow_slab',
};
Object.freeze(secondFloorStructureMethod);

const userRoles = {
  ADMIN: { label: 'roles.admin', role: 'ADMIN' },
  SALES_PERSON: { label: 'roles.sales_person', role: 'SALES_PERSON' },
  CUSTOMER: { label: 'roles.customer', role: 'CUSTOMER' },
  INSTALLER: { label: 'roles.installer', role: 'INSTALLER' },
  DRILLER: { label: 'roles.driller', role: 'DRILLER' },
  COMPANY_ADMIN: { label: 'roles.company_admin', role: 'COMPANY_ADMIN' },
  COMPANY_EMPLOYEE: { label: 'roles.company_employee', role: 'COMPANY_EMPLOYEE' },
};

Object.freeze(userRoles);

const attachmentableTypes = {
  TECHNICAL_INFORMATION: 'TECHNICAL_INFORMATION',
  HEATING_DEVICE: 'HEATING_DEVICE',
  OFFER: 'OFFER',
  COMPONENT: 'COMPONENT',
  SERVICE: 'SERVICE',
};
Object.freeze(attachmentableTypes);

const signatureTypes = {
  TYPE: 'TYPE',
  UPLOAD: 'UPLOAD',
  DRAW: 'DRAW',
};
Object.freeze(signatureTypes);

const heatingDeviceOrderedFrom = {
  MANUFACTURER: 'MANUFACTURER',
  WAREHOUSE: 'WAREHOUSE',
};
Object.freeze(heatingDeviceOrderedFrom);

const installedDeviceOptions = [
  { id: 1, value: 'EARTH_PUMP', label: 'assign_teams.test_report.earth_pump' },
  {
    id: 2,
    value: 'AIR_TO_WATER_HEAT_PUMP',
    label: 'assign_teams.test_report.air_to_water_heat_pump',
  },
  {
    id: 3,
    value: 'AIR_TO_AIR_HEAT_PUMP',
    label: 'assign_teams.test_report.air_to_air_heat_pump',
  },
  {
    id: 4,
    value: 'HOT_WATER_STORAGE_TANK',
    label: 'assign_teams.test_report.hot_water_storage_tank',
  },
];
const companyUserTaskStatuses = {
  NEW: 'NEW',
  COMPLETED: 'COMPLETED',
};
Object.freeze(companyUserTaskStatuses);

const serviceTypes = {
  INSTALLING: 'INSTALLING',
  DRILLING: 'DRILLING',
};

Object.freeze(serviceTypes);
const offerServiceTypes = {
  DRILLING: 'DRILLING',
  INSTALLING: 'INSTALLING',
};
Object.freeze(offerServiceTypes);

const scrollableTargetId = 'scrollableTarget';

const currentHeatingSystems = {
  OIL_HEATING: 'offer.onsite_memo.basic_information.current_heating_systems.oil_heating',
  WOOD_HEATING: 'offer.onsite_memo.basic_information.current_heating_systems.wood_heating',
  OIL_WOOD_HEATING: 'offer.onsite_memo.basic_information.current_heating_systems.oil_wood_heating',
  DIRECT_ELECTRIC_HEATING:
    'offer.onsite_memo.basic_information.current_heating_systems.direct_electric_heating',
  WATER_CIRCULATING_ELECTRIC_HEATING:
    'offer.onsite_memo.basic_information.current_heating_systems.water_circulating_electric_heating',
  DISTRICT_HEATING: 'offer.onsite_memo.basic_information.current_heating_systems.district_heating',
  GAS_HEATING: 'offer.onsite_memo.basic_information.current_heating_systems.gas_heating',
  GROUND_SOURCE_HEAT_PUMP:
    'offer.onsite_memo.basic_information.current_heating_systems.ground_source_heat_pump',
  STOKER_HEATING: 'offer.onsite_memo.basic_information.current_heating_systems.stoker_heating',
  AIR_TO_WATER_HEAT_PUMP:
    'offer.onsite_memo.basic_information.current_heating_systems.air_to_water_heat_pump',
};
Object.freeze(currentHeatingSystems);

const rockQualities = {
  GREY_STONE: 'assign_teams.drilling_crew.rock_quality_options.grey_stone',
  HARD_STONE: 'assign_teams.drilling_crew.rock_quality_options.hard_stone',
  BROKEN_STONE: 'assign_teams.drilling_crew.rock_quality_options.broken_stone',
};

Object.freeze(rockQualities);

const waterProductions = {
  RICH: 'assign_teams.drilling_crew.water_production_options.rich',
  MODERATE: 'assign_teams.drilling_crew.water_production_options.moderate',
  LOW: 'assign_teams.drilling_crew.water_production_options.low',
  DRY: 'assign_teams.drilling_crew.water_production_options.dry',
};
Object.freeze(waterProductions);

const geothermalFluids = {
  NATURET: 'assign_teams.drilling_crew.geothermal_fluid_options.naturet',
  THERMOL: 'assign_teams.drilling_crew.geothermal_fluid_options.thermol',
};
Object.freeze(geothermalFluids);

const drillingWasteHandling = {
  BLOWING_TO_PALLET: 'assign_teams.drilling_crew.drilling_waste_handling_options.blowing_to_pallet',
  BLOWING_TO_CONTAINER:
    'assign_teams.drilling_crew.drilling_waste_handling_options.blowing_to_container',
  BLOWING_TO_PLACE: 'assign_teams.drilling_crew.drilling_waste_handling_options.blowing_to_place',
};
Object.freeze(drillingWasteHandling);

const installedDevice = {
  GEOTHERMAL_HEAT_PUMP: 'assign_teams.test_report.installed_device_options.geothermal_heat_pump',
  AIR_WATER_HEAT_PUMP: 'assign_teams.test_report.installed_device_options.air_water_heat_pump',
  AIR_HEAT_PUMP: 'assign_teams.test_report.installed_device_options.air_heat_pump',
  HOT_WATER_STORAGE_TANK:
    'assign_teams.test_report.installed_device_options.hot_water_storage_tank',
};

Object.freeze(installedDevice);

const heatCollectionPipingOptions = {
  PEM_40_MM_PN10_SDR17:
    'assign_teams.drilling_crew.heat_collection_piping_options.PEM_40_MM_PN10_SDR17',
  PEM_45_mm_PN10_SDR17:
    'assign_teams.drilling_crew.heat_collection_piping_options.PEM_45_mm_PN10_SDR17',
  PEM_50_mm_PN10_SDR17:
    'assign_teams.drilling_crew.heat_collection_piping_options.PEM_50_mm_PN10_SDR17',
};
Object.freeze(heatCollectionPipingOptions);

module.exports = {
  offerRequestTypes,
  offerRequestStatuses,
  constructionMethods,
  installationTypes,
  heatCirculationMethod,
  groundFloorStructureMethod,
  secondFloorStructureMethod,
  userRoles,
  attachmentableTypes,
  signatureTypes,
  heatingDeviceOrderedFrom,
  installedDeviceOptions,
  companyUserTaskStatuses,
  serviceTypes,
  offerServiceTypes,
  scrollableTargetId,
  currentHeatingSystems,
  rockQualities,
  waterProductions,
  geothermalFluids,
  drillingWasteHandling,
  installedDevice,
  heatCollectionPipingOptions,
};
