import { gql } from 'graphql-request';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import styles from './Home.module.scss';
import sortOptions from './sortOptions.json';
import statuses from './statuses.json';
import { ReactComponent as PlusSVG } from '../../../assets/svgs/Plus.svg';
import Button from '../../../components/button';
import DataTable from '../../../components/data-table';
import DropDown from '../../../components/drop-down';
import Layout from '../../../components/layout';
import OfferRequestTypesModal from '../../../components/offer-request-types-modal';
import SearchBox from '../../../components/search-box';
import { SortDropdown } from '../../../components/sort-drop-down';
import Translate from '../../../components/translate';
import {
  getBasicInformationSessionInfo,
  setBasicInformationSessionInfo,
} from '../../../services/basicInformation';
import { fetchOfferRequests as fetchOfferRequestsService } from '../../../services/graphql/offer-request';

function OfferRequest({ onClick }) {
  return (
    <div
      className={`d-flex align-items-center justify-content-between ${styles.offerRequestContainer}`}
    >
      <div>
        <p className={`${styles.offerTitle}`}>
          <Translate id="components.navbar.offer_requests" />
        </p>
      </div>
      <div>
        <Button className="createNewBtn" onClick={onClick}>
          <PlusSVG className="createNewBtnIcon" /> <Translate id="components.button.create_new" />
        </Button>
      </div>
    </div>
  );
}

function Home({ setLoading }) {
  const { t } = useTranslation('lang');

  const translateObject = (objects, field = 'label') =>
    objects.map((object) => {
      const translatedObject = { ...object, [field]: t(object[field]) };
      return translatedObject;
    });
  const translatedStatuses = translateObject(statuses);
  const translatedSortOptions = translateObject(sortOptions);
  const navigate = useNavigate();
  const [selectedFilterOption, setSelectedFilterOption] = useState('ALL');
  const [searchTerm, setSearchTerm] = useState('');
  const [showNewOfferRequestTypeModal, setShowNewOfferRequestTypeModal] = useState(false);
  const [sortingOrder, setSortingOrder] = useState('DESC');
  const [sortOption, setSortOption] = useState('createdAt');
  const [queryParams, setQueryParams] = useState(
    `exclude[status]=TASK&sortBy=${sortOption}&sortOrder=${sortingOrder}`
  );
  const [offerRequests, setOfferRequests] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [nextCursor, setNextCursor] = useState(null);
  const { data, isLoading, error, refetch, fetchNextPage } = useInfiniteQuery(
    ['offerRequests', queryParams],
    async ({ pageParam = null }) => {
      const requestData = await fetchOfferRequestsService(
        gql`
          query ($queryParams: String, $first: Int!, $after: String, $limit: Int) {
            offerRequests(queryParams: $queryParams, first: $first, after: $after) {
              edges {
                node {
                  id
                  createdAt
                  updatedAt
                  customer {
                    address
                    fullName
                    city
                    zipCode
                  }
                  assignment {
                    employee {
                      id
                      fullName
                    }
                  }
                  offerRequestProgress(limit: $limit) {
                    event
                    createdAt
                  }
                }
                cursor
              }
              pageInfo {
                endCursor
                hasNextPage
              }
            }
          }
        `,
        queryParams,
        10, // TODO: should be a dynamic value in case of change page limit
        nextCursor ? pageParam : null,
        2
      );
      setLoading(false);
      return requestData;
    },
    {
      getNextPageParam: (lastPage) => lastPage.pageInfo.endCursor,
    }
  );

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (data?.pages?.length) {
      setOfferRequests(
        [].concat(
          ...data.pages.map((page) =>
            page?.edges?.length ? page.edges.map((edge) => edge.node) : []
          )
        )
      );
      setHasNextPage(data.pages[data.pages.length - 1]?.pageInfo?.hasNextPage);
      setNextCursor(data.pages[data.pages.length - 1]?.pageInfo?.endCursor);
    }
  }, [data]);

  const toggleOfferRequestTypesModal = () => setShowNewOfferRequestTypeModal((prev) => !prev);

  const headerOfferRequest = () => <OfferRequest onClick={toggleOfferRequestTypesModal} />;

  const fetchOfferRequests = async (
    sortBy = sortOption,
    sortOrder = sortingOrder,
    status = selectedFilterOption,
    q = searchTerm
  ) => {
    setNextCursor(null);
    let params = `exclude[status]=TASK&sortBy=${sortBy}&sortOrder=${sortOrder}`;
    if (q) {
      params += `&q=${q}`;
    }
    if (status === 'ASSIGNED') {
      params += '&isAssigned=true';
    }
    if (status === 'NOT_ASSIGNED') {
      params += '&isAssigned=false';
    }
    if (status && !['ALL', 'ASSIGNED', 'NOT_ASSIGNED'].includes(status)) {
      params += `&status=${status}`;
    }

    setQueryParams(params);
    await refetch();
    setLoading(false);
  };

  const onFilterOptionSelect = (selectedObj) => {
    setSelectedFilterOption(selectedObj);
    setLoading(true);
    fetchOfferRequests(sortOption, sortingOrder, selectedObj);
  };

  const onSearch = (value) => {
    setSearchTerm(value);
    fetchOfferRequests(sortOption, sortingOrder, undefined, value);
  };

  const debouncedSearch = debounce((newValue) => {
    onSearch(newValue);
  }, 1000);

  const onOfferTypeSelection = (requestedHeatingType) => {
    sessionStorage.clear();
    setBasicInformationSessionInfo({
      ...getBasicInformationSessionInfo(),
      requestedHeatingType,
    });
    navigate(`/offer-request/new/${requestedHeatingType}`);
  };

  const onSortingChange = ({ sortBy, sortOrder }) => {
    setSortOption(sortBy);
    setSortingOrder(sortOrder);
    setLoading(true);
    fetchOfferRequests(sortBy, sortOrder, selectedFilterOption);
  };

  const onOfferRequestSelect = (request) => {
    navigate(`/offer-requests/${request.id}/basic-details`);
  };

  return (
    <Layout headerComponent={headerOfferRequest}>
      <OfferRequestTypesModal
        show={showNewOfferRequestTypeModal}
        onHide={toggleOfferRequestTypesModal}
        onNext={onOfferTypeSelection}
      />
      <div className={`${styles.homePageContainer}`}>
        <div
          className={`d-md-flex align-items-md-center justify-content-md-between ${styles.headerContainer}`}
        >
          <div>
            <SearchBox value={searchTerm} onChange={debouncedSearch} />
          </div>

          <div className="d-md-flex align-items-center justify-content-end">
            <div className="d-flex align-items-center justify-content-end">
              <p className={`${styles.sortTitle}`}>{t('common.sort')}: </p>
              <SortDropdown
                sortOptions={translatedSortOptions}
                sortOrder={sortingOrder}
                selectedSort={sortOption}
                onChange={onSortingChange}
                className={`${styles.shortDropDown}`}
                selectionKey="value"
              />
            </div>
            <div
              className={`d-flex align-items-center justify-content-end ${styles.filterDropDownParent}`}
            >
              <p className={`${styles.filterTitle}`}>{t('common.filter')}: </p>
              <DropDown
                className={`${styles.filterDropDown}`}
                options={translatedStatuses}
                selected={selectedFilterOption}
                onChange={onFilterOptionSelect}
                selectionKey="value"
              />
            </div>
          </div>
        </div>

        {!isLoading && !error && (
          <div className={`${styles.contentContainer}`}>
            {Object.keys(offerRequests).length > 0 && (
              <DataTable
                responseData={offerRequests}
                onRowClick={onOfferRequestSelect}
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
              />
            )}
          </div>
        )}
      </div>
    </Layout>
  );
}

OfferRequest.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Home;
