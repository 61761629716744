/* eslint-disable react/prop-types */
import * as moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './index.module.scss';
import Button from '../../../components/button';
import CalendarControl from '../../../components/form-fields/calendar-control';
import DropDownControl from '../../../components/form-fields/drop-down-control';
import NumberControl from '../../../components/form-fields/number-control';
import OfferPageContentWrapper from '../../../components/offer-page-content-wrapper';
import Translate from '../../../components/translate';
import offerRequestService from '../../../services/offerRequest';
import { getOfferServices, patchOfferService } from '../../../services/offerService';
import { getOriginalErrors } from '../../../utils/errorHandler';

function InstallingCrewFooter({ onNext, onSave, isChanged }) {
  return isChanged ? (
    <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onSave}>
      <Translate id="components.button.save" />
    </Button>
  ) : (
    <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onNext}>
      <Translate id="components.button.next" />
    </Button>
  );
}

function InstallingDateInput({ installingDate, setInstallingDate, disabled }) {
  return (
    <CalendarControl
      label="task.installing_crew.installing_date"
      selected={installingDate}
      onChange={setInstallingDate}
      disabled={disabled}
    />
  );
}

function InstallingWorkTimeInput({ workTime, setWorkTime, readOnly }) {
  return (
    <NumberControl
      label="task.installing_crew.work_time"
      unit="units.hr/hrs"
      value={workTime}
      onChange={setWorkTime}
      disable={readOnly}
      isControlRequired={!readOnly}
    />
  );
}

// eslint-disable-next-line no-unused-vars
function InstallingWorkerInput({ value, onChange }) {
  // const { t } = useTranslation('lang');
  const options = [
    {
      id: 'NEW_BUILD',
      label: 'New build',
    },
    {
      id: 'RENOVATION_SITE',
      label: 'Renovation site',
    },
  ];
  return (
    <DropDownControl
      type="object"
      label="task.installing_crew.select_worker"
      options={options}
      selected={value}
      onChange={onChange}
      selectionKey="id"
    />
  );
}

function OrderControl({ onOrder, orderDisabled }) {
  return (
    <div className={`${styles.odderContainer} `}>
      <Button
        className="projectBtn offerTabFooterBtn offerTabGreenBtn"
        onClick={onOrder}
        disabled={orderDisabled}
      >
        <Translate id="task.installing_crew.order_installation" />
      </Button>
    </div>
  );
}

export function InstallingCrewFormFields({
  onOrder,
  installingCrew,
  setRequestedDate,
  setWorkTime,
  readOnly,
}) {
  return (
    <>
      {' '}
      <div className={`${styles.installingCrewFieldMainContainer}`}>
        <div className={`${styles.installingCrewFieldContainer}`}>
          <div className={`d-flex align-items-center ${styles.headerContainer}`}>
            <div>
              <p>
                <Translate id="common.id" />: {installingCrew.netvisorId} -{' '}
                <span className={`${styles.headerName}`}>{installingCrew.name}</span>
              </p>
              <p>{installingCrew.companyName}</p>
            </div>
          </div>
          <div className={`${styles.installingInputFields}`}>
            <InstallingDateInput
              installingDate={installingCrew.requestedDate}
              setInstallingDate={(newInstallingDate) =>
                setRequestedDate(newInstallingDate, installingCrew.id)
              }
              disabled={readOnly}
            />
            <InstallingWorkTimeInput
              workTime={installingCrew.efforts}
              setWorkTime={(newWorkTime) => setWorkTime(newWorkTime, installingCrew.id)}
              readOnly={readOnly}
            />
            {/* <InstallingWorkerInput /> */}
          </div>
        </div>
        {!readOnly && (
          <OrderControl
            onOrder={() => onOrder(installingCrew)}
            // orderDisabled={installingCrew.ordered}
          />
        )}
      </div>
    </>
  );
}

function InstallingCrew({ setLoading }) {
  const params = useParams();
  const navigate = useNavigate();
  const [defaultInstallingDate, setDefaultInstallingDate] = useState('');
  const [selectedInstallingCrews, setSelectedInstallingCrews] = useState([]);
  const { t } = useTranslation('lang');

  const fetchAdditionalInfo = async () => {
    try {
      const {
        data: {
          data: { additionalInformation },
        },
      } = await offerRequestService.getOfferRequestAdditionalInfo(params.offerRequestId);
      setDefaultInstallingDate(
        additionalInformation?.requestedInstallationTime ?? moment().toISOString()
      );
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const fetchSelectedInstallingCrews = async () => {
    setLoading(true);
    const {
      data: {
        data: { offerServices },
      },
    } = await getOfferServices(params.offerRequestId, params.offerId, {
      filter: { installing: true },
    });
    setSelectedInstallingCrews(
      offerServices.map((installing) => {
        const crew = { ...installing };
        crew.ordered = !!installing.requestedDate;
        if (!installing.requestedDate) {
          crew.requestedDate = defaultInstallingDate;
        }

        if (!installing.efforts) {
          crew.efforts = 0;
        }
        return crew;
      })
    );
    setLoading(false);
  };

  useEffect(() => {
    fetchSelectedInstallingCrews();
  }, [defaultInstallingDate]);

  const setRequestedDate = (newInstallingDate, selectedInstallingCrewId) => {
    const tempSelectedInstallingCrews = [...selectedInstallingCrews];
    tempSelectedInstallingCrews.find(
      (selectedInstallingCrew) => selectedInstallingCrew.id === selectedInstallingCrewId
    ).requestedDate = newInstallingDate;
    setSelectedInstallingCrews(tempSelectedInstallingCrews);
  };

  const setWorkTime = (newWorkTime, selectedInstallingCrewId) => {
    if (newWorkTime < 0) {
      return;
    }
    const tempSelectedInstallingCrews = [...selectedInstallingCrews];
    tempSelectedInstallingCrews.find(
      (selectedCrew) => selectedCrew.id === selectedInstallingCrewId
    ).efforts = newWorkTime;
    setSelectedInstallingCrews(tempSelectedInstallingCrews);
  };

  const onOrder = async (selectedInstallingCrew) => {
    try {
      setLoading(true);
      await patchOfferService(params.offerRequestId, params.offerId, selectedInstallingCrew.id, {
        requestedDate: selectedInstallingCrew.requestedDate,
        efforts: selectedInstallingCrew.efforts,
      });
      setLoading(false);
      toast.success(t('task.installing_crew.order_success_message'));
      fetchSelectedInstallingCrews();
    } catch (err) {
      setLoading(false);
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  useEffect(() => {
    fetchAdditionalInfo();
  }, []);

  const onNext = () => {
    navigate(
      `/offer-requests/${params.offerRequestId}/offers/${params.offerId}/tasks/invoice-items`
    );
  };

  const customFooter = () => <InstallingCrewFooter onNext={onNext} />;

  return (
    <OfferPageContentWrapper customFooter={customFooter}>
      <div className={`d-flex flex-column ${styles.installingCrewMainContainer}`}>
        <div className={`${styles.selectedCrewContainer}`}>
          <div className={`${styles.titleContainer}`}>
            <p>
              <Translate id="task.installing_crew.selected_installing_crew" />
            </p>
          </div>
          <div className={`${styles.selectedCrewFlexContainer}`}>
            {selectedInstallingCrews.length > 0 &&
              selectedInstallingCrews.map((selectedInstallingCrew) => (
                <InstallingCrewFormFields
                  key={selectedInstallingCrew.id}
                  installingCrew={selectedInstallingCrew}
                  setRequestedDate={setRequestedDate}
                  setWorkTime={setWorkTime}
                  onOrder={onOrder}
                />
              ))}
          </div>
        </div>
      </div>
    </OfferPageContentWrapper>
  );
}

InstallingCrewFormFields.propTypes = {
  readOnly: PropTypes.bool,
};

InstallingCrewFormFields.defaultProps = {
  readOnly: false,
};

export default InstallingCrew;
