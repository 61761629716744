import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { INSTALLATION_DEVICE } from './constants';
import styles from './index.module.scss';
import CompanyLogo from '../../assets/images/footerLogo.png';
import { ReactComponent as PenSvg } from '../../assets/svgs/Pen.svg';
import { userRoles, signatureTypes } from '../../constant';
import { useBasicInformationContext } from '../../pages/task-forms/context/basicInformationContext';
import { useServiceContext } from '../../pages/task-forms/context/service-context';
import {
  saveHeatPumpTestReport,
  getHeatPumpTestReport,
  saveHeatPumpTestReportEmployeeSign,
  saveHeatPumpTestReportCustomerSign,
} from '../../services/report';
import { takServiceComplete } from '../../services/task';
import { generateBlobSrc } from '../../utils/common';
import { getOriginalErrors } from '../../utils/errorHandler';
import Button from '../button';
import CalendarControl from '../form-fields/calendar-control';
import CheckBoxControl from '../form-fields/checkbox-control';
import Dropdown from '../form-fields/drop-down-control';
import NumberControl from '../form-fields/number-control';
import TextControl from '../form-fields/text-control';
import SignatureModal from '../signature-modal';
import Translate from '../translate';

function CustomerInformation() {
  const { customerInformation, offerRequest } = useBasicInformationContext();
  return (
    <div className={styles.customerInfoContainer}>
      <div className={`d-flex align-items-center justify-content-between ${styles.flexContainer}`}>
        <div>
          <Translate id="components.user.name" />
        </div>
        <div>{`${customerInformation.firstName} ${customerInformation.lastName}`}</div>
      </div>
      <div className={`d-flex align-items-center justify-content-between ${styles.flexContainer}`}>
        <div>
          <Translate id="components.address.address" />
        </div>
        <div>{offerRequest.installationTargetAddress}</div>
      </div>
      <div className={`d-flex align-items-center justify-content-between ${styles.flexContainer}`}>
        <div>
          <Translate id="components.address.zip_code" />
        </div>
        <div>{offerRequest.installationTargetZipCode}</div>
      </div>
      <div className={`d-flex align-items-center justify-content-between ${styles.flexContainer}`}>
        <div>
          <Translate id="components.address.city" />
        </div>
        <div>{offerRequest.installationTargetCity}</div>
      </div>
      <div className={`d-flex align-items-center justify-content-between ${styles.flexContainer}`}>
        <div>
          <Translate id="components.user.phone" />
        </div>
        <div>{customerInformation.phone}</div>
      </div>
      <div className={`d-flex align-items-center justify-content-between ${styles.flexContainer}`}>
        <div>
          <Translate id="components.user.email" />
        </div>
        <div>{customerInformation.email}</div>
      </div>
    </div>
  );
}

function InstalledDevice({ installationDevice, setInstallationDevice }) {
  const { t } = useTranslation('lang');
  return (
    <Dropdown
      label="heat_pump_test_report.installation_device.title"
      options={INSTALLATION_DEVICE.map((item) => ({ ...item, label: t(item.label) }))}
      selected={installationDevice}
      onChange={setInstallationDevice}
      selectionKey="value"
    />
  );
}

function MakeAndModel({ makeAndModel, setMakeAndModel }) {
  return (
    <TextControl
      label="heat_pump_test_report.make_and_model"
      placeholder="heat_pump_test_report.make_and_model"
      value={makeAndModel}
      onChange={(e) => setMakeAndModel(e.target.value)}
    />
  );
}

function SerialNumber({ serialNumber, setSerialNumber }) {
  return (
    <TextControl
      label="heat_pump_test_report.serial_number"
      placeholder="heat_pump_test_report.serial_number"
      value={serialNumber}
      onChange={(e) => setSerialNumber(e.target.value)}
    />
  );
}

function HeatCollectorCircuit({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.direction_of_rotation_checked"
        id="direction_of_rotation_checked"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function SystemTested({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.system_tested"
        id="system_tested"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function SystemVented({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.system_vented"
        id="system_vented"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function AmountOfLiquidInTheGroundSolutionCircuit({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.the_amount_of_liquid_in_the_ground_solution_circuit"
        id="the_amount_of_liquid_in_the_ground_solution_circuit"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function ExpansionTankOrCollectionFluidTank({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.expansion_tank_or_collection_fluid_tank"
        id="expansion_tank_or_collection_fluid_tank"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function FilterFlowDirection({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.filter_flow_direction"
        id="filter_flow_direction"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function SafetyValve({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.safety_valve"
        id="safety_valve"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function ShutOffValve({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.shut_off_value"
        id="shut_off_value"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function DepthOfTheWell({ wellDepth, setWellDepth }) {
  return (
    <div className={`${styles.whiteBackgroundInput}`}>
      <NumberControl
        label="heat_pump_test_report.the_depth_of_the_well"
        unit="units.m"
        value={wellDepth}
        onChange={(e) => setWellDepth(e)}
      />
    </div>
  );
}

function LengthOfTheCollectionCircuit({ lengthOfCollectionCircuit, setLengthOfCollectionCircuit }) {
  return (
    <div className={`${styles.whiteBackgroundInput}`}>
      <NumberControl
        label="heat_pump_test_report.the_length_of_the_collection_circuit_m"
        unit="units.m"
        value={lengthOfCollectionCircuit}
        onChange={setLengthOfCollectionCircuit}
      />
    </div>
  );
}

function SystemFilled({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.system_filled"
        id="system_filled"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function AccumulatorCoilFilledVented({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.accumulator_coil_filled_vented"
        id="accumulator_coil_filled_vented"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function HeatingSystemTestedControl({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.heating_system_tested"
        id="heating_system_tested"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function HeatingSystemVentedControl({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.heating_system_vented"
        id="heating_system_vented"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function SafetyValveAndDripTubeInstalled({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.safety_valve_and_drip_tube_installed"
        id="safety_valve_and_drip_tube_installed"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function DiaphragmExpansionVessel({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.diaphragm_expansion_vessel"
        id="diaphragm_expansion_vessel"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function Filter({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.filter"
        id="filter"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function PressureGuage({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.pressure_gauge"
        id="pressure_gauge"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function FillingValve({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.folling_valve"
        id="folling_valve"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function BumperCharger({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.bumper_charger"
        id="bumper_charger"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function TheControlOfTheHeatingCircuitsIsSet({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.the_control_of_the_heating_circuits_is_set"
        id="the_control_of_the_heating_circuits_is_set"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function CirculatingWaterPumps({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.circulating_water_pumps"
        id="circulating_water_pumps"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function DirectionOfRotationOfThePumps({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.direction_of_rotation_of_the_pumps"
        id="direction_of_rotation_of_the_pumps"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function Actuators({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.actuators"
        id="actuators"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function HeatingSystemShutOffControl({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.heating_system_shut_off_value"
        id="heating_system_shut_off_value"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function ServiceWaterSystemFilled({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.service_water_system_filled"
        id="service_water_system_filled"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function ServiceWaterSystemTested({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.service_water_system_tested"
        id="service_water_system_tested"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function ServiceWaterSafetyValveAndDripTubeInstalled({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.service_water_safety_valve_and_drip_tube_installed"
        id="service_water_safety_valve_and_drip_tube_installed"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function ServiceWaterHotWaterCirculation({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.hot_water_circulation"
        id="hot_water_circulation"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function ComfortSetpoint({ comfortSetPoint, setComfortSetPoint }) {
  return (
    <div className={`${styles.whiteBackgroundInput}`}>
      <NumberControl
        label="heat_pump_test_report.comfort_setpoint"
        unit="units.°C"
        value={comfortSetPoint}
        onChange={setComfortSetPoint}
      />
    </div>
  );
}

function TempFlowWaterMinimum({ tempFlowWaterMinimum, setTempFlowWaterMinimum }) {
  return (
    <div className={`${styles.whiteBackgroundInput}`}>
      <NumberControl
        label="heat_pump_test_report.temp_flow_water_minimum_°C"
        unit="units.°C"
        value={tempFlowWaterMinimum}
        onChange={setTempFlowWaterMinimum}
      />
    </div>
  );
}

function TempFlowWaterMaximum({ tempFlowWaterMaximum, setTempFlowWaterMaximum }) {
  return (
    <div className={`${styles.whiteBackgroundInput}`}>
      <NumberControl
        label="heat_pump_test_report.temp_flow_water_maximum_°C"
        unit="units.°C"
        value={tempFlowWaterMaximum}
        onChange={setTempFlowWaterMaximum}
      />
    </div>
  );
}

function DHWSetPoint({ dhwSetPoint, setDhwSetPoint }) {
  return (
    <div className={`${styles.whiteBackgroundInput}`}>
      <NumberControl
        label="heat_pump_test_report.dhw_set_point_°C"
        unit="units.°C"
        value={dhwSetPoint}
        onChange={setDhwSetPoint}
      />
    </div>
  );
}

function SlopOfTheCurve({ slopOfHeatingCurve, setSlopOfHeatingCurve }) {
  return (
    <div className={`${styles.whiteBackgroundInput}`}>
      <NumberControl
        label="heat_pump_test_report.the_slope_of_the_heating_curve"
        value={slopOfHeatingCurve}
        onChange={setSlopOfHeatingCurve}
      />
    </div>
  );
}

function ConnectionsAccordingToTheInstallationInstructions({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.connections_according_to_the_installation_instructions"
        id="connections_according_to_the_installation_instructions"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function TightnessOfJoints({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.tightness_of_joints"
        id="tightness_of_joints"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function TheDeviceIsStartedAccordingToTheInstructions({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.the_device_is_started_according_to_the_instructions"
        id="the_device_is_started_according_to_the_instructions"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function TheOperationOfTheMachineWasMonitored({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.the_operation_of_the_machine_was_monitored_for_30_min"
        id="the_operation_of_the_machine_was_monitored_for_30_min"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function IncreasingLiquidInEarthAtmosphere({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.increasing_the_liquid_in_the_earth_atmosphere"
        id="increasing_the_liquid_in_the_earth_atmosphere"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function IncreasingTheHeatingPressure({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.increasing_the_heating_pressure"
        id="increasing_the_heating_pressure"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function SettingTheHeatingControlCurve({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.setting_the_heating_control_curve"
        id="setting_the_heating_control_curve"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function ChangingTheRoomTemperature({ checked, setSelection }) {
  return (
    <div>
      <CheckBoxControl
        translationId="heat_pump_test_report.changing_the_room_temperature"
        id="changing_the_room_temperature"
        isChecked={checked}
        onChange={setSelection}
      />
    </div>
  );
}

function InstallationDate({ installationDate, setInstallationDate }) {
  return (
    <CalendarControl
      label="heat_pump_test_report.installation_date"
      selected={installationDate}
      onChange={setInstallationDate}
    />
  );
}

function Installer({ installer, setInstaller }) {
  return (
    <TextControl
      label="heat_pump_test_report.installer"
      placeholder="heat_pump_test_report.installer"
      value={installer}
      onChange={(e) => setInstaller(e.target.value)}
    />
  );
}

function SignaturePreview({ signature, label, onEdit }) {
  return signature.signatureType === signatureTypes.TYPE ? (
    <div>
      <p className={`${styles.signPreviewLabel} d-flex justify-content-between`}>
        <span>
          <Translate id={label} />:
        </span>
        <PenSvg className={styles.signEditButton} onClick={onEdit} />
      </p>
      <p>{signature.signature}</p>
    </div>
  ) : (
    <>
      <p className={`${styles.signPreviewLabel} d-flex justify-content-between`}>
        <span>
          <Translate id={label} />:
        </span>
        <PenSvg className={styles.signEditButton} onClick={onEdit} />
      </p>
      <img
        src={signature.signature}
        width={signature.signatureType === signatureTypes.UPLOAD ? 150 : '100%'}
        alt=""
      />
    </>
  );
}

function HeatPumpTestReport({ setLoading }) {
  const [userRole, setUserRole] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [installationDevice, setInstallationDevice] = useState('');
  const [makeAndModel, setMakeAndModel] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [customerSignature, setCustomerSignature] = useState({});
  const [employeeSignature, setEmployeeSignature] = useState({});
  const [heatCollectorCircuit, setHeatCollectorCircuit] = useState({
    directionOfRotationChecked: false,
    systemTested: false,
    systemVented: false,
    groundSolutionCircuit: false,
    expansionTank: false,
    filterDirection: false,
    safetyValve: false,
    shutoffValue: false,
  });
  const [wellDepth, setWellDepth] = useState(null);
  const [lengthOfCollectionCircuit, setLengthOfCollectionCircuit] = useState(null);
  const [heatingSystem, setHeatingSystem] = useState({
    systemFilled: false,
    accumulatorCoilFilled: false,
    systemTested: false,
    systemVented: false,
    safetyValveAndDripTubeInstalled: false,
    diaphragmExpansionVessel: false,
    filter: false,
    pressureGuage: false,
    shutoffValue: false,
    fillingValve: false,
    bumperCharger: false,
    controlOfHeatingCircuitSet: false,
    circulatingWaterPumps: false,
    directionOfRotationOfPump: false,
    actuators: false,
  });
  const [serviceWater, setServiceWater] = useState({
    systemFilled: false,
    systemTested: false,
    safetyValveAndDripTubeInstalled: false,
    hotWaterCirculation: false,
  });
  const [heatPumpAdjustment, setHeatPumpAdjustment] = useState({
    comfortSetPoint: null,
    tempFlowWaterMinimum: null,
    tempFlowWaterMaximum: null,
    dhwSetPoint: null,
    slopOfHeatingCurve: null,
  });
  const [general, setGeneral] = useState({
    connectionsAccordingToInstallationInstructions: false,
    tightnessOfJoints: false,
    deviceStartedAccordingToInstructions: false,
    operationOfMachineMonitoredFor30Min: false,
  });
  const [endUserGuidance, setEndUserGuidance] = useState({
    increasingLiquidInEarthsAtmosphere: false,
    increasingHeatingPresure: false,
    settingHeatingControlCurve: false,
    changingRoomTemperature: false,
  });
  const [installationDate, setInstallationDate] = useState(null);
  const [installer, setInstaller] = useState('');
  const [showSignatureButtons, setShowSignatureButtons] = useState(true);
  const [oldHeatPumpTestReport, setOldHeatPumpTestReport] = useState(null);
  const [signatureUpdated, setSignatureUpdated] = useState(false);

  const { companyId, employeeId, adminId } = useParams();

  const { service, offerRequest } = useServiceContext();
  const [mainInitalState, setMainInitalState] = useState({});

  const queryClient = useQueryClient();

  const { t } = useTranslation('lang');

  const changeHeatCollectorCircuit = (newValue, keyName) => {
    setHeatCollectorCircuit({ ...heatCollectorCircuit, [keyName]: newValue });
  };

  const changeHeatingSystem = (newValue, keyName) => {
    setHeatingSystem({ ...heatingSystem, [keyName]: newValue });
  };

  const changeServiceWater = (newValue, keyName) => {
    setServiceWater({ ...serviceWater, [keyName]: newValue });
  };

  const changeHeatPumpAdjustment = (newValue, keyName) => {
    setHeatPumpAdjustment({ ...heatPumpAdjustment, [keyName]: newValue });
  };

  const changeGeneral = (newValue, keyName) => {
    setGeneral({ ...general, [keyName]: newValue });
  };

  const changeEndUserGuidance = (newValue, keyName) => {
    setEndUserGuidance({ ...endUserGuidance, [keyName]: newValue });
  };

  useEffect(() => {
    if (userRole) {
      setShowModal(true);
    }
  }, [userRole]);

  const setSignature = async (data) => {
    setLoading(true);

    try {
      if (userRole.role === userRoles.CUSTOMER.role) {
        const signatureType = data.get('signatureType');

        if (signatureType === signatureTypes.TYPE) {
          setCustomerSignature({
            signature: data.get('signature'),
            signatureType: data.get('signatureType'),
            data,
          });
        }

        if (signatureType === signatureTypes.DRAW || signatureType === signatureTypes.UPLOAD) {
          const signatureBlob = generateBlobSrc(data.get('customerSignatureFile'));

          if (!signatureBlob) {
            toast.error('Invalid signature');
            return;
          }

          setCustomerSignature({
            signature: signatureBlob,
            signatureType: data.get('signatureType'),
            data,
          });
        }

        setSignatureUpdated(true);
        setLoading(false);
      }
      if (userRole.role === userRoles.INSTALLER.role) {
        const signatureType = data.get('signatureType');

        if (signatureType === signatureTypes.TYPE) {
          setEmployeeSignature({
            signature: data.get('signature'),
            signatureType: data.get('signatureType'),
            data,
          });
        }

        if (signatureType === signatureTypes.DRAW || signatureType === signatureTypes.UPLOAD) {
          const signatureBlob = generateBlobSrc(data.get('employeeSignatureFile'));

          if (!signatureBlob) {
            toast.error('Invalid signature');
            return;
          }

          setEmployeeSignature({
            signature: signatureBlob,
            signatureType: data.get('signatureType'),
            data,
          });
        }

        setSignatureUpdated(true);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const enableSave = () =>
    !isEqual(mainInitalState, {
      installationDevice,
      makeAndModel,
      serialNumber,
      heatCollectorCircuit,
      wellDepth,
      lengthOfCollectionCircuit,
      heatingSystem,
      serviceWater,
      heatPumpAdjustment,
      general,
      endUserGuidance,
      installationDate,
      installer,
      signatureUpdated,
    });
  const fetchHeatPumpTestReport = async () => {
    setLoading(true);
    const {
      data: {
        data: { heatingPumpTestReport },
      },
    } = await getHeatPumpTestReport(companyId, employeeId || adminId, service?.id);
    if (heatingPumpTestReport) {
      setInstallationDevice(heatingPumpTestReport.data.installationDevice);
      setMakeAndModel(heatingPumpTestReport.data.makeAndModel);
      setSerialNumber(heatingPumpTestReport.data.serialNumber);
      setHeatCollectorCircuit(heatingPumpTestReport.data.heatCollectorCircuit);
      setWellDepth(heatingPumpTestReport.data.wellDepth);
      setLengthOfCollectionCircuit(heatingPumpTestReport.data.lengthOfCollectionCircuit);
      setHeatingSystem(heatingPumpTestReport.data.heatingSystem);
      setServiceWater(heatingPumpTestReport.data.serviceWater);
      setHeatPumpAdjustment(heatingPumpTestReport.data.heatPumpAdjustment);
      setGeneral(heatingPumpTestReport.data.general);
      setEndUserGuidance(heatingPumpTestReport.data.endUserGuidance);
      setInstallationDate(heatingPumpTestReport.data.installationDate);
      setInstaller(heatingPumpTestReport.data.installer);
      setEmployeeSignature({
        signature: heatingPumpTestReport.employeeSignature,
        signatureType: heatingPumpTestReport.employeeSignatureType,
      });
      setCustomerSignature({
        signature: heatingPumpTestReport.customerSignature,
        signatureType: heatingPumpTestReport.customerSignatureType,
      });
      setMainInitalState({
        installationDevice: heatingPumpTestReport.data.installationDevice,
        makeAndModel: heatingPumpTestReport.data.makeAndModel,
        serialNumber: heatingPumpTestReport.data.serialNumber,
        heatCollectorCircuit: JSON.parse(
          JSON.stringify(heatingPumpTestReport.data.heatCollectorCircuit)
        ),
        wellDepth: heatingPumpTestReport.data.wellDepth,
        lengthOfCollectionCircuit: heatingPumpTestReport.data.lengthOfCollectionCircuit,
        heatingSystem: JSON.parse(JSON.stringify(heatingPumpTestReport.data.heatingSystem)),
        serviceWater: JSON.parse(JSON.stringify(heatingPumpTestReport.data.serviceWater)),
        heatPumpAdjustment: JSON.parse(
          JSON.stringify(heatingPumpTestReport.data.heatPumpAdjustment)
        ),
        general: JSON.parse(JSON.stringify(heatingPumpTestReport.data.general)),
        endUserGuidance: JSON.parse(JSON.stringify(heatingPumpTestReport.data.endUserGuidance)),
        installationDate: heatingPumpTestReport.data.installationDate,
        installer: heatingPumpTestReport.data.installer,
        signatureUpdated: false,
      });

      setOldHeatPumpTestReport(heatingPumpTestReport);
      setSignatureUpdated(false);
      setShowSignatureButtons(true);
    } else {
      setMainInitalState({
        installationDevice,
        makeAndModel,
        serialNumber,
        heatCollectorCircuit: JSON.parse(JSON.stringify(heatCollectorCircuit)),
        wellDepth,
        lengthOfCollectionCircuit,
        heatingSystem: JSON.parse(JSON.stringify(heatingSystem)),
        serviceWater: JSON.parse(JSON.stringify(serviceWater)),
        heatPumpAdjustment: JSON.parse(JSON.stringify(heatPumpAdjustment)),
        general: JSON.parse(JSON.stringify(general)),
        endUserGuidance: JSON.parse(JSON.stringify(endUserGuidance)),
        installationDate,
        installer,
        signatureUpdated: false,
      });
    }
    setLoading(false);
  };

  const onCloseModal = () => {
    setUserRole(null);
    setShowModal(false);
  };

  const persistExistingSignature = () => {
    let existingSignaturesTemp = {};

    if (oldHeatPumpTestReport) {
      const {
        employeeSignatureType,
        employeeSignature: existingEmployeeSignature,
        employeeSignedAt,
        signedEmployeeId,
        customerSignatureType,
        customerSignature: existingCustomerSignature,
        customerSignedAt,
      } = oldHeatPumpTestReport;
      if (
        employeeSignatureType &&
        existingEmployeeSignature &&
        employeeSignedAt &&
        signedEmployeeId
      ) {
        existingSignaturesTemp = {
          ...existingSignaturesTemp,
          employeeSignatureType,
          employeeSignedAt,
          signedEmployeeId,
          employeeSignature: existingEmployeeSignature,
        };
      }

      if ((customerSignatureType && existingCustomerSignature, customerSignedAt)) {
        existingSignaturesTemp = {
          ...existingSignaturesTemp,
          customerSignatureType,
          customerSignature: existingCustomerSignature,
          customerSignedAt,
        };
      }
    }

    return existingSignaturesTemp;
  };

  const saveSignatureForHeatPumpTestReport = async (reportId) => {
    if (!reportId) {
      return;
    }

    if (Object.keys(employeeSignature).length > 0 && employeeSignature.data) {
      await saveHeatPumpTestReportEmployeeSign(
        companyId,
        employeeId || adminId,
        service?.id,
        employeeSignature.data
      );
    }

    if (Object.keys(customerSignature).length > 0 && customerSignature.data) {
      await saveHeatPumpTestReportCustomerSign(
        companyId,
        employeeId || adminId,
        service?.id,
        customerSignature.data
      );
    }
  };

  const onSave = async () => {
    try {
      setLoading(true);
      const existingSignatures = persistExistingSignature();
      const res = await saveHeatPumpTestReport(companyId, employeeId || adminId, service?.id, {
        data: {
          installationDevice,
          makeAndModel,
          serialNumber,
          heatCollectorCircuit,
          wellDepth,
          lengthOfCollectionCircuit,
          heatingSystem,
          serviceWater,
          heatPumpAdjustment,
          general,
          endUserGuidance,
          installationDate,
          installer,
          ...existingSignatures,
        },
        offerServiceId: service?.id,
      });

      await saveSignatureForHeatPumpTestReport(res?.data?.data?.heatingPumpTestReport?.id);

      await takServiceComplete(offerRequest.id, service.offerId, service.id);
      toast.success(t('heat_pump_test_report.save_success_message'));
      queryClient.invalidateQueries();
      queryClient.invalidateQueries('Assignment');
      await fetchHeatPumpTestReport();
      setLoading(false);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error);
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if ((service?.id && employeeId) || adminId) {
      fetchHeatPumpTestReport();
    }
  }, [service, employeeId, adminId]);

  return (
    <div className={styles.formMainContainer}>
      <div className={styles.imgContainer}>
        <img src={CompanyLogo} alt="company_logo" />
      </div>
      <div className={styles.formTitleContainer}>
        <h2 className={styles.formTitle}>
          <Translate id="heat_pump_test_report.title" />
        </h2>
      </div>
      <CustomerInformation />
      <div className={styles.formContentMainContainer}>
        <div className={styles.formContentContainer}>
          <div>
            <InstalledDevice
              installationDevice={installationDevice}
              setInstallationDevice={setInstallationDevice}
            />
          </div>
          <div>
            <MakeAndModel makeAndModel={makeAndModel} setMakeAndModel={setMakeAndModel} />
          </div>
          <div>
            <SerialNumber serialNumber={serialNumber} setSerialNumber={setSerialNumber} />
          </div>
        </div>
      </div>
      <div className={styles.formContentMainContainer}>
        <div className={styles.formTitleContainer}>
          <p className={styles.formTitle}>
            <Translate id="heat_pump_test_report.heat_collector_circuit" />
          </p>
        </div>
        <div className={styles.formContentContainer}>
          <div>
            <HeatCollectorCircuit
              checked={heatCollectorCircuit.directionOfRotationChecked}
              setSelection={(newVal) =>
                changeHeatCollectorCircuit(newVal, 'directionOfRotationChecked')
              }
            />
          </div>
          <div>
            <SystemTested
              checked={heatCollectorCircuit.systemTested}
              setSelection={(newVal) => changeHeatCollectorCircuit(newVal, 'systemTested')}
            />
          </div>
          <div>
            <SystemVented
              checked={heatCollectorCircuit.systemVented}
              setSelection={(newVal) => changeHeatCollectorCircuit(newVal, 'systemVented')}
            />
          </div>
          <div>
            <AmountOfLiquidInTheGroundSolutionCircuit
              checked={heatCollectorCircuit.groundSolutionCircuit}
              setSelection={(newVal) => changeHeatCollectorCircuit(newVal, 'groundSolutionCircuit')}
            />
          </div>
          <div>
            <ExpansionTankOrCollectionFluidTank
              checked={heatCollectorCircuit.expansionTank}
              setSelection={(newVal) => changeHeatCollectorCircuit(newVal, 'expansionTank')}
            />
          </div>
          <div>
            <FilterFlowDirection
              checked={heatCollectorCircuit.filterDirection}
              setSelection={(newVal) => changeHeatCollectorCircuit(newVal, 'filterDirection')}
            />
          </div>
          <div>
            <SafetyValve
              checked={heatCollectorCircuit.safetyValve}
              setSelection={(newVal) => changeHeatCollectorCircuit(newVal, 'safetyValve')}
            />
          </div>
          <div>
            <ShutOffValve
              checked={heatCollectorCircuit.shutoffValue}
              setSelection={(newVal) => changeHeatCollectorCircuit(newVal, 'shutoffValue')}
            />
          </div>
          <div>
            <DepthOfTheWell wellDepth={wellDepth} setWellDepth={setWellDepth} />
          </div>
          <div>
            <LengthOfTheCollectionCircuit
              lengthOfCollectionCircuit={lengthOfCollectionCircuit}
              setLengthOfCollectionCircuit={setLengthOfCollectionCircuit}
            />
          </div>
        </div>
      </div>
      <div className={styles.formContentMainContainer}>
        <div className={styles.formTitleContainer}>
          <p className={styles.formTitle}>
            <Translate id="heat_pump_test_report.heating_system" />
          </p>
        </div>
        <div className={styles.formContentContainer}>
          <div>
            <SystemFilled
              checked={heatingSystem.systemFilled}
              setSelection={(newVal) => changeHeatingSystem(newVal, 'systemFilled')}
            />
          </div>
          <div>
            <AccumulatorCoilFilledVented
              checked={heatingSystem.accumulatorCoilFilled}
              setSelection={(newVal) => changeHeatingSystem(newVal, 'accumulatorCoilFilled')}
            />
          </div>
          <div>
            <HeatingSystemTestedControl
              checked={heatingSystem.systemTested}
              setSelection={(newVal) => changeHeatingSystem(newVal, 'systemTested')}
            />
          </div>
          <div>
            <HeatingSystemVentedControl
              checked={heatingSystem.systemVented}
              setSelection={(newVal) => changeHeatingSystem(newVal, 'systemVented')}
            />
          </div>
          <div>
            <SafetyValveAndDripTubeInstalled
              checked={heatingSystem.safetyValveAndDripTubeInstalled}
              setSelection={(newVal) =>
                changeHeatingSystem(newVal, 'safetyValveAndDripTubeInstalled')
              }
            />
          </div>
          <div>
            <DiaphragmExpansionVessel
              checked={heatingSystem.diaphragmExpansionVessel}
              setSelection={(newVal) => changeHeatingSystem(newVal, 'diaphragmExpansionVessel')}
            />
          </div>
          <Filter
            checked={heatingSystem.filter}
            setSelection={(newVal) => changeHeatingSystem(newVal, 'filter')}
          />
          <PressureGuage
            checked={heatingSystem.pressureGuage}
            setSelection={(newVal) => changeHeatingSystem(newVal, 'pressureGuage')}
          />
          <HeatingSystemShutOffControl
            checked={heatingSystem.shutoffValue}
            setSelection={(newVal) => changeHeatingSystem(newVal, 'shutoffValue')}
          />
          <FillingValve
            checked={heatingSystem.fillingValve}
            setSelection={(newVal) => changeHeatingSystem(newVal, 'fillingValve')}
          />
          <BumperCharger
            checked={heatingSystem.bumperCharger}
            setSelection={(newVal) => changeHeatingSystem(newVal, 'bumperCharger')}
          />
          <TheControlOfTheHeatingCircuitsIsSet
            checked={heatingSystem.controlOfHeatingCircuitSet}
            setSelection={(newVal) => changeHeatingSystem(newVal, 'controlOfHeatingCircuitSet')}
          />
          <CirculatingWaterPumps
            checked={heatingSystem.circulatingWaterPumps}
            setSelection={(newVal) => changeHeatingSystem(newVal, 'circulatingWaterPumps')}
          />
          <DirectionOfRotationOfThePumps
            checked={heatingSystem.directionOfRotationOfPump}
            setSelection={(newVal) => changeHeatingSystem(newVal, 'directionOfRotationOfPump')}
          />
          <Actuators
            checked={heatingSystem.actuators}
            setSelection={(newVal) => changeHeatingSystem(newVal, 'actuators')}
          />
        </div>
      </div>
      <div className={styles.formContentMainContainer}>
        <div className={styles.formTitleContainer}>
          <p className={styles.formTitle}>
            <Translate id="heat_pump_test_report.service_water" />
          </p>
        </div>
        <div className={styles.formContentContainer}>
          <div>
            <ServiceWaterSystemFilled
              checked={serviceWater.systemFilled}
              setSelection={(newVal) => changeServiceWater(newVal, 'systemFilled')}
            />
          </div>
          <div>
            <ServiceWaterSystemTested
              checked={serviceWater.systemTested}
              setSelection={(newVal) => changeServiceWater(newVal, 'systemTested')}
            />
          </div>
          <div>
            <ServiceWaterSafetyValveAndDripTubeInstalled
              checked={serviceWater.safetyValveAndDripTubeInstalled}
              setSelection={(newVal) =>
                changeServiceWater(newVal, 'safetyValveAndDripTubeInstalled')
              }
            />
          </div>
          <div>
            <ServiceWaterHotWaterCirculation
              checked={serviceWater.hotWaterCirculation}
              setSelection={(newVal) => changeServiceWater(newVal, 'hotWaterCirculation')}
            />
          </div>
        </div>
      </div>
      <div className={styles.formContentMainContainer}>
        <div className={styles.formTitleContainer}>
          <p className={styles.formTitle}>
            <Translate id="heat_pump_test_report.heat_pump_adjustment" />
          </p>
        </div>
        <div className={styles.formContentContainer}>
          <div>
            <ComfortSetpoint
              comfortSetPoint={heatPumpAdjustment.comfortSetPoint}
              setComfortSetPoint={(newVal) => changeHeatPumpAdjustment(newVal, 'comfortSetPoint')}
            />
          </div>
          <div>
            <TempFlowWaterMinimum
              tempFlowWaterMinimum={heatPumpAdjustment.tempFlowWaterMinimum}
              setTempFlowWaterMinimum={(newVal) =>
                changeHeatPumpAdjustment(newVal, 'tempFlowWaterMinimum')
              }
            />
          </div>
          <div>
            <TempFlowWaterMaximum
              tempFlowWaterMaximum={heatPumpAdjustment.tempFlowWaterMaximum}
              setTempFlowWaterMaximum={(newVal) =>
                changeHeatPumpAdjustment(newVal, 'tempFlowWaterMaximum')
              }
            />
          </div>
          <div>
            <DHWSetPoint
              dhwSetPoint={heatPumpAdjustment.dhwSetPoint}
              setDhwSetPoint={(newVal) => changeHeatPumpAdjustment(newVal, 'dhwSetPoint')}
            />
          </div>
          <div>
            <SlopOfTheCurve
              slopOfHeatingCurve={heatPumpAdjustment.slopOfHeatingCurve}
              setSlopOfHeatingCurve={(newVal) =>
                changeHeatPumpAdjustment(newVal, 'slopOfHeatingCurve')
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.formContentMainContainer}>
        <div className={styles.formTitleContainer}>
          <p className={styles.formTitle}>
            <Translate id="heat_pump_test_report.general" />
          </p>
        </div>
        <div className={styles.formContentContainer}>
          <div>
            <ConnectionsAccordingToTheInstallationInstructions
              checked={general.connectionsAccordingToInstallationInstructions}
              setSelection={(newVal) =>
                changeGeneral(newVal, 'connectionsAccordingToInstallationInstructions')
              }
            />
          </div>
          <div>
            <TightnessOfJoints
              checked={general.tightnessOfJoints}
              setSelection={(newVal) => changeGeneral(newVal, 'tightnessOfJoints')}
            />
          </div>
          <div>
            <TheDeviceIsStartedAccordingToTheInstructions
              checked={general.deviceStartedAccordingToInstructions}
              setSelection={(newVal) =>
                changeGeneral(newVal, 'deviceStartedAccordingToInstructions')
              }
            />
          </div>
          <div>
            <TheOperationOfTheMachineWasMonitored
              checked={general.operationOfMachineMonitoredFor30Min}
              setSelection={(newVal) =>
                changeGeneral(newVal, 'operationOfMachineMonitoredFor30Min')
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.formContentMainContainer}>
        <div className={styles.formTitleContainer}>
          <p className={styles.formTitle}>
            <Translate id="heat_pump_test_report.end_user_guidance" />
          </p>
        </div>
        <div className={styles.formContentContainer}>
          <div>
            <IncreasingLiquidInEarthAtmosphere
              checked={endUserGuidance.increasingLiquidInEarthsAtmosphere}
              setSelection={(newVal) =>
                changeEndUserGuidance(newVal, 'increasingLiquidInEarthsAtmosphere')
              }
            />
          </div>
          <div>
            <IncreasingTheHeatingPressure
              checked={endUserGuidance.increasingHeatingPresure}
              setSelection={(newVal) => changeEndUserGuidance(newVal, 'increasingHeatingPresure')}
            />
          </div>
          <div>
            <SettingTheHeatingControlCurve
              checked={endUserGuidance.settingHeatingControlCurve}
              setSelection={(newVal) => changeEndUserGuidance(newVal, 'settingHeatingControlCurve')}
            />
          </div>
          <div>
            <ChangingTheRoomTemperature
              checked={endUserGuidance.changingRoomTemperature}
              setSelection={(newVal) => changeEndUserGuidance(newVal, 'changingRoomTemperature')}
            />
          </div>
        </div>
      </div>
      <div className={styles.formContentMainContainer}>
        <div className={styles.formContentContainer}>
          <div>
            <InstallationDate
              installationDate={installationDate}
              setInstallationDate={setInstallationDate}
            />
          </div>
          <div>
            <Installer installer={installer} setInstaller={setInstaller} />
          </div>
        </div>
      </div>
      {showSignatureButtons ? (
        <div className={`${styles.signBtnMainContainer}`}>
          <div className={`${styles.signBtnContainer}`}>
            {!employeeSignature.signature ? (
              <div
                className={`d-flex align-items-center justify-content-between ${styles.btnContainer}`}
              >
                <div>
                  <Translate id="heat_pump_test_report.installer_sign" />
                </div>
                <div>
                  <Button
                    className={`projectBtn ${styles.signBtn}`}
                    onClick={() => setUserRole(userRoles.INSTALLER)}
                  >
                    <Translate id="components.signature.sign" />
                    <PenSvg className={`${styles.signBtnIcon}`} />
                  </Button>
                </div>
              </div>
            ) : (
              <SignaturePreview
                label="heat_pump_test_report.installer_sign"
                signature={employeeSignature}
                onEdit={() => setUserRole(userRoles.INSTALLER)}
              />
            )}
            {!customerSignature.signature ? (
              <div
                className={`d-flex align-items-center justify-content-between ${styles.btnContainer}`}
              >
                <div>
                  <Translate id="heat_pump_test_report.customer_sign" />
                </div>
                <div>
                  <Button
                    className={`projectBtn ${styles.signBtn}`}
                    onClick={() => setUserRole(userRoles.CUSTOMER)}
                  >
                    <Translate id="components.signature.sign" />
                    <PenSvg className={`${styles.signBtnIcon}`} />
                  </Button>
                </div>
              </div>
            ) : (
              <SignaturePreview
                label="heat_pump_test_report.customer_sign"
                signature={customerSignature}
                onEdit={() => setUserRole(userRoles.CUSTOMER)}
              />
            )}
          </div>
          {showModal && (
            <SignatureModal
              userRole={userRole}
              onClose={onCloseModal}
              showModal={showModal}
              setSignature={setSignature}
              signatureFileName={
                userRole === userRoles.CUSTOMER ? 'customerSignatureFile' : 'employeeSignatureFile'
              }
            />
          )}
        </div>
      ) : null}
      <div className={`${styles.reportMainContainer}`}>
        <div className={`${styles.noteContainer}`}>
          <p>
            <Translate id="heat_pump_test_report.note" />
          </p>
        </div>
        <div className={`${styles.noteBtnContainer}`}>
          <Button
            onClick={onSave}
            disabled={!enableSave()}
            className={`${enableSave() ? 'projectBtn greenBtn' : 'grayBtn'}`}
          >
            <Translate id="components.button.save" />
          </Button>
        </div>
        <div className={`${styles.contactDetailsContainer}`}>
          <div
            className={`d-flex align-items-center justify-content-between ${styles.contactFlexContainer}`}
          >
            <div>
              <Translate id="heat_pump_test_report.company.name" />
            </div>
            <div>Lannen Maalampo</div>
          </div>
          <div
            className={`d-flex align-items-center justify-content-between ${styles.contactFlexContainer}`}
          >
            <div>
              <Translate id="heat_pump_test_report.company.social_security_number" />
            </div>
            <div>165s1fsf</div>
          </div>
          <a
            className={`d-block d-flex align-items-center justify-content-between ${styles.contactFlexContainer}`}
            href="tel:+15616184915"
          >
            <div>
              <Translate id="heat_pump_test_report.company.phone" />
            </div>
            <div>+15616184915</div>
          </a>
          <div
            className={`d-flex align-items-center justify-content-between ${styles.contactFlexContainer}`}
          >
            <div>
              <Translate id="floor_heating_test_report.company.address" />
            </div>
            <div>street, 16516, Helsinki</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeatPumpTestReport;
