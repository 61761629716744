function convertBase64ToFile(base64String, fileName, splitRequired = true, type = 'image/png') {
  try {
    const byteCharacters = splitRequired ? atob(base64String.split(',')[1]) : atob(base64String);

    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type });

    const file = new File([blob], fileName, { type: blob.type });
    return file;
  } catch (error) {
    console.log({ error });
    return null;
  }
}

function downloadFile({ data }, filename) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${filename}`);
  document.body.appendChild(link);
  link.click();
}

function parseDescription(description) {
  if (!description) {
    return null;
  }
  const lines = description.split('\n');
  let content = '<ul>';
  lines.forEach((line) => {
    if (line.trim().startsWith('-')) {
      content += `<li>${line.replace('-', '')}</li>\n`;
    } else {
      content += `${line}\n`;
    }
  });
  content += '</ul>';
  return content;
}

function translateNumber(number, language) {
  return Number(number).toLocaleString(language === 'en' ? 'en' : 'DE');
}

function generateBlobSrc(signatureInput) {
  if (signatureInput instanceof File) {
    return URL.createObjectURL(signatureInput);
  }
  if (typeof signatureInput === 'string' && signatureInput.startsWith('data:image')) {
    return signatureInput;
  }

  return null;
}

module.exports = {
  convertBase64ToFile,
  downloadFile,
  parseDescription,
  translateNumber,
  generateBlobSrc,
};
