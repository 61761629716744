import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import styles from './offerPreview.module.scss';
import Button from '../../../components/button';
import Header from '../../../components/public/header';
import FinalOfferPreview from '../../offer-forms/final-offer-preview';

function CustomerOfferPreviewHeader() {
  const { t } = useTranslation('lang');
  return (
    <div className={`${styles.btnContainer}`}>
      <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn">
        {t('public.offer_preview.contact')}
      </Button>
    </div>
  );
}
function PublicOfferPreviewFooter() {
  return (
    <div>
      <p>Lännen Maalämpö Oy,</p>
      <p>Puurtajantie 18 ,60100 Seinäjoki</p>
      <p>Y-tunnus 2626239-1, VAT number FI26262391</p>
    </div>
  );
}

function CustomerOfferPreview({ setLoading }) {
  const [offerPreview, setOfferPreview] = useState(null);
  const [customerSignature, setCustomerSignature] = useState(null);

  const [search] = useSearchParams();

  const customHeader = () => <CustomerOfferPreviewHeader />;

  return (
    <div className={`${styles.mainContainer}`}>
      <div className={`${styles.headerContainer}`}>
        <Header component={customHeader} />
      </div>
      <div className={`projectContainer ${styles.customerOfferViewContainer}`}>
        <FinalOfferPreview
          offerPreview={offerPreview}
          setOfferPreview={setOfferPreview}
          customerSignature={customerSignature}
          setCustomerSignature={setCustomerSignature}
          offerRequestId={search.get('offerRequestId')}
          offerId={search.get('offerId')}
          setLoading={setLoading}
        />
      </div>
      <div className={`${styles.footerElement}`}>
        <div className={`${styles.footerContainer}`}>
          <div className="projectContainer d-flex align-items-center">
            <div className={`${styles.contentContainer}`}>
              <PublicOfferPreviewFooter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CustomerOfferPreview.propTypes = {
  setLoading: PropTypes.func.isRequired,
};

export default CustomerOfferPreview;
