import { Image, Text, View } from '@react-pdf/renderer';
import * as moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { signatureTypes, userRoles } from '../../constant';

function SignaturePreview({ signature = 'IMAGE' }) {
  if (!signature) return null;
  return signature.signatureType === signatureTypes.TYPE ? (
    <Text>{signature.signature}</Text>
  ) : (
    <div>
      <Image
        style={{
          width: '100%',
          height: '100%',
          ObjectFit: 'cover',
          display: 'block',
        }}
        src={`/${signature.signature}`}
      />
    </div>
  );
}

function CustomerSignature({ signature, customerDetails }) {
  const { t } = useTranslation('lang');
  return (
    <View
      style={{
        fontSize: '12px',
        color: '#707070',
      }}
    >
      <div
        style={{
          fontWeight: '600',
          marginBottom: '16px',
        }}
      >
        <Text>{t(userRoles.CUSTOMER.label)}:</Text>
      </div>
      <div
        style={{
          height: '30px',
        }}
      >
        {signature && <SignaturePreview signature={signature} />}
      </div>
      <div
        style={{
          fontWeight: '400',
          marginTop: '8px',
          borderTop: '1px solid #707070',
          paddingTop: '8px',
          textTransform: 'capitalize',
        }}
      >
        <Text>
          {customerDetails?.firstName} {customerDetails?.lastName}
        </Text>
      </div>
      {signature && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: 12,
            color: '#C5C5C5',
          }}
        >
          <Text>{t('components.signature.digitally_signed')}:</Text>
          <Text>
            {t('IP')}: {signature.IP}
          </Text>
          <Text>
            {t('components.signature.date_time')}:{' '}
            {moment(signature.signedAt).format('DD.MM.yyyy HH:mm')}
          </Text>
        </div>
      )}
    </View>
  );
}

function SalesPersonSignature({ signature, salesPersonDetails }) {
  const { t } = useTranslation('lang');
  return (
    <div
      style={{
        fontSize: '12px',
        color: '#707070',
      }}
    >
      <div
        style={{
          fontWeight: '600',
          marginBottom: '16px',
        }}
      >
        <Text>{t(userRoles.SALES_PERSON.label)}:</Text>
      </div>
      <div
        style={{
          height: '30px',
        }}
      >
        {signature && <SignaturePreview signature={signature} />}
      </div>
      <div
        style={{
          fontWeight: '400',
          marginTop: '8px',
          borderTop: '1px solid #707070',
          paddingTop: '8px',
          display: 'flex',
          flexDirection: 'column',
          textTransform: 'capitalize',
        }}
      >
        <Text>
          {salesPersonDetails?.firstName} {salesPersonDetails?.lastName}
        </Text>
        <Text style={{ fontSize: 12 }}>Lännen Maalämpö Oy</Text>
      </div>
      {signature && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: 12,
            color: '#C5C5C5',
          }}
        >
          <Text>{t('components.signature.digitally_signed')}:</Text>
          <Text>
            {t('IP')}: {signature.IP}
          </Text>
          <Text>
            {t('components.signature.date_time')}:{' '}
            {moment(signature.signedAt).format('DD.MM.yyyy HH:mm')}
          </Text>
        </div>
      )}
    </div>
  );
}

function OfferSignature({
  customerSignature,
  salesPersonSignature,
  customerDetails,
  salesPersonDetails,
}) {
  return (
    <View>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          marginTop: '48px',
        }}
      >
        <div
          style={{
            flexBasis: '20%',
            marginBottom: '14px',
          }}
        >
          <Text style={{ fontWeight: '700', fontSize: '12px', color: '#707070' }}>
            Allekirjoitukset:
          </Text>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            flexBasis: '80%',
            fontWeight: '600',
            fontSize: '12px',
            // paddingLeft: '16px',
          }}
        >
          <div
            style={{
              flexBasis: '46%',
            }}
          >
            <SalesPersonSignature
              signature={salesPersonSignature}
              salesPersonDetails={salesPersonDetails}
            />
          </div>
          <div
            style={{
              flexBasis: '46%',
            }}
          >
            <CustomerSignature signature={customerSignature} customerDetails={customerDetails} />
          </div>
        </div>
      </div>
    </View>
  );
}

SignaturePreview.propTypes = {
  signature: PropTypes.instanceOf(Object).isRequired,
};

CustomerSignature.propTypes = {
  signature: PropTypes.instanceOf(Object).isRequired,
  customerDetails: PropTypes.instanceOf(Object).isRequired,
};

SalesPersonSignature.propTypes = {
  signature: PropTypes.instanceOf(Object).isRequired,
  salesPersonDetails: PropTypes.instanceOf(Object).isRequired,
};

OfferSignature.propTypes = {
  customerSignature: PropTypes.instanceOf(Object).isRequired,
  salesPersonSignature: PropTypes.instanceOf(Object).isRequired,
  customerDetails: PropTypes.instanceOf(Object).isRequired,
  salesPersonDetails: PropTypes.instanceOf(Object).isRequired,
};

export default OfferSignature;
