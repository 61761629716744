import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './status.module.scss';
import { offerRequestStatuses } from '../../constant';

function Status({ value, statusLabel }) {
  const { t } = useTranslation('lang');

  const getStatusClassName = (status) => {
    switch (status) {
      case offerRequestStatuses.TASK.value:
        return styles.task;
      case offerRequestStatuses.OFFER_COMPLETION.value:
        return styles.completed;
      case offerRequestStatuses.OFFER_SENT.value:
        return styles.offerSent;
      case offerRequestStatuses.OFFER_CALCULATION.value:
        return styles.offerCalculation;
      case offerRequestStatuses.OFFER_CREATION.value:
        return styles.offerCreation;
      case offerRequestStatuses.ON_SITE_INSPECTION.value:
        return styles.onSiteInspection;
      case offerRequestStatuses.DECLINED.value:
        return styles.declined;
      case offerRequestStatuses.NEW.value:
        return styles.new;
      default:
        return '';
    }
  };

  const statusClassName = getStatusClassName(statusLabel);

  return <div className={`${statusClassName} ${styles.statusContainer} `}>{t(value)}</div>;
}

Status.propTypes = {
  value: PropTypes.string.isRequired,
  statusLabel: PropTypes.string.isRequired,
};

export default Status;
