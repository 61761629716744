import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import styles from './index.module.scss';
import useSaveProduct from './useSaveProduct';
import Button from '../../../components/button';
import OfferPageContentWrapper from '../../../components/offer-page-content-wrapper';
import ProductsFlow from '../../../components/products-flow';
import ProfitCalculation from '../../../components/profit-calculation';
import Translate from '../../../components/translate';
import heatingDeviceServices from '../../../services/heatingDevices';
import { getProductsByOffer } from '../../../services/offerProducts';
import { getOriginalErrors } from '../../../utils/errorHandler';
import WithEnableDisableTab from '../withEnableDisableTab';

function ProductsFooter({ onSave, isChanged, onContinue }) {
  const navigate = useNavigate();
  const { offerRequestId, offerId } = useParams();
  const onBack = () =>
    navigate(`/offer-requests/${offerRequestId}/offers/${offerId}/heating-devices`);
  return (
    <div className={`d-flex align-items-center justify-content-end ${styles.btnContainer}`}>
      <div>
        <Button className="projectBtn offerTabFooterBtn offerTabGrayBtn" onClick={onBack}>
          <Translate id="components.button.back" />
        </Button>
      </div>
      <div>
        {isChanged ? (
          <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onSave}>
            <Translate id="components.button.save" />
          </Button>
        ) : (
          <Button className="projectBtn offerTabFooterBtn offerTabGreenBtn" onClick={onContinue}>
            <Translate id="components.button.continue" />
          </Button>
        )}
      </div>
    </div>
  );
}

function Products() {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductsComparison, setSelectedProductsComparison] = useState([]);
  const [heatingDevicePrices, setHeatingDevicePrices] = useState({
    totalPurchasePrice: 0,
    totalSellingPrice: 0,
  });

  const params = useParams();
  const navigate = useNavigate();

  const { onProductSave } = useSaveProduct(params.offerRequestId, params.offerId);

  const fetchProductsByOffer = async () => {
    try {
      const {
        data: {
          data: { components: offerProducts },
        },
      } = await getProductsByOffer(params.offerRequestId, params.offerId);

      setSelectedProducts(offerProducts);
      setSelectedProductsComparison(offerProducts);
    } catch (err) {
      const originalErrors = getOriginalErrors(err);
      originalErrors.forEach((error) => {
        toast.error(error.message);
      });
    }
  };

  const onContinue = () => {
    navigate(`/offer-requests/${params.offerRequestId}/offers/${params.offerId}/services`);
  };

  const saveProducts = async () => {
    const success = await onProductSave(selectedProducts);
    if (success)
      navigate(`/offer-requests/${params.offerRequestId}/offers/${params.offerId}/services`);
  };

  const customFooter = () => (
    <ProductsFooter
      onSave={saveProducts}
      isChanged={JSON.stringify(selectedProducts) !== JSON.stringify(selectedProductsComparison)}
      onContinue={onContinue}
    />
  );

  const fetchHeatingDeviceCalculations = async () => {
    try {
      const {
        data: { data },
      } = await heatingDeviceServices.getOfferHeatingDevicesCalculations({
        requestId: params.offerRequestId,
        offerId: params.offerId,
      });
      if (data) {
        setHeatingDevicePrices(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchHeatingDeviceCalculations();
    fetchProductsByOffer();
  }, []);

  return (
    <OfferPageContentWrapper customFooter={customFooter}>
      <div className={`d-flex flex-column ${styles.productListMainContainer}`}>
        <ProductsFlow
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
        <div className={`${styles.profitCalculation}`}>
          <ProfitCalculation
            items={selectedProducts}
            purchasePriceFieldName="purchasePriceExclusiveVat"
            sellingPriceFieldName="sellingPriceExclusiveVat"
            previousPurchasePrice={heatingDevicePrices.totalPurchasePrice}
            previousSellingPrice={heatingDevicePrices.totalSellingPrice}
          />
        </div>
      </div>
    </OfferPageContentWrapper>
  );
}

ProductsFooter.propTypes = {
  onSave: PropTypes.func.isRequired,
};

export default WithEnableDisableTab(Products);
